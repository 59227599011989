import api from "api";
import { BodyParams } from "api/api";
import { useMutation } from "react-query";

const OP = "POST /invitations/clinic_partner";

export type Vars = BodyParams<typeof OP>;

const useInvitePartner = () =>
  useMutation((data: Vars) => {
    return api.parse(OP, { data });
  });

export default useInvitePartner;
