import React, { ChangeEvent, useState } from "react";
import { isUsMarket } from "utils/market";
import RCMTable from "./components/RCMTable";
import { useRCM } from "./queries/useRCM";
import { Container, TablePagination } from "@material-ui/core";
import RCMHeader from "shared/RCMHeader";
import { Query } from "api";

type Params = Query<"GET /us_insurance_claims">;

const RCM: React.VFC = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<Params["order_by"]>("claim_id");
  const [orderDirection, setOrderDirection] = useState<Params["order_direction"]>("asc");

  const { data } = useRCM({
    rows_per_page: rowsPerPage,
    current_page: currentPage,
    order_by: orderBy,
    order_direction: orderDirection,
  });

  if (!isUsMarket) return null;

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const updateOrderBy = (column: Params["order_by"]) => {
    if (column !== orderBy) {
      setOrderBy(column);
      setOrderDirection("asc");
    } else {
      setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    }
  };

  return (
    <>
      <RCMHeader />
      <Container maxWidth={false}>
        <RCMTable data={data} orderBy={orderBy} orderDirection={orderDirection} updateOrderBy={updateOrderBy} />
        <TablePagination
          component="div"
          count={data?.total_count || 0}
          page={data?.current_page || 0}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </>
  );
};

export default RCM;
