import React from "react";
import { Table, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { TFuncKey, useTranslation } from "react-i18next";
import { Colors } from "theme";
import InvoiceTableBody from "./InvoiceTableBody";

interface HeadCell {
  phraseTitle: TFuncKey;
}

const headCells: HeadCell[] = [
  {
    phraseTitle: "rcm.payer",
  },
  {
    phraseTitle: "rcm.invoice_created",
  },
  {
    phraseTitle: "rcm.invoice_billed",
  },
];

const useStyles = makeStyles(() => ({
  tableContainer: {
    paddingLeft: 28,
  },
  table: {
    minWidth: 250,
    border: "none",
  },
  tableHead: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    marginBottom: 5,
    borderBottom: `2px solid ${Colors.DARK_BLACK_FADED}`,
  },
}));

const InvoiceTable: React.VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headCells.map((headCell, i) => {
                return <TableCell key={`cell-${headCell.phraseTitle}-${i}`}>{t(headCell.phraseTitle)}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <InvoiceTableBody />
        </Table>
      </TableContainer>
    </>
  );
};

export default InvoiceTable;
