import { HFlex, Text, Icon, useErrorContext, Dimmer, Loading } from "shared";
import { css } from "@emotion/css";
import React, { useState } from "react";
import useRecords from "./queries/useRecords";
import useUpdateRecord from "./queries/useUpdateRecord";
import useDeleteRecord from "./queries/useDeleteRecord";
import Cell from "./components/Cell";
import { ArrowDropDown, ArrowDropUp, Close } from "@material-ui/icons";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Head from "./components/Head";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { join } from "utils/misc";
import { Area } from "types";
import { useSearchParams } from "utils/hooks";
import useRegistry from "./useRegistry";

enum Order {
  Asc = "asc",
  Desc = "desc",
}

interface View {
  filter: string[];
  sortBy: string;
  order: Order;
}

export interface Registry {
  name: string;
  api: string;
  columns: Column[];
  filter?: string[];
}

export enum Input {
  Text = "text",
  Number = "number",
  Checkbox = "checkbox",
  Date = "date",
}

export type Row = Record<string, any>;

export interface Column {
  name: string;
  label: string;
  type?: Input;
  required?: boolean;
  editable?: boolean;
}

const flip = (order: Order) => {
  if (order === Order.Asc) return Order.Desc;
  return Order.Asc;
};

const byColumn = (column: string, order: Order) => (a: Row, b: Row) => {
  const str1 = String(a[column]).toLowerCase();
  const str2 = String(b[column]).toLowerCase();
  const dir = order === Order.Asc ? 1 : -1;
  if (str1 === str2) return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1;
  return str1 > str2 ? dir : -dir;
};

const ValueTable: React.VFC = () => {
  const history = useHistory();
  const location = useLocation();
  const { columns, filter: defaultFilter } = useRegistry();
  const { data: records, isLoading, isSuccess } = useRecords();
  const { updateRecord } = useUpdateRecord();
  const { deleteRecord } = useDeleteRecord();
  const [highlighted, setHighlighted] = useState<number | null>(null);
  const setError = useErrorContext();
  const { t } = useTranslation();

  const availableColumns = columns.map(({ name }) => name);

  const { sortBy, order } = useSearchParams(
    { sortBy: columns[0].name, order: Order.Asc },
    { sortBy: availableColumns, order: Object.values(Order) }
  );

  const urlParsedFilter = location.search
    .slice(1)
    .split("&")
    .filter(f => availableColumns.includes(f));

  const filter = (urlParsedFilter.length && urlParsedFilter) || defaultFilter || availableColumns;
  const filteredColumns = columns.filter(({ name }) => filter.includes(name));
  const sorted = records?.slice(0).sort(byColumn(sortBy, order));
  const isDeleteMode = Number.isInteger(highlighted);

  const changeView = ({ filter, sortBy, order }: View) => {
    history.push("?" + filter.join("&") + `&sortBy=${sortBy}&order=${order}`);
  };

  const changeRegistry = (registry: string) => {
    history.push(join(Area.Registry, registry));
  };

  const deleteRow = async (row: number) => {
    if (!isDeleteMode) return setHighlighted(row);
    setHighlighted(null);
    try {
      await deleteRecord(sorted![row].value);
    } catch (err) {
      setError(t`errors.could_not_delete_record`, err);
    }
  };

  const updateRow = async (row: Row) => {
    try {
      await updateRecord(row);
    } catch (err) {
      setError(t`errors.could_not_update_record`, err);
    }
  };

  const tableEm = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {filteredColumns.map(({ name, label }) => {
              const isPrimary = sortBy === name;
              return (
                <TableCell variant="head" key={label}>
                  <HFlex key={label} alignItems="center">
                    <Text
                      role="button"
                      onClick={() => changeView({ filter, sortBy: name, order: isPrimary ? flip(order) : Order.Asc })}
                    >
                      {label}
                    </Text>
                    {isPrimary && <Icon size={1.7} of={order === Order.Asc ? ArrowDropUp : ArrowDropDown} />}
                  </HFlex>
                </TableCell>
              );
            })}
            <TableCell variant="head" align="right">
              {t`buttons.delete`}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted?.map((record, row) => {
            const isHighlighted = highlighted === row;
            return (
              <TableRow key={record.value} {...(isHighlighted ? { className: styles.highlight } : { hover: true })}>
                {filteredColumns.map(column => (
                  <Cell
                    key={column.name}
                    editable={column.editable}
                    type={column.type}
                    value={record[column.name]}
                    onChange={value => updateRow({ ...record, [column.name]: value })}
                  />
                ))}
                <TableCell align="right">
                  <Icon
                    size={1.5}
                    role="button"
                    onClick={() => deleteRow(row)}
                    of={Close}
                    color={isHighlighted ? "error" : "secondary"}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="lg">
      <Head
        filtered={filteredColumns}
        onFilterChange={columns => changeView({ filter: columns, sortBy, order })}
        onRegistryChange={changeRegistry}
      />
      {isLoading && <Loading />}
      {isSuccess && tableEm}
      {isDeleteMode && <Dimmer zIndex={1} onClick={() => setHighlighted(null)} />}
    </Container>
  );
};

export default ValueTable;

const styles = {
  highlight: css`
    position: relative;
    z-index: 2;
    background-color: white;
  `,
};
