import React from "react";
import { Error as ErrorIcon, CheckCircle } from "@material-ui/icons";
import { HFlex, Icon, Text } from "shared";
import { Tooltip } from "@material-ui/core";
import useInsuranceProfile, { ClaimOrderType } from "routes/patient/queries/useInsuranceProfile";
import { useTranslation } from "react-i18next";

interface Props {
  claim_order_type: ClaimOrderType;
}

const InsuranceSummary: React.VFC<Props> = ({ claim_order_type }) => {
  const { t } = useTranslation();
  const profiles = useInsuranceProfile();
  const profile = profiles.data?.find(insurance => insurance.claim_order_type === claim_order_type);

  const { us_insurance_company, status } = profile ?? {};

  return (
    <HFlex minWidth={0} alignItems="center">
      <Text light nowrap>
        {claim_order_type === "secondary" ? t`insurance.secondary` : t`insurance.primary`}:
      </Text>
      <Text bold nowrap truncate mx={1}>
        {us_insurance_company?.payer?.name ?? t`insurance.none`}
      </Text>
      {status &&
        (status === "eligible" ? (
          <Icon successLight of={CheckCircle} size={1.2} data-testid="eligible" />
        ) : (
          <Tooltip placement="top" title={status}>
            <Icon errorLight of={ErrorIcon} data-testid="ineligible" />
          </Tooltip>
        ))}
    </HFlex>
  );
};

export default InsuranceSummary;
