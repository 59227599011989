import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { HFlex, Pane, Text } from "shared";
import { Area } from "types";
import { Link } from "react-router-dom";
import { join } from "utils/misc";
import { PTSummary } from "..";
import { useTranslation } from "react-i18next";
import { Active, Deleted } from "./";
import usePatient from "routes/patient/queries/usePatient";

export const Status: React.VFC = () => {
  const { t } = useTranslation();
  const { data: patient } = usePatient();
  if (!patient) return null;
  const { deleted_at, sibling_accounts } = patient;

  return (
    <Pane data-testid="status">
      {sibling_accounts?.length ? (
        <Accordion disabled={!sibling_accounts.length}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <HFlex alignItems="center" flexGrow={1}>
              {deleted_at ? <Deleted mb={0} /> : <Active mb={0} />}
              <Text ml="auto">+{sibling_accounts.length}</Text>
            </HFlex>
          </AccordionSummary>
          <AccordionDetails data-testid="sibling-accounts">
            <ul>
              {sibling_accounts.map(({ id, deleted_at }) => (
                <li key={id}>
                  <Pane dark px={1.5} py={1} display="flex" alignItems="center" justifyContent="space-between">
                    <div style={{ transform: "scale(0.91)", overflow: "auto" }}>
                      {deleted_at ? <Deleted patientId={id} dense /> : <Active patientId={id} dense />}
                      <PTSummary patientId={id} />
                    </div>
                    <Link to={join(Area.Patients, id)}>
                      <Text caption link>
                        {t("patients.handle")}
                      </Text>
                    </Link>
                  </Pane>
                </li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ) : deleted_at ? (
        <Deleted mb={0} />
      ) : (
        <Active mb={0} />
      )}
    </Pane>
  );
};
