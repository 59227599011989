import { join, getIDToken, refreshTokenIfNeeded } from "utils/misc";
import { paths } from "./__generatedTypes";

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
type Path = keyof paths;
type MethodOf<E extends Operation> = E extends `${infer R} ${string}` ? R : never;
type PathOf<E extends Operation> = E extends `${string} ${infer R}` ? R : E;

export type Operation<S extends Method = Method, T extends Path = Path> = paths extends {
  [path in infer P]: any;
}
  ? P extends T
    ? paths[P] extends {
        [method in infer M]: Object;
      }
      ? M extends string
        ? M extends Lowercase<S>
          ? `${Uppercase<M>} ${P}`
          : never
        : never
      : never
    : never
  : never;

export type Result<E extends Operation> = paths extends {
  [prop in PathOf<E>]: {
    [prop in Lowercase<MethodOf<E>>]: {
      responses: {
        200: {
          schema: infer R;
        };
      };
    };
  };
}
  ? R
  : never;

export type Query<E extends Operation> = paths extends {
  [prop in PathOf<E>]: {
    [prop in Lowercase<MethodOf<E>>]: {
      parameters: {
        query: infer R;
      };
    };
  };
}
  ? R
  : never;

export type BodyParams<E extends Operation> = paths extends {
  [prop in PathOf<E>]: {
    [prop in Lowercase<MethodOf<E>>]: {
      parameters: {
        body: infer R;
      };
    };
  };
}
  ? R extends { params?: infer R2 }
    ? R2
    : never
  : never;

export type Form<E extends Operation> = paths extends {
  [prop in PathOf<E>]: {
    [prop in Lowercase<MethodOf<E>>]: {
      parameters: {
        formData: infer R;
      };
    };
  };
}
  ? R
  : never;

type Params = Array<string | number>;

interface Options<D = any> {
  data?: D;
  method?: string;
  headers?: Headers;
  root?: string;
}

interface XOptions<E extends Operation> extends Options<BodyParams<E> | FormData> {
  params?: Params;
  query?: Query<E>;
}

export interface APIError {
  status?: number;
  method?: string;
  url?: string;
  details?: string[];
}

const isParams = (opt: any): opt is Params => Array.isArray(opt);

const interpolate = (path: string, params: Params) => {
  const parameters = [...params];
  return path.replaceAll(/\{.+?\}/g, () => String(parameters.shift()));
};

const toSearchParams = (query: Record<string, any>) =>
  new URLSearchParams(
    Object.entries(query).reduce(
      (acc, [key, value]) => (value === undefined ? acc : { ...acc, [key]: String(value) }),
      Object.create(null)
    )
  );

// Abstraction on top of "request" for type checking the request anatomy against the API spec.
// Accepts an endpoint argument like "GET /patients/{id}"
// Optionally accepts a params array for path interpolation and query key/value pairs.

const api = <M extends Method, P extends Path, E extends Operation<M, P>>(op: E, opt?: XOptions<E> | Params) => {
  const result = op.match(/(GET|POST|PUT|PATCH|DELETE)\s(.+)/);
  if (!result) throw Error(`Invalid endpoint format`);
  const [, method, path] = result;

  if (!opt) return call(path, { method });

  if (isParams(opt)) {
    return call(interpolate(path, opt), { method });
  }

  let p: string = path;
  const { params, query, ...restOpt } = opt;

  if (params) {
    p = interpolate(p, params);
  }

  if (query) {
    p = join(p, toSearchParams(query as Record<string, any>));
  }

  return call(p, { ...restOpt, method });
};

// Wrapper around "api" For JSON parsing

api.parse = async <M extends Method, P extends Path, E extends Operation<M, P>>(op: E, opt?: XOptions<E> | Params) => {
  const response = await api(op, opt);
  const data: Result<E> = await response.json();
  return data;
};

// Simple request. Default method is GET or POST if "data" exists in options

const call = async (path: string, { data, headers = new Headers(), ...opt }: Options = {}) => {
  await refreshTokenIfNeeded();

  const isEmpty = data === undefined;
  const method = opt.method ?? (isEmpty ? "GET" : "POST");
  const token = getIDToken();
  const root = opt.root ?? process.env.REACT_APP_API_URL!;
  if (token) headers.append("Authorization", "Bearer " + token);
  if (!isEmpty && !headers.get("Content-Type") && !(data instanceof FormData)) {
    headers.append("Content-Type", "application/json");
    data = JSON.stringify(data);
  }

  let response: Response;

  try {
    response = await fetch(join(root, path), { method, headers, body: data });
  } catch (err) {
    throw Error("Could not reach server");
  }

  if (response.ok) return response;
  const errorBody: APIError = { method, url: response.url, status: response.status };
  let body: any;

  try {
    body = await response.json();
  } catch (err) {
    throw Error(JSON.stringify(errorBody));
  }

  const details = body?.error ?? body?.errors;
  if (details) errorBody.details = [details].flat().filter(Boolean) as string[];

  throw Error(JSON.stringify(errorBody));
};

call.parse = async (path: string, { data, headers = new Headers(), ...opt }: Options = {}) => {
  const response = await call(path, opt);
  return response.json();
};

api.call = call;

export default api;
