import api from "api";
import { queryKeys } from "utils/misc";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";

export const useUkDischargeLetter = () => {
  const id = useId();
  const key = queryKeys.ukDischargeLetter(id);
  //   @ts-ignore
  return useQuery(key, () => api.parse("GET /patients/{patient_id}/uk_discharge_letter", [id]));
};
