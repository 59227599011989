import React from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import useFrInvoices from "routes/other/queries/useFrInvoices";

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    marginBottom: 5,
    borderBottom: "2px solid rgba(38,50,56, 0.16)",
  },
  table: {
    minWidth: 250,
    marginBottom: 200,
    border: "none",
  },
  row: {
    borderTop: "1px solid rgba(38,50,56, 0.16)",
    "&:nth-of-type(2n + 0)": {
      backgroundColor: "rgba(80,150,232, 0.04)",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface FrInvoicesTableProps {
  month: string;
}

const FrInvoicesTable: React.VFC<FrInvoicesTableProps> = ({ month }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, isLoading } = useFrInvoices(`${month}-15`);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>{t("fr_invoices.table.therapist")}</TableCell>
            <TableCell>{t("fr_invoices.table.care_events")}</TableCell>
            <TableCell>{t("fr_invoices.table.video_calls")}</TableCell>
            <TableCell>{t("fr_invoices.table.supplementary")}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody style={{ width: "100%" }}>
          {isLoading && (
            <TableRow style={{ border: "none" }}>
              <TableCell colSpan={4}>
                <div className={classes.loader}>
                  <CircularProgress size={60} />
                </div>
              </TableCell>
            </TableRow>
          )}

          {data?.therapists &&
            data.therapists.map(({ full_name, care_events, video_calls, supplementary }, i) => (
              <TableRow key={`pt-headcell-${i}`} className={classes.row}>
                <TableCell>{full_name}</TableCell>
                <TableCell>{care_events}</TableCell>
                <TableCell>{video_calls}</TableCell>
                <TableCell>{supplementary}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FrInvoicesTable;
