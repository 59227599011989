import api from "api";
import { useMutation, useQueryClient } from "react-query";
import { join, queryKeys } from "utils/misc";

export const useDeleteUnclaimedMedicalReferral = () => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.unclaimedMedicalReferrals();

  return useMutation((id: number) => api.call(join("/unclaimed_medical_referrals/", id), { method: "DELETE" }), {
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};
