import { Switch } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { Pane, useErrorContext, Text } from "shared";
import { useTranslation } from "react-i18next";
import useTherapist from "../queries/useTherapist";
import useToggleVideoCalls from "../queries/useToggleVideoCalls";
import { queryKeys } from "utils/misc";

const ToggleVideoCalls: React.VFC = () => {
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { t } = useTranslation();
  const { mutateAsync: toggleVideoCalls } = useToggleVideoCalls();
  const { data: pt } = useTherapist();

  if (!pt) return null;

  const { accepting_video_calls } = pt;

  const toggle = async () => {
    const ptKey = queryKeys.therapist(pt.id);
    const rollbackData = queryClient.getQueryData(ptKey);
    queryClient.cancelQueries(ptKey);
    queryClient.setQueryData(ptKey, old => Object.assign(old, { accepting_video_calls: !accepting_video_calls }));

    try {
      await toggleVideoCalls(!accepting_video_calls);
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      queryClient.invalidateQueries(ptKey);
      setErrors(t`errors.could_not_change_video_call_preferences`, err);
    }
  };

  return (
    <Pane
      component="label"
      role="button"
      dark
      py={1}
      display="flex"
      alignItems="center"
      aria-label="Toggle video calls"
    >
      <Text light small>
        {t("form.video_calls")}
      </Text>
      <Text small ml="auto">
        {accepting_video_calls ? t`buttons.on` : t`buttons.off`}
      </Text>
      <Switch color="primary" checked={pt.accepting_video_calls} onChange={toggle} />
    </Pane>
  );
};

export default ToggleVideoCalls;
