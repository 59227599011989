import api, { BodyParams } from "api";
import { useMutation } from "react-query";
import { Country } from "utils/market";

const OP = "POST /invitations/therapist";

export type Vars = BodyParams<typeof OP>;

const useInviteTherapist = () =>
  useMutation(
    ({
      available_for_patients,
      country = Country.US,
      email,
      first_name,
      health_care_provider_name,
      health_care_provider_new,
      health_care_provider_invitation_payer_id,
      language_code,
      last_name,
      timezone,
      us_licenses,
      profession,
    }: Vars) =>
      api(OP, {
        data: {
          available_for_patients,
          country,
          email,
          first_name,
          health_care_provider_name,
          health_care_provider_new,
          health_care_provider_invitation_payer_id,
          language_code,
          last_name,
          timezone,
          us_licenses,
          profession,
        },
      })
  );

export default useInviteTherapist;
