import { Circle } from "assets";
import { useTranslation } from "react-i18next";
import usePatient from "routes/patient/queries/usePatient";
import { Text, TextProps, Icon } from "shared";
import { getDate } from "utils/time";

interface Props extends TextProps {
  patientId?: number;
}

export const Active: React.VFC<Props> = ({ patientId, ...rest }) => {
  const { t } = useTranslation();
  const { data: patient } = usePatient(patientId);
  if (!patient) return null;
  const { id, created_at } = patient;

  return (
    <Text paragraph {...rest}>
      <Icon of={Circle} success size={0.7} aria-label="Active account" />
      <Text ml={1} bold>
        {id}
      </Text>
      <Text light ml={2}>
        {t`patients.created`}:{" "}
      </Text>
      <Text bold>{created_at && getDate(created_at)}</Text>
    </Text>
  );
};
