import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useSubstituteTherapist = () => {
  const id = useId();

  return useMutation((substitute_therapist_id: number | null) =>
    api("PUT /patients/{id}/substitute_therapist", {
      params: [id],
      data: { substitute_therapist_id },
    } as any)
  );
};

export default useSubstituteTherapist;
