import api from "api";
import { Query } from "api/api";
import { useQuery } from "react-query";
import { queryKeys } from "utils/misc";

const OP = "GET /us_insurance_claims";

export type Vars = Query<typeof OP>;

export const useRCM = (vars: Vars) => useQuery(queryKeys.claims(vars), () => api.parse(OP, { query: vars }));
