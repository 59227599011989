import React from "react";
import styled from "styled-components";
import { ButtonBase, ButtonBaseProps } from "@material-ui/core";
import { Shadows } from "theme";

interface Props extends ButtonBaseProps {
  startIcon: React.ReactNode;
}

const BoxButton: React.VFC<Props> = ({ startIcon, children, ...rest }) => {
  return (
    <StyledButton {...rest}>
      {startIcon}
      {children}
    </StyledButton>
  );
};

export default BoxButton;

const StyledButton = styled(ButtonBase)`
  height: 60px;
  width: 100%;
  justify-content: flex-start;
  padding: 0 25px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: ${Shadows.soft};
  border-radius: 8px;
  color: #384a5f;
  font-size: 18px;
  font-weight: 600;
  transition-duration: 250ms;

  &:hover {
    background-color: #fbfbfb;
    box-shadow: ${Shadows.sharp};
  }

  & > *:first-child {
    margin-right: 25px;
  }
`;
