import React, { useState } from "react";
import { Button, Container } from "@material-ui/core";
import { VFlex, ProgressButton, Text, CAutocomplete, DialogSuccessNotice, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { delay, fullName, queryKeys } from "utils/misc";
import usePatient from "routes/patient/queries/usePatient";
import usePotentialProviderGroups from "routes/patient/queries/usePotentialProviderGroups";
import useUpdateProviderGroup from "routes/patient/queries/useUpdateProviderGroup";
import { useQueryClient } from "react-query";
import { FORM_NOTIFICATION_DELAY } from "theme";
export interface FormVars {
  readonly us_provider_group_id: number;
}

interface Props {
  onClose: () => void;
}

const UpdateProviderGroupForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<FormVars>();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [updatingProivderGroup, setIsUpdatingProviderGroup] = useState(false);
  const queryClient = useQueryClient();
  const { data: patient } = usePatient();
  const setErrors = useErrorContext();
  const { mutateAsync: updateProviderGroup } = useUpdateProviderGroup();
  const { data: providerGroups = [], isLoading: loadingProviderGroups } = usePotentialProviderGroups();
  if (!patient) return null;

  const submit = form.handleSubmit(async ({ us_provider_group_id }) => {
    try {
      setIsUpdatingProviderGroup(true);
      // @ts-ignore
      await updateProviderGroup({ us_provider_group_id });
      setSuccess(true);
      queryClient.invalidateQueries(queryKeys.providerGroupProfile(patient.id));
      await delay(FORM_NOTIFICATION_DELAY);
      onClose();
    } catch (error) {
      setErrors(t`provider_group.could_not_update`, error);
    } finally {
      setIsUpdatingProviderGroup(false);
    }
  });

  if (success) return <DialogSuccessNotice heading={t`provider_group.updated`} onClose={onClose} />;
  return (
    <Container>
      <Text heading bold id="update-provider-group">
        {t`provider_group.update`}
      </Text>
      <Text paragraph mb={4}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <VFlex>
            <CAutocomplete
              loading={loadingProviderGroups}
              getOptionLabel={option => option.name || "UNKNOWN"}
              getOptionValue={option => option.id}
              margin="normal"
              options={providerGroups}
              label={t`provider_group.label`}
              name="us_provider_group_id"
              fullWidth
              required
            />
          </VFlex>
          <VFlex alignItems="center" gap={2} my={5}>
            <ProgressButton loading={updatingProivderGroup}>{t`buttons.save`}</ProgressButton>
            <Button disabled={updatingProivderGroup} onClick={onClose}>{t`buttons.cancel`}</Button>
          </VFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default UpdateProviderGroupForm;
