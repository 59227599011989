import api, { BodyParams } from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "POST /invitations/patient";

export type Vars = BodyParams<typeof OP>;

const useInvitePatient = () => {
  const therapist_id = useId();

  return useMutation(({ email, payer_name }: Vars) =>
    api.parse(OP, {
      data: { email, payer_name, therapist_id },
    })
  );
};

export default useInvitePatient;
