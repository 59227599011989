import React, { useEffect, useState } from "react";
import { CDialog, Loading } from "shared";
import { useTranslation } from "react-i18next";
import { Delete } from "@material-ui/icons";
import { Button, Container, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { UnclaimedMedicalReferral, useUnclaimedMedicalReferrals } from "./queries/useUnclaimedMedicalReferrals";
import { ConfirmDeletion } from "./ConfirmDeletion";

const columns = ["id", "patient_first_name", "patient_last_name"];

export const UnclaimedMedicalReferrals: React.VFC = () => {
  const { data, isLoading, isSuccess } = useUnclaimedMedicalReferrals();
  const { t } = useTranslation();
  const [unclaimedReferrals, setUnclaimedReferrals] = useState<UnclaimedMedicalReferral[]>();
  const [searchString, setSearchString] = useState("");

  const [isOpen, setOpen] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState<UnclaimedMedicalReferral | null>(null);

  useEffect(() => {
    if (data?.unclaimed_medical_referrals) {
      if (searchString) {
        const segments = searchString.trim().toLowerCase().split(" ");

        const filteredUnclaimedReferrals = data.unclaimed_medical_referrals.filter(referral => {
          return segments.every(segment => {
            return columns.some(column =>
              // TODO: fix ts-ignore later
              // @ts-ignore
              referral[column].toString().toLowerCase().includes(segment.toLocaleLowerCase())
            );
          });
        });

        setUnclaimedReferrals(filteredUnclaimedReferrals);
      } else {
        setUnclaimedReferrals(data.unclaimed_medical_referrals);
      }
    }
  }, [data, searchString]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };

  const handleClick = (referral: UnclaimedMedicalReferral) => {
    setSelectedReferral(referral);
    openDialog();
  };

  const closeDialog = () => {
    setSelectedReferral(null);
    setOpen(false);
  };

  const openDialog = () => setOpen(true);

  const tableEm = (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell>{t("patients.id")}</TableCell>
          <TableCell>{t("partner_patients.name")}</TableCell>
          <TableCell>{t("partner_referral_table.referral_issued")}</TableCell>
          <TableCell>{t("partner_referral_table.duration")}</TableCell>
          <TableCell>{t("buttons.delete")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {unclaimedReferrals?.map(referral => {
          return (
            <TableRow key={referral.id} hover>
              <TableCell>{referral.id}</TableCell>
              <TableCell>{`${referral.patient_first_name} ${referral.patient_last_name}`}</TableCell>
              <TableCell>{referral.created_at}</TableCell>
              <TableCell>{referral.duration_days}</TableCell>
              <TableCell>
                <Button onClick={() => handleClick(referral)}>
                  <Delete />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <Container maxWidth="lg">
      <TextField id="search-string" label="Search" onChange={handleChange} value={searchString} />
      {isLoading && <Loading />}
      {isSuccess && tableEm}

      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="reopen-account">
        <ConfirmDeletion onClose={closeDialog} selectedReferral={selectedReferral} />
      </CDialog>
    </Container>
  );
};
