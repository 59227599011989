import React, { useEffect, useState } from "react";
import api, { Result } from "api";
import CareEventConfigurationForm from "../CareEventConfigurationForm/CareEventConfigurationForm";

const CreateCareEventConfiguration = () => {
  const [data, set] = useState<Result<"GET /care_event_configurations/new"> | undefined>();

  useEffect(() => {
    const initData = async () => {
      const response = await api.parse("GET /care_event_configurations/new");
      set(response);
    };
    initData();
  }, []);

  if (!data) return null;
  return <CareEventConfigurationForm create careEventConfiguration={data} />;
};

export default CreateCareEventConfiguration;
