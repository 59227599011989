import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { CDialog, HFlex } from "shared";
import ChangeTherapistForm from "./ChangeTherapistForm";
import RemoveSubstituteTherapistForm from "./RemoveSubstituteTherapistForm";
import { useTranslation } from "react-i18next";
import usePatient from "routes/patient/queries/usePatient";

const ChangeTherapist: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const [isSubstituteOpen, setSubstituteOpen] = useState(false);
  const { t } = useTranslation();
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);
  const { data: patient } = usePatient();
  if (!patient) return null;

  const openSubstituteDialog = () => setSubstituteOpen(true);
  const closeSubstituteDialog = () => setSubstituteOpen(false);

  return (
    <HFlex alignItems="center" gap={2}>
      {!!patient.substitute_therapist && (
        <>
          <Button onClick={openSubstituteDialog} aria-label="Remove substitute PT">
            {t("change_therapist.remove_substitute")}
          </Button>
          <CDialog
            maxWidth="xs"
            fullWidth
            open={isSubstituteOpen}
            onClose={closeSubstituteDialog}
            aria-labelledby="remove-substitute-pt"
          >
            <RemoveSubstituteTherapistForm onClose={closeSubstituteDialog} />
          </CDialog>
        </>
      )}
      <Button onClick={openDialog} aria-label="Change PT">
        {t("buttons.change")}
      </Button>
      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="change-pt">
        <ChangeTherapistForm onClose={closeDialog} />
      </CDialog>
    </HFlex>
  );
};

export default ChangeTherapist;
