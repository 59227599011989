import React from "react";
import { Box, Button, Container } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import { HFlex, VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import useCloseTherapistAccount from "../../queries/useCloseTherapistAccount";
import useTherapist from "routes/therapist/queries/useTherapist";
import { fullName, queryKeys } from "utils/misc";

interface Props {
  onClose: () => void;
}

const CloseAccountForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const { mutateAsync, isLoading } = useCloseTherapistAccount();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { data: pt } = useTherapist();
  if (!pt) return null;

  const submit = handleSubmit(async () => {
    try {
      await mutateAsync(pt.id);
      queryClient.invalidateQueries(queryKeys.therapist(pt.id));
      queryClient.invalidateQueries(queryKeys.therapistSearch());
      onClose();
    } catch (err) {
      setErrors(t`errors.could_not_close_account`, err);
    }
  });

  return (
    <Container>
      <HFlex justifyContent="center" mb={2} mt={4}>
        <CancelRounded style={{ color: "#ff8585", width: 80, height: 80 }} />
      </HFlex>
      <Text heading bold center id="close-account">
        {t("patients.end_treatment_form.close_account")}
      </Text>
      <Text center paragraph mb={3}>
        {fullName(pt)}, <Text link>{pt.id}</Text>
      </Text>
      <Box mx={2}>
        <form onSubmit={submit}>
          {/* TODO: Enable when BE support reason */}
          {/*<CTextField*/}
          {/*  margin="normal"*/}
          {/*  size="small"*/}
          {/*  fullWidth*/}
          {/*  multiline*/}
          {/*  name="reason"*/}
          {/*  placeholder="Reason"*/}
          {/*  control={control}*/}
          {/*  noClear*/}
          {/*  required*/}
          {/*/>*/}
          <VFlex alignItems="center" gap={2} my={5}>
            <ProgressButton loading={isLoading} type="submit" variant="contained" color="primary">
              {t("patients.end_treatment_form.close_account")}
            </ProgressButton>
            <Button disabled={isLoading} onClick={onClose}>
              {t("buttons.cancel")}
            </Button>
          </VFlex>
        </form>
      </Box>
    </Container>
  );
};

export default CloseAccountForm;
