import { default as React, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { ReportTypeResponse, getReportTypes, convertReportContent } from "./queries/reportTypes";
import Reports from "./Reports";
import ReportsDrawer from "./ReportsDrawer";
import ErrorComponent from "./components/ReportErrorComponent";
import LoadingComponent from "./components/ReportLoadingComponet";
import { DrawerContents } from "./components/DrawerContent";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Layout: React.VFC = () => {
  const { data, error, isLoading } = useQuery<ReportTypeResponse, Error, DrawerContents[]>(
    ["no empty arrays here"],
    getReportTypes,
    {
      select: convertReportContent,
    }
  );
  const [activeValue, setActiveValue] = useState<string>();

  if (error) return <ErrorComponent error={error} />;
  if (data === undefined || isLoading) return <LoadingComponent />;
  return (
    <Container>
      <ReportsDrawer drawerContent={data} activeValue={activeValue ?? ""} setActiveValue={setActiveValue} />
      {activeValue !== undefined && <Reports activeReport={activeValue} />}
    </Container>
  );
};

export default Layout;
