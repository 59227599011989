import styled from "styled-components";
import { Colors } from "theme";
import { Area } from "types";
import { join } from "utils/misc";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: ${Colors.JA_BLUE};
`;

interface TherapistLinkProps {
  readonly therapistId: string | number | null;
}
const TherapistLink: React.VFC<TherapistLinkProps> = ({ therapistId }) => (
  <StyledLink to={join(Area.Therapists, therapistId ?? "")}>Therapist: {therapistId}</StyledLink>
);

export default TherapistLink;
