import React from "react";
import { List } from "@material-ui/core";
import TreatmentActivationAccordion from "./TreatmentActivationAccordion";
import type { Joint, TreatmentOption, TreatmentActiveScope } from "../../queries/marketTreatmentAvailability";

interface Props {
  treatmentOptions: TreatmentOption[];
  onClickActivate: (scope: TreatmentActiveScope, joint: Joint) => void;
}

const TreatmentList: React.VFC<Props> = ({ treatmentOptions, onClickActivate }) => (
  <List>
    {treatmentOptions.map((treatmentOption: TreatmentOption) => (
      <TreatmentActivationAccordion
        key={treatmentOption.joint}
        treatmentOption={treatmentOption}
        onClickActivate={scope => onClickActivate(scope, treatmentOption.joint)}
      />
    ))}
  </List>
);

export default TreatmentList;
