import React from "react";
import { Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import { ChangeTherapist, Insurances } from "./";
import usePatient from "../queries/usePatient";
import { isUsMarket } from "utils/market";
import ExemptionCard from "./ExemptionCard";
import { fullName } from "utils/misc";
import ProviderGroup from "./ProviderGroups/ProviderGroup";
import { paneStyles } from "shared/styles";
import { PremiumIcon } from "shared";

const Treatment: React.VFC = () => {
  const { t } = useTranslation();
  const { data: patient } = usePatient();
  if (!patient) return null;
  const { therapist, substitute_therapist } = patient;

  return (
    <div data-testid="treatment">
      <Text paragraph caption secondary>
        {t`patients.treatment`}
      </Text>

      <Pane dark>
        <Text light mr={1}>
          {t("patients.ailment")}:
        </Text>
        <Text bold>{patient?.health_journal?.ailment}</Text>
      </Pane>

      <Pane dark>
        <Text light mr={1}>
          {t("patients.header_info.most_painful_joint_short")}:
        </Text>

        {patient?.most_painful_joint?.lateral_location && (
          // @ts-ignore
          <Text bold>{t(`patients.lateral_location.${patient?.most_painful_joint?.lateral_location}`)} </Text>
        )}

        {patient?.most_painful_joint?.location ? (
          // @ts-ignore
          <Text bold>{t(`patients.pain_location.${patient?.most_painful_joint?.location}`)}</Text>
        ) : (
          <Text bold>N/A</Text>
        )}
      </Pane>

      <Pane dark>
        <Text light mr={1}>
          {t`patients.state`}:
        </Text>
        <Text bold>
          {
            // @ts-ignore
            t(`patient.journey_state.${patient.state}`)
          }
        </Text>
      </Pane>

      <Pane dark>
        <Text light mr={1}>
          {t("patient.discharged")}:
        </Text>
        <Text bold>
          {patient.in_self_care ? t("patient.journey_in_selfcare.true") : t("patient.journey_in_selfcare.false")}
        </Text>
      </Pane>

      <Pane dark style={{ display: "flex", alignItems: "center" }}>
        <Text light mr={1}>
          {t("patient.premium_type")}:
        </Text>
        {patient.premium_type && (
          <>
            <Text bold>{t(`patient.premium_types.${patient.premium_type}`)}</Text>
            <PremiumIcon premiumType={patient.premium_type} size="big" margin="0 0 0 16px" />
          </>
        )}
      </Pane>

      {isUsMarket && <Insurances />}
      {isUsMarket && <ProviderGroup />}
      {!isUsMarket && <ExemptionCard />}

      <Pane dark mb={2} className={paneStyles.grid}>
        <Text light>{substitute_therapist ? t`substitute_therapist` : t`patients.roles.therapist`}:</Text>
        <Text bold>{fullName(substitute_therapist ?? therapist)}</Text>
        <ChangeTherapist />
      </Pane>
    </div>
  );
};

export default Treatment;
