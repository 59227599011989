import api from "api";
import { useQuery } from "react-query";
import { Row } from "../ValueTable";
import useRegistry from "../useRegistry";

const useRecords = <R extends Row>(overrideRegistry?: string) => {
  const registry = useRegistry(overrideRegistry);
  return useQuery<R[]>(registry.name, () => api.call.parse(registry.api));
};

export default useRecords;
