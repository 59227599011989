import { Box, ButtonBase } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import produce from "immer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useDeleteABN from "routes/patient/queries/useDeleteABN";
import useDeleteReferral from "routes/patient/queries/useDeleteReferral";
import useDeletePOC from "routes/patient/queries/useDeletePOC";
import usePatient from "routes/patient/queries/usePatient";
import { ErrorBox, Pane, Text, Loading, useErrorContext, HFlex, Icon, Dimmer } from "shared";
import { isUsMarket } from "utils/market";
import { queryKeys } from "utils/misc";
import useDocuments, { OP } from "../../queries/useDocuments";
import ABN from "./ABN";
import POC from "./POC";
import Referral from "./Referral";
import { definitions, Result } from "api";

type Documents = Result<typeof OP>;

namespace Types {
  export type ABN = definitions["advanced_beneficiary_notice"];
  export type MedicalReferral = definitions["medical_referral"];
  export type PlanOfCare = definitions["plan_of_care"];
}

const isABN = (doc: any): doc is Types.ABN => "option" in doc;
const isReferral = (doc: any): doc is Types.MedicalReferral => "icd_codes" in doc;
const isPOC = (doc: any): doc is Types.PlanOfCare => "date_of_service" in doc;

const CurrentDocuments: React.VFC = () => {
  const { data, isLoading, error, isSuccess } = useDocuments();
  const { t } = useTranslation();
  const { data: patient } = usePatient();
  const { mutateAsync: deleteABN } = useDeleteABN();
  const { mutateAsync: deleteReferral } = useDeleteReferral();
  const { mutateAsync: deletePOC } = useDeletePOC();
  const setError = useErrorContext();
  const queryClient = useQueryClient();
  const [deleteMode, setDeleteMode] = useState(false);

  if (!patient || !isUsMarket) return null;

  if (!isSuccess)
    return (
      <Pane>
        {error && <ErrorBox error={error} />}
        {isLoading && <Loading />}
      </Pane>
    );

  const abns = data?.advanced_beneficiary_notices ?? [];
  const referrals = data?.medical_referrals ?? [];
  const pocs = data?.plans_of_care ?? [];
  const docs = [...abns, ...referrals, ...pocs].sort((a, b) =>
    new Date(b.created_at) > new Date(a.created_at) ? 1 : -1
  );

  if (!docs.length)
    return (
      <Pane>
        <Text disabled>{t`patients.no_documents`}</Text>
      </Pane>
    );

  const doc2em = (doc: Types.ABN | Types.MedicalReferral | Types.PlanOfCare) => {
    if (isABN(doc))
      return (
        <ABN
          key={doc.id}
          deleteMode={deleteMode}
          onDelete={() => onDelete("advanced_beneficiary_notices", doc.id)}
          {...doc}
        />
      );
    if (isReferral(doc))
      return (
        <Referral
          key={doc.id}
          deleteMode={deleteMode}
          onDelete={() => onDelete("medical_referrals", doc.id)}
          {...doc}
        />
      );
    if (isPOC(doc))
      return <POC key={doc.id} deleteMode={deleteMode} onDelete={() => onDelete("plans_of_care", doc.id)} {...doc} />;
  };

  const onDelete = async (field: keyof Documents, id: number) => {
    const key = queryKeys.documents(patient.id);
    const rollback = queryClient.getQueryData(key);
    queryClient.cancelQueries(key);
    queryClient.setQueryData<Documents>(
      key,
      produce(draft => {
        const items = draft![field] as Array<Types.ABN | Types.MedicalReferral | Types.PlanOfCare>;
        const item = items.find(doc => doc.id === id)!;
        items.splice(items.indexOf(item), 1);
      })
    );
    try {
      switch (field) {
        case "advanced_beneficiary_notices":
          return await deleteABN(id);
        case "medical_referrals":
          return await deleteReferral(id);
        case "plans_of_care":
          return await deletePOC(id);
      }
    } catch (err) {
      queryClient.setQueryData(key, rollback);
      queryClient.invalidateQueries(key);
      setError(t`errors.could_not_delete_document`, err);
    }
  };

  const buttonEm = (
    <ButtonBase onClick={() => setDeleteMode(true)}>
      <Icon disabled of={Close} />
    </ButtonBase>
  );

  return (
    <div>
      <HFlex justifyContent="space-between" alignItems="center">
        <Text paragraph caption secondary mt={1}>
          {t`patients.documents`}
        </Text>
        {!deleteMode && buttonEm}
      </HFlex>
      <Box zIndex={1} position="relative">
        {deleteMode && <Dimmer onClick={() => setDeleteMode(false)} />}
        {docs.map(doc2em)}
      </Box>
    </div>
  );
};

export default CurrentDocuments;
