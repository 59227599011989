import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useToggleInflow = () => {
  const id = useId();

  return useMutation((available_for_patients: boolean) =>
    api("PUT /therapists/{id}/toggle_inflow", {
      params: [id],
      data: {
        therapist_profile: { available_for_patients },
      },
    })
  );
};

export default useToggleInflow;
