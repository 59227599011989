import api from "api";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";

export const fetchPotentialProviderGroups = (id: number) =>
  api.parse("GET /patients/{patient_id}/us_provider_group_profiles/potential_provider_groups", [id]);

const usePotentialProviderGroups = () => {
  const id = useId();
  return useQuery("potential-provider-groups", () => fetchPotentialProviderGroups(id));
};

export default usePotentialProviderGroups;
