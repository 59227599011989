import api from "api";
import { QueryFunction } from "react-query";
import { Category, categoryValues, drawerContents } from "../components/DrawerContent";

export interface ReportType {
  readonly report_name: string;
  readonly display_name: string;
  readonly category: Category;
}

export type ReportTypeResponse = Array<ReportType>;

export const getReportTypes: QueryFunction<ReportTypeResponse> = () =>
  api.parse("GET /reports/report_types") as Promise<ReportTypeResponse>;

export const convertReportContent = (data: ReportTypeResponse) => {
  const groupedData = data.reduce((groupedObject, currentValue) => {
    const isOther = !categoryValues.includes(currentValue.category);
    const category = isOther ? Category.OTHER : currentValue.category;
    groupedObject[category] = groupedObject[category] || [];
    const { display_name, report_name } = currentValue;
    groupedObject[category].push({ display_name, report_name });
    return groupedObject;
  }, Object.create(null));
  return drawerContents.map(drawerContent => ({ ...drawerContent, values: groupedData[drawerContent.category] || [] }));
};
