import React from "react";
import { Avatar, Box } from "@material-ui/core";
import { HFlex, Text } from "shared";
import usePatient from "../queries/usePatient";
import { PTSummary } from "./";

const Summary: React.VFC = () => {
  const { data } = usePatient();
  if (!data) return null;
  const { first_name, last_name, avatar } = data;

  return (
    <HFlex gap={2} alignItems="center" mb={2}>
      <Avatar src={avatar?.medium} />
      <Box overflow="auto">
        <Text heading bold nowrap mb={0}>
          {first_name} {last_name}
        </Text>
        <PTSummary />
      </Box>
    </HFlex>
  );
};

export default Summary;
