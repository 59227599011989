import { Button, makeStyles, Modal } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "theme";
import InvoicePreviewTable from "./InvoicePreviewTable";

const useStyles = makeStyles(() => ({
  button: {
    fontSize: "inherit",
    color: Colors.JA_BLUE,
  },
}));

type InvoicePreviewModalProps = {
  payerId: number;
};

const InvoicePreviewModal: React.VFC<InvoicePreviewModalProps> = ({ payerId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button className={classes.button} onClick={handleOpen}>
        {t("rcm.open_invoice_details")}
      </Button>

      <Modal open={open} onClose={handleClose}>
        <InvoicePreviewTable closeModal={handleClose} payerId={payerId} />
      </Modal>
    </>
  );
};

export default InvoicePreviewModal;
