import { Switch } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { Pane, useErrorContext, Text } from "shared";
import { useTranslation } from "react-i18next";
import useTherapist from "../queries/useTherapist";
import useToggleSubstitute from "../queries/useToggleSubstitute";
import { queryKeys } from "utils/misc";

const ToggleSubstitute: React.VFC = () => {
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { t } = useTranslation();
  const { mutateAsync: toggleSubstitute } = useToggleSubstitute();
  const { data: pt } = useTherapist();

  if (!pt) return null;

  // @ts-ignore
  const { available_to_substitute } = pt;

  const toggle = async () => {
    const ptKey = queryKeys.therapist(pt.id);
    const rollbackData = queryClient.getQueryData(ptKey);
    queryClient.cancelQueries(ptKey);
    queryClient.setQueryData(ptKey, old => Object.assign(old, { available_to_substitute: !available_to_substitute }));

    try {
      await toggleSubstitute(!available_to_substitute);
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      queryClient.invalidateQueries(ptKey);
      setErrors(t("errors.could_not_change_available_to_substitute"), err);
    }
  };

  return (
    <Pane
      component="label"
      role="button"
      dark
      py={1}
      display="flex"
      alignItems="center"
      aria-label="Toggle available to substitute"
    >
      <Text light small>
        {t("form.available_to_substitute")}
      </Text>
      <Text small ml="auto">
        {available_to_substitute ? t("buttons.yes") : t("buttons.no")}
      </Text>
      {/* @ts-ignore */}
      <Switch color="primary" checked={available_to_substitute} onChange={toggle} />
    </Pane>
  );
};

export default ToggleSubstitute;
