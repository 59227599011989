import api from "api";
import { BodyParams } from "api/api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "POST /patients/{id}/close";

export type Vars = NonNullable<BodyParams<typeof OP>["reason"]>;

const useClosePatientAccount = () => {
  const id = useId();

  return useMutation(({ option, comment }: Vars) =>
    api(OP, {
      params: [id],
      data: {
        id,
        reason: { option, comment },
      },
    })
  );
};

export default useClosePatientAccount;
