import React, { useState } from "react";
import { Button, Checkbox, FormControlLabel, Container } from "@material-ui/core";
import { CTextField, VFlex, ProgressButton, Text, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import useChangeTherapist from "../../queries/useChangeTherapist";
import useSubstituteTherapist from "../../queries/useSubstituteTherapist";
import usePatient from "routes/patient/queries/usePatient";
import { fullName, queryKeys } from "utils/misc";
import { useMountState } from "utils/hooks";

interface Props {
  onClose: () => void;
}

const ChangeTherapistForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const [tempPTToggle, setTempPTToggle] = useState(false);
  const form = useForm<Record<string, number>>();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { mutateAsync, isLoading: isLoadingPermanentPT } = useChangeTherapist();
  const { mutateAsync: substituteMutateAsync, isLoading: isLoadingSubstitutePT } = useSubstituteTherapist();
  const { data: patient } = usePatient();
  const getMountState = useMountState();

  if (!patient) return null;
  const { therapist } = patient;

  const submit = form.handleSubmit(async fields => {
    try {
      await mutateAsync(fields.therapist_id);
      queryClient.invalidateQueries(queryKeys.patient(patient.id));
      getMountState() && onClose();
    } catch (err) {
      setErrors(t`errors.generic`, err);
    }
  });

  const subSubmit = form.handleSubmit(async fields => {
    try {
      await substituteMutateAsync(fields.substitute_therapist_id);
      queryClient.invalidateQueries(queryKeys.patient(patient.id));
      getMountState() && onClose();
    } catch (err) {
      setErrors(t`errors.generic`, err);
    }
  });
  const toggleSetSubstituteTherapist = () => {
    setTempPTToggle(!tempPTToggle);
  };

  return (
    <Container>
      <Text heading bold id="change-pt">
        {t("form.assign_new_therapist")}
      </Text>
      <Text paragraph mb={3}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>

      <FormProvider {...form}>
        <form onSubmit={tempPTToggle ? subSubmit : submit}>
          <CTextField
            asNumber
            autoFocus
            autoSelect
            defaultValue={therapist?.id}
            required
            name={tempPTToggle ? "substitute_therapist_id" : "therapist_id"}
            label={t("patients.id")}
            margin="normal"
            fullWidth
            type="number"
          />
          <FormControlLabel
            control={<Checkbox checked={tempPTToggle} onChange={toggleSetSubstituteTherapist} />}
            label={t("form.assign_temporary_therapist")}
          />
          <VFlex alignItems="center" gap={2} my={5}>
            <ProgressButton loading={isLoadingPermanentPT || isLoadingSubstitutePT}>{t("buttons.save")}</ProgressButton>
            <Button disabled={isLoadingPermanentPT || isLoadingSubstitutePT} onClick={onClose}>
              {t("buttons.cancel")}
            </Button>
          </VFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default ChangeTherapistForm;
