import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useRemoveOfflineVisitAndBilling = () => {
  const id = useId();
  return useMutation(() => api("PUT /patients/{id}/remove_offline_visit_and_payer_claims", [id]));
};

export default useRemoveOfflineVisitAndBilling;
