import styled from "styled-components";
import { FolderOpen, CreditCard, EnhancedEncryption, AssignmentInd } from "@material-ui/icons";
import { ReportTypeResponse } from "../queries/reportTypes";

const StyledAssignmentIndIcon = styled(AssignmentInd)({ display: "block" });
const StyledEnhancedEncryptionIcon = styled(EnhancedEncryption)({ display: "block" });
const StyledCreditCardIcon = styled(CreditCard)({ display: "block" });
const StyledOtherIcon = styled(FolderOpen)({ display: "block" });

export interface DrawerContents {
  readonly category: Category;
  readonly displayName: string;
  readonly values: ReportTypeResponse;
  readonly icon: React.ReactNode;
}

export enum Category {
  PATIENT = "patient",
  THERAPIST = "therapist",
  INSURANCE_AND_BILLING = "insurance-and-billing",
  OTHER = "other",
}

export const categoryValues = Object.values(Category);

export const drawerContents: DrawerContents[] = [
  {
    category: Category.PATIENT,
    displayName: "Patient",
    icon: <StyledAssignmentIndIcon />,
    values: [],
  },
  {
    category: Category.THERAPIST,
    displayName: "Therapist",
    icon: <StyledEnhancedEncryptionIcon />,
    values: [],
  },
  {
    category: Category.INSURANCE_AND_BILLING,
    displayName: "Insurance & Billing",
    icon: <StyledCreditCardIcon />,
    values: [],
  },
  {
    category: Category.OTHER,
    displayName: "Other",
    icon: <StyledOtherIcon />,
    values: [],
  },
];
