import api from "api";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";
import { delay, queryKeys } from "utils/misc";
import { useEffect } from "react";

const useTimeOffPeriod = (overrideId?: number) => {
  const defaultId = useId();
  const id = overrideId ?? defaultId;
  const key = queryKeys.therapist(id);
  const { data, refetch, isSuccess, isLoading } = useQuery(key, _ =>
    api.parse("GET /time_off_periods/{time_off_period_id}", [id])
  );

  useEffect(() => {
    if (data?.status !== "pending") return;
    delay(3000).then(() => {
      refetch();
    });
  }, [data, key, refetch]);

  return { isSuccess, isLoading, data };
};

export default useTimeOffPeriod;
