import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useUpdateCopayConsent = () => {
  const id = useId();

  return useMutation((copay_consent: boolean) =>
    api("PUT /us_insurance/patient_insurance/{patient_id}/copay_consent", {
      params: [id],
      data: { copay_consent },
    })
  );
};

export default useUpdateCopayConsent;
