import api from "api";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";
import { queryKeys } from "utils/misc";

const useActivities = (course_week_number?: number) => {
  const patientId = useId();
  const key = queryKeys.activities(patientId, course_week_number);

  return useQuery(
    key,
    () =>
      api.parse("GET /patients/{patient_id}/activities", {
        params: [patientId],
        query: { course_week_number },
      }),
    { keepPreviousData: true, enabled: !!key }
  );
};

export default useActivities;
