import React, { useState } from "react";
import { Table, TableBody, TableCell, TableRow, TableHead, TableProps, ButtonBase } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { HFlex } from "shared";
import { HasId } from "types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";

type Mapped<S, T> = {
  [prop in keyof S]: T;
};

interface Props<S, T extends keyof S> extends TableProps {
  header: Mapped<Partial<Pick<S, T>>, string>;
  items?: S[];
  cellFactory: (item: S) => Mapped<Pick<S, T>, React.ReactElement>;
}

const CTable = <S extends HasId, T extends keyof S>({
  header,
  items,
  cellFactory,
  ...rest
}: Props<S, T>): React.ReactElement => {
  const keys = Object.keys(header) as T[];
  const labels = Object.values<string>(header);
  const [[sortIndex, sortDir], setSorted] = useState([0, 1]);
  const sortKey = keys[sortIndex];
  const sorted = items && [...items].sort((a, b) => (a[sortKey] > b[sortKey] ? sortDir : -sortDir));

  return (
    <Table {...rest}>
      <TableHead>
        <TableRow>
          {labels.map((label, i) => (
            <TableCell variant="head" key={label}>
              <HFlex alignItems="center">
                {i === sortIndex ? (
                  <ButtonBase onClick={() => setSorted([i, -sortDir])} aria-label="Column">
                    {label}
                    {sortDir > 0 ? <ArrowDropUp /> : <ArrowDropDown />}
                  </ButtonBase>
                ) : (
                  <ButtonBase onClick={() => setSorted([i, 1])} aria-label="Column">
                    {label}
                  </ButtonBase>
                )}
              </HFlex>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sorted?.map((item, i) => {
          const cells = cellFactory(item);
          return (
            <TableRow
              key={item.id}
              hover
              className={css`
                position: relative;
              `}
            >
              {keys.map(key => (
                <React.Fragment key={key.toString()}>{cells[key]}</React.Fragment>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const RowLink = styled(Link)`
  content: "";
  position: absolute;
  height: 40px;
  width: 959px;
  margin: -10px 0 0 -16px; // must just minus margin because of safari bug using position: absolute and top
`;

export default CTable;
