import React, { useEffect } from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { removeAuth, join, refreshTokenIfNeeded } from "utils/misc";
import { Area, WithChildren } from "types";

const Protected: React.VFC<WithChildren> = ({ children }) => {
  const { pathname, search } = useLocation();
  const b64url = btoa(join(pathname, search));
  const history = useHistory();

  useEffect(() => {
    if ("Cypress" in window) return;

    (async () => {
      const refreshed = await refreshTokenIfNeeded();
      if (!refreshed) {
        removeAuth();
        history.push({
          pathname: Area.Login,
          state: b64url,
        });
      }
    })();
  }, [b64url, history]);

  return <>{children}</>;
};

const ProtectedRoute: React.VFC<RouteProps> = ({ children, ...rest }) => (
  <Route {...rest}>
    <Protected>{children}</Protected>
  </Route>
);

export default ProtectedRoute;
