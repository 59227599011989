import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Text } from "shared";
import useInsuranceProfile, { ClaimOrderType } from "routes/patient/queries/useInsuranceProfile";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import InsuranceSummary from "./InsuranceSummary";
import { useTranslation } from "react-i18next";

const Insurance: React.VFC<{ claim_order_type: ClaimOrderType }> = ({ claim_order_type }) => {
  const { t } = useTranslation();
  const { data } = useInsuranceProfile();
  const profile = data?.find(insurance => insurance.claim_order_type === claim_order_type);

  if (!profile) return <InsuranceSummary claim_order_type={claim_order_type} />;

  const { member_id, copay_consent, copay_estimate_cents, medicare_amount_used, us_insurance_company } = profile;

  const details = [
    [t`insurance.memberid`, member_id],
    [t`insurance.copay`, copay_consent ? t`buttons.yes` : t`buttons.no`],
    [t`insurance.copay_estimate_cents`, copay_estimate_cents ?? 0],
    [t`insurance.medicare`, us_insurance_company?.medicare ? t`buttons.yes` : t`buttons.no`],
    [t`insurance.medicare_amount_used`, medicare_amount_used ?? 0],
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="insurance-content" id="insurance-header">
        <InsuranceSummary claim_order_type={claim_order_type} />
      </AccordionSummary>
      <AccordionDetails>
        {details.map(([label, value]) => (
          <Text paragraph tiny light dense key={label}>
            {label}:
            <Text bold ml={1}>
              {value}
            </Text>
          </Text>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default Insurance;
