import React, { useEffect, useState } from "react";
import { Checkbox, Switch, FormControlLabel, Container } from "@material-ui/core";
import {
  CTextField,
  CSelect,
  HFlex,
  ProgressButton,
  Text,
  DialogSuccessNotice,
  useErrorContext,
  ErrorBox,
  MultiSelect,
} from "shared";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Send } from "assets";
import useInviteTherapist, { Vars } from "../../queries/useInviteTherapist";
import { Country, isUsMarket, TimeZone } from "utils/market";
import { css } from "@emotion/css";
import { useStates } from "shared/ValueTable";
import { useMountState } from "utils/hooks";
import usePayers from "routes/patientSearch/queries/usePayers";

interface Props {
  onClose: () => void;
}

const countriesEU = [
  { value: Country.UK, label: "United Kingdom" },
  { value: Country.DE, label: "Germany" },
  { value: Country.FR, label: "France" },
  { value: Country.SE, label: "Sweden" },
];

const timeZonesEU = [
  { value: TimeZone.London, label: "London" },
  { value: TimeZone.Stockholm, label: "Stockholm" },
  { value: TimeZone.Berlin, label: "Berlin" },
  { value: TimeZone.Paris, label: "Paris" },
];

const timeZonesUS = [
  { value: TimeZone.Pacific, label: "Pacific Time" },
  { value: TimeZone.Mountain, label: "Mountain Time" },
  { value: TimeZone.Central, label: "Central Time" },
  { value: TimeZone.Eastern, label: "Eastern Time" },
];

const professions = [
  { value: "physical_therapist", label: "Physical Therapist" },
  { value: "occupational_therapist", label: "Occupational Therapist" },
  { value: "speech_therapist", label: "Speech Therapist" },
  { value: "wellness_therapist", label: "Wellness Therapist" },
];

const InviteTherapistForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<Vars>();
  const { t } = useTranslation();
  const { mutateAsync, isLoading, isSuccess } = useInviteTherapist();
  const setError = useErrorContext();
  const [email, setEmail] = useState<string>();
  const [therapist, setTherapist] = useState<{ external_uuid: string } | null>(null);
  const [hcp, setHcp] = useState<{ name: string } | null>(null);
  const [payers, setPayers] = useState<{ label: string | undefined; value: number }[] | null>([]);
  const licenses = useStates();
  const getMountState = useMountState();

  const country = isUsMarket ? Country.US : Country.SE;
  const { data: dataPayers, isSuccess: isSuccessPayers } = usePayers(country);

  const createNewHCP = form.watch("health_care_provider_new");
  const availableForPatients = form.watch("available_for_patients");

  useEffect(() => {
    if (isSuccessPayers && dataPayers) {
      const payerChoices = dataPayers.map(x => {
        return { value: x.id, label: x.name };
      });
      setPayers(payerChoices);
    }
  }, [dataPayers, isSuccessPayers]);

  const submit = form.handleSubmit(async fields => {
    setEmail(fields.email);
    try {
      await mutateAsync(fields, {
        onSuccess: data => {
          data.json().then(res => {
            setHcp({
              name: res.health_care_provider.name
            });
            setTherapist({
              external_uuid: res.external_uuid,
            });
          });
        },
      });
      if (!getMountState()) return;
    } catch (err) {
      setError(t`errors.could_not_send_invite`, err);
    }
  });

  if (isSuccess && hcp && therapist)
    return (
      <DialogSuccessNotice
        heading={t("patients.invite_sent")}
        body={`${t("form.invitation_successfully_sent_to")} ${email}. HCP ${hcp.name} UUID ${therapist.external_uuid}`}
        onClose={onClose}
      />
    );

  return (
    <Container>
      <Text heading bold mb={3} id="invite-pt">
        {t("form.invite_a_therapist")}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <CTextField
            required
            name="email"
            autoFocus
            label={t("form.email")}
            type="email"
            margin="normal"
            size="small"
            fullWidth
          />
          <HFlex gap={2}>
            <CTextField
              name="first_name"
              label={t("form.first_name")}
              margin="normal"
              size="small"
              fullWidth
              required
            />
            <CTextField name="last_name" label={t("form.last_name")} margin="normal" size="small" fullWidth required />
          </HFlex>
          <CTextField
            name="health_care_provider_name"
            label={t("form.health_care_provider")}
            margin="normal"
            size="small"
            required
            fullWidth
            defaultValue="Joint Academy"
          />
          <FormControlLabel
            control={<Checkbox inputRef={form.register} name="health_care_provider_new" color="primary" />}
            label={<Text small light>{t`form.create_new_health_care_provider`}</Text>}
          />
          {createNewHCP && payers && (
            <CSelect
              name="health_care_provider_invitation_payer_id"
              label={t("form.health_care_provider_invitation_payer")}
              items={payers}
              margin="normal"
              size="small"
              fullWidth
            >
              {({ label }) => <Text>{label}</Text>}
            </CSelect>
          )}
          {!isUsMarket && (
            <CSelect
              name="country"
              label={t("form.country")}
              items={countriesEU}
              margin="normal"
              size="small"
              required
              fullWidth
              defaultValue={Country.SE}
            >
              {({ label }) => <Text>{label}</Text>}
            </CSelect>
          )}
          <CSelect
            name="timezone"
            label={t("form.home_timezone")}
            items={isUsMarket ? timeZonesUS : timeZonesEU}
            margin="normal"
            size="small"
            required
            fullWidth
            defaultValue={!isUsMarket ? TimeZone.Stockholm : null}
          >
            {({ label }) => <Text>{label}</Text>}
          </CSelect>
          <CSelect
            name="profession"
            label={t("form.profession")}
            items={professions}
            margin="normal"
            size="small"
            required
            fullWidth
            defaultValue={"physical_therapist"}
          >
            {({ label }) => <Text>{label}</Text>}
          </CSelect>
          <label role="button" className={styles.label}>
            <Text small light>
              {t("form.available_for_patients")}
            </Text>
            <Text small ml="auto">
              {availableForPatients ? t("buttons.yes") : t("buttons.no")}
            </Text>
            <Switch color="primary" inputRef={form.register} name="available_for_patients" />
          </label>
          {isUsMarket && (
            <MultiSelect
              getOptionLabel={({ value, code }) => `${value} (${code})`}
              getOptionValue={opt => opt.code}
              loading={licenses.isLoading}
              noOptionsText={licenses.error ? <ErrorBox error={licenses.error} /> : ""}
              name="us_licenses"
              options={licenses.data ?? []}
              label={t`form.state_licenses`}
              margin="normal"
            />
          )}
          <HFlex justifyContent="center" my={6}>
            <ProgressButton loading={isLoading} endIcon={<Send width={20} />}>
              {t("buttons.send_invitation")}
            </ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default InviteTherapistForm;

const styles = {
  label: css`
    display: flex;
    align-items: center;
  `,
};
