import React from "react";
import { Grid } from "@material-ui/core";
import {
  Activities,
  CreatePlanOfCare,
  UploadDocument,
  Documents,
  TFSubmissions,
  UploadReferralForEu,
  Account,
  Actions,
} from "./components";
import { Container, Box, Fab } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "shared";
import usePatient from "./queries/usePatient";
import { Search } from "@material-ui/icons";
import { join } from "utils/misc";
import { Area } from "types";
import { UkDischargeLetter } from "./components/UkDischargeLetter";
import { Country } from "utils/market";

const Patient: React.VFC = () => {
  const { state } = useLocation<string | undefined>();
  const { data: patient, isSuccess } = usePatient();

  const mainEm = (
    <Grid container spacing={2}>
      <Grid item zeroMinWidth md={7}>
        <Account />
      </Grid>
      <Grid item zeroMinWidth md={5}>
        <Actions />
        <TFSubmissions />
        <CreatePlanOfCare />
        <UploadDocument />
        <UploadReferralForEu />
        <Activities />
        <Documents />
        {patient?.country === Country.UK && <UkDischargeLetter />}
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Box ml={3} mt={3} mb={4}>
        <Link to={join(Area.Patients, state)}>
          <Fab color="secondary" size="medium" aria-label="Back to search">
            <Icon of={Search} />
          </Fab>
        </Link>
      </Box>
      <Container>{isSuccess ? mainEm : <Account />}</Container>
    </div>
  );
};

export default Patient;
