import { PaginationParams } from "types";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { removeAuth } from "./misc";
import { useQueryClient } from "react-query";

type SearchConstraints<T> = {
  [P in keyof T]: Array<T[P]>;
};

const hasKey = <T>(constraints: T, key: any): key is keyof T => key in constraints;

export const useSearchParams = <T extends {}, S extends keyof T>(
  defaults: T,
  constraints?: Pick<SearchConstraints<T>, S>
) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return [...params.entries()].reduce<T>(
    (acc, [key, value]) =>
      constraints && hasKey(constraints, key) && !constraints[key].includes(value as any)
        ? acc
        : { ...acc, [key]: value },
    defaults
  );
};

export const usePaginationParams = (limitOptions: number[]): PaginationParams => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const _limit = Number(params.get("limit"));
  const limit = limitOptions.includes(_limit) ? _limit : limitOptions[0];
  const _offset = Math.max(Number(params.get("offset")) || 0, 0);
  const offset = _offset - (_offset % limit);
  return { offset, limit };
};

export const usePagination = () => {
  const history = useHistory();
  const location = useLocation();

  return {
    changeLimit: (limit: number) => {
      const params = new URLSearchParams(location.search);
      params.set("limit", limit.toString());
      params.set("offset", "0");
      history.push("?" + params.toString());
    },

    changeOffset: (offset: number) => {
      const params = new URLSearchParams(location.search);
      params.set("offset", offset.toString());
      history.push("?" + params.toString());
    },
  };
};

export const useLogout = (path: string, redirect?: string) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const isMatch = !!useRouteMatch(path)?.isExact;

  useEffect(() => {
    if (isMatch) {
      // TODO: When supported by API, add call to invalidate the JWT before removing cookies
      removeAuth();
      queryClient.clear();
      if (redirect) history.push(redirect);
    }
  }, [isMatch, history, queryClient, redirect]);
};

export const useId = () => Number(useParams<{ item?: string }>().item);

export const useMountState = () => {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = ref.current = true;
    return () => {
      ref.current = false;
    };
  });

  return () => ref.current;
};

export const useListener = <K extends keyof WindowEventMap>(
  type: K,
  handler: (event: WindowEventMap[K]) => void,
  dependencies: any[]
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(handler, dependencies);

  useEffect(() => {
    window.addEventListener(type, cb);
    return () => {
      window.removeEventListener(type, cb);
    };
  }, [type, cb]);
};

export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useListener(
    "resize",
    () => {
      setWidth(window.innerWidth);
    },
    [setWidth]
  );

  return width;
};
