import { Box, Button } from "@material-ui/core";
import React from "react";
import { Text } from "shared";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";
import { SPACING } from "theme";

export enum Type {
  ABN = "ABN",
  Referral = "Referral",
  POC = "Plan of Care",
}

interface Props {
  onClose: () => void;
  onSelect: (value: Type) => void;
}

const Options: React.VFC<Props> = ({ onClose, onSelect }) => {
  const { t } = useTranslation();
  const types = Object.values(Type);

  return (
    <div>
      <Text heading bold center mb={4} id="document-type">
        {t`form.select_document_type_to_upload`}
      </Text>
      <Box mb={6} className={styles.grid}>
        {types.map(uploadType => (
          <Button
            key={uploadType}
            onClick={() => onSelect(uploadType)}
            variant="contained"
            color="primary"
            aria-label={`Upload ${uploadType}`}
          >
            {uploadType}
          </Button>
        ))}
        <Button onClick={onClose}>{t`buttons.cancel`}</Button>
      </Box>
    </div>
  );
};

export default Options;

const styles = {
  grid: css`
    display: grid;
    gap: ${2 * SPACING}px;
    grid-template-columns: auto;
    justify-content: center;
  `,
};
