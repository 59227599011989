import React, { useState } from "react";
import PublishIcon from "@material-ui/icons/Publish";
import { useTranslation } from "react-i18next";
import { BoxButton, CDialog } from "shared";
import UploadUnclaimedReferralForm from "./UploadUnclaimedReferralForm";

const UploadUnclaimedReferral: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const showDialog = (value: boolean) => setOpen(value);

  return (
    <>
      <BoxButton startIcon={<PublishIcon />} onClick={() => showDialog(true)}>
        {t("translations.unclaimed_referral_upload")}
      </BoxButton>

      <CDialog
        maxWidth="xs"
        fullWidth
        open={isOpen}
        onClose={() => showDialog(false)}
        aria-labelledby="uploadUnclaimedReferral"
      >
        <UploadUnclaimedReferralForm onClose={() => showDialog(false)} />
      </CDialog>
    </>
  );
};

export default UploadUnclaimedReferral;
