import api from "api";
import { useQuery } from "react-query";
import { queryKeys } from "utils/misc";
import { OrderBy } from "../Reports";

export interface ReportVars {
  readonly report: string;
  readonly rows_per_page: number;
  readonly current_page: number;
  readonly order_by?: string;
  readonly order_direction: OrderBy;
}

export interface ColumnEntry {
  readonly name: string;
  readonly type: string;
}

export type RowEntry = { [key: string]: string | null | number };

export interface Report {
  readonly report: string;
  readonly display_name: string;
  readonly columns: Array<ColumnEntry>;
  readonly pages: number;
  readonly current_page: number;
  readonly rows_per_page: number;
  readonly order_by: string | null;
  readonly order_direction: OrderBy;
  readonly total_count: number;
  readonly rows: Array<RowEntry>;
}

export interface ReportResponse extends Report {}

export const useReports = (params: ReportVars) =>
  useQuery(queryKeys.reports(params), () => api.parse("GET /reports", { query: params }) as Promise<ReportResponse>);
