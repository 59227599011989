import React, { useMemo } from "react";
import styled from "styled-components";
import { Accordion, AccordionSummary, List, AccordionDetails, Button } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import TreatmentActivation from "./TreatmentActivation";
import { Joint, TreatmentOption, TreatmentActiveScope } from "../../queries/marketTreatmentAvailability";

type TreatmentActivationOption = {
  scope: TreatmentActiveScope;
  label: string;
  buttonLabel: string;
  active: boolean;
  reactivationEnabled: boolean;
  reactivationButtonLabel?: string;
};

interface Props {
  treatmentOption: TreatmentOption;
  onClickActivate: (scope: TreatmentActiveScope) => void;
}

const TreatmentActivationAccordion: React.VFC<Props> = ({ treatmentOption, onClickActivate }) => {
  const { allCurrentTherapistsActive, incomingPatientsActive, incomingTherapistsActive } = treatmentOption;

  const { t } = useTranslation();

  const TreatmentLabels: Record<Joint, string> = {
    [Joint.Hand]: t("manage_treatments.hand"),
    [Joint.Shoulder]: t("manage_treatments.shoulder"),
    [Joint.LowerBack]: t("manage_treatments.lower_back"),
    [Joint.Neck]: t("manage_treatments.neck"),
  };

  const treatmentActivationOptions: TreatmentActivationOption[] = useMemo(
    () => [
      {
        scope: "current_therapists",
        label: t("manage_treatments.all_current_therapists"),
        active: allCurrentTherapistsActive,
        reactivationEnabled: true,
        buttonLabel: t("manage_treatments.trigger_treatment"),
        reactivationButtonLabel: t("manage_treatments.trigger_treatment_again"),
      },
      {
        scope: "incoming_therapists",
        label: t("manage_treatments.all_incoming_therapists"),
        active: incomingTherapistsActive,
        reactivationEnabled: false,
        buttonLabel: t("manage_treatments.activate_treatment"),
      },
      {
        scope: "incoming_patients",
        label: t("manage_treatments.all_incoming_patients"),
        active: incomingPatientsActive,
        reactivationEnabled: false,
        buttonLabel: t("manage_treatments.activate_treatment"),
      },
    ],
    [allCurrentTherapistsActive, incomingPatientsActive, incomingTherapistsActive, t]
  );

  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
        <Button>{TreatmentLabels[treatmentOption.joint]}</Button>
      </StyledAccordionSummary>
      <AccordionDetails>
        <StyledList>
          {treatmentActivationOptions.map(
            ({ scope, label, buttonLabel, active, reactivationEnabled, reactivationButtonLabel }) => (
              <TreatmentActivation
                key={scope}
                label={label}
                buttonLabel={buttonLabel}
                reactivationEnabled={reactivationEnabled}
                reactivationButtonLabel={reactivationButtonLabel}
                active={active}
                onClickActivate={() => onClickActivate(scope)}
              />
            )
          )}
        </StyledList>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)`
  font-size: inherit;
  ::before {
    height: 4px;
    background-color: #f7f7f7;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 15px 0;
`;

const StyledList = styled(List)`
  & > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

export default TreatmentActivationAccordion;
