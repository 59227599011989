import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pane, Text, CDialog, Loading } from "shared";
import { getDate, getDay } from "utils/time";
import { Attending, NotAttending } from "./ActivityStatus";
import usePatient from "../../queries/usePatient";
import { FontSize, SPACING } from "theme";
import useActivities from "../../queries/useActivities";
import BrowseActivities from "./BrowseActivities";
import EditActivity from "./EditActivity";
import { css } from "@emotion/css";
import { definitions } from "api";

type Activity = definitions["activity"];

export const TYPES = {
  health: ["w1_health_report", "weekly_health_report", "followup_health_report"],
  functionality: ["functionality_1"],
};

const allTypes = [...TYPES.functionality, ...TYPES.health];

export const isEditableActivity = (activityType: string): boolean => allTypes.includes(activityType);

export const activityValue = (activity: Activity) => {
  let value;

  if (TYPES.functionality.includes(activity.type)) {
    value = activity.functionality?.repetitions;
  }
  if (TYPES.health.includes(activity.type)) {
    value = activity.specific_joint_pain;
  }

  return value?.toString() ?? "";
};

const isCourseWeek = (week?: number | null): week is number => Number.isInteger(week);

const Activities: React.VFC = () => {
  const [expanded, setExpanded] = useState(false);
  const expand = () => setExpanded(true);
  const collapse = () => setExpanded(false);
  const { t } = useTranslation();
  const currentCourseWeek = usePatient().data?.current_course_week?.course_week_number;
  const { data: activitiesData, isSuccess, isLoading } = useActivities(currentCourseWeek);
  const [editActivity, setEditActivity] = useState<Activity | null>(null);

  if (!isCourseWeek(currentCourseWeek)) {
    return (
      <Pane>
        <Text disabled>{t`patients.no_activities`}</Text>
      </Pane>
    );
  }

  const mainEm = activitiesData?.current && (
    <>
      <Pane dark py={1}>
        <Text paragraph bold center mb={0}>
          {t`patients.week`} {currentCourseWeek}
        </Text>
      </Pane>
      <div className={styles.grid}>
        {activitiesData.current.map(activity => (
          <React.Fragment key={activity.id}>
            <Text>{getDate(activity.start_date)}</Text>
            <Text>{getDay(activity.start_date, true)}</Text>
            <Text>
              {
                // @ts-ignore
                t(`activities.${activity.type}`)
              }
            </Text>
            {activity.completed ? <Attending /> : <NotAttending />}
            <Text>{activityValue(activity)}</Text>
            <Button
              disabled={!isEditableActivity(activity.type)}
              onClick={() => {
                setEditActivity(activity);
              }}
              aria-label="Edit activity"
            >
              {t`buttons.edit`}
            </Button>
          </React.Fragment>
        ))}
      </div>
      <CDialog maxWidth="md" fullWidth open={expanded} onClose={collapse} aria-label="Activity browser">
        <BrowseActivities />
      </CDialog>
      <EditActivity
        activity={editActivity}
        onClose={() => {
          setEditActivity(null);
        }}
      />
      <Box mt={2} mb={1} textAlign="center">
        <Button color="primary" onClick={expand} aria-label="Browse activities">
          {t`patients.view_all`}
        </Button>
      </Box>
    </>
  );

  return (
    <div>
      <Text paragraph caption secondary mt={1}>
        {t`patients.activities`}
      </Text>
      <Pane data-testid="current-activities">
        {isSuccess && mainEm}
        {isLoading && <Loading />}
      </Pane>
    </div>
  );
};

export default Activities;

const styles = {
  grid: css`
    display: grid;
    align-items: center;
    font-size: ${FontSize.xxs}px;
    grid-row-gap: ${SPACING}px;
    grid-column-gap: ${SPACING}px;
    justify-content: space-evenly;
    grid-template-columns: repeat(6, auto);
    gap: ${SPACING}px;
    white-space: nowrap;
  `,
};
