import api from "api";
import { useQuery } from "react-query";

const useAllowedPayerTransitions = (patientId?: number) => {
  return useQuery(
    ["getAllowedPayerTransitions", patientId],
    () =>
      // @ts-ignore
      api.parse(`GET /patients/${patientId}/allowed_payer_transitions`),
    { enabled: !!patientId }
  );
};

export default useAllowedPayerTransitions;
