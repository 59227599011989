import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { useRouteMatch, NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Area } from "types";
import { HFlex, Icon } from "shared";
import { Logo } from "assets";
import { Colors, SPACING } from "theme";
import styled from "styled-components";
import { isUsMarket } from "utils/market";
import { Text } from "shared";
import { ExitToApp } from "@material-ui/icons";
import { css } from "@emotion/css";

const NavBar: React.VFC = () => {
  const { t } = useTranslation();
  const area = "/" + String(useRouteMatch<{ area: string }>("/:area")?.params.area);

  return (
    <header className={styles.header}>
      <HFlex gap={2} alignItems="center" color={Colors.NAVIGATION_INACTIVE}>
        <Icon of={Logo} size={2.5} />
        <Link to={Area.Logout} className={styles.link}>
          <Text caption ml={1}>
            {t("dropdown.logout")}
          </Text>
          <Icon of={ExitToApp} />
        </Link>
      </HFlex>

      <Tabs value={area}>
        <Tab component={StyledNavLink} label={t("views.patient")} to={Area.Patients} value={Area.Patients} />
        <Tab component={StyledNavLink} label={t("views.therapist")} to={Area.Therapists} value={Area.Therapists} />
        <Tab component={StyledNavLink} label={t("views.reports")} to={Area.Reports} value={Area.Reports} />
        {isUsMarket && <Tab component={StyledNavLink} label={t("views.rcm")} to={Area.RCM} value={Area.RCM} />}
        <Tab component={StyledNavLink} label={t("views.other")} to={Area.Other} value={Area.Other} />
      </Tabs>
    </header>
  );
};

export default NavBar;

const StyledNavLink = styled(NavLink)`
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.27px;
`;

const styles = {
  link: css`
    display: flex;
    align-items: center;
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(42, 63, 88, 0.0680452);
    padding: 0 ${3 * SPACING}px;
  `,
};
