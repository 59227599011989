import React, { useState } from "react";
import { CDialog, BoxButton } from "shared";
import { useTranslation } from "react-i18next";
import { AddBox } from "@material-ui/icons";
import CreatePlanOfCareForm from "./CreatePlanOfCareForm";
import { isUsMarket } from "utils/market";

const CreatePlanOfCare: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  if (!isUsMarket) return null;
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <>
      <BoxButton onClick={openDialog} startIcon={<AddBox />}>
        {t("create_plan_of_care.box_button")}
      </BoxButton>
      <CDialog maxWidth="md" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="create-poc">
        <CreatePlanOfCareForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default CreatePlanOfCare;
