import api from "api";
import { useQuery } from "react-query";

export interface Payer {
  id: number;
  name: string | null;
  copay_consent: boolean;
}

export type Result = Array<Payer>;

const useInsurancePayers = () =>
  useQuery<Result, Error>("potential-payers", () =>
    api.call.parse("public/us_insurance/potential_payers?all=true", { root: process.env.REACT_APP_API_URL_V1! })
  );

export default useInsurancePayers;
