import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useDeleteABN = () => {
  const patientId = useId();
  return useMutation((id: number) =>
    api("DELETE /patients/{patient_id}/advanced_beneficiary_notices/{id}", [patientId, id])
  );
};

export default useDeleteABN;
