import React from "react";
import { useErrorContext, ErrorBox, Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";
import useUpdateLicenses from "../queries/useUpdateLicenses";
import useTherapist from "../queries/useTherapist";
import { useQueryClient } from "react-query";
import { useStates } from "shared/ValueTable";
import { queryKeys } from "utils/misc";
import { isUsMarket } from "utils/market";
import { definitions } from "api";

type PT = definitions["therapist_full"];

const StateLicenses: React.VFC = () => {
  const { t } = useTranslation();
  const setError = useErrorContext();
  const { data = [], isLoading, error } = useStates();
  const { data: pt } = useTherapist();
  const { mutateAsync: updateLicenses } = useUpdateLicenses();
  const queryClient = useQueryClient();

  if (!pt || !isUsMarket) return null;

  const regions = data.map(({ code }) => code as string).sort();
  const currentLicenses = pt.state_licences?.slice(0).sort() ?? [];

  const change = async (state_licences: string[]) => {
    const ptKey = queryKeys.therapist(pt.id);
    const rollbackData = queryClient.getQueryData<PT>(ptKey);
    queryClient.cancelQueries(ptKey);
    queryClient.setQueryData<PT>(ptKey, old => ({ ...old!, state_licences }));

    try {
      await updateLicenses(state_licences);
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      setError(t`errors.could_not_update_state_licences`, err);
    } finally {
      queryClient.invalidateQueries(ptKey);
    }
  };

  const getOption = (code: string) => data.find(region => region.code === code);

  return (
    <div>
      <Text paragraph caption secondary>
        {t`form.state_licenses`}
      </Text>
      <Pane dark>
        <Autocomplete
          disableClearable
          size="small"
          ChipProps={{ color: "secondary" }}
          autoComplete
          autoHighlight
          filterSelectedOptions
          value={isLoading ? [] : currentLicenses}
          fullWidth
          getOptionLabel={code => `${getOption(code)?.value} (${code})`}
          loading={isLoading}
          loadingText={<CircularProgress />}
          noOptionsText={error ? <ErrorBox error={error} /> : ""}
          multiple
          onChange={(_, codes) => change(codes)}
          options={regions}
          renderInput={({ InputProps, ...rest }) => (
            <TextField variant="standard" {...rest} InputProps={{ ...InputProps, disableUnderline: true }} />
          )}
        />
      </Pane>
    </div>
  );
};

export default StateLicenses;
