import api from "api";
import { useMutation } from "react-query";
import usePatient from "./usePatient";

const useUpdateHealthJournal = () => {
  const { data: patient } = usePatient();

  return useMutation((specific_joint_pain: number) =>
    api("PUT /patients/{id}", {
      params: [patient?.id ?? ""],
      data: { id: patient?.id, health_journal: { specific_joint_pain } },
    } as any)
  );
};

export default useUpdateHealthJournal;
