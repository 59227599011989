import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CRadioGroup, Pane, Text } from "shared";

interface Props {
  name: string;
  value: boolean;
  edit: boolean;
}

export const BoolSelector: React.VFC<Props> = ({ name, value, edit }) => {
  const { t } = useTranslation();

  const typeName = `weekly_config.${name}`;
  return (
    <Pane dark>
      <Grid container style={{ marginBottom: 12 }}>
        <Grid item xs={12} md={6} style={{ paddingLeft: 4 }}>
          <Text style={{ fontWeight: 500, fontSize: 20 }}>
            {
              // @ts-ignore
              t(`care_event_configurations.${name}`)
            }
          </Text>
        </Grid>
        <Grid item xs={12} md={6}>
          <CRadioGroup
            disabled={!edit}
            name={typeName}
            row
            items={[
              { label: t("buttons.off"), value: "false" },
              { label: t("buttons.on"), value: "true" },
            ]}
            defaultValue={`${value}`}
          />
        </Grid>
      </Grid>
    </Pane>
  );
};

export default BoolSelector;
