import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  Patient,
  Reports,
  Therapist,
  SignIn,
  PatientSearch,
  TherapistSearch,
  ProtectedRoute,
  Auth,
  Other,
  RCM,
  Invoices,
  Payers,
  LessonEditor,
} from "routes";
import CDM from "routes/cdm/CDM";
import GenerateClaim from "routes/generateClaim/GenerateClaim";
import { NavBar, ValueTable } from "shared";
import { Area } from "types";
import { useLogout } from "utils/hooks";
import { join } from "utils/misc";
import { CareEventConfigurationsTable } from "routes/other/routes/careEventConfigurations/components/CareEventConfigurationsTable";
import CreateCareEventConfiguration from "routes/other/routes/careEventConfigurations/components/CreateCareEventConfiguration/CreateCareEventConfiguration";
import CareEventConfiguration from "routes/other/routes/careEventConfigurations/components/CareEventConfiguration/CareEventConfiguration";
import { UnclaimedMedicalReferrals } from "routes/other/components/UnclaimedMedicalReferrals";

const Main: React.VFC = () => {
  useLogout(Area.Logout, Area.Login);

  return (
    <Switch>
      <Route exact path={Area.Login}>
        <SignIn redirect={Area.Auth} />
      </Route>
      <Route exact path={Area.Auth}>
        <Auth target={Area.Patients} />
      </Route>
      <ProtectedRoute path="/">
        <NavBar />
        <Switch>
          <Route exact path={join(Area.Patients, ":item")}>
            <Patient />
          </Route>
          <Route exact path={join(Area.Therapists, ":item")}>
            <Therapist />
          </Route>
          <Route exact path={Area.Reports}>
            <Reports />
          </Route>
          <Route exact path={Area.Payers}>
            <Payers />
          </Route>
          <Route exact path={Area.LessonEditor}>
            <LessonEditor />
          </Route>
          <Route exact path={Area.Other}>
            <Other />
          </Route>
          <Route exact path={Area.RCM}>
            <RCM />
          </Route>
          <Route exact path={Area.Invoices}>
            <Invoices />
          </Route>
          <Route exact path={Area.CDM}>
            <CDM />
          </Route>
          <Route exact path={join(Area.Registry, ":item")}>
            <ValueTable />
          </Route>
          <Route exact path={Area.GenerateClaim}>
            <GenerateClaim />
          </Route>
          <Route exact path={Area.Therapists}>
            <TherapistSearch />
          </Route>
          <Route exact path={Area.Patients}>
            <PatientSearch />
          </Route>
          <Route exact path={Area.CareEventConfigurations}>
            <CareEventConfigurationsTable />;
          </Route>
          <Route exact path={join(Area.CareEventConfigurations, "new")}>
            <CreateCareEventConfiguration />;
          </Route>
          <Route exact path={join(Area.CareEventConfigurations, "edit", ":id")}>
            <CareEventConfiguration />
          </Route>
          <Route exact path={Area.UnclaimedMedicalReferrals}>
            <UnclaimedMedicalReferrals />
          </Route>
          <Route path="*">
            <Redirect to={Area.Patients} />
          </Route>
        </Switch>
      </ProtectedRoute>
    </Switch>
  );
};

export default Main;
