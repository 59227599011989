import { useParams } from "react-router-dom";
import { registries } from "shared/ValueTable";
import { Registry } from "shared/ValueTable/ValueTable";

const useRegistry = (overrideName?: string) => {
  const defaultName = useParams<{ item: string }>().item;
  const name = overrideName ?? defaultName;
  return registries.find(registry => registry.name === name) as Registry;
};

export default useRegistry;
