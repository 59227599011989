import React from "react";
import { Button, Container } from "@material-ui/core";
import {
  Text,
  FileUpload,
  useErrorContext,
  DialogSuccessNotice,
  ProgressButton,
  CTextField,
  HFlex,
  CAutocomplete,
} from "shared";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import useUploadPOC, { Vars } from "../../queries/useUploadPOC";
import { delay, fullName, queryKeys } from "utils/misc";
import { FORM_NOTIFICATION_DELAY, SPACING } from "theme";
import { css } from "@emotion/css";
import { useQueryClient } from "react-query";
import { useMountState } from "utils/hooks";
import usePatient from "routes/patient/queries/usePatient";
import { useCareEvents } from "routes/patient/queries/useCareEvents";
import { getDate } from "utils/time";
import { useSortCareEventsByNameAndDate } from "../CreatePlanOfCare/CreatePlanOfCareForm";

interface Props {
  onClose: () => void;
}

const UploadPOCForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const setError = useErrorContext();
  const { data: patient } = usePatient();
  const careEvents = useCareEvents();
  const upload = useUploadPOC();
  const form = useForm<Vars>();
  const queryClient = useQueryClient();
  const getMountState = useMountState();
  const byNameAndDate = useSortCareEventsByNameAndDate();
  if (!patient) return null;

  const sortedCareEvents = careEvents.data?.slice(0).sort(byNameAndDate);

  const onSubmit = form.handleSubmit(async data => {
    const queryKey = queryKeys.documents(patient.id);
    try {
      await upload.mutateAsync(data);
      queryClient.invalidateQueries(queryKey);
      if (!getMountState()) return;
      await delay(FORM_NOTIFICATION_DELAY);
      getMountState() && onClose();
    } catch (error) {
      setError(t`errors.could_not_upload_poc`, error);
    }
  });

  if (upload.isSuccess)
    return <DialogSuccessNotice heading={t`form.success`} body={t`form.document_uploaded`} onClose={onClose} />;

  return (
    <Container>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Text heading bold id="upload-poc">
            {t`form.upload_poc`}
          </Text>
          <Text paragraph mb={4}>
            {fullName(patient)}, <Text link>{patient.id}</Text>
          </Text>
          <div className={styles.grid}>
            <CAutocomplete
              label={t`form.care_event`}
              size="small"
              name="care_event_id"
              options={sortedCareEvents ?? []}
              required
              getOptionLabel={ce => {
                // @ts-ignore
                return `${t(`dashboard.tables.notes.types.${ce.label.toUpperCase()}`)} (${getDate(ce.start_time)})`;
              }}
              getOptionValue={ce => ce.id}
              loading={careEvents.isLoading}
              className={styles.spanRow}
            />
            <FileUpload size="small" name="file" label={t`patients.document`} required fullWidth />
            <CTextField
              size="small"
              name="provider_npi"
              label={t`form.provider_npi`}
              placeholder="XXXXXXXXXX"
              pattern={/\d{10}/}
            />
            <CTextField size="small" name="provider_first_name" label={t`form.provider_first_name`} />
            <CTextField size="small" name="provider_last_name" label={t`form.provider_last_name`} />
            <CTextField label={t`form.date_of_service`} size="small" name="date_of_service" type="date" required />
            <CTextField label={t`form.duration_days`} size="small" name="duration_days" type="number" required />
          </div>
          <HFlex justifyContent="center" gap={2} my={6}>
            <Button disabled={upload.isLoading} onClick={onClose}>
              {t`buttons.cancel`}
            </Button>
            <ProgressButton loading={upload.isLoading}>{t`buttons.upload`}</ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default UploadPOCForm;

const styles = {
  grid: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${1.5 * SPACING}px;
  `,
  spanRow: css`
    grid-column: 1 / span 2;
  `,
};
