import { Box, Button, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text, HFlex } from "shared";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import usePatient from "routes/patient/queries/usePatient";

interface Props {
  week: number;
  onWeekChange: (week: number) => void;
}

const BrowserHeader: React.VFC<Props> = ({ week, onWeekChange }) => {
  const { t } = useTranslation();
  const currentCourseWeek = usePatient().data?.current_course_week?.course_week_number;
  if (currentCourseWeek === undefined) return null;

  const clampedWeek = Math.min(week, currentCourseWeek);

  const hasNext = (): boolean => clampedWeek !== currentCourseWeek;
  const hasPrev = (): boolean => clampedWeek !== 0;

  const next = () => {
    hasNext() && onWeekChange(clampedWeek + 1);
  };
  const prev = () => {
    hasPrev() && onWeekChange(clampedWeek - 1);
  };

  const weeks = [...Array(currentCourseWeek + 1)].map((_, i) => (
    <MenuItem key={i} value={i}>
      <Text xlarge light>
        {i}
      </Text>
    </MenuItem>
  ));

  return (
    <HFlex justifyContent="space-between" bgcolor="primary.secondary" color="text.primary" px={2} py={1}>
      <Button onClick={prev} disabled={!hasPrev()} aria-label="Previous week">
        <ArrowBack />
      </Button>
      <Box>
        <Text heading light>
          {t`patients.week`}{" "}
          <Select
            value={clampedWeek}
            onChange={event => onWeekChange(event.target.value as number)}
            aria-label="Select week"
          >
            {weeks}
          </Select>
        </Text>
      </Box>
      <Button onClick={next} disabled={!hasNext()} aria-label="Next week">
        <ArrowForward />
      </Button>
    </HFlex>
  );
};

export default BrowserHeader;
