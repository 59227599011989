import { css } from "@emotion/css";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import { Download } from "assets";
import { useTranslation } from "react-i18next";
import { HFlex, Pane, Text, Icon, Anchor } from "shared";
import { Shadows } from "theme";
import { getDate, offsetDate } from "utils/time";
import { definitions } from "api";

type Props = definitions["medical_referral"] & {
  deleteMode?: boolean;
  onDelete?: () => void;
};

export const Referral: React.VFC<Props> = ({
  date_of_physician_signature,
  duration_days,
  reason,
  doctor_id,
  icd_codes,
  provider_first_name,
  provider_last_name,
  provider_npi,
  source,
  url,
  created_at,
  deleteMode,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Pane className={styles.doc}>
      <Accordion>
        <AccordionSummary
          expandIcon={deleteMode ? <Icon role="button" error of={Close} onClick={onDelete} /> : <ExpandMore />}
        >
          <HFlex width="100%" justifyContent="space-between" alignItems="baseline">
            <Text>{t`patients.medical_records.referral`}</Text>
            <span>
              <Text small light>
                {t`common.signed`}:{" "}
              </Text>
              <Text small>{date_of_physician_signature && getDate(date_of_physician_signature)}</Text>
            </span>
            <span>
              <Text small light>
                {t`common.expires`}:{" "}
              </Text>
              <Text small>
                {date_of_physician_signature && offsetDate({ days: duration_days }, date_of_physician_signature)}
              </Text>
            </span>
          </HFlex>
        </AccordionSummary>
        <AccordionDetails>
          <Text paragraph small>
            <Text light>{t`common.created_at`}: </Text>
            {getDate(created_at)}
          </Text>
          <Text paragraph small>
            <Text light>{t`patients.document`}: </Text>
            <Anchor link href={url}>
              <Icon of={Download} />
            </Anchor>
          </Text>
          <Text paragraph small>
            <Text light>{t`form.reason`}: </Text>
            {reason}
          </Text>
          <Text paragraph small>
            <Text light>{t`form.doctor_id`}: </Text>
            {doctor_id}
          </Text>
          <Text paragraph small>
            <Text light>{t`form.icd_codes`}: </Text>
            {icd_codes.join(", ")}
          </Text>
          <Text paragraph small>
            <Text light>{t`form.provider`}: </Text>
            {provider_first_name} {provider_last_name}
          </Text>
          <Text paragraph small>
            <Text light>{t`form.provider_npi`}: </Text>
            {provider_npi}
          </Text>
          <Text paragraph small>
            <Text light>{t`form.source`}: </Text>
            {source}
          </Text>
        </AccordionDetails>
      </Accordion>
    </Pane>
  );
};

export default Referral;

const styles = {
  doc: css`
    box-shadow: ${Shadows.soft};
    flex-grow: 1;
  `,
};
