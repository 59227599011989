import { Switch, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import { queryKeys, typedKeys } from "utils/misc";
import { useQueryClient } from "react-query";
import { HFlex, Pane, Text, useErrorContext } from "shared";
import { Breakpoint, SPACING } from "theme";
import { useTranslation } from "react-i18next";
import { useId } from "utils/hooks";
import useService, { Feature } from "routes/therapist/queries/useService";
import { definitions } from "api";

type PT = definitions["therapist_full"];

const Grid = styled.div`
  display: grid;
  gap: ${SPACING}px;
  @media screen and (max-width: ${Breakpoint.lg - 1}px) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: ${Breakpoint.lg}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ToggleFeatures: React.VFC = () => {
  const id = useId();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const service = useService();

  const labels: Record<Feature, string> = {
    selfcare_enabled: t`form.selfcare`,
    WEEKLY_PATIENT_ASSIGNMENT_LIMIT: t`form.weekly_patient_assignment_limit`,
    PHONE_CALLS: t`form.phone_calls`,
    CUSTOM_WELCOME_MESSAGE: t`form.custom_welcome_message`,
    VOICE_CALLS: t`form.voice_calls`,
    SUGGEST_ADHERENCE_APPOINTMENT: t`form.suggest_adherence_appointment`,
    CAP10_LIMIT_CARE_EVENTS_CREATED: t`form.cap10_limit_care_events`,
    SHOW_METRICS_PAGE: t`form.show_metrics`,
    ADHERENCE_APPOINTMENT_ON_SUDDEN_INACTIVITY: t`form.adherence_appointment_on_sudden_inactivity`,
    DROP_IN_CALLS_PULL: "DROP_IN_CALLS_PULL (DO NOT USE)",
    DROP_IN_CALLS_PUSH: t`form.drop_in_calls_push`,
    WELCOME_MESSAGE_ONLY_EXTRA: t`form.welcome_message_only_extra`,
  };

  const handleChange = async (feature: Feature) => {
    const ptKey = queryKeys.therapist(id);
    const rollbackData = queryClient.getQueryData(ptKey);
    const { update, mutate, state } = service[feature];
    queryClient.cancelQueries(ptKey);
    queryClient.setQueryData<PT>(ptKey, () => update(!state));
    try {
      await mutate(!state);
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      setErrors(err);
    } finally {
      queryClient.invalidateQueries(queryKeys.therapistSearch());
    }
  };

  return (
    <>
      <Text paragraph caption secondary>
        {t`therapist_profile.feature_toggle_title`}
      </Text>
      <Pane dark p={1}>
        <Grid data-testid="feature-toggle">
          {typedKeys(service)
            .filter(feature => typeof service[feature].state !== "undefined")
            .map(feature => (
              <Pane key={feature} component="label" role="button" mb={0} py={0.5}>
                <HFlex justifyContent="space-between" alignItems="center">
                  <Tooltip placement="top" title={service[feature].description ?? ""}>
                    <Text light small>
                      {labels[feature] ?? feature}
                    </Text>
                  </Tooltip>
                  <Switch checked={!!service[feature].state} onChange={() => handleChange(feature)} color="primary" />
                </HFlex>
              </Pane>
            ))}
        </Grid>
      </Pane>
    </>
  );
};

export default ToggleFeatures;
