import { css } from "@emotion/css";
import { ButtonBase } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { HFlex, Icon, Text } from "shared";
import { SPACING } from "theme";

interface Props {
  onClose: () => void;
  originalValue: string;
  value: string;
  onChange: (value: string) => void;
}

const SplitView: React.VFC<Props> = ({ originalValue, value, onChange, onClose }) => {
  const { t } = useTranslation();
  const area1 = useRef<HTMLTextAreaElement>(null);
  const area2 = useRef<HTMLTextAreaElement>(null);
  const ref = useRef(0);

  const onScrollFactory = (emRef: React.RefObject<HTMLTextAreaElement>) => (
    event: React.UIEvent<HTMLTextAreaElement>
  ) => {
    if (!emRef.current) return;
    const em = event.target as HTMLTextAreaElement;
    const { scrollTop } = em;
    if (ref.current === scrollTop) return;
    ref.current = scrollTop;
    emRef.current.scrollTop = scrollTop;
  };

  return (
    <div className={styles.grid}>
      <HFlex justifyContent="space-between" alignItems="center">
        <Text caption disabled tiny>
          {t`common.original`}
        </Text>
        <ButtonBase onClick={onClose}>
          <Icon of={Close} size={1.4} disabled />
        </ButtonBase>
      </HFlex>
      <Text caption disabled tiny>
        {t`common.modified`}
      </Text>
      <textarea
        ref={area1}
        className={styles.textArea}
        value={originalValue}
        disabled
        onScroll={onScrollFactory(area2)}
      />
      <textarea
        ref={area2}
        className={styles.textArea}
        value={value}
        onScroll={onScrollFactory(area1)}
        onChange={evt => onChange(evt.target.value)}
      />
    </div>
  );
};

export default SplitView;

const styles = {
  grid: css`
    grid-column-gap: ${SPACING}px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  `,
  textArea: css`
    padding: ${2 * SPACING}px;
    width: 500px;
    height: 500px;
    resize: none;
  `,
};
