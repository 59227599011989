import { useQuery } from "react-query";
import api from "api";
import { queryKeys } from "utils/misc";

const useInvoice = (invoiceId: number) => {
  const key = queryKeys.usInvoices(invoiceId);

  return useQuery(key, () => {
    return api.parse("GET /us_invoices/{invoice_id}", [invoiceId]);
  });
};

export default useInvoice;
