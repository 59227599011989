import api from "api";
import produce from "immer";
import useTherapist from "./useTherapist";
import { definitions } from "api";

export type Feature =
  | "selfcare_enabled"
  | "WEEKLY_PATIENT_ASSIGNMENT_LIMIT"
  | "PHONE_CALLS"
  | "CUSTOM_WELCOME_MESSAGE"
  | "VOICE_CALLS"
  | "SUGGEST_ADHERENCE_APPOINTMENT"
  | "CAP10_LIMIT_CARE_EVENTS_CREATED"
  | "SHOW_METRICS_PAGE"
  | "ADHERENCE_APPOINTMENT_ON_SUDDEN_INACTIVITY"
  | "DROP_IN_CALLS_PULL"
  | "DROP_IN_CALLS_PUSH"
  | "WELCOME_MESSAGE_ONLY_EXTRA";

interface ServiceField<Subject, State> {
  state?: State;
  description?: string;
  update: (state: State) => Subject;
  mutate: (state: State) => Promise<unknown>;
}

export type Service<Feature extends string, Subject, State = any> = Record<Feature, ServiceField<Subject, State>>;

type PT = definitions["therapist_full"];

const useService = (): Service<Feature, PT, boolean> => {
  const { data: pt } = useTherapist();
  const { id, feature_flags = [] } = pt || {};

  const toggle = (flag: string) => (state: boolean) =>
    produce(pt!, pt => {
      pt.feature_flags.find(feature => feature.flag === flag)!.enabled = state;
    });

  const patched = (flag: string) => (state: boolean) =>
    api("PATCH /therapists/{id}", {
      params: [id!],
      // @ts-ignore
      data: { feature_flags: [{ flag, enabled: state }] },
    });

  return feature_flags.reduce((agg, { flag, enabled, description }) => {
    const feature = String(flag);
    return {
      ...agg,
      [feature]: {
        state: enabled,
        description,
        update: toggle(feature),
        mutate: patched(feature),
      },
    };
  }, Object.create(null));
};

export default useService;
