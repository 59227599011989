import React, { useState } from "react";
import { BoxButton, CDialog, Icon } from "shared";
import { Close } from "@material-ui/icons";
import RemoveOfflineVisitAndBillingForm from "./RemoveOfflineVisitAndBillingForm";
import { useTranslation } from "react-i18next";
import usePatient from "routes/patient/queries/usePatient";

const sormlandOrgNumber = "559202-4987";

const RemoveOfflineVisitAndBilling: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const { data: patient } = usePatient();

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const showRemoveOfflineVisitAndBilling =
    patient?.payer?.organization_number === sormlandOrgNumber && patient.offline_visit_claimed_at && patient.deleted_at;

  if (!showRemoveOfflineVisitAndBilling) return null;

  return (
    <>
      <BoxButton startIcon={<Icon of={Close} error />} onClick={openDialog}>
        {t("patients.remove_offline_visit_and_billing")}
      </BoxButton>

      <CDialog open={isOpen} onClose={closeDialog}>
        <RemoveOfflineVisitAndBillingForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default RemoveOfflineVisitAndBilling;
