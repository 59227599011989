import React from "react";
import { isUsMarket } from "utils/market";
import RCMHeader from "shared/RCMHeader";
import CDMTable from "./components/CDMTable";

const CDM: React.VFC = () => {
  if (!isUsMarket) return null;

  return (
    <>
      <RCMHeader />
      <CDMTable />
    </>
  );
};

export default CDM;
