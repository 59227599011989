import React from "react";
import { Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CAutocomplete, CRadioGroup, Pane, Text } from "shared";
import { useFormContext } from "react-hook-form";

export interface Week {
  label: string;
  protocol: boolean;
  appointment: boolean;
}

interface Props {
  weekNumber: string;
  week: Week;
  edit: boolean;
}

export const ProtocolSelector: React.VFC<Props> = ({ week, weekNumber, edit }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const typeName = `weekly_config.${weekNumber}.type`;
  const labelName = `weekly_config.${weekNumber}.label`;

  const currentType = watch(typeName, week.protocol ? "protocol" : "none");

  // if (!edit && currentType === "none") return null;

  return (
    <Pane dark>
      <Grid container style={{ marginBottom: 12 }}>
        <Grid item xs={12} md={6} style={{ paddingLeft: 4 }}>
          <Text style={{ fontWeight: 500, fontSize: 20 }}>
            {
              // @ts-ignore
              t(`care_event_configurations.${weekNumber}`)
            }
          </Text>
        </Grid>
        <Grid item xs={12} md={6}>
          <CRadioGroup
            disabled={!edit}
            name={typeName}
            row
            items={[
              { label: t("none"), value: "none" },
              { label: t("protocol"), value: "protocol" },
            ]}
            defaultValue={currentType}
          />
        </Grid>
      </Grid>
      {currentType !== "none" && (
        <Box>
          <CAutocomplete
            required
            disabled={!edit}
            name={labelName}
            label={t("form.note_type")}
            getOptionValue={option => option.value}
            getOptionLabel={({ name }) => {
              // @ts-ignore
              return t(`dashboard.tables.notes.types.${name.toUpperCase()}`) as string;
            }}
            loading={false}
            options={protocolTypes}
            defaultValue={week.label}
          />
        </Box>
      )}
    </Pane>
  );
};

const protocolTypes = [
  { name: "protocol_weekly_w1", value: "protocol_weekly_w1" },
  { name: "protocol_weekly_w2", value: "protocol_weekly_w2" },
  { name: "protocol_weekly_w3", value: "protocol_weekly_w3" },
  { name: "protocol_weekly_w4", value: "protocol_weekly_w4" },
  { name: "protocol_weekly_w5", value: "protocol_weekly_w5" },
  { name: "protocol_weekly_w6", value: "protocol_weekly_w6" },
  { name: "protocol_weekly_w7", value: "protocol_weekly_w7" },
  { name: "protocol_weekly_w8", value: "protocol_weekly_w8" },
  { name: "protocol_weekly_w9", value: "protocol_weekly_w9" },
  { name: "protocol_weekly_w10", value: "protocol_weekly_w10" },
  { name: "protocol_weekly_w11", value: "protocol_weekly_w11" },
  { name: "protocol_weekly_w12", value: "protocol_weekly_w12" },
  { name: "treatment", value: "treatment" },
  { name: "discharge", value: "discharge" },
  { name: "progress", value: "progress" },
  { name: "protocol_discharge", value: "protocol_discharge" },
  { name: "protocol_monthly", value: "protocol_monthly" },
  { name: "protocol_quarterly", value: "protocol_quarterly" },
];
