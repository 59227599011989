import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { CDialog, Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import usePatient from "../../queries/usePatient";
import ChangePayerForm from "./ChangePayerForm";
import { paneStyles } from "shared/styles";

const Payer: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);
  const { t } = useTranslation();
  const { data: patient } = usePatient();

  if (!patient) return null;
  const { payer } = patient;

  return (
    <div data-testid="payer">
      <Text paragraph caption secondary>
        {t("patients.payer")}
      </Text>

      <Pane dark className={paneStyles.grid}>
        <Text light>{t("patients.payers.name")}:</Text>
        <Text bold>{payer?.name}</Text>

        <Button onClick={openDialog} aria-label="Change PT">
          {t("buttons.change")}
        </Button>
        <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="change-pt">
          <ChangePayerForm onClose={closeDialog} />
        </CDialog>
      </Pane>
    </div>
  );
};

export default Payer;
