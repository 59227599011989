import React, { useState } from "react";
import { Button, Container } from "@material-ui/core";
import { VFlex, ProgressButton, DialogSuccessNotice, useErrorContext, CTextField, Text, CAutocomplete } from "shared";
import { useTranslation } from "react-i18next";
import useUpdateInsurance from "routes/patient/queries/useUpdateInsurance";
import useInsuranceProfile, { ClaimOrderType, fetchInsuranceProfile } from "routes/patient/queries/useInsuranceProfile";
import { FormProvider, useForm } from "react-hook-form";
import useInsurancePayers from "routes/patient/queries/useInsurancePayers";
import { useQueryClient } from "react-query";
import useUpdateCopayConsent from "routes/patient/queries/useUpdateCopayConsent";
import { delay, fullName, queryKeys } from "utils/misc";
import { FORM_NOTIFICATION_DELAY } from "theme";
import { useId } from "utils/hooks";
import usePatient from "routes/patient/queries/usePatient";
import { definitions } from "api";

type InsuranceProfile = definitions["us_insurance_profile"];
export interface FormVars {
  member_id: string;
  payer_id: number;
}

interface Props {
  onClose: () => void;
  claim_order_type: ClaimOrderType;
}

const UpdateInsuranceForm: React.VFC<Props> = ({ onClose, claim_order_type }) => {
  const form = useForm<FormVars>();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [isCheckingInsurance, setIsCheckingInsurance] = useState(false);
  const insurancePayers = useInsurancePayers();
  const { mutateAsync: updateInsurance } = useUpdateInsurance();
  const { mutateAsync: updateCopayConsent } = useUpdateCopayConsent();
  const setErrors = useErrorContext();
  const queryClient = useQueryClient();
  const id = useId();
  const profiles = useInsuranceProfile();
  const { data: patient } = usePatient();

  if (!patient) return null;

  const profile = profiles.data?.find(profile => profile.claim_order_type === claim_order_type);
  const secondary = claim_order_type === "secondary";

  const submit = form.handleSubmit(async ({ member_id, payer_id }) => {
    const payer = insurancePayers.data!.find(payer => payer.id === payer_id)!;
    setIsCheckingInsurance(true);

    const pollInsuranceProfile = async (tries = 10): Promise<InsuranceProfile> => {
      const insuranceProfileArray = (await fetchInsuranceProfile(id)) ?? [];
      const insuranceProfile = insuranceProfileArray.find(profile => profile.claim_order_type === claim_order_type);
      if (insuranceProfile === undefined) {
        throw Error("Could not find insurance");
      }
      if (insuranceProfile.status === "pending") {
        if (tries <= 0) {
          throw Error("Timeout");
        }
        await delay(1000);
        return await pollInsuranceProfile(tries - 1);
      }
      return insuranceProfile;
    };

    try {
      await updateInsurance({
        claim_order_type,
        member_id,
        insurance_company_id: payer.id,
      });
      const insuranceProfile = await pollInsuranceProfile();
      if (insuranceProfile.status !== "eligible") {
        setErrors(`Insurance is ${insuranceProfile.status} status`);
      } else {
        if (!secondary) {
          await updateCopayConsent(payer.copay_consent);
        }
        setSuccess(true);
        await delay(FORM_NOTIFICATION_DELAY);
        onClose();
      }
    } catch (error) {
      setErrors(t`insurance.could_not_update_insurance`, error);
    } finally {
      setIsCheckingInsurance(false);
      queryClient.invalidateQueries(queryKeys.insuranceProfile(id));
    }
  });

  if (success) return <DialogSuccessNotice heading={t`insurance.patient_eligible`} onClose={onClose} />;

  return (
    <Container>
      <Text heading bold id="update-insurance">
        {secondary ? t`insurance.update_secondary_insurance` : t`insurance.update_primary_insurance`}
      </Text>
      <Text paragraph mb={4}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <VFlex>
            <CAutocomplete
              defaultValue={profile?.us_insurance_company?.id}
              loading={insurancePayers.isLoading}
              getOptionLabel={opt => opt.name || "UNKNOWN"}
              getOptionValue={opt => opt.id}
              margin="normal"
              fullWidth
              options={insurancePayers.data ?? []}
              required
              label={secondary ? t`insurance.secondary` : t`insurance.primary`}
              name="payer_id"
            />
            <CTextField
              margin="normal"
              fullWidth
              required
              label={t`insurance.memberid`}
              name="member_id"
              defaultValue={profile?.member_id}
            />
          </VFlex>
          <VFlex alignItems="center" gap={2} my={5}>
            <ProgressButton loading={isCheckingInsurance}>{t`buttons.save`}</ProgressButton>
            <Button disabled={isCheckingInsurance} onClick={onClose}>
              {t`buttons.cancel`}
            </Button>
          </VFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default UpdateInsuranceForm;
