import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useUpdateLicenses = () => {
  const id = useId();

  return useMutation((state_licences: string[]) =>
    api("PATCH /therapists/{id}/state_licenses", {
      params: [id],
      data: {
        state_licences,
        us_licenses: state_licences,
      } as any,
    })
  );
};

export default useUpdateLicenses;
