import { Box, Button } from "@material-ui/core";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CTextField, Dimmer, HFlex, Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import { Breakpoint, SPACING } from "theme";
import styled from "styled-components";
import { stripDeletedTagFromEmail } from "utils/misc";
import { css } from "@emotion/css";
import useTherapist from "../queries/useTherapist";

const FieldXS = styled(CTextField)`
  & input {
    padding-top: 7px;
    padding-bottom: 7px;
  }
`;

interface Props {
  setEdit: (editing: boolean) => void;
}

export type Fields = Record<string, string>;

const PersonalForm: React.VFC<Props> = ({ setEdit }) => {
  const form = useForm<Fields>();
  const { t } = useTranslation();
  const { data: pt } = useTherapist();
  if (!pt) return null;

  // const params = useParams<{ id: string }>();
  // const id = parseInt(params.id, 10);
  // const setErrors = useErrorContext();
  // const [mutate] = useMutation<Result, Vars>(UPDATE_PERSONAL, {
  //   ignoreResults: true,
  // });

  const submit = form.handleSubmit(async fields => {
    // setEdit(false);
    // try {
    //   const { data } = await mutate({
    //     variables: { id, ...fields },
    //     optimisticResponse: {
    //       __typename: Schema.Mutation,
    //       update: {
    //         __typename: Schema.Therapist,
    //         id,
    //         ...fields,
    //       },
    //     },
    //   });
    //   const errors = data?.update.errors;
    //   errors && setErrors(errors);
    // } catch (err) {
    //   setErrors([err.message]);
    // }
  });

  const onCancel = () => setEdit(false);
  const { formState } = form;

  return (
    <Box position="relative" zIndex={2}>
      <Pane dark>
        <FormProvider {...form}>
          <form onSubmit={submit}>
            <Dimmer />
            <div className={styles.grid}>
              <Text light>{t("form.first_name")}</Text>
              <FieldXS
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!formState.errors.first_name}
                helperText={formState.errors.first_name?.message}
                name="first_name"
                defaultValue={pt.first_name}
              />
              <Text light>{t("form.last_name")}</Text>
              <FieldXS
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!formState.errors.last_name}
                helperText={formState.errors.last_name?.message}
                name="last_name"
                defaultValue={pt.last_name}
              />
              <Text light>{t("form.state_zip")}</Text>
              <FieldXS
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!formState.errors.state_zip}
                helperText={formState.errors.state_zip?.message}
                name="state_zip"
                defaultValue={pt.state_zip}
              />
              <Text light>{t("form.address")}</Text>
              <FieldXS
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!formState.errors.address}
                helperText={formState.errors.address?.message}
                name="address"
                defaultValue={pt.address}
              />
              <Text light>{t("form.birth")}</Text>
              <FieldXS
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!formState.errors.birth_date}
                helperText={formState.errors.birth_date?.message}
                name="birth_date"
                defaultValue={pt.birthday}
              />
              <Text light>{t("form.phone")}</Text>
              <FieldXS
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!formState.errors.phone}
                helperText={formState.errors.phone?.message}
                name="phone"
                type="tel"
                defaultValue={pt.phone_number}
              />
              <Text light>{t("form.email")}</Text>
              <FieldXS
                fullWidth
                required
                size="small"
                autoComplete="off"
                name="email"
                type="email"
                defaultValue={pt.email && stripDeletedTagFromEmail(pt.email)}
              />
              <Text light>{t("settings.profile_information.time_zone")}</Text>
              <FieldXS
                fullWidth
                required
                size="small"
                autoComplete="off"
                error={!!formState.errors.time_zone}
                helperText={formState.errors.time_zone?.message}
                name="time_zone"
                defaultValue={pt.time_zone}
              />
            </div>
            <HFlex mt={2} gap={3} alignItems="baseline" justifyContent="flex-end">
              <Button onClick={onCancel}>{t("buttons.cancel")}</Button>
              <Button type="submit" variant="contained" color="primary" size="medium">
                {t("buttons.save")}
              </Button>
            </HFlex>
          </form>
        </FormProvider>
      </Pane>
    </Box>
  );
};

export default PersonalForm;

const styles = {
  grid: css`
    display: grid;
    grid-auto-rows: 45px;
    align-items: center;
    grid-column-gap: ${SPACING}px;
    @media screen and (max-width: ${Breakpoint.sm - 1}px) {
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${Breakpoint.sm}px) {
      grid-template-columns: auto 1fr;
    }
  `,
};
