import {
  ChangeHCP,
  CloseAccount,
  ReopenAccount,
  InvitePatient,
  StartOutOfOffice,
  EndOutOfOffice,
  AddStripeCustomer,
} from "./";
import { Text } from "shared";
import { useTranslation } from "react-i18next";
import useTherapist from "../queries/useTherapist";
import useTimeOffPeriod from "../queries/useTimeOffPeriod";

const Actions = () => {
  const { t } = useTranslation();

  const { data: pt } = useTherapist();
  const { data } = useTimeOffPeriod(pt?.time_off_period?.id);

  const showStartOutOfOffice = () => {
    return (
      data?.status === undefined ||
      ["failed", "pending", "ended"].includes(data.status) ||
      pt?.time_off_period?.status === "ended"
    );
  };

  return (
    <div>
      <Text paragraph caption secondary>
        {t`patients.actions`}
      </Text>
      {pt?.deleted_at ? <ReopenAccount /> : <CloseAccount />}
      <InvitePatient />
      <ChangeHCP />
      {showStartOutOfOffice() ? (
        <>
          <StartOutOfOffice />
          {data?.status === "failed" && data?.resulting_errors && <div>Error: {data?.resulting_errors[0]}</div>}
        </>
      ) : (
        <EndOutOfOffice />
      )}
      <AddStripeCustomer />
    </div>
  );
};
export default Actions;
