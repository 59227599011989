import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { Info, CheckCircle } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { TreatmentActiveScope } from "routes/other/queries/marketTreatmentAvailability";
import { Treatment } from "./ManageTreatmentsForm";
import { Icon } from "shared";

interface Props {
  treatment: Treatment;
  onConfirm: () => void;
  onClose: () => void;
  confirmed: boolean;
}

const TreatmentActivationFeedback: React.VFC<Props> = ({ treatment, onConfirm, onClose, confirmed }) => {
  const { t } = useTranslation();

  const TreatmentScopeLabels: Record<TreatmentActiveScope, string> = {
    current_therapists: t("manage_treatments.all_current_therapists"),
    incoming_therapists: t("manage_treatments.all_incoming_therapists"),
    incoming_patients: t("manage_treatments.all_incoming_patients"),
  };

  return (
    <Container>
      {confirmed ? (
        <ActivatedContainer>
          <H2>{t("manage_treatments.activated_header")}</H2>
          <Icon of={CheckCircle} size="52px" success />
        </ActivatedContainer>
      ) : (
        <>
          <H3>
            {t("manage_treatments.activate_for", {
              joint: treatment.joint.toLowerCase(),
              scope: TreatmentScopeLabels[treatment.scope].toLowerCase(),
            })}
          </H3>
          <ActivationContainer>
            <InfoMessage>
              <Info color="primary" />
              <p>{t("manage_treatments.activation_warning")}</p>
            </InfoMessage>
            <Buttons>
              <Button onClick={onClose}>{t("manage_treatments.cancel_button_label")}</Button>
              <Button variant="contained" color="primary" onClick={onConfirm}>
                {t("manage_treatments.activate_button_label")}
              </Button>
            </Buttons>
          </ActivationContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 19px 19px;
`;

const ActivatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eff9f3;
  border-radius: 8px;
  padding: 27px;
`;

const H2 = styled.h2`
  margin-bottom: 21px;
  font-weight: 500;
`;

const H3 = styled.h3`
  margin-bottom: 21px;
  font-weight: 500;
`;

const ActivationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #edf4fc;
  border-radius: 8px;
  padding: 16px;
`;

const InfoMessage = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  & > svg {
    margin-right: 16px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 40px;
`;

export default TreatmentActivationFeedback;
