import { useQuery } from "react-query";
import api from "api";
import { queryKeys } from "utils/misc";

const useInvoices = () => {
  const key = queryKeys.usInvoices();

  return useQuery(key, () => {
    return api.parse("GET /us_invoices");
  });
};

export default useInvoices;
