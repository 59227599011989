import api from "api";
import { useQuery } from "react-query";
import { Country } from "utils/market";
import { queryKeys } from "utils/misc";

const usePayers = (country: Country) => {
  const key = queryKeys.payers(country);
  return useQuery(key, () => api.parse("GET /payers", { query: { country } }));
};

export default usePayers;
