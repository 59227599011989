import React, { useState } from "react";
import { BoxButton, CDialog, Icon } from "shared";
import StartOutOfOfficeForm from "./StartOutOfOfficeForm";
import { BeachAccessRounded } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import useTherapist from "routes/therapist/queries/useTherapist";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  progressWrapper: {
    marginLeft: 16,
  },
}));

const StartOutOfOffice: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const { data: pt } = useTherapist();

  return (
    <>
      <BoxButton startIcon={<Icon of={BeachAccessRounded} color="primary" success />} onClick={openDialog}>
        {t("therapists.out_of_office.start.button")}
        {pt?.time_off_period?.status === "pending" && (
          <div className={classes.progressWrapper}>
            <CircularProgress size={32} />
          </div>
        )}
      </BoxButton>

      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="closeAccount">
        <StartOutOfOfficeForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default StartOutOfOffice;
