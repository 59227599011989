import React from "react";
import Radio from "@material-ui/core/Radio";
import { FormControl, FormLabel, RadioGroup, RadioGroupProps } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Controller, useFormContext } from "react-hook-form";
import { Text } from "shared";

type Item = {
  value: any;
  label: React.ReactNode;
  disabled?: boolean;
};

interface Props extends RadioGroupProps {
  name: string;
  label?: string;
  items: Item[];
  disabled?: boolean;
}

const CRadioGroup: React.VFC<Props> = ({ items, name, defaultValue, label, disabled = false, ...rest }) => {
  const { control } = useFormContext();
  const value = control.watchInternal(name, defaultValue);

  return (
    <FormControl disabled={disabled}>
      {label && <FormLabel>{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        as={
          <RadioGroup name={name} value={value} {...rest}>
            {items.map(item => (
              <FormControlLabel
                key={String(item.value)}
                control={
                  <Radio
                    value={item.value}
                    color="primary"
                    onChange={() => control.setValue(name, item.value)}
                    disabled={item.disabled}
                  />
                }
                label={<Text tiny>{item.label}</Text>}
              />
            ))}
          </RadioGroup>
        }
      />
    </FormControl>
  );
};

export default CRadioGroup;
