import React from "react";
import { Button, Box } from "@material-ui/core";
import { VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import useRemoveOfflineVisitAndBilling from "routes/patient/queries/useRemoveOfflineVisitAndBilling";
import usePatient from "routes/patient/queries/usePatient";
import { useQueryClient } from "react-query";
import { fullName, queryKeys } from "utils/misc";

interface Props {
  onClose: () => void;
}

const RemoveOfflineVisitAndBillingForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { mutateAsync, isLoading } = useRemoveOfflineVisitAndBilling();
  const { data: patient } = usePatient();

  if (!patient) return null;

  const removeOfflineVisitAndBilling = async () => {
    const patientKey = queryKeys.patient(patient.id);
    onClose();

    try {
      await mutateAsync();
    } catch (err) {
      setErrors(t`errors.could_not_remove_offline_visit_and_billing`, err);
    } finally {
      queryClient.invalidateQueries(patientKey);
    }
  };

  return (
    <>
      <Text heading bold center>
        {t("patients.remove_offline_visit_and_billing")}
      </Text>
      <Text center paragraph mb={3}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>
      <Box mx={2}>
        <VFlex alignItems="center" gap={2} my={5}>
          <ProgressButton loading={isLoading} onClick={removeOfflineVisitAndBilling}>
            {t("patients.remove_offline_visit_and_billing")}
          </ProgressButton>
          <Button disabled={isLoading} onClick={onClose}>
            {t("buttons.cancel")}
          </Button>
        </VFlex>
      </Box>
    </>
  );
};

export default RemoveOfflineVisitAndBillingForm;
