import React from "react";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import styled from "styled-components";
import { capitalizeFirstLetter, queryKeys } from "utils/misc";
import { useQueryClient } from "react-query";
import useToggleEnrollments from "../../queries/useToggleJoints";
import { Pane, Text, useErrorContext } from "shared";
import useTherapist from "routes/therapist/queries/useTherapist";
import { useTranslation } from "react-i18next";

const StyledFormGroup = styled(FormGroup)`
  justify-content: space-evenly;
`;

const InvitePatientForm: React.VFC = () => {
  const { data: pt } = useTherapist();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { mutateAsync } = useToggleEnrollments();
  const { t } = useTranslation();

  if (!pt) return null;

  const {
    hip_treatment_enrollment_enabled,
    knee_treatment_enrollment_enabled,
    back_treatment_enrollment_enabled,
    hand_treatment_enrollment_enabled,
    shoulder_treatment_enrollment_enabled,
    neck_treatment_enrollment_enabled,
    foot_treatment_enrollment_enabled,
    elbow_treatment_enrollment_enabled,
    lower_leg_treatment_enrollment_enabled,
    wrist_treatment_enrollment_enabled,
    fall_prevention_treatment_enrollment_enabled,
    osteoporosis_treatment_enrollment_enabled,
  } = pt;

  const joints = {
    hip_treatment_enrollment_enabled,
    knee_treatment_enrollment_enabled,
    back_treatment_enrollment_enabled,
    hand_treatment_enrollment_enabled,
    shoulder_treatment_enrollment_enabled,
    neck_treatment_enrollment_enabled,
    foot_treatment_enrollment_enabled,
    elbow_treatment_enrollment_enabled,
    lower_leg_treatment_enrollment_enabled,
    wrist_treatment_enrollment_enabled,
    fall_prevention_treatment_enrollment_enabled,
    osteoporosis_treatment_enrollment_enabled,
  };

  const handleChange = async (joint: keyof typeof joints) => {
    const ptKey = queryKeys.therapist(pt.id);
    const rollbackData = queryClient.getQueryData(ptKey);
    queryClient.cancelQueries(ptKey);
    queryClient.setQueryData(ptKey, old => Object.assign(old, { [joint]: !joints[joint] }));
    try {
      await mutateAsync({ ...joints, [joint]: !joints[joint] });
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      queryClient.invalidateQueries(ptKey);
      setErrors(err);
    }
  };

  const parseJointName = (joint: string) => {
    const jointParts = joint.split("_");
    if (jointParts[1] !== "treatment") {
      return `${jointParts[0]} ${jointParts[1]}`;
    }

    return jointParts[0];
  };

  return (
    <>
      <Text paragraph caption secondary>
        {t`therapist_profile.treatment_toggle_title`}
      </Text>
      <Pane dark p={2}>
        <StyledFormGroup row>
          {(Object.entries(joints) as [keyof typeof joints, boolean][]).map(([joint, enabled]) => {
            const jointName = parseJointName(joint);

            return (
              <FormControlLabel
                key={joint}
                labelPlacement="start"
                label={<Text caption>{capitalizeFirstLetter(jointName)}</Text>}
                control={
                  <Switch checked={enabled} onChange={() => handleChange(joint)} name={jointName} color="primary" />
                }
              />
            );
          })}
        </StyledFormGroup>
      </Pane>
    </>
  );
};

export default InvitePatientForm;
