import { en } from "assets/translations/en";
import { sv } from "assets/translations/sv";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

localStorage.setItem("language", process.env.REACT_APP_LOCALE);

export const defaultNS = "translation";
export const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  ns: ["translation"],
  defaultNS,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
