import { useMutation } from "react-query";
import api from "api";

type Vars = {
  payer_id: number;
  token: string;
};

const useCreateInvoice = () => {
  return useMutation(({ payer_id, token }: Vars) => {
    return api.parse("POST /us_invoices", {
      query: {
        payer_id,
      },
      data: {
        token,
      },
    });
  });
};

export default useCreateInvoice;
