import api from "api";
import { useQuery } from "react-query";

export const useCareEventConfiguration = (id: string) => {
  return useQuery(["care_event_configuration", id], () => {
    return api.parse("GET /care_event_configurations/{id}", [id]);
  });
};

export default useCareEventConfiguration;
