import { CheckCircleOutline } from "@material-ui/icons";
import React from "react";
import { Box, BoxProps, Button } from "@material-ui/core";
import { VFlex, Text, Icon } from "shared";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
  heading: string;
  body?: string;
  onClose: () => void;
}

const DialogSuccessNotice: React.VFC<Props> = ({ heading, body, onClose, ...rest }) => {
  const { t } = useTranslation();

  return (
    <VFlex justifyContent="center" alignItems="center" mb={6} data-testid="success-notice" {...rest}>
      <Icon of={CheckCircleOutline} size="80px" success />
      <Box mt={2} mb={4}>
        <Text heading center>
          {heading}
        </Text>
        {body && (
          <Text paragraph center>
            {body}
          </Text>
        )}
      </Box>
      <Button variant="contained" color="primary" onClick={onClose} aria-label="Confirm">
        {t`buttons.ok`}
      </Button>
    </VFlex>
  );
};

export default DialogSuccessNotice;
