import {
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import { TFuncKey, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useErrorContext } from "shared";
import muiTheme, { Colors } from "theme";
import { queryKeys } from "utils/misc";
import useCreateInvoice from "../queries/useCreateInvoice";
import useInvoicePreview from "../queries/useInvoicePreview";

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: muiTheme.palette.background.paper,
    boxShadow: "0px 2px 7px rgba(68, 104, 147, 0.2)",
    border: `2px solid ${Colors.BACKGROUND}`,
    borderRadius: "8px",
    padding: "20px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  tableHead: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    marginBottom: 5,
    borderBottom: `2px solid ${Colors.DARK_BLACK_FADED}`,
  },
  headCell: {
    color: Colors.DARK_BLACK,
  },
  table: {
    minWidth: 250,
    marginLeft: 28,
    border: "none",
  },
  tableContainer: {
    maxHeight: "70vh",
  },
}));

interface HeadCell {
  phraseTitle: TFuncKey;
}

const headCells: HeadCell[] = [
  {
    phraseTitle: "rcm.member_id",
  },
  {
    phraseTitle: "rcm.physician_id",
  },
];

type InvoicePreviewTableProps = {
  payerId: number;
  closeModal: () => void;
};

const InvoicePreviewTable: React.VFC<InvoicePreviewTableProps> = ({ payerId, closeModal }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { data } = useInvoicePreview(payerId);
  const { mutateAsync: createInvoice } = useCreateInvoice();
  const setError = useErrorContext();

  const handleCreateInvoice = async () => {
    if (!data || !data.token) return;

    try {
      await createInvoice({ payer_id: payerId, token: data.token });
      closeModal();
    } catch (err) {
      setError(t("rcm.error_create_invoice"), err);
    } finally {
      queryClient.invalidateQueries(queryKeys.usInvoicePreviews(payerId));
      queryClient.invalidateQueries(queryKeys.usInvoices());
    }
  };

  return (
    <div className={classes.paper}>
      <div className={classes.headerContainer}>
        {data && (
          <Button color="primary" onClick={handleCreateInvoice}>
            {t("rcm.create")}
          </Button>
        )}
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headCells.map((headCell, i) => {
                return (
                  <TableCell key={`cell-${headCell.phraseTitle}-${i}`} className={classes.headCell}>
                    {t(headCell.phraseTitle)}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.rows?.map(row => {
              return (
                <TableRow>
                  {[row.us_member_id, row.therapist_id].map((field, i) => {
                    return <TableCell key={`cell-${i}`}>{field}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InvoicePreviewTable;
