import React, { useState } from "react";
import { BoxButton, CDialog } from "shared";
import PublishIcon from "@material-ui/icons/Publish";
import { useTranslation } from "react-i18next";
import Options, { Type } from "./Options";
import UploadABNForm from "./UploadAbnForm";
import UploadReferralForm from "./UploadReferralForm";
import UploadPOCForm from "./UploadPOCForm";
import { isUsMarket } from "utils/market";

const UploadDocument: React.VFC = () => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<Type | "options" | null>(null);
  if (!isUsMarket) return null;

  const closeDialog = () => setMode(null);
  const openDialog = () => setMode("options");

  const getContent = () => {
    switch (mode) {
      case "options":
        return (
          <CDialog maxWidth="sm" fullWidth open onClose={closeDialog} aria-labelledby="document-type">
            <Options onClose={closeDialog} onSelect={setMode} />
          </CDialog>
        );
      case Type.ABN:
        return (
          <CDialog maxWidth="sm" fullWidth open onClose={closeDialog} aria-labelledby="upload-abn">
            <UploadABNForm onClose={closeDialog} />
          </CDialog>
        );
      case Type.Referral:
        return (
          <CDialog maxWidth="sm" fullWidth open onClose={closeDialog} aria-labelledby="upload-referral">
            <UploadReferralForm onClose={closeDialog} />
          </CDialog>
        );
      case Type.POC:
        return (
          <CDialog maxWidth="sm" fullWidth open onClose={closeDialog} aria-labelledby="upload-poc">
            <UploadPOCForm onClose={closeDialog} />
          </CDialog>
        );
      default:
        return null;
    }
  };

  const content = getContent();

  return (
    <>
      <BoxButton onClick={openDialog} startIcon={<PublishIcon />} aria-label="Upload document">
        {t("referral.upload_document")}
      </BoxButton>
      {content}
    </>
  );
};

export default UploadDocument;
