import { Image, Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { styles } from "../styles";
import jointacademy_logo from "assets/Joint_Academy_Logo_RGB.png";

export const PageHeader: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <View fixed style={styles.pageHeader}>
      <Image src={jointacademy_logo} style={{ height: "40px" }} />
      <Text>{t("patients.uk_discharge_letter.header.line_1")}</Text>
      <Text>{t("patients.uk_discharge_letter.header.line_2")}</Text>
      <Text>{t("patients.uk_discharge_letter.header.line_3")}</Text>
    </View>
  );
};
