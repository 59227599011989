import { green, red, grey } from "@material-ui/core/colors";

export const FORM_NOTIFICATION_DELAY = 2500;

export const SPACING = 10;

export enum Breakpoint {
  xs = 480,
  sm = 600,
  md = 960,
  mi = 1050,
  lg = 1280,
  xl = 1920,
}

export enum FontSize {
  xxs = 13,
  xs = 14,
  sm = 16,
  md = 18,
  lg = 22,
  xl = 26,
}

export const Colors = {
  BACKGROUND: "#ECECEC",
  JA_BLUE: "#5096E8",
  NAVIGATION: "#536479",
  NAVIGATION_INACTIVE: "#8D9BAD",
  PRIMARY_TEXT: "#384A5F",
  SECONDARY_TEXT: "#7F94A9",
  ERROR: red[500],
  ERROR_LIGHT: red[200],
  WARNING_BG: "rgb(253, 236, 234)",
  WARNING_TEXT: "rgb(97, 26, 21)",
  DARK_BLUE: "#3C4C60",
  DARK_BLACK: "#263238",
  DARK_BLACK_FADED: "rgba(38,50,56, 0.16)",
  DARK_BLUE_FADED: "rgba(80,150,232, 0.04)",
  JA_PINK: "#FD7B7B",
  PAPER: "#ffffff",
  SUCCESS: green[300],
  SUCCESS_LIGHT: green[200],
  DISABLED: "rgba(0, 0, 0, 0.4)",
};

export const Shadows = {
  sharp: "0px 0px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  soft: "0px 3px 7px rgba(39, 48, 58, 0.0973558)",
  sm: "0px 3px 10px rgba(68, 104, 147, 0.3)",
  md: "0px 10px 19px rgba(68, 104, 147, 0.3)",
};

export const fonts = {
  initial: {
    "font-size": FontSize.md,
    "font-weight": 400,
    "letter-spacing": "initial",
    "font-variant": "initial",
    "text-transform": "initial",
  },

  caption: {
    "font-size": FontSize.xs,
    "font-weight": 600,
    "letter-spacing": 1.25,
    "font-variant": "small-caps",
    "text-transform": "uppercase",
  },

  formHelp: {
    "line-height": 1,
    "font-size": FontSize.xs,
  },
};

const muiTheme = {
  spacing: SPACING,

  breakpoints: {
    values: {
      xs: Breakpoint.xs,
      sm: Breakpoint.sm,
      md: Breakpoint.md,
      lg: Breakpoint.lg,
      xl: Breakpoint.xl,
    },
  } as any,

  props: {
    MuiButtonBase: {
      disableRipple: true,
    },

    MuiButton: {
      size: "large" as any,
    },

    MuiTextField: {
      variant: "outlined" as any,
    },
  },

  palette: {
    background: {
      paper: Colors.PAPER,
      default: Colors.BACKGROUND,
    },

    text: {
      primary: Colors.PRIMARY_TEXT,
      secondary: Colors.SECONDARY_TEXT,
    },

    primary: {
      main: Colors.JA_BLUE,
      contrastText: Colors.PAPER,
    },

    secondary: {
      main: Colors.PAPER,
      contrastText: Colors.JA_BLUE,
    },

    success: {
      main: Colors.SUCCESS,
      light: Colors.SUCCESS_LIGHT,
    },

    error: {
      main: Colors.ERROR,
      light: Colors.ERROR_LIGHT,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        button: {
          fontFamily: "unset",
        },

        html: {
          width: "100vw",
          "overflow-x": "hidden",
        },

        "p, h1, h2, h3, h4, h5, h6": {
          fontWeight: "inherit",
          margin: "0 0 10px 0",
        },

        h1: {
          fontSize: FontSize.xl,
        },

        ul: {
          paddingLeft: 0,
          margin: 0,
        },

        li: {
          listStyleType: "none",
        },

        a: {
          lineHeight: 1,
          color: "inherit",

          "&:active": {
            color: "inherit",
          },

          textDecoration: "none",
        },

        "[role='button']": {
          cursor: "pointer",
        },

        body: {
          backgroundColor: Colors.BACKGROUND,
          color: Colors.PRIMARY_TEXT,
          ...fonts.initial,
        },

        "input[type='number']::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },

        "input[type='number']::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },

        "input[type=number]": {
          "-moz-appearance": "textfield",
        },
      },
    },

    MuiAutocomplete: {
      popper: {
        background: Colors.PAPER,
        boxShadow: Shadows.md,
        borderRadius: "5px",
      },
      loading: {
        textAlign: "center",
      },
    },

    MuiChip: {
      root: {
        backgroundColor: Colors.BACKGROUND,
      },
      deleteIcon: {
        color: "rgba(56, 74, 95, 0.36)",
        "&:hover": {
          color: "rgba(56, 74, 95, 0.46)",
        },
      },
    },

    MuiFab: {
      root: {
        boxShadow: Shadows.md,
      },
      secondary: {
        "&:hover": {
          backgroundColor: "#f7f7f7",
        },
      },
    },

    MuiFilledInput: {
      root: {
        backgroundColor: Colors.PAPER,
        "&:hover": {
          backgroundColor: Colors.BACKGROUND,
        },
      },
    },

    MuiInput: {
      underline: {
        "&::before": {
          border: "none",
        },
      },
    },

    MuiGrid: {
      item: {
        flexGrow: 1,
      },
    },

    MuiListItem: {
      root: {
        display: "block",
      },
    },

    MuiTabs: {
      root: {
        color: Colors.NAVIGATION,
      },

      indicator: {
        height: 4,
        backgroundColor: Colors.NAVIGATION,
        borderRadius: "5px 5px 0 0",
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(14, 64, 126, 0.45)",
      },
    },

    MuiTable: {
      root: {
        "border-width": 1,
        "border-style": "solid",
        "border-color": Colors.BACKGROUND,
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: "none",
        "white-space": "nowrap",
        paddingTop: SPACING,
        paddingBottom: SPACING,
      },
      head: {
        color: Colors.SECONDARY_TEXT,
      },
    },

    MuiTableHead: {
      root: {
        backgroundColor: grey[100],

        "& .MuiTypography-root": {
          cursor: "pointer",
          userSelect: "none",
        },
      },
    },

    MuiTableBody: {
      root: {
        "& tr": {
          "border-bottom-width": 1,
          "border-bottom-style": "solid",
          "border-bottom-color": Colors.BACKGROUND,
        },
        backgroundColor: Colors.PAPER,
      },
    },

    MuiPaper: {
      elevation1: {
        boxShadow: "none",
        backgroundColor: "initial",
      },
    },

    MuiOutlinedInput: {
      root: {
        backgroundColor: Colors.PAPER,
      },
    },

    MuiTypography: {
      paragraph: {
        marginBottom: SPACING,
      },
    },

    MuiFormControlLabel: {
      label: {
        ...fonts.initial,
        fontSize: 16,
      },
    },

    MuiFormHelperText: {
      root: {
        color: Colors.ERROR,
        ...fonts.formHelp,
      },
    },

    MuiAvatar: {
      root: {
        width: "60px",
        height: "60px",
      },
    },

    MuiAccordion: {
      root: {
        minWidth: 0,
      },
    },

    MuiAccordionSummary: {
      root: {
        padding: 0,
        minHeight: "0 !important",
      },
      content: {
        minWidth: 0,
        margin: "0 !important",
      },
      expandIcon: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    MuiAccordionDetails: {
      root: {
        marginTop: 2 * SPACING,
        padding: 0,
        display: "block",
      },
    },

    MuiAlert: {
      root: {
        "font-size": FontSize.xs,
      },
    },

    MuiInputBase: {
      root: {
        fontSize: "1em",
      },
      input: {
        padding: 0,
      },
    },

    MuiSwitch: {
      switchBase: {
        "&$checked": {
          transform: "translateX(12px)",
        },
      },

      thumb: {
        backgroundColor: Colors.PAPER,
      },

      track: {
        width: 34,
        height: 22,
        marginLeft: -4,
        marginTop: -4,
        borderRadius: 100,
        opacity: "1 !important",
        backgroundColor: "#aaa",
      },
    },

    MuiTooltip: {
      tooltip: {
        backgroundColor: Colors.JA_BLUE,
        color: "white",
        padding: 12,
        boxShadow: Shadows.sm,
      },
    },

    MuiButton: {
      root: {
        minWidth: "initial",
        ...fonts.caption,
      },
      text: {
        color: Colors.SECONDARY_TEXT,
      },
      contained: {
        padding: "8px 22px",
        borderRadius: 40,
      },

      containedSizeLarge: {
        padding: "16px 56px",
      },
    },

    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: Colors.JA_BLUE,
        },
      },
    },

    MuiToggleButtonGroup: {
      root: {
        display: "block",
      },
    },

    MuiToggleButton: {
      root: {
        width: "100%",
        border: "none",
        fontSize: "inherit",

        "&.Mui-selected": {
          "background-color": "#70baf840",
          "& .MuiToggleButton-label span": {
            color: Colors.JA_BLUE,
          },
        },
      },
    },
  },
};

export default muiTheme;
