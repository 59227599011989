import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

// TODO: move this to common route/queries, and take user_id as param to useMutation?

const OP = "POST /customers";

export type Vars = {
  //user_id: integer;
  stripe_account: string;
  siren?: string;
};

const useAddStripeCustomer = () => {
  const id = useId();

  return useMutation(({ stripe_account, siren }: Vars) =>
    api.parse(OP, {
      data: {
        user_id: id,
        stripe_account: stripe_account,
        siren: siren,
      },
    })
  );
};

export default useAddStripeCustomer;
