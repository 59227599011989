import React from "react";
import { Checkbox, Container, FormControlLabel } from "@material-ui/core";
import { CTextField, HFlex, DialogSuccessNotice, ProgressButton, Text, useErrorContext } from "shared";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { delay, fullName, queryKeys } from "utils/misc";
import { useId, useMountState } from "utils/hooks";
import { FORM_NOTIFICATION_DELAY } from "theme";
import useChangeHCP, { Vars } from "../../queries/useChangeHCP";
import useTherapist from "../../queries/useTherapist";
import { useQueryClient } from "react-query";
import { Edit } from "@material-ui/icons";

interface Props {
  onClose: () => void;
}

const ChangeHCPForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<Vars>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data } = useChangeHCP();
  const therapistId = useId();
  const { data: therapist } = useTherapist();

  const setErrors = useErrorContext();
  const getMountState = useMountState();

  const createNewHCP = form.watch("health_care_provider_new");

  const submit = form.handleSubmit(async fields => {
    const ptKey = queryKeys.therapist(therapistId);
    const rollbackData = queryClient.getQueryData(ptKey);
    queryClient.cancelQueries(ptKey);

    try {
      queryClient.setQueryData(ptKey, old => Object.assign(old, { hcp: fields.health_care_provider_name }));
      await mutateAsync(fields);

      if (!getMountState()) {
        return;
      }

      await delay(FORM_NOTIFICATION_DELAY);
      getMountState() && onClose();
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      queryClient.invalidateQueries(ptKey);
      queryClient.invalidateQueries(queryKeys.therapistSearch());
      setErrors(t("errors.could_not_change_hcp"), err);
    }
  });

  const onDefaultSubmit = async () => {
    const ptKey = queryKeys.therapist(therapistId);

    try {
      await mutateAsync({ reset_to_market_default: true });
      queryClient.invalidateQueries(ptKey);
      queryClient.invalidateQueries(queryKeys.therapistSearch());
    } catch (err) {
      setErrors(t("errors.could_not_change_hcp"), err);
    }
  };

  if (!isError && data) {
    return (
      <DialogSuccessNotice
        heading={t("form.changed_hcp.header")}
        body={`${t("form.successfully_changed_hcp_to")} ${data.hcp}`}
        onClose={onClose}
      />
    );
  }

  return (
    <Container>
      <Text heading bold id="change-hcp">
        {t("form.change_hcp.header")}
      </Text>
      <Text paragraph mb={4}>
        {fullName(therapist)}, <Text link>{therapist?.id}</Text>
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <CTextField
            name="health_care_provider_name"
            label={t("form.health_care_provider")}
            margin="normal"
            size="small"
            required
            fullWidth
            defaultValue={therapist?.hcp}
          />
          <FormControlLabel
            control={<Checkbox inputRef={form.register} name="health_care_provider_new" color="primary" />}
            label={
              <Text small light>
                {t("form.create_new_health_care_provider")}
              </Text>
            }
          />
          <HFlex justifyContent="center" my={3}>
            <ProgressButton loading={isLoading} endIcon={<Edit width={20} />}>
              {createNewHCP ? t("therapist.create_and_change_hcp.button") : t("therapist.change_hcp.button")}
            </ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
      <HFlex justifyContent="center" my={3}>
        <ProgressButton color="secondary" loading={isLoading} endIcon={<Edit width={20} />} onClick={onDefaultSubmit}>
          {t("therapist.reset_to_default_hcp.button")}
        </ProgressButton>
      </HFlex>
    </Container>
  );
};

export default ChangeHCPForm;
