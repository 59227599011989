import api from "api";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";
import { queryKeys } from "utils/misc";

export const fetchProviderGroupProfile = (id: number) =>
  api.parse("GET /patients/{patient_id}/us_provider_group_profiles", [id]);

const useProviderGroupsProfile = () => {
  const id = useId();
  const key = queryKeys.providerGroupProfile(id);
  return useQuery(key, () => fetchProviderGroupProfile(id));
};

export default useProviderGroupsProfile;
