import { TableCell } from "@material-ui/core";
import React from "react";
import { Text } from "shared";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const CCell: React.VFC<Props> = ({ children, className }) => (
  <TableCell className={className}>
    <Text tiny>{children}</Text>
  </TableCell>
);

export default CCell;
