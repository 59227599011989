import React from "react";
import { Error as ErrorIcon } from "@material-ui/icons";
import { Box, Button, Dialog } from "@material-ui/core";
import { Text, Icon, ErrorBox } from "shared";
import { useTranslation } from "react-i18next";
import useErrorContext from "./useErrorContext";

export interface Props {
  heading?: string | null;
  error: Error | null;
}

export const ErrorModal: React.VFC<Props> = ({ heading, error }) => {
  const setError = useErrorContext();
  const { t } = useTranslation();
  if (!error) return null;

  const submit = (event: React.FormEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
  };

  const onClose = () => {
    setError(null);
  };

  const head = heading ?? error?.name;

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose} aria-label="Error" transitionDuration={0}>
      <form onSubmit={submit}>
        <Box p={5}>
          <Text heading center large>
            <Icon of={ErrorIcon} error size={1.5} mr={1} />
            {head}
          </Text>
          <ErrorBox error={error} verbose centered dark my={4} />
          <Box textAlign="center">
            <Button type="submit" variant="contained" color="primary" aria-label="Confirm">
              {t`buttons.ok`}
            </Button>
          </Box>
        </Box>
      </form>
    </Dialog>
  );
};

export default ErrorModal;
