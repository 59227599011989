import api from "api";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";
import { isUsMarket } from "utils/market";
import { queryKeys } from "utils/misc";

export const OP = "GET /patients/{patient_id}/documents";

const useDocuments = () => {
  const patientId = useId();
  const key = queryKeys.documents(patientId);

  return useQuery(key, () => api.parse(OP, [patientId]), {
    enabled: isUsMarket,
  });
};

export default useDocuments;
