import { Box, CircularProgress } from "@material-ui/core";
import { VFlex } from "shared";

const LoadingComponent: React.VFC = () => (
  <VFlex height={1000} gap={3} justifyContent="center" alignItems="center">
    <Box mb={2}>
      <CircularProgress size={140} />
    </Box>
  </VFlex>
);

export default LoadingComponent;
