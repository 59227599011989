import { Box, BoxProps } from "@material-ui/core";
import React from "react";

interface Shortcuts {
  dark?: boolean;
  zeroBorderRadius?: boolean;
}

export type Props = BoxProps & Shortcuts;

const mapProps = ({ dark, bgcolor, ...rest }: Props): BoxProps => ({
  ...rest,
  bgcolor: dark ? "grey.100" : bgcolor || "background.paper",
});

const Pane: React.VFC<Props> = ({ zeroBorderRadius, ...rest }) => (
  <Box p={1.5} borderRadius={zeroBorderRadius ? 0 : 8} mb={1} {...mapProps(rest)} />
);

export default Pane;
