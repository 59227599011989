import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, MenuItem, Select } from "@material-ui/core";
import styled from "styled-components";
import { Breakpoint } from "theme";
import { CDialog, Icon, useErrorContext } from "shared";
import TreatmentList from "./TreatmentList";
import TreatmentActivationFeedback from "./TreatmentActivationFeedback";
import fetchTreatmentsForMarket, {
  useMarkets,
  enableTreatmentForMarket,
  enableTreatmentForNewTherapists,
  Joint,
  TreatmentOption,
  TreatmentMarket,
  bulkEnableTherapists,
  TreatmentActiveScope,
} from "../../queries/marketTreatmentAvailability";
import { queryKeys } from "utils/misc";

export type Treatment = {
  joint: Joint;
  scope: TreatmentActiveScope;
};
interface Props {
  onClose: () => void;
}

const ManageTreatmentsForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const setError = useErrorContext();
  const queryClient = useQueryClient();

  const { data: markets, isLoading: isLoadingMarkets } = useMarkets();
  const [selectedMarket, setSelectedMarket] = useState<TreatmentMarket | null>(null);
  const [selectedTreatment, setSelectedTreatment] = useState<Treatment | null>(null);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const [treatmentOptions, setTreatmentOptions] = useState<TreatmentOption[] | null>(null);

  const { mutateAsync: newTherapistsMutateAsync } = useMutation(enableTreatmentForNewTherapists);
  const { mutateAsync: marketMutateAsync } = useMutation(enableTreatmentForMarket);
  const { mutateAsync: bulkEnableMutateAsync } = useMutation(bulkEnableTherapists);

  const updateTreatmentList = useCallback(
    async (market: TreatmentMarket) => {
      let treatments = null;

      try {
        treatments = await fetchTreatmentsForMarket(market);
      } catch (error) {
        setError(t("manage_treatments.fetch_treatments_error_message"), error);
      }

      setTreatmentOptions(treatments);
    },
    [setError, setTreatmentOptions, t]
  );

  useEffect(() => {
    if (markets && !selectedMarket) {
      setSelectedMarket(markets[0]);
    }
  }, [markets, selectedMarket]);

  useEffect(() => {
    if (selectedMarket) {
      updateTreatmentList(selectedMarket);
    }
  }, [selectedMarket, updateTreatmentList]);

  const onSelectMarket = useCallback(
    (name: string) => {
      const market = markets?.find(m => m.countryName === name || m.usStateName === name);

      if (market) {
        setSelectedMarket(market);
      }
    },
    [markets]
  );

  const onClickActivate = useCallback(
    (scope: TreatmentActiveScope, joint: Joint) => {
      setConfirmed(false);

      setSelectedTreatment({
        joint,
        scope,
      });
      setConfirmationOpen(true);
    },
    [setConfirmationOpen, setConfirmed]
  );

  const onClickConfirmActivation = useCallback(async () => {
    if (selectedMarket && selectedTreatment) {
      try {
        const params = {
          country_code: selectedMarket.countryCode,
          us_state_code: selectedMarket.usStateCode,
          joint_treatment_availability: selectedTreatment.joint,
        };

        switch (selectedTreatment.scope) {
          case "current_therapists":
            await bulkEnableMutateAsync(params);
            queryClient.invalidateQueries(queryKeys.therapist());
            break;
          case "incoming_therapists":
            await newTherapistsMutateAsync(params);
            break;
          case "incoming_patients":
            await marketMutateAsync(params);
            break;
          default:
            throw new Error(`Invalid scope: ${selectedTreatment.scope}`);
        }

        updateTreatmentList(selectedMarket);

        setConfirmed(true);
        setTimeout(() => {
          setConfirmationOpen(false);
        }, 1500);
      } catch (error) {
        setError(t("manage_treatments.activate_treatment_error_message"), error);
        setConfirmationOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedMarket,
    selectedTreatment,
    updateTreatmentList,
    setConfirmed,
    bulkEnableMutateAsync,
    newTherapistsMutateAsync,
    marketMutateAsync,
    setConfirmationOpen,
    setError,
    t,
  ]);

  if (isLoadingMarkets) {
    return (
      <LoadingBox mx={3} textAlign="center">
        <CircularProgress />
      </LoadingBox>
    );
  }

  return (
    <Box mx={3}>
      <H1>
        {t("manage_treatments.header")}
        {markets && (
          <StyledSelect
            name="market"
            value={selectedMarket ? selectedMarket.usStateName || selectedMarket.countryName : ""}
            onChange={event => onSelectMarket(event.target.value as string)}
            disableUnderline
          >
            {markets.map(({ countryName, usStateName, Flag }, index) => (
              <MenuItem key={index} value={usStateName || countryName} disableGutters>
                <LocationItem>
                  {usStateName || countryName}
                  {Flag && <Icon of={Flag} />}
                </LocationItem>
              </MenuItem>
            ))}
          </StyledSelect>
        )}
      </H1>
      {treatmentOptions && <TreatmentList treatmentOptions={treatmentOptions} onClickActivate={onClickActivate} />}
      {selectedTreatment && (
        <CDialog maxWidth="xs" fullWidth open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
          <TreatmentActivationFeedback
            treatment={selectedTreatment}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={onClickConfirmActivation}
            confirmed={confirmed}
          />
        </CDialog>
      )}
    </Box>
  );
};

const LoadingBox = styled(Box)`
  padding: 42px;
`;

const H1 = styled.h1`
  display: flex;
  margin: auto 0 42px;
  font-size: 20px;
  font-weight: 300;

  @media screen and (max-width: ${Breakpoint.xs}px) {
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
  }
`;

const StyledSelect = styled(Select)`
  font-size: inherit;
`;

const LocationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;

  svg {
    margin: 0 16px;
  }
`;

export default ManageTreatmentsForm;
