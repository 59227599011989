import React from "react";
import { Button, Container } from "@material-ui/core";
import {
  CSelect,
  Text,
  FileUpload,
  useErrorContext,
  DialogSuccessNotice,
  ProgressButton,
  CTextField,
  HFlex,
} from "shared";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import useUploadABNDocument, { Vars } from "../../queries/useUploadABN";
import { delay, fullName, queryKeys } from "utils/misc";
import { FORM_NOTIFICATION_DELAY, SPACING } from "theme";
import { css } from "@emotion/css";
import { useQueryClient } from "react-query";
import { useId, useMountState } from "utils/hooks";
import usePatient from "routes/patient/queries/usePatient";

const options = [
  {
    value: "option_1",
    label: "Option 1",
  },
  {
    value: "option_2",
    label: "Option 2",
  },
  {
    value: "option_3",
    label: "Option 3",
  },
];

interface Props {
  onClose: () => void;
}

const UploadABNForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const setError = useErrorContext();
  const id = useId();
  const { mutateAsync, isLoading, isSuccess } = useUploadABNDocument();
  const form = useForm<Vars>();
  const queryClient = useQueryClient();
  const getMountState = useMountState();
  const { data: patient } = usePatient();

  if (!patient) return null;

  const onSubmit = form.handleSubmit(async data => {
    try {
      await mutateAsync(data);
      queryClient.invalidateQueries(queryKeys.documents(id));
      if (!getMountState()) return;
      await delay(FORM_NOTIFICATION_DELAY);
      getMountState() && onClose();
    } catch (error) {
      setError(t`errors.could_not_upload_abn`, error);
    }
  });

  if (isSuccess)
    return <DialogSuccessNotice heading={t`form.success`} body={t`form.document_uploaded`} onClose={onClose} />;

  return (
    <Container>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Text heading bold id="upload-abn">
            {t`form.upload_abn`}
          </Text>
          <Text paragraph mb={5}>
            {fullName(patient)}, <Text link>{patient.id}</Text>
          </Text>
          <div className={styles.grid}>
            <FileUpload label={t`patients.document`} size="small" name="file" required fullWidth />
            <CSelect label={t`form.option`} size="small" name="option" items={options} required>
              {({ label }) => label}
            </CSelect>
            <CTextField label={t`form.signed_at`} type="date" size="small" name="signed_at" required />
            <CTextField label={t`form.expires_at`} type="date" size="small" name="expires_at" required />
          </div>
          <HFlex justifyContent="center" gap={2} my={6}>
            <Button disabled={isLoading} onClick={onClose}>
              {t`buttons.cancel`}
            </Button>
            <ProgressButton loading={isLoading}>{t`buttons.upload`}</ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default UploadABNForm;

const styles = {
  grid: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${1.5 * SPACING}px;
  `,
};
