import api, { BodyParams } from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "POST /therapists/{therapist_id}/time_off_periods";

type Vars = BodyParams<typeof OP>;

const useStartOutOfOffice = () => {
  const id = useId();

  return useMutation(({ end_date }: Vars) => {
    return api.parse(OP, {
      params: [id],
      data: { end_date },
    });
  });
};

export default useStartOutOfOffice;
