import { css } from "@emotion/css";
import { Checkbox, TableCell, TextField } from "@material-ui/core";
import { KeyboardEventHandler } from "react";
import { Input } from "../ValueTable";

interface Props {
  editable?: boolean;
  value?: any;
  type?: Input;
  onChange: (value: any) => void;
}

const isTextInput = (type: Input): type is Input.Text | Input.Number | Input.Date =>
  type !== Input.Checkbox && !Array.isArray(type);

const Cell: React.VFC<Props> = ({ editable, value, type = Input.Text, onChange }) => {
  const change = ({ currentTarget: { value: newValue } }: React.SyntheticEvent<HTMLInputElement>) => {
    if (newValue === value) return;
    const parsed = type === Input.Number ? parseInt(newValue, 10) : newValue;
    onChange(parsed);
  };

  const keyDown: KeyboardEventHandler<HTMLInputElement> = ({ key, currentTarget: em }) => {
    if (key === "Escape") {
      em.value = value;
      em.blur();
    }
    if (key === "Enter") {
      em.blur();
    }
  };

  return (
    <TableCell>
      {isTextInput(type) ? (
        editable ? (
          <TextField
            variant="filled"
            key={value}
            fullWidth
            spellCheck={false}
            type={type}
            margin="none"
            defaultValue={String(value)}
            {...{ [type === Input.Date ? "onChange" : "onBlur"]: change }}
            onFocus={event => event.currentTarget.select()}
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              onKeyDown: keyDown,
              className: styles.input,
            }}
          />
        ) : (
          <span>{value}</span>
        )
      ) : (
        <Checkbox checked={!!value} onChange={() => onChange(!value)} className={styles.check} />
      )}
    </TableCell>
  );
};

export default Cell;

const styles = {
  check: css`
    padding: 0 !important;
  `,
  input: css`
    padding: 5px !important;
    min-width: 100px !important;
  `,
};
