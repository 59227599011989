import { spacing, SpacingProps as SpacingSystemProps } from "@material-ui/system";
import { fonts, Colors, SPACING, FontSize } from "theme";
import { createStyleReducer, composeStyle } from "utils/misc";
import styled, { css } from "styled-components";
import React, { HTMLAttributes } from "react";

const colors = {
  primary: Colors.PRIMARY_TEXT,
  secondary: Colors.SECONDARY_TEXT,
  error: Colors.ERROR,
  link: Colors.JA_BLUE,
  black: Colors.DARK_BLACK,
  success: Colors.SUCCESS,
  successLight: Colors.SUCCESS_LIGHT,
  errorLight: Colors.ERROR_LIGHT,
  disabled: Colors.DISABLED,
};

type Color = keyof typeof colors;

type Attributes = HTMLAttributes<HTMLElement>;

interface ColorSystemProps extends Partial<Record<Color, boolean>> {
  color?: Color;
}

interface SizeSystemProps {
  size?: number | string;
}

type TypographySystemProps = Partial<
  Record<
    | "bold"
    | "caption"
    | "center"
    | "dense"
    | "disabled"
    | "heavy"
    | "initial"
    | "large"
    | "light"
    | "mini"
    | "nowrap"
    | "right"
    | "small"
    | "tiny"
    | "truncate"
    | "xlarge",
    boolean
  >
>;

export interface TextProps
  extends Omit<Attributes, "color">,
    SpacingSystemProps,
    ColorSystemProps,
    TypographySystemProps {
  heading?: boolean;
  paragraph?: boolean;
}

export interface IconProps extends Omit<Attributes, "color">, SpacingSystemProps, ColorSystemProps, SizeSystemProps {
  of?: React.ComponentType;
}

const styleReducer = createStyleReducer(fonts, {
  whiteSpace: {
    nowrap: "nowrap",
  },
  overflow: {
    truncate: "hidden",
  },
  textOverflow: {
    truncate: "ellipsis",
  },
  marginBottom: {
    dense: `${0.25 * SPACING}px`,
  },
  fontSize: {
    mini: FontSize.xxs,
    tiny: FontSize.xs,
    small: FontSize.sm,
    large: FontSize.lg,
    xlarge: FontSize.xl,
  },
  fontWeight: {
    light: 200,
    bold: 500,
    heavy: 700,
  },
  textAlign: {
    center: "center",
  },
  float: {
    right: "right",
  },
  color: colors,
});

const styling = <T extends Record<string, any>>(props: T) => composeStyle(props, styleReducer);
const coloring = <T extends ColorSystemProps>({ color }: T) => color && `color: ${colors[color]};`;
const sizing = <T extends SizeSystemProps>({ size = "1.5rem" }: T) => css`
  vertical-align: 0.35em;
  line-height: 0;
  display: inline-block;
  > * {
    vertical-align: -0.5em;
    ${typeof size === "number"
      ? css`
          width: 1em;
          height: 1em;
          font-size: ${size}em;
        `
      : css`
          width: ${size};
          height: ${size};
        `}
  }
`;

const Span = styled.span<SpacingSystemProps & ColorSystemProps & TypographySystemProps>(styling, coloring, spacing);
const IconWrapper = styled.span<SpacingSystemProps & ColorSystemProps & SizeSystemProps>(
  styling,
  sizing,
  coloring,
  spacing
);

const Text = React.forwardRef<HTMLElement, TextProps>((props, ref) => {
  const { paragraph, heading } = props;
  return <Span as={paragraph ? "p" : heading ? "h1" : "span"} {...props} ref={ref} />;
});

export default Text;

export const Anchor = styled.a<SpacingSystemProps & ColorSystemProps & TypographySystemProps>(
  styling,
  coloring,
  spacing
);

// Icon wrapper for vertical centering in non-flex layout. Exposes useful props for color coding and sizing.
export const Icon = React.forwardRef<HTMLSpanElement, IconProps>(({ of: Icon, children, ...rest }, ref) => (
  <IconWrapper {...rest} ref={ref}>
    {Icon ? <Icon /> : children}
  </IconWrapper>
));
