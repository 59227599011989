import React from "react";
import { Button, Container } from "@material-ui/core";
import { VFlex, ProgressButton, Text, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useSubstituteTherapist from "../../queries/useSubstituteTherapist";
import { useId, useMountState } from "utils/hooks";
import { queryKeys } from "utils/misc";

interface Props {
  onClose: () => void;
}

const RemoveSubstituteTherapistForm: React.VFC<Props> = ({ onClose }) => {
  const id = useId();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const getMountState = useMountState();

  const { mutateAsync, isLoading } = useSubstituteTherapist();
  const submit = async () => {
    try {
      await mutateAsync(null);
      queryClient.invalidateQueries(queryKeys.patient(id));
      getMountState() && onClose();
    } catch (err) {
      setErrors(t`errors.generic`, err);
    }
  };

  return (
    <Container>
      <Text heading bold center mb={3} id="remove-substitute-pt">
        {t("remove_sub_form.remove_substitute")}
      </Text>
      <VFlex alignItems="center" gap={2} my={5}>
        <ProgressButton onClick={submit} loading={isLoading}>
          {t("buttons.confirm")}
        </ProgressButton>
        <Button disabled={isLoading} onClick={onClose}>
          {t("buttons.cancel")}
        </Button>
      </VFlex>
    </Container>
  );
};

export default RemoveSubstituteTherapistForm;
