import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    paddingTop: 150,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
    fontSize: 10,
  },
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    position: "absolute",
    top: 35,
    right: 35,
    fontSize: 8,
    textAlign: "right",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  section: {
    marginBottom: 10,
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  hidden: {
    color: "#fff",
  },

  table: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellFull: {
    width: "75%",
  },
  tableCell: {
    marginTop: 5,
    marginLeft: 5,
    marginBottom: 5,
  },
  underline: {
    textDecoration: "underline",
  },
});
