import React, { useState } from "react";
import useTFSubmission, { TFAnswer, TFField } from "../queries/useTFSubmission";
import { getDate, getTime } from "utils/time";
import { CDialog, Pane, Text } from "shared";
import { FontSize, SPACING } from "theme";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { PDFDownloadLink, Page, Text as PDFText, View, Document, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import usePatient from "../queries/usePatient";
import { isUsMarket } from "utils/market";

interface TFParsedData {
  id: number;
  user_id: number;
  form_id: number;
  created_at: string;
  updated_at: string;
  data: MergedFormData[];
}

interface MergedFormData extends TFAnswer {
  title: string;
}

const getFormRowValue = (answer: MergedFormData): string => {
  let type = answer.type;
  switch (true) {
    case type === "text":
      return answer.text ? answer.text : "N/A";
    case type === "number":
      return answer.number ? answer.number.toString() : "N/A";
    case type === "boolean":
      return answer.boolean ? "vraie" : "faux";
    case type === "choice":
      return answer.choice ? answer.choice.label || answer.choice.other : "N/A";
    case type === "choices":
      let value = answer.choices?.labels ? answer.choices.labels.join(", ") : answer.choices?.other;
      return value ? value : "N/A";
    default:
      return "N/A";
  }
};

const TFSubmissions: React.VFC = () => {
  const [expanded, setExpanded] = useState(false);
  const expand = () => setExpanded(true);
  const collapse = () => setExpanded(false);
  const { data: submissions } = useTFSubmission();
  const { data: patient } = usePatient();

  if (isUsMarket) return null;
  if (!(submissions && submissions.length > 0) || !patient) return null;

  const submissionsMergedData = submissions.map((entry: any) => {
    let fields = entry.submission.form_response.definition.fields;
    let answers = entry.submission.form_response.answers;
    let mergedData = fields.map(
      (field: TFField): MergedFormData => {
        let answer = answers.filter((answer: TFAnswer) => answer.field.ref === field.ref);
        return { title: field.title, ...answer[0] };
      }
    );
    return {
      id: entry.id,
      user_id: entry.user_id,
      form_id: entry.form_id,
      created_at: entry.created_at,
      updated_at: entry.updated_at,
      data: mergedData,
    };
  });

  const { health_journal, first_name, last_name, phone_number, email, therapist, birthday } = patient;

  type PDFProps = {
    submission: TFParsedData;
  };

  const PDFDocument: React.VFC<PDFProps> = ({ submission }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.question}>
            <PDFText>
              <b>{"Full name - JA"}</b>
            </PDFText>
          </View>
          <View style={styles.answer}>
            <PDFText>{first_name + " " + last_name}</PDFText>
          </View>
          <View style={styles.question}>
            <PDFText>
              <b>{"Contact (Email + Phone) - JA"}</b>
            </PDFText>
          </View>
          <View style={styles.answer}>
            <PDFText>{email + ", " + phone_number}</PDFText>
          </View>
          <View style={styles.question}>
            <PDFText>
              <b>{"Birthday - JA"}</b>
            </PDFText>
          </View>
          <View style={styles.answer}>
            <PDFText>{birthday}</PDFText>
          </View>
          <View style={styles.question}>
            <PDFText>
              <b>{"Therapist - JA"}</b>
            </PDFText>
          </View>
          <View style={styles.answer}>
            <PDFText>{therapist?.first_name + " " + therapist?.last_name + " #" + therapist?.id}</PDFText>
          </View>
          <View style={styles.question}>
            <PDFText>
              <b>{"Gender - JA"}</b>
            </PDFText>
          </View>
          <View style={styles.answer}>
            <PDFText>{health_journal?.gender}</PDFText>
          </View>
          <View style={styles.question}>
            <PDFText>
              <b>{"Height - JA"}</b>
            </PDFText>
          </View>
          <View style={styles.answer}>
            <PDFText>{health_journal?.height}</PDFText>
          </View>
          <View style={styles.question}>
            <PDFText>
              <b>{"Weight - JA"}</b>
            </PDFText>
          </View>
          <View style={styles.answer}>
            <PDFText>{health_journal?.weight}</PDFText>
          </View>
        </View>
        {submission.data.map((row: MergedFormData, x: number) => (
          <View key={x}>
            <View style={styles.question}>
              <PDFText>
                <b>{row.title}</b>
              </PDFText>
            </View>
            <View style={styles.answer}>
              <PDFText>{getFormRowValue(row)}</PDFText>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );

  return (
    <div>
      <Text paragraph caption secondary mt={1}>
        {"Typeform Submissions"}
      </Text>
      <Pane>
        <FormDetails>
          {submissionsMergedData.map(
            (submission: TFParsedData, i: number): JSX.Element => (
              <React.Fragment key={i}>
                <Text>{"#" + submission.id}</Text>
                <Text>{getDate(submission.created_at)}</Text>
                <Text>{getTime(submission.created_at)}</Text>
                <Text>{"FID#" + submission.form_id}</Text>
                <Button className="left" onClick={expand} data-test-id="view-all">
                  {"View"}
                </Button>
                <PDFDownloadLink
                  document={<PDFDocument submission={submission} key={i} />}
                  fileName={submission.id + ".pdf"}
                >
                  {({ blob, url, loading, error }) => {
                    return loading ? "Loading" : <Button className="left">Download</Button>;
                  }}
                </PDFDownloadLink>
                <CDialog
                  key={i}
                  maxWidth="md"
                  fullWidth
                  open={expanded}
                  onClose={collapse}
                  aria-labelledby="submissions"
                >
                  <PDFViewer>
                    <PDFDocument submission={submission} key={i} />
                  </PDFViewer>
                </CDialog>
              </React.Fragment>
            )
          )}
        </FormDetails>
      </Pane>
    </div>
  );
};

export default TFSubmissions;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  question: {
    fontSize: 10,
    marginBottom: 0,
    fontWeight: "bold",
    padding: 10,
    paddingBottom: 0,
    flexGrow: 1,
  },
  answer: {
    fontSize: 10,
    marginBottom: 0,
    padding: 10,
    paddingTop: 0,
    marginTop: 3,
    color: "blue",
    flexGrow: 1,
  },
});

const FormDetails = styled.div`
  display: grid;
  align-items: center;
  font-size: ${FontSize.xxs}px;
  grid-row-gap: ${SPACING}px;
  grid-column-gap: ${SPACING}px;
  justify-content: space-evenly;
  grid-template-columns: repeat(6, auto);
  gap: ${SPACING}px;
  white-space: nowrap;
`;
