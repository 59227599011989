import { useQuery, UseQueryResult, MutationFunction } from "react-query";
import api, { Result } from "api";
import { isUsMarket } from "utils/market";
import { FlagDE, FlagFR, FlagSE, FlagUK } from "assets";
import { ArrayEm } from "types";
import { Operation } from "api";

type BackendMarket = ArrayEm<Result<"GET /market_treatment_availability/markets">>;

const Flags: Record<string, React.ComponentType> = {
  de: FlagDE,
  fr: FlagFR,
  se: FlagSE,
  gb: FlagUK,
};

const UsStateNames: Record<string, string> = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  DC: "District of Columbia",
  AS: "American Samoa",
  GU: "Guam",
  MP: "Northern Mariana Islands",
  PR: "Puerto Rico",
  VI: "U.S. Virgin Islands",
};

export type TreatmentActiveScope = "current_therapists" | "incoming_therapists" | "incoming_patients";

export interface TreatmentMarket {
  countryName: string;
  countryCode: string;
  usStateCode?: string;
  usStateName?: string;
  Flag?: React.ComponentType;
}

export enum Joint {
  Hand = "hand",
  Shoulder = "shoulder",
  LowerBack = "back",
  Neck = "neck",
}

export interface TreatmentOption {
  joint: Joint;
  allCurrentTherapistsActive: boolean;
  incomingPatientsActive: boolean;
  incomingTherapistsActive: boolean;
}

export const useMarkets = (): UseQueryResult<TreatmentMarket[]> => {
  return useQuery("/market_treatment_availability/markets", async () => {
    const data = await api.parse("GET /market_treatment_availability/markets");

    const predicate = isUsMarket
      ? (market: BackendMarket) => market.country_code === "US"
      : (market: BackendMarket) => market.country_code !== "US";

    const filtered = data.filter(predicate).map((market: BackendMarket) => ({
      countryName: market.country_name,
      countryCode: market.country_code,
      usStateCode: market.us_state_code,
      usStateName: market.us_state_code ? UsStateNames[market.us_state_code] : undefined,
      Flag: Flags[market.country_code!],
    }));

    return filtered;
  });
};

type EnableTreatmentData = {
  joint_treatment_availability: string;
  country_code: string;
  us_state_code?: string;
};

const enableTreatment = (
  endpoint: Operation,
  { country_code, us_state_code, joint_treatment_availability }: EnableTreatmentData
): Promise<void> => {
  const data: EnableTreatmentData = {
    joint_treatment_availability,
    country_code: country_code.toUpperCase(),
  };

  if (us_state_code) {
    data.us_state_code = us_state_code.toUpperCase();
  }

  return api.parse(endpoint, { data } as any);
};

export const enableTreatmentForMarket: MutationFunction<void, EnableTreatmentData> = data =>
  enableTreatment("POST /market_treatment_availability/enable_treatment_for_market", data);

export const enableTreatmentForNewTherapists: MutationFunction<void, EnableTreatmentData> = data =>
  enableTreatment("POST /market_treatment_availability/enable_treatment_for_new_therapists", data);

export const bulkEnableTherapists: MutationFunction<void, EnableTreatmentData> = data =>
  enableTreatment("POST /market_treatment_availability/bulk_enable_therapist_joint_availability", data);

const fetchTreatmentsForMarket = async (market: TreatmentMarket): Promise<TreatmentOption[]> => {
  const upperCaseCountryCode = market.countryCode.toUpperCase();
  const upperCaseUsStateCode = market.usStateCode?.toUpperCase();

  const data = await api.parse("GET /market_treatment_availability", {
    query: { country_code: upperCaseCountryCode, us_state_code: upperCaseUsStateCode },
  });

  return [
    {
      joint: Joint.Hand,
      allCurrentTherapistsActive: data.all_therapists_offer!.hand!,
      incomingTherapistsActive: data.therapist_enrollment!.hand!,
      incomingPatientsActive: data.market_enabled!.hand!,
    },
    {
      joint: Joint.Shoulder,
      allCurrentTherapistsActive: data.all_therapists_offer!.shoulder!,
      incomingTherapistsActive: data.therapist_enrollment!.shoulder!,
      incomingPatientsActive: data.market_enabled!.shoulder!,
    },
    {
      joint: Joint.LowerBack,
      allCurrentTherapistsActive: data.all_therapists_offer!.back!,
      incomingTherapistsActive: data.therapist_enrollment!.back!,
      incomingPatientsActive: data.market_enabled!.back!,
    },
    {
      joint: Joint.Neck,
      allCurrentTherapistsActive: data.all_therapists_offer!.neck!,
      incomingTherapistsActive: data.therapist_enrollment!.neck!,
      incomingPatientsActive: data.market_enabled!.neck!,
    },
  ];
};

export default fetchTreatmentsForMarket;
