import { Switch } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { Pane, useErrorContext, Text } from "shared";
import { useTranslation } from "react-i18next";
import useTherapist from "../queries/useTherapist";
import useToggleInflow from "../queries/useToggleInflow";
import { queryKeys } from "utils/misc";

const ToggleInflow: React.VFC = () => {
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { t } = useTranslation();
  const { mutateAsync: toggleInflow } = useToggleInflow();
  const { data: pt } = useTherapist();

  if (!pt) return null;

  const { accepting_patients } = pt;

  const toggle = async () => {
    const ptKey = queryKeys.therapist(pt.id);
    const rollbackData = queryClient.getQueryData(ptKey);
    queryClient.cancelQueries(ptKey);

    try {
      queryClient.setQueryData(ptKey, old => Object.assign(old, { accepting_patients: !accepting_patients }));
      await toggleInflow(!accepting_patients);
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      queryClient.invalidateQueries(ptKey);
      queryClient.invalidateQueries(queryKeys.therapistSearch());
      setErrors(t`errors.could_not_change_patient_inflow`, err);
    }
  };

  return (
    <Pane
      component="label"
      role="button"
      dark
      py={1}
      mb={0}
      display="flex"
      alignItems="center"
      aria-label="Toggle inflow"
    >
      <Text light small>
        {t`form.accepting_patients`}
      </Text>
      <Text small ml="auto">
        {accepting_patients ? t`buttons.yes` : t`buttons.no`}
      </Text>
      <Switch color="primary" checked={pt.accepting_patients} onChange={toggle} />
    </Pane>
  );
};

export default ToggleInflow;
