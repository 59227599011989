import React, { useState } from "react";
import { BoxButton, CDialog, Icon } from "shared";
import { Payment } from "@material-ui/icons";
import AddStripeCustomerForm from "./AddStripeCustomerForm";
import { useTranslation } from "react-i18next";
import useTherapist from "../../queries/useTherapist";

const AddStripeCustomer: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const { data: therapist } = useTherapist();

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  // Blocking other countries for now...
  if (therapist?.has_customer || therapist?.country !== "France") return null;

  return (
    <>
      <BoxButton startIcon={<Icon of={Payment} />} onClick={openDialog}>
        {t("form.add_stripe_customer.title")}
      </BoxButton>

      <CDialog open={isOpen} onClose={closeDialog}>
        <AddStripeCustomerForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default AddStripeCustomer;
