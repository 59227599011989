import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Container } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { CAutocomplete, ProgressButton, Text, VFlex } from "shared";
import { useId } from "utils/hooks";
import { delay, fullName, queryKeys } from "utils/misc";
import { FORM_NOTIFICATION_DELAY } from "theme";
import useChangePayer from "routes/patient/queries/useChangePayer";
import usePatient from "routes/patient/queries/usePatient";
import useAllowedPayerTransitions from "routes/patientSearch/queries/useAllowedPayerTransitions";

type Payer = {
  id: number;
  name: string;
};

interface Props {
  onClose: () => void;
}

const ChangePayerForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const id = useId();
  const { data: patient } = usePatient();
  const { mutateAsync, isLoading } = useChangePayer();
  const { data, isLoading: isLoadingPayers } = useAllowedPayerTransitions(patient?.id);

  // @ts-ignore
  const payers: Payer[] = data?.payers;

  const form = useForm<any>({ defaultValues: { payer_id: patient?.payer?.id } });

  if (!patient) return null;

  const onSubmit = form.handleSubmit(async fields => {
    const patientKey = queryKeys.patient(id);

    try {
      await mutateAsync({ payer_id: fields.payer_id });
      await delay(FORM_NOTIFICATION_DELAY);
      onClose();
    } finally {
      queryClient.invalidateQueries(patientKey);
    }
  });

  return (
    <Container>
      <Text heading bold id="change-payer">
        {t("form.change_payer")}
      </Text>
      <Text paragraph mb={3}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>

      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <CAutocomplete
            getOptionValue={({ id }) => id}
            getOptionLabel={({ name }) => name ?? "NAME MISSING"}
            loading={isLoadingPayers}
            options={payers ?? []}
            name="payer_id"
            label={t("form.payer_name")}
            margin="normal"
            size="small"
            fullWidth
          />
          <VFlex alignItems="center" gap={2} my={5}>
            <Text error center>
              {t("payers.save_warn")}
            </Text>
            <ProgressButton loading={isLoading}>{t("buttons.save")}</ProgressButton>
            <Button disabled={isLoading} onClick={onClose}>
              {t("buttons.cancel")}
            </Button>
          </VFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default ChangePayerForm;
