import api from "api";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";
import { queryKeys } from "utils/misc";

export type TFSubmission = {
  id: number;
  user_id: number;
  form_id: number;
  created_at: string;
  updated_at: string;
  submission: {
    form_response: {
      answers: TFAnswer[];
      definition: {
        fields: TFField[];
      };
    };
  };
};
export interface TFField {
  id: string;
  ref: string;
  title: string;
  type: string;
}

export interface TFAnswer {
  field: {
    id: string;
    ref: string;
    type: string;
  };
  type: string;
  text?: string;
  number?: number;
  boolean?: boolean;
  choice?: {
    label: string;
    other: string;
  };
  choices?: {
    labels: string[];
    other: string;
  };
}

const useTFSubmission = () => {
  const patientId = useId();
  const key = queryKeys.tfSubmissions(patientId);

  return useQuery(key, () => api.parse("GET /users/{user_id}/typeform_submissions", [patientId]));
};

export default useTFSubmission;
