import api from "api";
import { useMutation } from "react-query";
import { HasId } from "types";

const OP = "PUT /time_off_periods/{time_off_period_id}/end_time_off";

interface Vars extends HasId {}

const useEndOutOfOffice = () =>
  useMutation(({ id }: Vars) =>
    api(OP, {
      params: [id],
    })
  );

export default useEndOutOfOffice;
