import { useQuery } from "react-query";
import api from "api";
import { queryKeys } from "utils/misc";

const useFrInvoices = (month: string) => {
  const key = queryKeys.frInvoices(month);

  return useQuery(key, () => {
    return api.parse("GET /fr_invoices", { query: { month } });
  });
};

export default useFrInvoices;
