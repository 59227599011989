import { StrictRJSFSchema } from "@rjsf/utils";

export type Lesson = {
  identifier: string;
  coverImage: string;
  targetJoints: Joint[];
  swedish: TranslatedLesson;
  english: TranslatedLesson;
};

export type TranslatedLesson = {
  title: string;
  parts: LessonPart[];
};

const JOINTS = [
  "neck",
  "spine",
  "lower_back",
  // "upper_back", // not used?
  "knee",
  "hip",
  "shoulder",
  "foot",
  "elbow",
  "hand",
  "osteoporosis",
  "fall_prevention",
] as const;

type Joint = typeof JOINTS[number];

type LessonPart = {
  contents: Content[];
  questions: Question[];
};

type Content = {
  type: ContentType;
  content: string;
};

const CONTENT_TYPES = ["heading", "paragraph", "image"] as const;

type ContentType = typeof CONTENT_TYPES[number];

type Question = {
  title: string;
  tip_description: string;
  answers: Answer[];
};

type Answer = {
  text: string;
  correct: boolean;
};

export const example: Lesson = {
  identifier: "first_lesson_example",
  coverImage:
    "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/cover_images/1.whatisOA-74328074295eb74cb56b582b5dbf6a0015ed002b91a058e6fe2c36425eeace98.svg",
  targetJoints: ["knee", "hip", "lower_back"],
  swedish: {
    title: "Mitt första lektionsexempel",
    parts: [
      {
        contents: [
          {
            type: "heading",
            content: "Vad är artros?",
          },
          {
            type: "paragraph",
            content: "Artros är en sjukdom som innefattar försämrad ledfunktion och sämre kvalitet på brosket...",
          },
          {
            type: "image",
            content:
              "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/1/what-is-OA-1a-dd048fbb687ec56f7351380cb281a8c5c869fc39c19ad2f49a44a50ad152b406.svg",
          },
          {
            type: "heading",
            content: "Var finns artros?",
          },
          {
            type: "paragraph",
            content: "Artros förekommer över hela jordklotet, och är den vanligaste ledsjukdomen i världen...",
          },
          {
            type: "image",
            content:
              "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/text_lesson_6_part_1a-fe81ca4b4b4cad57e35a2c8f41c03f215fd86bad400b4ccefb9c65f30ff7b0b0.svg",
          },
        ],
        questions: [
          {
            title: "Vad gör artros?",
            tip_description: "Tänk dig två isbitar som ligger an mot varandra...",
            answers: [
              {
                text: "Stoppar produktionen av ledvätska.",
                correct: false,
              },
              {
                text: "Påverkar brosket.",
                correct: true,
              },
              {
                text: "Rubbar kroppens immunförsvar.",
                correct: false,
              },
              {
                text: "Skadar produktionen av vita blodkroppar.",
                correct: false,
              },
            ],
          },
          {
            title: "Varför drabbas knän och höfter av artros oftare än andra leder?",
            tip_description: "Knä- och höftlederna får bära upp ganska mycket av din kroppsvikt...",
            answers: [
              {
                text: "De används mer än andra leder, vilket gör att det är lättare att belasta dem fel.",
                correct: false,
              },
              {
                text: "Eftersom de ligger långt bort från hjärtat är det svårare att pumpa dit friskt blod.",
                correct: false,
              },
              {
                text: "För att de bär upp mycket vikt, och på grund av deras konstruktion.",
                correct: true,
              },
              {
                text: "De skadas oftare än andra leder, vilket gör att artros lättare utvecklas.",
                correct: false,
              },
            ],
          },
        ],
      },
      {
        contents: [
          {
            type: "heading",
            content: "Vad är enkla maskiner?",
          },
          {
            type: "paragraph",
            content: "Enkla maskiner är grundläggande mekaniska enheter som används för att utföra arbete...",
          },
          {
            type: "image",
            content:
              "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/17/prostheticsurgeryprosandcons-2a-ff2e544ea1964d503d128766a882108805cf9afce85592ee705f7bca976cc231.svg",
          },
          {
            type: "heading",
            content: "Typer av enkla maskiner",
          },
          {
            type: "paragraph",
            content:
              "Det finns sex vanliga typer av enkla maskiner: hävstång, lutande plan, kil, skruv, hjul och axel...",
          },
          {
            type: "image",
            content:
              "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/25/naturalremediesandothernonprescriptiondrugs-1a-28ea54aea36b30c295b3484109bd9b11dfd5c7dcdd1d10d3cf3248a566e0c9ed.svg",
          },
        ],
        questions: [
          {
            title: "Vad är syftet med en hävstång?",
            tip_description: "En hävstång används för att öka kraften som kan appliceras på ett objekt...",
            answers: [
              {
                text: "För att minska avståndet mellan två punkter.",
                correct: false,
              },
              {
                text: "För att öka kraften som kan appliceras.",
                correct: true,
              },
              {
                text: "För att öka hastigheten av arbete.",
                correct: false,
              },
              {
                text: "För att ändra riktningen av kraften.",
                correct: false,
              },
            ],
          },
          {
            title: "Vad är ett exempel på en lutande plan?",
            tip_description:
              "En lutande plan används för att minska kraften som krävs för att lyfta ett tungt objekt...",
            answers: [
              {
                text: "En hammare.",
                correct: false,
              },
              {
                text: "En ramp för att rulla upp tunga föremål.",
                correct: true,
              },
              {
                text: "En skiftnyckel.",
                correct: false,
              },
              {
                text: "En glidbana.",
                correct: false,
              },
            ],
          },
        ],
      },
    ],
  },
  english: {
    title: "My First Lesson Example",
    parts: [
      {
        contents: [
          {
            type: "heading",
            content: "What can I do today to improve my condition?",
          },
          {
            type: "paragraph",
            content: "Above all, you should not stop using your joints because you have osteoarthritis...",
          },
          {
            type: "image",
            content:
              "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/1/what-is-OA-1a-dd048fbb687ec56f7351380cb281a8c5c869fc39c19ad2f49a44a50ad152b406.svg",
          },
          {
            type: "heading",
            content: "What can I take away from this lesson?",
          },
          {
            type: "paragraph",
            content: "Our joints, together with our skeleton, muscles, and other organs, become weaker as we age...",
          },
          {
            type: "image",
            content:
              "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/text_lesson_6_part_1a-fe81ca4b4b4cad57e35a2c8f41c03f215fd86bad400b4ccefb9c65f30ff7b0b0.svg",
          },
        ],
        questions: [
          {
            title: "What should you do to improve your osteoarthritis?",
            tip_description: "When we avoid activity altogether, the joints make the body believe...",
            answers: [
              {
                text: "Use the joint as little as possible to avoid wearing it out.",
                correct: false,
              },
              {
                text: "Find the right amount of load so that the cartilage is motivated to repair itself.",
                correct: true,
              },
              {
                text: "Use the joints as much as possible even though it’s very painful.",
                correct: false,
              },
              {
                text:
                  "It’s impossible to improve osteoarthritis - you can only bite the bullet until it’s time for surgery.",
                correct: false,
              },
            ],
          },
          {
            title: "What’s the most important thing to keep in mind when you have osteoarthritis?",
            tip_description:
              "Even though proper nutrition and enough sleep are foundational building blocks for a healthy body...",
            answers: [
              {
                text: "Putting load on the joint in the correct way.",
                correct: true,
              },
              {
                text: "Using the joints as little as possible.",
                correct: false,
              },
              {
                text: "Getting the proper nutrition and enough sleep.",
                correct: false,
              },
              {
                text: "Completely ignoring the pain.",
                correct: false,
              },
            ],
          },
        ],
      },
      {
        contents: [
          {
            type: "heading",
            content: "What are Simple Machines?",
          },
          {
            type: "paragraph",
            content: "Simple machines are basic mechanical devices used to perform work...",
          },
          {
            type: "image",
            content:
              "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/17/prostheticsurgeryprosandcons-2a-ff2e544ea1964d503d128766a882108805cf9afce85592ee705f7bca976cc231.svg",
          },
          {
            type: "heading",
            content: "Types of Simple Machines",
          },
          {
            type: "paragraph",
            content:
              "There are six common types of simple machines: lever, inclined plane, wedge, screw, wheel and axle...",
          },
          {
            type: "image",
            content:
              "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons/25/naturalremediesandothernonprescriptiondrugs-1a-28ea54aea36b30c295b3484109bd9b11dfd5c7dcdd1d10d3cf3248a566e0c9ed.svg",
          },
        ],
        questions: [
          {
            title: "What is the purpose of a lever?",
            tip_description: "A lever is used to increase the force that can be applied to an object...",
            answers: [
              {
                text: "To decrease the distance between two points.",
                correct: false,
              },
              {
                text: "To increase the force that can be applied.",
                correct: true,
              },
              {
                text: "To increase the speed of work.",
                correct: false,
              },
              {
                text: "To change the direction of the force.",
                correct: false,
              },
            ],
          },
          {
            title: "What is an example of an inclined plane?",
            tip_description: "An inclined plane is used to reduce the force required to lift a heavy object...",
            answers: [
              {
                text: "A hammer.",
                correct: false,
              },
              {
                text: "A ramp for rolling up heavy objects.",
                correct: true,
              },
              {
                text: "A wrench.",
                correct: false,
              },
              {
                text: "A slide.",
                correct: false,
              },
            ],
          },
        ],
      },
    ],
  },
};

export const empty: Lesson = {
  identifier: "",
  coverImage: "",
  targetJoints: [],
  swedish: {
    title: "",
    parts: [],
  },
  english: {
    title: "",
    parts: [],
  },
};

// ChatGPT generated from Lesson type
export const jsonSchema: StrictRJSFSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    identifier: {
      type: "string",
    },
    coverImage: {
      type: "string",
    },
    targetJoints: {
      type: "array",
      items: {
        type: "string",
        enum: [
          "neck",
          "spine",
          "lower_back",
          "knee",
          "hip",
          "shoulder",
          "foot",
          "elbow",
          "hand",
          "osteoporosis",
          "fall_prevention",
        ],
      },
    },
    swedish: {
      type: "object",
      properties: {
        title: {
          type: "string",
        },
        parts: {
          type: "array",
          items: {
            type: "object",
            properties: {
              contents: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    type: {
                      type: "string",
                      enum: ["heading", "paragraph", "image"],
                    },
                    content: {
                      type: "string",
                    },
                  },
                  required: ["type", "content"],
                },
              },
              questions: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    title: {
                      type: "string",
                    },
                    answers: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          text: {
                            type: "string",
                          },
                          correct: {
                            type: "boolean",
                            default: false,
                          },
                        },
                        required: ["text", "correct"],
                      },
                    },
                    tip_description: {
                      type: "string",
                    },
                  },
                  required: ["title", "tip_description", "answers"],
                },
              },
            },
            required: ["contents", "questions"],
          },
        },
      },
      required: ["title", "parts"],
    },
    english: {
      type: "object",
      properties: {
        title: {
          type: "string",
        },
        parts: {
          type: "array",
          items: {
            type: "object",
            properties: {
              contents: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    type: {
                      type: "string",
                      enum: ["heading", "paragraph", "image"],
                    },
                    content: {
                      type: "string",
                    },
                  },
                  required: ["type", "content"],
                },
              },
              questions: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    title: {
                      type: "string",
                    },
                    tip_description: {
                      type: "string",
                    },
                    answers: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          text: {
                            type: "string",
                          },
                          correct: {
                            type: "boolean",
                            default: false,
                          },
                        },
                        required: ["text", "correct"],
                      },
                    },
                  },
                  required: ["title", "tip_description", "answers"],
                },
              },
            },
            required: ["contents", "questions"],
          },
        },
      },
      required: ["title", "parts"],
    },
  },
  required: ["identifier", "coverImage", "targetJoints", "swedish", "english"],
};
