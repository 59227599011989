import React from "react";
import { Button, Container } from "@material-ui/core";
import { CTextField, HFlex, VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { getDate } from "utils/time";
import useUpdateActivity from "routes/patient/queries/useUpdateActivity";
import { useId, useMountState } from "utils/hooks";
import { useQueryClient } from "react-query";
import { fullName, queryKeys } from "utils/misc";
import usePatient from "routes/patient/queries/usePatient";
import { css } from "@emotion/css";
import { FontSize } from "theme";
import { definitions } from "api";
import { Vars } from "../../queries/useUpdateActivity";

type Activity = definitions["activity"];

interface Props {
  activity: Activity;
  onClose: () => void;
}

const FunctionalityForm: React.VFC<Props> = ({ activity, onClose }) => {
  const id = useId();
  const form = useForm<Vars>();
  const setError = useErrorContext();
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useUpdateActivity(activity.id);
  const getMountState = useMountState();
  const queryClient = useQueryClient();
  const { data: patient } = usePatient();

  if (!patient) return null;

  const onSave = form.handleSubmit(async ({ repetitions }) => {
    try {
      await mutateAsync({ repetitions });
      queryClient.invalidateQueries(queryKeys.activities(id, activity.course_week_number!));
      getMountState() && onClose();
    } catch (err) {
      setError(t`errors.could_not_update_activity`, err);
    }
  });

  return (
    <Container>
      <Text heading bold id="edit-activity">
        {t`form.functionality_test`}
      </Text>
      <Text paragraph mb={2}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>
      <Text paragraph disabled mb={6}>
        {getDate(activity.start_date)}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={onSave}>
          <HFlex justifyContent="center" mb={6}>
            <CTextField
              asNumber
              autoFocus
              autoSelect
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 1,
                max: 50,
                className: styles.input,
              }}
              type="number"
              defaultValue={activity?.functionality?.repetitions || null}
              required
              name="repetitions"
              noClear
              label={t`form.repetitions`}
            />
          </HFlex>
          <VFlex alignItems="center" gap={2} mb={5}>
            <ProgressButton loading={isLoading} type="submit" variant="contained" color="primary">
              {t`buttons.save`}
            </ProgressButton>
            <Button onClick={onClose}>{t`buttons.cancel`}</Button>
          </VFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default FunctionalityForm;

const styles = {
  input: css`
    width: 125px !important;
    font-size: ${FontSize.xl}px !important;
    text-align: center !important;
  `,
};
