import React, { useState } from "react";
import { Button, Container } from "@material-ui/core";
import { Text, CDialog, CTextField, HFlex, VFlex, useErrorContext, Pane } from "shared";
import { useTranslation } from "react-i18next";
import { Breakpoint, FontSize, SPACING } from "theme";
import useUpdateHealthJournal from "../queries/useUpdateHealthJournal";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { css } from "@emotion/css";
import usePatient from "../queries/usePatient";
import produce from "immer";
import { fullName, queryKeys } from "utils/misc";

interface Vars {
  specific_joint_pain: number;
}

interface Dialog {
  isOpen: boolean;
  formDefault?: number;
}

const HealthJournal: React.VFC = () => {
  const { t } = useTranslation();
  const form = useForm<Vars>();
  const setError = useErrorContext();
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateHealthJournal();
  const [dialog, setDialog] = useState<Dialog>({ isOpen: false });
  const { data: patient } = usePatient();

  if (!patient) return null;

  const onSave = form.handleSubmit(async ({ specific_joint_pain }) => {
    setDialog({ isOpen: false });
    const queryKey = queryKeys.patient(patient.id);
    const rollbackData = queryClient.getQueryData(queryKey);
    queryClient.cancelQueries(queryKey);
    queryClient.setQueryData(
      queryKey,
      produce(draft => void Object.assign(draft.health_journal, { specific_joint_pain }))
    );

    try {
      await mutateAsync(specific_joint_pain);
    } catch (err) {
      queryClient.setQueryData(queryKey, rollbackData);
      setDialog({ isOpen: true, formDefault: specific_joint_pain });
      setError(err);
    } finally {
      queryClient.invalidateQueries(queryKey);
    }
  });

  const painRating = patient?.health_journal?.specific_joint_pain;

  return (
    <div>
      <Text paragraph caption secondary>
        {t`patients.medical_records.health_journal`}
      </Text>
      <Pane dark className={styles.grid}>
        <Text light>{t`patient.medical_records.initial_pain_rating`}: </Text>
        <Text bold>{painRating ?? t`patients.medical_records.not_applicable`}</Text>
        <Button onClick={() => setDialog({ isOpen: true })}>{t`buttons.edit`}</Button>
        <CDialog
          maxWidth="xs"
          fullWidth
          open={dialog.isOpen}
          onClose={() => setDialog({ isOpen: false })}
          aria-labelledby="initial-pain-rating"
        >
          <Container>
            <FormProvider {...form}>
              <form onSubmit={onSave}>
                <Text heading bold id="initial-pain-rating">
                  {t`form.initial_pain_rating.header`}
                </Text>
                <Text paragraph mb={6}>
                  {fullName(patient)}, <Text link>{patient.id}</Text>
                </Text>
                <HFlex justifyContent="center">
                  <CTextField
                    asNumber
                    autoFocus
                    autoSelect
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0,
                      max: 10,
                      className: styles.input,
                    }}
                    type="number"
                    defaultValue={dialog.formDefault ?? painRating ?? null}
                    required
                    name="specific_joint_pain"
                    noClear
                    label="0-10"
                  />
                </HFlex>
                <VFlex alignItems="center" gap={2} my={5}>
                  <Button type="submit" variant="contained" color="primary">
                    {t`buttons.save`}
                  </Button>
                  <Button onClick={() => setDialog({ isOpen: false })}>{t`buttons.cancel`}</Button>
                </VFlex>
              </form>
            </FormProvider>
          </Container>
        </CDialog>
      </Pane>
    </div>
  );
};

export default HealthJournal;

const styles = {
  grid: css`
    display: grid;
    grid-auto-rows: 45px;
    align-items: center;
    grid-column-gap: ${SPACING}px;
    & *:nth-child(3n + 0) {
      justify-self: end;
    }
    @media screen and (max-width: ${Breakpoint.sm - 1}px) {
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${Breakpoint.sm}px) {
      grid-template-columns: auto auto 1fr;
    }
  `,
  input: css`
    width: 125px !important;
    font-size: ${FontSize.xl}px !important;
    text-align: center !important;
  `,
};
