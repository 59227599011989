import { Pane, Text } from "shared";
import { Props as PaneProps } from "./Pane";
import React from "react";
import { css } from "@emotion/css";
import { APIError } from "api";
import { Box, Container } from "@material-ui/core";
import { WithChildren } from "types";

interface Props extends PaneProps {
  error: any;
  centered?: boolean;
  verbose?: boolean;
}

interface ParsedError {
  summaryEm: React.ReactNode;
  detailsEm?: React.ReactNode;
}

const Wrapper: React.VFC<WithChildren & Pick<Props, "centered">> = ({ centered, children }) => (
  <Text paragraph small error center={centered} mb={0} className={styles.breakWord}>
    {children}
  </Text>
);

const ErrorBox: React.VFC<Props> = ({ error, centered, verbose, dark, ...rest }) => {
  const parseError = (): ParsedError => {
    try {
      const { method, url, status, details } = JSON.parse(error?.message) as APIError;
      const summaryEm = (
        <Wrapper centered={centered}>
          <Text light>
            {method} <i>{url}</i> returned status{" "}
          </Text>
          {status}
        </Wrapper>
      );
      const detailsEm = (
        <Box component="ul" mt={1}>
          {details?.map(detail => (
            <li key={detail}>
              <Pane dark={dark}>
                {detail.split("\n").map(line => (
                  <Text key={line} paragraph tiny error mb={0} className={styles.breakWord}>
                    <code>{line}</code>
                  </Text>
                ))}
              </Pane>
            </li>
          ))}
        </Box>
      );
      return { summaryEm, detailsEm };
    } catch (err) {
      return { summaryEm: <Wrapper centered={centered}>{error?.message}</Wrapper> };
    }
  };

  const { summaryEm, detailsEm } = parseError();

  const containerEm = (
    <Container maxWidth="md">
      <>{detailsEm}</>
    </Container>
  );

  return (
    <Box {...rest}>
      {summaryEm}
      {verbose && (centered ? containerEm : detailsEm)}
    </Box>
  );
};

export default ErrorBox;

const styles = {
  breakWord: css`
    word-break: break-word;
  `,
};
