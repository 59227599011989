import React from "react";
import { TableBody, TableCell, TableRow, makeStyles, CircularProgress } from "@material-ui/core";
import { Colors } from "theme";
import useInvoices from "../queries/useInvoices";
import InvoiceModal from "./InvoiceModal";
import InvoicePreviewModal from "./InvoicePreviewModal";
import { getDate } from "../../../utils/time";

const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: Colors.DARK_BLUE_FADED,
  },
  row: {
    borderTop: `1px solid ${Colors.DARK_BLACK_FADED}`,
  },
  cell: {
    fontSize: 16,
  },
  bold: {
    fontWeight: 500,
    color: Colors.JA_BLUE,
  },
  centered: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    overflowY: "hidden",
  },
}));

const humanaPayerId = 3;
const keyMedicalHumanaPayerId = 413;

const InvoiceTableBody: React.VFC = () => {
  const { data } = useInvoices();
  const classes = useStyles();

  if (!data) {
    return (
      <TableBody>
        <TableRow style={{ border: "none" }}>
          <TableCell colSpan={4}>
            <div className={classes.loader}>
              <CircularProgress size={60} />
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      <TableRow className={classes.row} key={`RCM-table-row-humana`}>
        <TableCell colSpan={3} className={classes.cell}>
          Humana
        </TableCell>
        <TableCell>
          <InvoicePreviewModal payerId={humanaPayerId} />
        </TableCell>
      </TableRow>

      <TableRow className={classes.evenRow} key={`RCM-table-row-chenmed`}>
        <TableCell colSpan={3} className={classes.cell}>
          Key Medical Humana
        </TableCell>
        <TableCell>
          <InvoicePreviewModal payerId={keyMedicalHumanaPayerId} />
        </TableCell>
      </TableRow>

      {data.map((invoice, i: number) => {
        const {
          created_at,
          billed_at,
          payer: { name },
        } = invoice;

        const billedDate = billed_at ? getDate(billed_at) : "";
        const createdDate = created_at ? getDate(created_at) : "";

        return (
          <TableRow className={i % 2 === 0 ? classes.row : classes.evenRow} key={`RCM-table-row-${i}`}>
            {[name, createdDate, billedDate].map((field, i) => (
              <TableCell key={`table-body-cell-${i}`} className={classes.cell}>
                {field}
              </TableCell>
            ))}
            <TableCell>
              <InvoiceModal invoiceId={invoice.id} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default InvoiceTableBody;
