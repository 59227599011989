import { useTranslation } from "react-i18next";
import { Pane, Text } from "shared";
import usePatient from "../queries/usePatient";

const ExemptionCard: React.VFC = () => {
  const { t } = useTranslation();
  const { data: patient } = usePatient();
  const card = patient?.exemption_card;
  if (!card) return null;
  const { card_number, expiration_date } = card;

  return (
    <Pane dark>
      <Text paragraph dense>
        <Text light mr={1}>
          {t`patient.exemption_card`}:
        </Text>
        <Text bold>{card_number}</Text>
      </Text>
      <Text paragraph tiny dense>
        <Text light>{t`form.expires_at`}: </Text>
        <Text bold>{expiration_date}</Text>
      </Text>
    </Pane>
  );
};

export default ExemptionCard;
