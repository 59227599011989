import { useCallback, useState } from "react";
import { Props } from "./ErrorModal";
import { Setter } from "./useErrorContext";

const useError = () => {
  const [{ heading, error }, setErr] = useState<Props>({
    heading: null,
    error: null,
  });

  const setError: Setter = useCallback(
    function (arg1: unknown, arg2?: unknown) {
      let err, head;
      if (arguments.length === 1) {
        err = arg1;
      } else {
        head = arg1;
        err = arg2;
      }

      if (!err) return setErr({ error: null });

      setErr({
        heading: head as string,
        error: err instanceof Error ? err : Error(String(err)),
      });
    },
    [setErr]
  );

  return { error, heading, setError };
};

export default useError;
