import React from "react";
import { ErrorBox, HFlex, VFlex, Pane, Loading } from "shared";
import MedicareUsage from "./MedicareUsage";
import useInsuranceProfile, { ClaimOrderType } from "routes/patient/queries/useInsuranceProfile";
import UpdateInsurance from "./UpdateInsurance";
import Insurance from "./Insurance";

const Insurances: React.VFC = () => {
  const { error, isLoading, isSuccess } = useInsuranceProfile();

  const claimTypes: ClaimOrderType[] = ["primary", "secondary"];

  const mainEm = (
    <VFlex gap={1}>
      {claimTypes.map(claim_order_type => (
        <HFlex
          alignItems="baseline"
          justifyContent="space-between"
          key={claim_order_type}
          data-testid={`${claim_order_type}-insurance`}
        >
          <Insurance claim_order_type={claim_order_type} />
          <UpdateInsurance claim_order_type={claim_order_type} />
        </HFlex>
      ))}
      <MedicareUsage />
    </VFlex>
  );

  return (
    <Pane dark>
      {error && <ErrorBox error={error} dark />}
      {isLoading && <Loading />}
      {isSuccess && mainEm}
    </Pane>
  );
};

export default Insurances;
