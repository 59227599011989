import { useQuery } from "react-query";
import api from "api";
import { queryKeys } from "utils/misc";

const useInvoicePreview = (payerId: number) => {
  const key = queryKeys.usInvoicePreviews(payerId);

  return useQuery(key, () => {
    return api.parse("GET /us_invoice_previews/{payer_id}", [payerId]);
  });
};

export default useInvoicePreview;
