import React from "react";
import { CTextField, CAutocomplete } from "shared";
import { useTranslation } from "react-i18next";
import usePayers from "routes/patientSearch/queries/usePayers";
import { Country, isUsMarket } from "utils/market";
import { Box } from "@material-ui/core";

interface Props {
  id?: number;
  name?: string;
  country?: string;
  utilizer_id?: number;
  utilizer_type?: string;
  edit: boolean;
}

const ConfigurationInfo: React.VFC<Props> = ({ name, utilizer_id, utilizer_type, edit }) => {
  const { t } = useTranslation();
  const { data: payers, isLoading: isLoadingPayers } = usePayers(isUsMarket ? Country.US : Country.SE);

  if (isLoadingPayers) return null;
  return (
    <>
      <Box mb={2}>
        <CTextField
          inputProps={{ "data-testid": "care-event-config-name" }}
          fullWidth
          required
          disabled={!edit}
          autoComplete="off"
          name="name"
          label={t("form.name")}
          defaultValue={name}
          noClear
        />
      </Box>
      <Box mb={2}>
        <CAutocomplete
          name="payer_id"
          label={t("form.payer")}
          getOptionLabel={payer => payer.name ?? "NO NAME"}
          getOptionValue={payer => payer.id}
          loading={isLoadingPayers}
          options={payers ?? []}
          defaultValue={utilizer_id}
          disabled={!edit}
        />
      </Box>
    </>
  );
};

export default ConfigurationInfo;
