import React from "react";
import { Box, Container, TableCell } from "@material-ui/core";
import { InvitePatient, SearchPatientForm } from "./components";
import { CTable, HFlex, Text, VFlex, Icon, Loading, ErrorBox } from "shared";
import useListPatients, { Category } from "./queries/useListPatients";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "utils/hooks";
import { Country, countries } from "utils/market";
import { Area } from "types";
import { RowLink } from "shared/CTable/CTable";
import { join, stripDeletedTagFromEmail } from "utils/misc";
import { Circle } from "assets";
import { definitions } from "api";
import CCell from "shared/CTable/CCell";
import { PremiumIcon } from "shared";
import styled from "styled-components";

const PatientSearch: React.VFC = () => {
  const { t } = useTranslation();

  const searchQuery = useSearchParams(
    {
      country: countries[0].value,
      category: Category.id,
      search: "",
    },
    {
      country: Object.values(Country),
      category: Object.values(Category),
    }
  );

  const { data, error, isLoading, isLoadingError: isError, isSuccess, isIdle } = useListPatients(searchQuery);

  const tableHeader = {
    id: t("patients.id"),
    state: t("form.state"),
    in_self_care: t("patient.discharged"),
    premium_type: t("patient.premium_type"),
    ...(searchQuery.country === Country.SE
      ? { personal_number: t("patients.medical_records.personal_number") }
      : { birthday: t("form.birth") }),
    first_name: t("form.first_name"),
    last_name: t("form.last_name"),
    phone_number: t("form.phone"),
    email: t("form.email"),
  };

  const tableCellFactory = (item: definitions["patient"]) => ({
    id: (
      <TableCell>
        <RowLink to={{ pathname: join(Area.Patients, item.id), state: window.location.href.match(/\?.+$/)?.[0] }} />
        <Icon of={Circle} color={item.deleted_at ? "error" : "success"} size={0.8} />
        <Text tiny ml={0.5}>
          {item.id}
        </Text>
      </TableCell>
    ),
    state: <CCell>{item.state}</CCell>,
    in_self_care: (
      <StyledCCell>
        {item.in_self_care ? t("patient.journey_in_selfcare.true") : t("patient.journey_in_selfcare.false")}
      </StyledCCell>
    ),
    premium_type: (
      <StyledCCell>
        {item.premium_type !== "none" ? (
          <PremiumIcon premiumType={item.premium_type} size="big" margin="0 0 0 10px" />
        ) : (
          t("patient.premium_types.none")
        )}
      </StyledCCell>
    ),
    personal_number: <CCell>{item.personal_number}</CCell>,
    birthday: <CCell>{item.birthday}</CCell>,
    first_name: <CCell>{item.first_name}</CCell>,
    last_name: <CCell>{item.last_name}</CCell>,
    phone_number: <CCell>{item.phone_number}</CCell>,
    email: <CCell>{item.email && stripDeletedTagFromEmail(item.email)}</CCell>,
  });

  const noMatchEm = (
    <Text bold secondary paragraph mb={0} center>
      {t`dashboard.tables.no_patients_found`}
    </Text>
  );

  const tableEm = (
    <Container>
      <CTable header={tableHeader} items={(data as any)?.items} cellFactory={tableCellFactory} />
    </Container>
  );

  const resultEm = (
    <Box bgcolor="background.paper" py={2} overflow="auto" data-testid="search-results">
      {isError && <ErrorBox error={error!} verbose centered dark />}
      {isLoading && <Loading />}
      {isSuccess && (!!(data as any)?.items.length ? tableEm : noMatchEm)}
    </Box>
  );

  return (
    <VFlex>
      <HFlex alignSelf="flex-end" mr={3} my={3}>
        <InvitePatient />
      </HFlex>
      <Container maxWidth="md">
        <Box mb={3}>
          <SearchPatientForm {...searchQuery} />
        </Box>
      </Container>
      {!isIdle && resultEm}
    </VFlex>
  );
};

export default PatientSearch;

const StyledCCell = styled(CCell)`
  span {
    display: flex;
    align-items: center;
  }
`;
