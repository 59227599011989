import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useChangeTherapist = () => {
  const id = useId();

  return useMutation((therapist_id: number) =>
    api("PUT /patients/{id}/assign_therapist", {
      params: [id],
      data: { therapist_id: String(therapist_id) },
    })
  );
};

export default useChangeTherapist;
