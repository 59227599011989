import React from "react";
import { Button, Container } from "@material-ui/core";
import { VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useReopenTreatment from "../../queries/useReopenTreatment";
import usePatient from "routes/patient/queries/usePatient";
import { fullName, queryKeys } from "utils/misc";

interface Props {
  onClose: () => void;
}

const ReopenTreatmentForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { mutateAsync, isLoading } = useReopenTreatment();
  const { data: patient } = usePatient();

  if (!patient) return null;

  const reopenTreatment = async () => {
    const patientKey = queryKeys.patient(patient.id);
    onClose();

    try {
      await mutateAsync();
    } catch (err) {
      setErrors(t("errors.could_not_reopen_treatment"), err);
    } finally {
      queryClient.invalidateQueries(patientKey);
      queryClient.invalidateQueries(queryKeys.patientSearch());
    }
  };

  return (
    <Container>
      <Text heading bold center id="reopen-treatment">
        {t("patients.reopen_treatment")}
      </Text>
      <Text center paragraph mb={3}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>
      <VFlex alignItems="center" gap={2} my={5}>
        <ProgressButton loading={isLoading} onClick={reopenTreatment}>
          {t("patients.reopen_treatment")}
        </ProgressButton>
        <Button disabled={isLoading} onClick={onClose}>
          {t("buttons.cancel")}
        </Button>
      </VFlex>
    </Container>
  );
};

export default ReopenTreatmentForm;
