import { Box } from "@material-ui/core";
import { ErrorBox, VFlex } from "shared";

interface Props {
  readonly error: any;
}
const ErrorComponent: React.VFC<Props> = ({ error }) => (
  <VFlex height={1000} gap={3} justifyContent="center" alignItems="center">
    <Box mb={2}>
      <ErrorBox error={error} />
    </Box>
  </VFlex>
);

export default ErrorComponent;
