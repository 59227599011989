import { Box, Container } from "@material-ui/core";
import { definitions } from "api";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import usePayers from "routes/patientSearch/queries/usePayers";
import { CSelect, CTable, ErrorBox, HFlex, Icon, Loading, Text } from "shared";
import CCell from "shared/CTable/CCell";
import { countries, Country, isUsMarket } from "utils/market";

type Props = {};
const Payers: React.VFC<Props> = () => {
  const country = isUsMarket ? Country.US : Country.SE;
  const { t } = useTranslation();
  const form = useForm();
  const currentCountry = form.watch("country", country);
  const { data, isLoading, isError, isSuccess, error } = usePayers(currentCountry);

  const tableCellFactory = (item: definitions["payer"]) => ({
    id: <CCell>{item.id}</CCell>,
    name: <CCell>{item.name}</CCell>,
    code: <CCell>{item.code}</CCell>,
    category: <CCell>{item.category}</CCell>,
  });

  const noMatchEm = (
    <Text bold secondary paragraph mb={0} center>
      {t("payers.table.no_payers_found")}
    </Text>
  );

  const tableHeader = {
    id: t("payers.table.header.id"),
    name: t("payers.table.header.name"),
    code: t("payers.table.header.code"),
    category: t("payers.table.header.category"),
  };

  const tableEm = (
    <Container>
      <CTable header={tableHeader} items={data} cellFactory={tableCellFactory} />
    </Container>
  );

  return (
    <FormProvider {...form}>
      <form>
        <HFlex mb={4} mt={4} justifyContent="center" alignItems="baseline" pre>
          <Text xlarge light>
            {t("payers.payers_in")}{" "}
          </Text>
          <CSelect name="country" items={countries} defaultValue={country} variant="standard">
            {({ name, Flag }) => (
              <HFlex alignItems="center">
                <Text xlarge light mr={2}>
                  {name}
                </Text>
                <Icon ml="auto" size={3} of={Flag} />
              </HFlex>
            )}
          </CSelect>
        </HFlex>
        <Box bgcolor="background.paper" py={2} overflow="auto" data-testid="search-results">
          {isError && <ErrorBox error={error!} verbose centered dark />}
          {isLoading && <Loading />}
          {isSuccess && (!!data?.length ? tableEm : noMatchEm)}
        </Box>
      </form>
    </FormProvider>
  );
};

export default Payers;
