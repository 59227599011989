import { css } from "@emotion/css";
import { Breakpoint, SPACING } from "theme";

export const paneStyles = {
  grid: css`
    overflow: auto;
    display: grid;
    grid-auto-rows: 45px;
    align-items: center;
    grid-column-gap: ${SPACING}px;
    & *:nth-child(3n + 0) {
      justify-self: end;
    }
    @media screen and (max-width: ${Breakpoint.sm - 1}px) {
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${Breakpoint.sm}px) {
      grid-template-columns: auto auto 1fr;
    }
  `,
};
