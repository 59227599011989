import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import { useTranslation } from "react-i18next";
import { BoxButton } from "shared";
import { useHistory } from "react-router-dom";
import { Area } from "types";

const CareEventConfigurations: React.VFC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <BoxButton startIcon={<PersonIcon />} onClick={() => history.push(Area.CareEventConfigurations)}>
      {t("care_event_configurations.label")}
    </BoxButton>
  );
};

export default CareEventConfigurations;
