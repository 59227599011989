import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { CDialog } from "shared";
import UpdateInsuranceForm from "./UpdateInsuranceForm";
import { useTranslation } from "react-i18next";
import { ClaimOrderType } from "routes/patient/queries/useInsuranceProfile";

interface Props {
  claim_order_type: ClaimOrderType;
}

const UpdateInsurance: React.VFC<Props> = props => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <>
      <Button onClick={openDialog}>{t("buttons.update")}</Button>
      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="update-insurance">
        <UpdateInsuranceForm {...props} onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default UpdateInsurance;
