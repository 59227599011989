import React from "react";
import { isUsMarket } from "utils/market";
import InvoiceTable from "./components/InvoiceTable";
import RCMHeader from "shared/RCMHeader";

const Invoices: React.VFC = () => {
  if (!isUsMarket) return null;

  return (
    <>
      <RCMHeader />
      <InvoiceTable />
    </>
  );
};

export default Invoices;
