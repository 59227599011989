import { css } from "@emotion/css";
import { Button, Container } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useResubmitClaim from "routes/reports/queries/useResubmitClaim";
import { DialogSuccessNotice, HFlex, ProgressButton, Text, useErrorContext } from "shared";
import { FORM_NOTIFICATION_DELAY, SPACING } from "theme";
import { delay } from "utils/misc";
import SplitView from "./SplitView";

interface Props {
  patientId: number;
  defaultValue: any;
  onClose: () => void;
}

const ClaimForm: React.VFC<Props> = ({ patientId, defaultValue, onClose }) => {
  const originalValue = JSON.stringify(defaultValue, undefined, 2);
  const [value, setValue] = useState(originalValue);
  const { t } = useTranslation();
  const setError = useErrorContext();
  const [isSplitView, setSplitView] = useState(false);
  const { mutateAsync: resubmitClaim, isLoading, isSuccess } = useResubmitClaim(patientId);

  if (isSuccess)
    return <DialogSuccessNotice px={6} heading={t`rcm.claim_successfully_resubmitted`} onClose={onClose} />;

  const submit = async () => {
    try {
      const serialized = JSON.parse(value);
      try {
        await resubmitClaim(serialized);
        await delay(FORM_NOTIFICATION_DELAY);
        onClose();
      } catch (err) {
        setError(t`errors.could_not_resubmit_claim`, err);
      }
    } catch (err) {
      setError(t`errors.claim_is_badly_formatted`, err);
    }
  };

  const singleView = (
    <>
      <div>
        <Text link tiny role="button" onClick={() => setSplitView(true)}>{t`common.compare_to_original`}</Text>
      </div>
      <textarea className={styles.textArea} value={value} onChange={evt => setValue(evt.target.value)} />
    </>
  );

  const splitView = (
    <SplitView
      originalValue={originalValue}
      value={value}
      onChange={value => setValue(value)}
      onClose={() => setSplitView(false)}
    />
  );

  return (
    <Container>
      <Text heading bold id="resubmit-claim" mb={3}>
        {t`rcm.resubmit_claim`}
      </Text>
      {isSplitView ? splitView : singleView}
      <HFlex justifyContent="center" gap={2} mt={2} mb={6}>
        <Button disabled={isLoading} onClick={onClose}>{t`buttons.cancel`}</Button>
        <ProgressButton loading={isLoading} onClick={submit}>{t`rcm.resubmit`}</ProgressButton>
      </HFlex>
    </Container>
  );
};

export default ClaimForm;

const styles = {
  textArea: css`
    padding: ${2 * SPACING}px;
    width: 750px;
    height: 500px;
    resize: none;
  `,
};
