import React, { useState } from "react";
import { BoxButton, CDialog, Icon } from "shared";
import { Close } from "@material-ui/icons";
import CloseAccountForm from "./ClosePatientAccountForm";
import { useTranslation } from "react-i18next";

const CloseAccount: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <>
      <BoxButton startIcon={<Icon of={Close} error />} onClick={openDialog}>
        {t("patients.end_treatment_form.close_account")}
      </BoxButton>

      <CDialog maxWidth="sm" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="close-account">
        <CloseAccountForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default CloseAccount;
