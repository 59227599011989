import { createContext, useContext } from "react";

export interface Setter {
  (heading: string, error: unknown): void;
  (error: unknown): void;
}

export const ErrorContext = createContext<Setter>(() => {});
const useErrorContext = () => useContext(ErrorContext);

export default useErrorContext;
