import api from "api";
import { queryKeys } from "utils/misc";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";

const usePatient = (overrideId?: number) => {
  const defaultId = useId();
  const id = overrideId ?? defaultId;
  const key = queryKeys.patient(id);
  return useQuery(key, () => api.parse("GET /patients/{id}", [id]));
};

export default usePatient;
