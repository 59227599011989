import React, { useState } from "react";
import PersonIcon from "@material-ui/icons/Person";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { BoxButton, CDialog, Text, HFlex } from "shared";
import { makeStyles, TextField } from "@material-ui/core";
import FrInvoicesTable from "./FrInvoicesTable";

const useStyles = makeStyles(() => ({
  margin: {
    marginLeft: 12,
  },
}));

const FRInvoices: React.VFC = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(format(new Date(), "yyyy-MM"));
  const classes = useStyles();

  return (
    <>
      <BoxButton startIcon={<PersonIcon />} onClick={() => setOpen(true)}>
        {t("fr_invoices.label")}
      </BoxButton>

      <CDialog maxWidth="md" fullWidth open={isOpen} onClose={() => setOpen(false)} aria-labelledby="ManageJoints">
        <HFlex className={classes.margin} alignItems="center">
          <Text mr={1}>{t("fr_invoices.month_label")}:</Text>
          <TextField
            id="month"
            label="Month"
            type="month"
            value={selectedMonth}
            onChange={e => setSelectedMonth(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </HFlex>

        <FrInvoicesTable month={selectedMonth} />
      </CDialog>
    </>
  );
};

export default FRInvoices;
