import { Box } from "@material-ui/core";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useUpdatePersonal from "routes/patient/queries/useUpdatePersonal";
import { useErrorContext } from "shared";
import PersonalInfo from "./PersonalInfo";
import PersonalForm, { Fields } from "./PersonalForm";
import { queryKeys } from "utils/misc";
import { useId } from "utils/hooks";

interface FormState {
  isActive: boolean;
  defaults?: Fields;
}

// This component defines the submit procedure while PersonalForm exposes a submit callback. This is to allow for optimisic updates with failure guard that reopens the form prefilled with previous entries.

const Personal: React.VFC = () => {
  const id = useId();
  const setError = useErrorContext();
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdatePersonal();
  const { t } = useTranslation();
  const [form, setForm] = useState<FormState>({ isActive: false });

  const submit: SubmitHandler<Fields> = async data => {
    setForm({ isActive: false });
    const patientKey = queryKeys.patient(id);
    const rollbackData = queryClient.getQueryData(patientKey);
    try {
      await queryClient.cancelQueries(patientKey);
      queryClient.setQueryData(patientKey, old => Object.assign(old, data));
      await mutateAsync(data);
    } catch (err) {
      queryClient.setQueryData(patientKey, rollbackData);
      setForm({ isActive: true, defaults: data });
      setError(t`errors.could_not_update_personal_information`, err);
    } finally {
      queryClient.invalidateQueries(patientKey);
      queryClient.invalidateQueries(queryKeys.patientSearch());
    }
  };

  if (form.isActive)
    return (
      <Box position="relative" zIndex={2}>
        <PersonalForm onSubmit={submit} onCancel={() => setForm({ isActive: false })} />
      </Box>
    );

  return <PersonalInfo onEdit={() => setForm({ isActive: true })} />;
};

export default Personal;
