import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

export type Vars = {
  specific_joint_pain?: number;
  repetitions?: number;
};

const useUpdateActivity = (id: number) => {
  const patientId = useId();
  return useMutation(({ specific_joint_pain, repetitions }: Vars) =>
    api("PUT /patients/{patient_id}/activities/{id}", {
      params: [patientId, id],
      data: {
        specific_joint_pain,
        functionality: { repetitions },
      } as any,
    })
  );
};

export default useUpdateActivity;
