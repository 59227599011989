import api from "api";
import { useMutation } from "react-query";

const useCreatePOC = () =>
  useMutation((care_event_id: number) =>
    api.parse("POST /care_events/{care_event_id}/us_patient_plan_of_care/render_pdf", {
      params: [care_event_id],
    })
  );

export default useCreatePOC;
