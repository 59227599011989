import React from "react";
import { Button, Container } from "@material-ui/core";
import { VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useReopenTherapistAccount from "../../queries/useReopenTherapistAccount";
import useTherapist from "routes/therapist/queries/useTherapist";
import { fullName, queryKeys } from "utils/misc";
import { definitions } from "api";

interface Props {
  onClose: () => void;
}

const ReopenAccountForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { mutateAsync, isLoading } = useReopenTherapistAccount();
  const { data: pt } = useTherapist();

  if (!pt) return null;

  const reopenAccount = async () => {
    const therapistKey = queryKeys.therapist(pt.id);
    const oldData = queryClient.getQueryData<definitions["therapist_full"]>(therapistKey);
    queryClient.cancelQueries(therapistKey);
    queryClient.setQueryData(therapistKey, { ...oldData, deleted_at: null });
    onClose();

    try {
      await mutateAsync();
    } catch (err) {
      queryClient.setQueryData(therapistKey, oldData);
      setErrors(t`errors.could_not_reopen_account`, err);
    } finally {
      queryClient.invalidateQueries(therapistKey);
      queryClient.invalidateQueries(queryKeys.therapistSearch());
    }
  };

  return (
    <Container>
      <Text heading bold center id="reopen-account">
        {t("patients.reopen_account")}
      </Text>
      <Text center paragraph mb={3}>
        {fullName(pt)}, <Text link>{pt.id}</Text>
      </Text>
      <VFlex alignItems="center" gap={2} my={5}>
        <ProgressButton loading={isLoading} onClick={reopenAccount}>
          {t("patients.reopen_account")}
        </ProgressButton>
        <Button disabled={isLoading} onClick={onClose}>
          {t("buttons.cancel")}
        </Button>
      </VFlex>
    </Container>
  );
};

export default ReopenAccountForm;
