import React, { useState } from "react";
import { BoxButton, CDialog, Icon } from "shared";
import { Edit } from "@material-ui/icons";
import ChangeHCPForm from "./ChangeHCPForm";
import { useTranslation } from "react-i18next";

const ChangeHCP: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <>
      <BoxButton startIcon={<Icon of={Edit} />} onClick={openDialog}>
        {t("therapist.change_hcp.button")}
      </BoxButton>

      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="change-hcp">
        <ChangeHCPForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default ChangeHCP;
