import React from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutline } from "@material-ui/icons";
import { CTextField, HFlex, ProgressButton, Text, useErrorContext, VFlex } from "shared";
import RCMHeader from "shared/RCMHeader";
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core";
import useGenerateClaim, { Vars } from "./useGenerateClaim";
import { Colors } from "theme";
import { isUsMarket } from "utils/market";

const useStyles = makeStyles(() => ({
  margin: {
    marginLeft: 28,
  },
  btn: {
    marginTop: 28,
    width: 250,
  },
  field: {
    width: 400,
  },
  icon: {
    color: Colors.SUCCESS_LIGHT,
    marginRight: 12,
  },
}));

const GenerateClaim: React.VFC = () => {
  const { t } = useTranslation();
  const form = useForm<Vars>();
  const { mutateAsync, isLoading, isError, data } = useGenerateClaim();
  const setError = useErrorContext();
  const styles = useStyles();

  if (!isUsMarket) return null;

  const submit = form.handleSubmit(async fields => {
    try {
      await mutateAsync(fields);
    } catch (err) {
      setError(t("rcm.error_claim_generation"), err);
    }
  });

  return (
    <>
      <RCMHeader />
      <FormProvider {...form}>
        <form className={styles.margin} onSubmit={submit}>
          <VFlex>
            <CTextField
              required
              name="care_event_id"
              autoFocus
              label={t("rcm.care_event")}
              asNumber
              margin="normal"
              size="small"
              fullWidth
              className={styles.field}
            />
            <CTextField
              required
              name="cpt_code"
              autoFocus
              label="CPT Code"
              type="text"
              margin="normal"
              size="small"
              fullWidth
              className={styles.field}
            />
            <CTextField
              required
              name="amount_cents"
              autoFocus
              label="Amount"
              asNumber
              margin="normal"
              size="small"
              fullWidth
              className={styles.field}
            />
            {!isError && data && (
              <HFlex>
                <CheckCircleOutline className={styles.icon} />
                <Text successLight>{t("rcm.generate_success")}</Text>
              </HFlex>
            )}
            <ProgressButton loading={isLoading} className={styles.btn}>
              {t("rcm.generate_claim")}
            </ProgressButton>
          </VFlex>
        </form>
      </FormProvider>
    </>
  );
};

export default GenerateClaim;
