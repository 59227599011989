import api, { definitions } from "api";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";
import { queryKeys } from "utils/misc";

const OP = "GET /patients/{patient_id}/us_insurance_profiles";

export type ClaimOrderType = NonNullable<definitions["us_insurance_profile"]["claim_order_type"]>;

export const fetchInsuranceProfile = (id: number) => api.parse(OP, [id]);

const useInsuranceProfile = () => {
  const id = useId();
  const key = queryKeys.insuranceProfile(id);
  return useQuery(key, () => fetchInsuranceProfile(id));
};

export default useInsuranceProfile;
