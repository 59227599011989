import api from "api";
import { BodyParams } from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "POST /patients/{patient_id}/us_insurance_profiles";

type Vars = BodyParams<typeof OP>;

const useUpdateInsurance = () => {
  const id = useId();

  return useMutation(({ insurance_company_id, member_id, claim_order_type }: Vars) => {
    return api(OP, {
      params: [id],
      data: { member_id, insurance_company_id, claim_order_type },
    });
  });
};

export default useUpdateInsurance;
