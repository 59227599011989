import React from "react";
import { Table, TableCell, TableContainer, TableHead, TableRow, makeStyles, TableSortLabel } from "@material-ui/core";
import { TFuncKey, useTranslation } from "react-i18next";
import { Colors } from "theme";
import RCMTableBody from "./RCMTableBody";
import { Query, Result } from "api";
import { Loading } from "shared";

type Params = Query<"GET /us_insurance_claims">;

interface HeadCell {
  phraseTitle: TFuncKey;
  order: Params["order_by"];
}

const headCells: HeadCell[] = [
  {
    phraseTitle: "rcm.patientid",
    order: "patient_id",
  },
  {
    phraseTitle: "rcm.insurancecompany",
    order: "primary_payer_name",
  },
  {
    phraseTitle: "rcm.memberid",
    order: "member_id",
  },
  {
    phraseTitle: "rcm.referenceid",
    order: "claim_reference_id",
  },
  {
    phraseTitle: "rcm.claimid",
    order: "claim_id",
  },
  {
    phraseTitle: "rcm.service",
    order: "date_of_service",
  },
  {
    phraseTitle: "rcm.sentdate",
    order: "date_of_service",
  },
  {
    phraseTitle: "rcm.state",
    order: "claim_state",
  },
  {
    phraseTitle: "rcm.payerstate",
    order: "payer_state",
  },
  {
    phraseTitle: "rcm.label",
    order: "care_event_label",
  },
  {
    phraseTitle: "rcm.videocall",
    order: "confirmed_video_call",
  },
  {
    phraseTitle: "rcm.icd",
    order: "icd_codes",
  },
];

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: Colors.PAPER,
    borderBottom: `2px solid ${Colors.DARK_BLACK_FADED}`,
  },
  headCell: {
    color: Colors.DARK_BLACK,
  },
  table: {
    minWidth: 250,
    border: "none",
  },
}));

type RCMDataObject = Result<"GET /us_insurance_claims">;

interface RCMTableProps {
  data: RCMDataObject | undefined;
  orderBy: Params["order_by"];
  orderDirection: Params["order_direction"];
  updateOrderBy: (orderBy: Params["order_by"]) => void;
}

const RCMTable: React.VFC<RCMTableProps> = ({ data, orderBy, updateOrderBy, orderDirection }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!data?.rows) {
    return <Loading />;
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headCells.map(({ order, phraseTitle }: HeadCell, i: number) => {
                const nameEqualsOrderBy = order === orderBy;

                return (
                  <TableCell
                    key={`cell-${order}-${i}`}
                    className={classes.headCell}
                    sortDirection={nameEqualsOrderBy ? orderDirection : false}
                  >
                    <TableSortLabel
                      active={order === orderBy}
                      direction={nameEqualsOrderBy ? orderDirection : "asc"}
                      onClick={() => updateOrderBy(order)}
                    >
                      {t(phraseTitle)}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              <TableCell className={classes.headCell}>{t`rcm.note`}</TableCell>
              <TableCell className={classes.headCell} align="center">{t`rcm.resubmit_claim`}</TableCell>
            </TableRow>
          </TableHead>
          <RCMTableBody data={data} />
        </Table>
      </TableContainer>
    </>
  );
};

export default RCMTable;
