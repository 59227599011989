import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import useUpdatePTOnDemandEnabled from "../queries/useUpdatePTOnDemandEnabled";
import { useTranslation } from "react-i18next";
import { Pane, Text, useErrorContext } from "shared";
import usePatient from "../queries/usePatient";
import { useQueryClient } from "react-query";
import { queryKeys } from "utils/misc";
import { definitions } from "api";

const PTOnDemand: React.VFC = () => {
  const { t } = useTranslation();
  const setError = useErrorContext();
  const { mutateAsync } = useUpdatePTOnDemandEnabled();
  const { data: patient } = usePatient();
  const queryClient = useQueryClient();
  if (!(patient?.out_of_pocket_state === "selfcare")) return null;

  const togglePTOnDemand = async () => {
    const queryKey = queryKeys.patient(patient.id);
    const rollbackData = queryClient.getQueryData(queryKey);
    queryClient.cancelQueries(queryKey);
    queryClient.setQueryData<definitions["patient_full"]>(queryKey, old => ({
      ...old!,
      pt_on_demand_enabled: !patient.pt_on_demand_enabled,
    }));

    try {
      await mutateAsync(!patient.pt_on_demand_enabled);
    } catch (err) {
      setError(err);
      queryClient.setQueryData(queryKey, rollbackData);
    } finally {
      queryClient.invalidateQueries(queryKey);
    }
  };

  return (
    <Pane dark>
      <FormControlLabel
        control={<Checkbox checked={patient.pt_on_demand_enabled} onChange={togglePTOnDemand} />}
        label={<Text small>{t`patient.pt_on_demand.pt_on_demand`}</Text>}
      />
    </Pane>
  );
};

export default PTOnDemand;
