import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Area } from "types";
import { useTranslation } from "react-i18next";
import styled, { createGlobalStyle } from "styled-components";
import { Colors, SPACING } from "theme";
import { Text } from "shared";

const RCMHeader: React.FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const getHeading = () => {
    switch (path) {
      case Area.RCM:
        return t`rcm.claimstitle`;
      case Area.GenerateClaim:
        return t`rcm.generate_claim`;
      case Area.Invoices:
        return t`rcm.invoices`;
      default:
        return t`rcm.cdmfull`;
    }
  };

  return (
    <HeaderWrapper>
      <BodyOverride />
      <div className="header">
        <StyledLink exact to={Area.RCM} activeClassName="selected">
          {t`rcm.claims`}
        </StyledLink>
        <StyledLink exact to={Area.GenerateClaim} activeClassName="selected">
          {t`rcm.generate_claim`}
        </StyledLink>
        <StyledLink exact to={Area.CDM} activeClassName="selected">
          {t`rcm.cdm`}
        </StyledLink>
        <StyledLink exact to={Area.Invoices} activeClassName="selected">
          {t`rcm.invoices`}
        </StyledLink>
      </div>
      <Text heading large bold my={4} ml={4}>
        {getHeading()}
      </Text>
    </HeaderWrapper>
  );
};

export default RCMHeader;

const BodyOverride = createGlobalStyle`
  body {
    background-color: ${Colors.PAPER};
  }
`;

const HeaderWrapper = styled.div`
  margin-top: -10px;

  .header {
    background-color: ${Colors.DARK_BLUE};
    line-height: 1;
  }

  .selected {
    background: linear-gradient(94.62deg, ${Colors.JA_BLUE} 6.86%, ${Colors.JA_PINK} 92.37%);
  }
`;

const StyledLink = styled(NavLink)`
  padding: ${SPACING}px ${4 * SPACING}px;
  display: inline-block;
  cursor: pointer;
  color: ${Colors.PAPER} !important;
`;
