import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useUpdatePTOnDemandEnabled = () => {
  const id = useId();

  return useMutation((pt_on_demand_enabled: boolean) =>
    api("PUT /patients/{id}", {
      params: [id],
      data: { id, patient_profile_attributes: { pt_on_demand_enabled } } as any,
    })
  );
};

export default useUpdatePTOnDemandEnabled;
