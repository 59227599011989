import React from "react";
import { Box, Button } from "@material-ui/core";
import { WorkRounded } from "@material-ui/icons";
import { HFlex, VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { queryKeys } from "utils/misc";
import useEndOutOfOffice from "routes/therapist/queries/useEndOutOfOffice";
import useTherapist from "routes/therapist/queries/useTherapist";
import { useId } from "utils/hooks";

interface Props {
  onClose: () => void;
}
interface FormState {
  readonly returnDate?: string;
}

const EndOutOfOfficeForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm<FormState>();
  const { mutateAsync, isLoading } = useEndOutOfOffice();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();

  const id = useId();
  const { data: pt } = useTherapist();

  const submit = handleSubmit(async () => {
    try {
      if (pt?.time_off_period?.id) {
        await mutateAsync({ id: pt.time_off_period.id });
        queryClient.invalidateQueries(queryKeys.therapist(id));
      }
      onClose();
    } catch (err) {
      setErrors(t`errors.could_not_register_time_off`, err);
    }
  });

  return (
    <>
      <HFlex justifyContent="center" mb={2}>
        <WorkRounded style={{ color: "#5096E8", width: 80, height: 80 }} />
      </HFlex>
      <Text heading bold center>
        {t("therapists.out_of_office.end.title")}
      </Text>
      <Box mx={2}>
        <form onSubmit={submit}>
          <VFlex alignItems="center" gap={2} my={5}>
            <ProgressButton loading={isLoading} type="submit" variant="contained" color="primary">
              {t("therapists.out_of_office.end.submit")}
            </ProgressButton>
            <Button disabled={isLoading} onClick={onClose}>
              {t("buttons.cancel")}
            </Button>
          </VFlex>
        </form>
      </Box>
    </>
  );
};

export default EndOutOfOfficeForm;
