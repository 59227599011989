import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { CDialog } from "shared";
import { useTranslation } from "react-i18next";
import UpdateProviderGroupForm from "./UpdateProviderGroupForm";

const UpdateProviderGroup: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <>
      <Button onClick={openDialog}>{t("buttons.update")}</Button>
      {isOpen && (
        <CDialog maxWidth="xs" fullWidth open={true} onClose={closeDialog}>
          <UpdateProviderGroupForm onClose={closeDialog} />
        </CDialog>
      )}
    </>
  );
};

export default UpdateProviderGroup;
