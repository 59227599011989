import styled from "styled-components";

interface Props {
  zIndex?: number;
}

const Dimmer = styled.div<Props>`
  background-color: rgba(14, 64, 126, 0.15);
  z-index: ${p => p.zIndex ?? -1};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default Dimmer;
