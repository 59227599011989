import { useMutation } from "react-query";
import api from "api";

const useBillInvoice = () => {
  return useMutation((invoiceId: number) => {
    return api.parse("PUT /us_invoices/{invoice_id}", {
      params: [invoiceId],
      data: {
        billed: true,
      },
    });
  });
};

export default useBillInvoice;
