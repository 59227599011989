import React from "react";
import { Text, Icon } from "shared";
import { getDate } from "utils/time";
import { useTranslation } from "react-i18next";
import { Circle } from "assets";
import useTherapist from "routes/therapist/queries/useTherapist";

const Deleted: React.VFC = () => {
  const { t } = useTranslation();
  const { data: pt } = useTherapist();
  if (!pt?.deleted_at) return null;

  return (
    <Text paragraph mb={0} data-testid="status">
      <Icon of={Circle} error size={0.7} aria-label="Inactive account" />
      <Text ml={1} bold>
        {pt.id}
      </Text>
      <Text right>
        <Text light ml="auto">
          {t`patient.deleted`}:{" "}
        </Text>
        <Text bold mr={1}>
          {getDate(pt.deleted_at as string)}
        </Text>
      </Text>
    </Text>
  );
};

export default Deleted;
