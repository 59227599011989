import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useRevertTreatmentSwitch = () => {
  const id = useId();
  //   @ts-ignore
  return useMutation(() => api("PUT /patients/{patient_id}/revert_treatment_switch", [id]));
};

export default useRevertTreatmentSwitch;
