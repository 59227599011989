import api from "api";
import { useQuery } from "react-query";
import { Country } from "utils/market";
import { queryKeys } from "utils/misc";

export enum Category {
  name = "name",
  id = "id",
  hcp = "hcp",
}

export interface Vars {
  country: Country;
  category: Category;
  search: string;
}

const useListTherapists = (params: Vars) => {
  const { country, category, search } = params;
  const key = queryKeys.therapistSearch(params);

  return useQuery(
    key,
    async () => {
      return api.parse("GET /therapists", {
        query: {
          [category]: search,
          country: country,
        },
      });
    },
    { enabled: !!search }
  );
};

export default useListTherapists;
