import { useState, useEffect } from "react";
import { useTranslation, TFunction } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import { IChangeEvent } from "@rjsf/core";
import Form from "@rjsf/material-ui";
import { RJSFValidationError } from "@rjsf/utils";
import { Lesson, TranslatedLesson, jsonSchema, example, empty } from "./lesson";
import styled from "styled-components";
import groupedImages from "./images";
import { Tabs, Tab, Box, Button, TextField } from "@material-ui/core";
import parseHTML from "../../utils/parseHtml";


const LessonEditor = () => {
  const [lesson, setLesson] = useState(example);
  const [lessonRaw, setLessonRaw] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();

  const handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const resetExample = () => {
    setLesson(example);
  };

  const clearEditor = () => {
    setLesson(empty);
  };

  useEffect(() => {
    const storedLesson = localStorage.getItem("lesson");
    if (storedLesson) {
      setLesson(JSON.parse(storedLesson));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("lesson", JSON.stringify(lesson));
  }, [lesson]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs value={tabValue} onChange={handleChangeTab} aria-label="lesson tabs">
        <Tab label={t("lesson_editor.editor_tab")} />
        <Tab label={t("lesson_editor.preview_tab")} />
        <Tab label={t("lesson_editor.images_tab")} />
        <Tab label={t("lesson_editor.raw_tab")} />
      </Tabs>

      {tabValue === 0 && (
        <>
          <div>
            <Button onClick={resetExample}>{t("lesson_editor.reset_example")}</Button>
            <Button onClick={clearEditor}>{t("lesson_editor.clear")}</Button>
          </div>

          <StyledForm
            schema={jsonSchema}
            validator={validator}
            onChange={e => setLesson(e.formData)}
            onSubmit={submit}
            onError={logError}
            formData={lesson}
          />
        </>
      )}

      {tabValue === 1 && (
        <div>
          <p>{t("lesson_editor.identifier")}{lesson.identifier}</p>
          <p>{t("lesson_editor.target_joints")}{lesson.targetJoints.join(", ")}</p>
          <img
            src={lesson.coverImage}
            alt={lesson.identifier}
            style={{
              maxWidth: "500px",
              maxHeight: "500px",
            }}
          />
          {renderTranslatedLesson(lesson.swedish, t)}
          {renderTranslatedLesson(lesson.english, t)}
        </div>
      )}

      {tabValue === 2 && (
        <>
          {Object.entries(groupedImages).map(([group, imageNames]) => (
            <GroupedImages key={group} group={group} imageNames={imageNames} />
          ))}
        </>
      )}

      {tabValue === 3 && (
        <>
          <Button
            onClick={() => {
              setLessonRaw(JSON.stringify(lesson, null, 2));
            }}
          >
            Load
          </Button>
          <Button
            onClick={() => {
              setLesson(JSON.parse(lessonRaw));
            }}
          >
            Save
          </Button>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            value={lessonRaw}
            onChange={e => setLessonRaw(e.target.value)}
          />
        </>
      )}
    </Box>
  );
};

const submit = ({ formData: lesson }: IChangeEvent<Lesson>) => {
  if (!lesson) return;

  const dummyLink = document.createElement("a");
  dummyLink.href = encodeAsJson(lesson);
  dummyLink.download = `${lesson.identifier}.json`;
  dummyLink.click();
};

const encodeAsJson = (object: Object) => {
  const encoded = encodeURIComponent(JSON.stringify(object, null, 2));
  return "data:text/json;charset=utf-8," + encoded;
};

const logError = (error: RJSFValidationError[]) => {
  console.error(error);
};

type GroupedImagesProps = {
  group: string;
  imageNames: string[];
};

const GroupedImages: React.FC<GroupedImagesProps> = ({ group, imageNames }) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div>
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        {isCollapsed ? "▶️" : "🔽"} {group || "misc"}
      </div>
      {!isCollapsed && imageNames.map(imageName => renderImage(group, imageName, t))}
    </div>
  );
};

const base = "https://jojnts-app-prod.s3.amazonaws.com/content_images/text_lessons";
const renderImage = (group: string, imageName: string, t: TFunction) => {
  const url = [base, group, imageName].filter(part => part !== "").join("/");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      alert(t("lesson_editor.image_url_copied", { url }));
    });
  };

  return (
    <img
      src={url}
      onClick={copyToClipboard}
      alt={imageName}
      key={imageName}
      style={{
        maxWidth: "500px",
        maxHeight: "500px",
        border: "1px solid black",
      }}
    />
  );
};

const renderTranslatedLesson = (translatedLesson: TranslatedLesson, t: TFunction) => (
  <div>
    <h2>{translatedLesson.title}</h2>
    {translatedLesson.parts.map((part, index) => (
      <div key={index}>
        <Divider>{t("lesson_editor.part_divider", { number: index + 1 })}</Divider>

        {part.contents.map((content, contentIndex) => (
          <div key={contentIndex}>
            {content.type === "heading" && <h3>{content.content}</h3>}
            {content.type === "paragraph" && (
              <div dangerouslySetInnerHTML={{ __html: parseHTML(content.content) }}></div>
            )}

            {content.type === "image" && (
              <img
                src={content.content}
                alt={content.content}
                style={{
                  maxWidth: "500px",
                  maxHeight: "500px",
                }}
              />
            )}
          </div>
        ))}
        {part.questions.map((question, questionIndex) => (
          <div key={questionIndex} style={{ margin: "20px", padding: "10px", border: "1px solid #ccc" }}>
            <h4>{question.title}</h4>
            {question.answers.map((answer, answerIndex) => (
              <div key={answerIndex}>
                {answer.correct ? "🟩" : "⬜"}
                {answer.text}
              </div>
            ))}
            <div style={{ backgroundColor: "#f0f0f0" }}>{question.tip_description}</div>
          </div>
        ))}
      </div>
    ))}
  </div>
);

export default LessonEditor;

const Divider = styled.div`
  display: flex;
  align-items: center;

  &::before,
  &::after {
    flex: 1;
    content: "";
    padding: 1px;
    background-color: black;
  }
`;

const StyledForm = styled(Form)`
  .MuiGrid-root .MuiGrid-item .MuiGrid-grid-xs-true {
    flex-grow: 1;
  }

  .MuiGrid-root .MuiGrid-item {
    flex-grow: 0;
  }

  button.MuiIconButton-colorSecondary {
    color: red;
  }
`;
