import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Text } from "shared";

export const Attending: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <Box px={1} color="success.contrastText" bgcolor="success.light" borderRadius="2px" textAlign="center">
      <Text>{t`buttons.yes`}</Text>
    </Box>
  );
};

export const NotAttending: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <Box px={1} color="error.contrastText" bgcolor="error.light" borderRadius="2px" textAlign="center">
      <Text>{t`buttons.no`}</Text>
    </Box>
  );
};
