import React from "react";
import { FlagDE, FlagEU, FlagFR, FlagSE, FlagUK, FlagUS } from "assets";

export enum Market {
  EU = "EU",
  US = "US",
}

export enum Country {
  DE = "Germany",
  SE = "Sweden",
  UK = "United_Kingdom",
  FR = "France",
  US = "USA",
}

export enum CountryCode {
  SE = "SE",
  US = "US",
  GB = "GB",
  DE = "DE",
  FR = "FR",
}

export enum TimeZone {
  London = "London",
  Stockholm = "Stockholm",
  Berlin = "Berlin",
  Paris = "Paris",
  Eastern = "Eastern Time (US & Canada)",
  Central = "Central Time (US & Canada)",
  Mountain = "Mountain Time (US & Canada)",
  Pacific = "Pacific Time (US & Canada)",
}

export enum LanguageCode {
  SV = "sv",
  EN = "en",
}

interface SignInLink {
  Flag: React.ComponentType;
  name: string;
  url: string;
  value: Market;
}

interface Location {
  Flag: React.ComponentType;
  name: string;
  value: Country;
}

export const isUsMarket = process.env.REACT_APP_DATABASE?.toUpperCase() === Market.US;

const stage = [
  { Flag: FlagEU, name: "Europe", value: Market.EU, url: "https://backoffice.eu.jastage.io" },
  { Flag: FlagUS, name: "USA", value: Market.US, url: "https://backoffice.us.jastage.io" },
];

const release = [
  { Flag: FlagEU, name: "Europe", value: Market.EU, url: "https://eu-backoffice-release.jointacademy.com" },
  { Flag: FlagUS, name: "USA", value: Market.US, url: "https://us-backoffice-release.jointacademy.com" },
];

const production = [
  { Flag: FlagEU, name: "Europe", value: Market.EU, url: "https://backoffice.eu.jointacademy.com" },
  { Flag: FlagUS, name: "USA", value: Market.US, url: "https://backoffice.us.jointacademy.com" },
];

export const getSignInLinks = (): SignInLink[] => {
  if (window.location.hostname.includes("stage")) {
    return stage;
  }

  if (window.location.hostname.includes("release")) {
    return release;
  }

  return production;
};

const eu = [
  { Flag: FlagSE, name: "Sweden", value: Country.SE },
  { Flag: FlagUK, name: "United Kingdom", value: Country.UK },
  { Flag: FlagDE, name: "Germany", value: Country.DE },
  { Flag: FlagFR, name: "France", value: Country.FR },
];

const us = [{ Flag: FlagUS, name: "USA", value: Country.US }];

export const countries: Location[] = isUsMarket ? us : eu;
