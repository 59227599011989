import { format, getWeek as week } from "date-fns";
import locale from "date-fns/locale/en-US";
import add from "date-fns/add";

const d = (date: string) => {
  // NOTE: Remove timezone, https://stackoverflow.com/a/52352512
  const dt = new Date(date);
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
};

const dt = (date: Date) => format(date, "PP", { locale });

export const getDate = (date: string) => format(d(date), "PP", { locale });
export const getTime = (date: string) => format(d(date), "p", { locale });
export const getDateAndTime = (date: string) => format(d(date), "PPp", { locale });
export const getWeek = (date: string) => week(d(date), { locale });
export const incrementWeek = (date: string) => dt(add(d(date), { weeks: 1 }));
export const decrementWeek = (date: string) => dt(add(d(date), { weeks: -1 }));
export const getDay = (date: string, short?: boolean) => format(d(date), short ? "EEE" : "EEEE", { locale });
export const offsetDate = (offset: Duration, date: string) => dt(add(d(date), offset));
