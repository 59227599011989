import { useTranslation } from "react-i18next";
import AddRecordForm from "./RecordForm";
import { Meta } from "types";
import { definitions } from "api";

export type ReferralSourceBody = Omit<definitions["referral_source"], keyof Meta>;

interface Props {
  onAdd: (referralSource: ReferralSourceBody) => void;
  onCancel: () => void;
  defaults?: ReferralSourceBody;
}

const AddReferralSourceForm: React.VFC<Props> = ({ onAdd, onCancel, defaults }) => {
  const { t } = useTranslation();

  return (
    <AddRecordForm
      heading={t`form.new_referral_source`}
      overrideRegistry="referral-sources"
      onCancel={onCancel}
      onAdd={record => onAdd(record as ReferralSourceBody)}
      defaults={defaults}
    />
  );
};

export default AddReferralSourceForm;
