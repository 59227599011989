import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useToggleSubstitute = () => {
  const id = useId();

  return useMutation((available_to_substitute: boolean) =>
    api("PATCH /therapists/{id}", {
      params: [id],
      data: {
        // @ts-ignore
        available_to_substitute: available_to_substitute,
      },
    })
  );
};

export default useToggleSubstitute;
