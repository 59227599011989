import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";
import { Country } from "utils/market";
import usePatient from "./usePatient";

const OP = "POST /patients/{patient_id}/medical_referrals";
const frOP = "POST /fr/patients/{patient_id}/medical_referrals";

export interface Vars {
  source: string;
  doctor_id: string;
  duration_days: string;
  reason: string;
  icd_codes: string;
  date_of_physician_signature: string;
  provider_first_name: string;
  provider_last_name: string;
  provider_npi: string;
  file: File[];
}

const useUploadReferralDocument = () => {
  const patientId = useId();
  const { data: patient } = usePatient();
  const isFrench = patient?.country === Country.FR;

  return useMutation<Response, Error, Vars>(
    ({
      file,
      source,
      doctor_id,
      duration_days,
      reason,
      icd_codes,
      date_of_physician_signature,
      provider_first_name,
      provider_last_name,
      provider_npi,
    }) => {
      const icd = icd_codes?.toUpperCase().match(/[A-Z]\d\d(\.\d[A-Z]?)?/g);
      const [doc] = file;
      const data = new FormData();
      data.append("file", doc, doc.name);
      data.append("source", source ?? "");
      data.append("doctor_id", doctor_id);
      data.append("duration_days", duration_days);
      data.append("reason", reason);
      if (icd) {
        for (let code of icd) {
          data.append("icd_codes[]", code);
        }
      }
      data.append("date_of_physician_signature", date_of_physician_signature);
      data.append("provider_first_name", provider_first_name);
      data.append("provider_last_name", provider_last_name);
      data.append("provider_npi", provider_npi);

      return api(isFrench ? frOP : OP, {
        params: [patientId],
        data,
        headers: new Headers({ Accept: "*/*" }),
      });
    }
  );
};

export default useUploadReferralDocument;
