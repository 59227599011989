import React from "react";
import { Text, Icon } from "shared";
import { getDate } from "utils/time";
import { useTranslation } from "react-i18next";
import { Circle } from "assets";
import useTherapist from "routes/therapist/queries/useTherapist";

const Active: React.VFC = () => {
  const { t } = useTranslation();
  const { data: pt } = useTherapist();
  if (!pt) return null;

  return (
    <Text paragraph mb={0} data-testid="status">
      <Icon of={Circle} success size={0.7} aria-label="Active account" />
      <Text ml={1} bold>
        {pt.id}
      </Text>
      <Text right>
        <Text light>{t`patients.created`}: </Text>
        <Text bold>{pt.created_at && getDate(pt.created_at)}</Text>
      </Text>
    </Text>
  );
};

export default Active;
