import React, { useState } from "react";
import { Area } from "types";
import { Grid, Container, Box, Button, Fab, Hidden } from "@material-ui/core";
import { Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import { ConfigurationInfo } from "../ConfigurationInfo";
import { WeeklyConfiguration } from "../WeeklyConfiguration";
import { ArrowBack, EditOutlined } from "@material-ui/icons";
import { FormProvider, useForm } from "react-hook-form";
import useUpdateCareEventConfiguration, { Fields } from "../../queries/useUpdateCareEventConfiguration";
import { useHistory } from "react-router-dom";
import useCreateCareEventConfiguration from "../../queries/useCreateCareEventConfiguration";
import ConfigurationOther from "../ConfigurationOther/ConfigurationOther";
import { Result } from "api";

export interface Props {
  careEventConfiguration: Result<"GET /care_event_configurations/{id}">;
  create?: boolean;
}
const CareEventConfigurationForm: React.VFC<Props> = ({ careEventConfiguration, create }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(!!create);
  const { mutateAsync: updateAsync } = useUpdateCareEventConfiguration(careEventConfiguration.id ?? -1);
  const { mutateAsync: createAsync } = useCreateCareEventConfiguration();
  const form = useForm<Fields>();
  const history = useHistory();

  const submit = form.handleSubmit(async fields => {
    try {
      if (create) {
        await createAsync(fields);
      } else {
        await updateAsync(fields);
      }
    } catch (err) {
      console.error(err);
    }
  });

  const toggleEdit = () => setEdit(prev => !prev);
  const handleToggleOrSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (edit) submit();
    toggleEdit();
  };

  return (
    <form onSubmit={handleToggleOrSubmit}>
      <FormProvider {...form}>
        <Container fixed>
          <Grid container>
            <Grid item xs={6}>
              <Box pb={1}>
                <Fab color="secondary" size="medium" onClick={() => history.push(Area.CareEventConfigurations)}>
                  <ArrowBack />
                </Fab>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box pb={1} textAlign="right">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<EditOutlined fontSize="small" />}
                  data-test-id="edit-personal"
                >
                  {edit ? t`buttons.save` : t`buttons.edit`}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <Text paragraph caption secondary>
                {t("care_event_configurations.configuration")}
              </Text>
            </Grid>
            <Hidden smDown>
              <Grid item xs={7}>
                <Text paragraph caption secondary>
                  {t("care_event_configurations.weekly_config")}
                </Text>
              </Grid>
            </Hidden>
          </Grid>
          <Grid container spacing={2}>
            <Grid item zeroMinWidth md={5}>
              <Pane>
                <Pane dark mb={2}>
                  <ConfigurationInfo {...careEventConfiguration} edit={edit} />
                </Pane>
              </Pane>
              <Text paragraph caption secondary>
                {t("care_event_configurations.other")}
              </Text>
              <Pane>
                <Pane dark mb={2}>
                  <ConfigurationOther weeklyConfig={careEventConfiguration?.weekly_config} edit={edit} />
                </Pane>
              </Pane>
            </Grid>

            <Grid item zeroMinWidth md={7}>
              <Hidden mdUp>
                <Text paragraph caption secondary>
                  {t("care_event_configurations.weekly_config")}
                </Text>
              </Hidden>
              <Pane>
                <WeeklyConfiguration weeklyConfig={careEventConfiguration?.weekly_config} edit={edit} />
              </Pane>
            </Grid>
          </Grid>
        </Container>
      </FormProvider>
    </form>
  );
};

export default CareEventConfigurationForm;
