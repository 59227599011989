import { IconButton, InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { Icon } from "shared";
import { Attachment, Cancel } from "@material-ui/icons";
import React from "react";
import { css } from "@emotion/css";

type Props = TextFieldProps & {
  name: string;
  multiple?: boolean;
  accept?: string;
};

const FileUpload: React.VFC<Props> = ({ name, multiple, accept, ...rest }) => {
  const { control } = useFormContext();

  const clear = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    control.setValue(name, null);
  };

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    event.currentTarget.firstChild!.dispatchEvent(new MouseEvent("click"));
  };

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files?.length) control.setValue(name, files);
  };

  const onFocusCapture = (event: React.FocusEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const id = `file-upload-${name}`;
  const files = control.watchInternal(name, null) as FileList | null;
  const feedback = files ? (files.length > 1 ? `${files.length} files` : files[0]?.name) : "";

  return (
    <Controller
      defaultValue={null}
      name={name}
      control={control}
      render={() => (
        <label htmlFor={id} onClick={onClick}>
          <input
            name={name}
            id={id}
            type="file"
            hidden
            disabled={rest.disabled}
            multiple={multiple}
            accept={accept}
            onChange={onChange}
          />
          <TextField
            value={feedback}
            spellCheck={false}
            inputProps={{
              className: styles.input,
              onFocusCapture,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {files ? (
                    <IconButton onClick={clear} aria-label="Clear input" className={styles.clear}>
                      <Icon of={Cancel} secondary />
                    </IconButton>
                  ) : (
                    <Attachment className={styles.attach} />
                  )}
                </InputAdornment>
              ),
            }}
            {...rest}
          />
        </label>
      )}
    />
  );
};

export default FileUpload;

const styles = {
  input: css`
    caret-color: transparent;
    cursor: default !important;
  `,
  clear: css`
    padding: 0 !important;
  `,
  attach: css`
    cursor: default !important;
  `,
};
