import api from "api";
import { useQuery } from "react-query";

export const useCareEventConfigurations = () => {
  return useQuery(["care_event_configurations"], () => {
    return api.parse("GET /care_event_configurations");
  });
};

export default useCareEventConfigurations;
