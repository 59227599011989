import { Tooltip } from "@material-ui/core";
import { Circle } from "assets";
import { useTranslation } from "react-i18next";
import { Text, Icon, TextProps } from "shared";
import { getDate } from "utils/time";
import { InfoOutlined } from "@material-ui/icons";
import usePatient from "routes/patient/queries/usePatient";
import { fullName } from "utils/misc";

interface Props extends TextProps {
  patientId?: number;
}

export const Deleted: React.VFC<Props> = ({ patientId, ...rest }) => {
  const { t } = useTranslation();
  const { data: patient } = usePatient(patientId);
  if (!patient) return null;
  const { id, deleted_at, deleted_by, reason_for_deletion } = patient;

  return (
    <Text paragraph {...rest}>
      <Icon of={Circle} error size={0.7} aria-label="Inactive account" />
      <Text ml={1} bold>
        {id}
      </Text>
      <Text light ml={2}>
        {t`patient.deleted`}:{" "}
      </Text>
      <Text bold mr={1}>
        {getDate(deleted_at!)}
      </Text>
      <Tooltip
        placement="top"
        title={
          <>
            <p>
              {t`patients.ended_by`}: {fullName(deleted_by)}
            </p>
            <p>{t`form.reason`}:</p>
            <p>{reason_for_deletion}</p>
          </>
        }
      >
        <Icon of={InfoOutlined} disabled aria-label="Deletion info" />
      </Tooltip>
    </Text>
  );
};
