import api from "api";
import { useQuery } from "react-query";
import { useId } from "utils/hooks";
import { queryKeys } from "utils/misc";

export const useCareEvents = () => {
  const id = useId();
  const key = queryKeys.careEvents(id);
  return useQuery(key, () => api.parse("GET /patients/{patient_id}/care_events", [id]));
};
