import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useUpdatePersonal = () => {
  const id = useId();

  return useMutation(
    ({ first_name, last_name, preferred_name, email, birthday, phone_number }: Record<string, string>) =>
      api("PUT /patients/{id}", {
        params: [id],
        data: {
          first_name,
          last_name,
          preferred_name,
          email,
          patient_profile_attributes: {
            birthday,
            phone_number,
          },
        } as any,
      })
  );
};

export default useUpdatePersonal;
