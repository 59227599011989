import api from "api";
import { useMutation, useQueryClient } from "react-query";

type Week = {
  label?: string;
  type?: string;
  protocol?: boolean;
  appointment?: boolean;
};

export type Fields = {
  name: string;
  country: string;
  payer_id: number;
  weekly_config: Record<string, Week | boolean>;
};

const useUpdateCareEventConfiguration = (id: number | string) => {
  const queryClient = useQueryClient();
  return useMutation<Response, Error, Fields>(
    data =>
      api("PUT /care_event_configurations/{id}", {
        params: [id],
        data: convertTypes(data),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["care_event_configurations"]);
        queryClient.invalidateQueries(["care_event_configuration", id]);
      },
    }
  );
};

export const convertTypes = (fields: Fields) => {
  const newFields = { ...fields };
  Object.keys(newFields.weekly_config).forEach((name: string) => {
    if (typeof newFields.weekly_config[name] === "object") {
      const obj = newFields.weekly_config[name] as Week;
      const type = obj.type;
      switch (type) {
        case "protocol":
          obj["protocol"] = true;
          break;
        case "appointment":
          obj["appointment"] = true;
          break;
        default:
          break;
      }
      delete obj.type;
      newFields.weekly_config[name] = obj;
    }
  });
  return newFields;
};

export default useUpdateCareEventConfiguration;
