import api from "api";

const OP = "POST /oauth/token";
export type Vars = {
  code: string;
  redirect_uri: string;
};

const validateToken = async ({ code, redirect_uri }: Vars) => {
  const authServiceBaseURL = process.env.REACT_APP_AUTH_SERVICE_URL;
  const client_id = process.env.REACT_APP_COGNITO_CLIENT_ID as string;
  const role = "support_admin";
  const grant_type = "authorization_code";

  // @ts-ignore
  return api.parse(OP, { data: { client_id, code, redirect_uri, role, grant_type }, root: authServiceBaseURL });
};

export default validateToken;
