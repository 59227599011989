import React, { useState } from "react";
import PersonIcon from "@material-ui/icons/Person";
import { useTranslation } from "react-i18next";
import { BoxButton, CDialog } from "shared";
import ManageTreatmentsForm from "./ManageTreatmentsForm";

const ManageTreatments: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <BoxButton startIcon={<PersonIcon />} onClick={() => setOpen(true)}>
        {t("manage_treatments.label")}
      </BoxButton>

      <CDialog maxWidth="md" fullWidth open={isOpen} onClose={() => setOpen(false)} aria-labelledby="ManageJoints">
        <ManageTreatmentsForm onClose={() => setOpen(false)} />
      </CDialog>
    </>
  );
};

export default ManageTreatments;
