import api, { BodyParams } from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "PATCH /therapists/{id}";

type Vars = BodyParams<typeof OP>;

const useToggleEnrollments = () => {
  const id = useId();
  return useMutation((data: Vars) => api(OP, { params: [id], data }));
};

export default useToggleEnrollments;
