import api, { Form } from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "POST /patients/{patient_id}/advanced_beneficiary_notices";

export type Vars = Form<typeof OP>;

const useUploadABNDocument = () => {
  const patientId = useId();

  return useMutation(({ option, signed_at, expires_at, file }: Vars) => {
    const [doc] = file as File[];

    const data = new FormData();
    data.append("file", doc, doc.name);
    data.append("signed_at", signed_at);
    data.append("expires_at", expires_at);
    data.append("option", option);

    return api(OP, {
      params: [patientId],
      data,
      headers: new Headers({ Accept: "*/*" }),
    });
  });
};

export default useUploadABNDocument;
