import api from "api";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { Row } from "shared/ValueTable/ValueTable";
import { join } from "utils/misc";
import useRegistry from "../useRegistry";

const useDeleteRecord = (overrideRegistry?: string) => {
  const registry = useRegistry(overrideRegistry);
  const queryClient = useQueryClient();
  const mutation = useMutation(({ value, ...data }: Row) =>
    api.call(join(registry.api, value), { data, method: "DELETE" })
  );

  const deleteRecord = async (value: string) => {
    const key = registry.name;
    const old = queryClient.getQueryData<Row[]>(key)!;
    queryClient.cancelQueries(key);
    queryClient.setQueryData(
      key,
      produce(old, draft => {
        const index = old.indexOf(old.find(record => value === record.value)!);
        draft.splice(index, 1);
      })
    );

    try {
      await mutation.mutateAsync({ value });
      queryClient.invalidateQueries(key);
    } catch (err) {
      queryClient.setQueryData(key, old);
      throw err;
    }
  };

  return { ...mutation, deleteRecord };
};

export default useDeleteRecord;
