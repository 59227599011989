import { MenuItem, TextFieldProps, TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

type Item<K extends string, V> = {
  [prop in K]: V;
};

type CSelectProps<T, D, K> = TextFieldProps & {
  name: string;
  items: T[];
  children: (item: T) => React.ReactNode;
  defaultValue?: D | null;
  valueKey?: K;
};

const CSelect = <
  V extends string | number,
  T extends Item<K, V> | string | number,
  D extends V,
  K extends string = "value"
>({
  items,
  children,
  name,
  defaultValue,
  valueKey = "value" as K,
  ...rest
}: CSelectProps<T, D, K>): React.ReactElement => {
  const { control } = useFormContext();

  const isObj = (item: any): item is Item<K, V> => typeof item !== "string" && typeof item !== "number";

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      as={
        <TextField name={name} select id={name} {...rest}>
          {items.map(item => {
            const key = isObj(item) ? item[valueKey] : (item as string);
            return (
              <MenuItem key={key} value={key}>
                {children(item)}
              </MenuItem>
            );
          })}
        </TextField>
      }
    />
  );
};

export default CSelect;
