import React, { useState } from "react";
import { BoxButton, CDialog } from "shared";
import EndOutOfOfficeForm from "./EndOutOfOfficeForm";
import { WorkRounded } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import useTimeOffPeriod from "../../queries/useTimeOffPeriod";
import useTherapist from "routes/therapist/queries/useTherapist";
import { format } from "date-fns";

const EndOutOfOffice: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const { data: pt } = useTherapist();
  const { data } = useTimeOffPeriod(pt?.time_off_period?.id);

  const closeDialog = () => {
    setOpen(false);
  };
  const openDialog = () => setOpen(true);

  return (
    <>
      <BoxButton startIcon={<WorkRounded style={{ color: "#5096E8" }} />} onClick={openDialog}>
        {t("therapists.out_of_office.end.button")} ({t("therapists.out_of_office.active_until")}{" "}
        {data?.end_date && format(new Date(data?.end_date), "yyyy-MM-dd")})
      </BoxButton>

      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="closeAccount">
        <EndOutOfOfficeForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default EndOutOfOffice;
