import React from "react";
import { useTranslation } from "react-i18next";
import { Pane, Text, Loading, ProgressButton } from "shared";
import styled from "styled-components";
import useActivities from "../queries/useActivities";
import usePatient from "../queries/usePatient";
import useRevertTreatmentSwitch from "../queries/useRevertTreatmentSwitch";
import { useQueryClient } from "react-query";
import { queryKeys } from "utils/misc";
import { useId } from "utils/hooks";

const TreatmentHistory: React.VFC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: patient } = usePatient();
  const { data, isSuccess, isLoading, refetch } = useActivities();
  const id = useId();

  const { mutateAsync, isLoading: isLoadingRevertTreatmentSwitch } = useRevertTreatmentSwitch();

  const revertTreatmentSwitch = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      console.error(error);
    } finally {
      const patientKey = queryKeys.patient(id);
      queryClient.invalidateQueries(patientKey);
      refetch();
    }
  };

  const historyComponent = (
    <>
      {data?.previous && data?.previous.length > 0 && (
        <div data-testid="treatment-history">
          <Text paragraph caption secondary>
            {t("patients.treatment_history")}
          </Text>

          <Pane dark>
            {data.previous.map((p, i) => (
              <HistoryItemContainer>
                <Text>
                  {p.lateral_location && p.location ? (
                    <>
                      <Text>{t(`patients.lateral_location.${p.lateral_location}`)} </Text>
                      <Text>{t(`patients.pain_location.${p.location}`)}</Text>
                    </>
                  ) : (
                    <Text>N/A</Text>
                  )}
                </Text>
                {/* @ts-ignore */}
                {patient.has_switched_treatment && i === 0 && (
                  <ProgressButton onClick={revertTreatmentSwitch} loading={isLoadingRevertTreatmentSwitch}>
                    {t("patients.revert_treatment_switch")}
                  </ProgressButton>
                )}
                <Text>
                  {p.start_date} - {p.end_date}
                </Text>
              </HistoryItemContainer>
            ))}
          </Pane>
        </div>
      )}
    </>
  );

  return (
    <>
      {isSuccess && historyComponent}
      {isLoading && <Loading />}
    </>
  );
};

const HistoryItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default TreatmentHistory;
