import React from "react";
import { useLocation } from "react-router-dom";
import { Button, MenuItem, Select } from "@material-ui/core";
import { doorPng, Logo } from "assets";
import { join } from "utils/misc";
import { useTranslation } from "react-i18next";
import { getSignInLinks } from "utils/market";
import { Breakpoint } from "theme";
import { Anchor, HFlex, Icon, Text, VFlex } from "shared";
import { css } from "@emotion/css";

interface Props {
  redirect: string;
}

const SignIn: React.VFC<Props> = ({ redirect }) => {
  const { t } = useTranslation();
  const { state } = useLocation<string>();
  const authServer = join(process.env.REACT_APP_COGNITO_BASE_URL, "/login");
  const authParams = new URLSearchParams({
    response_type: "code",
    client_id: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
    redirect_uri: join(window.location.origin, redirect, "/"),
    scope: "openid profile email",
  });

  if (state) {
    authParams.append("state", state);
  }

  return (
    <VFlex justifyContent="flex-end" height="100vh" alignItems="center" bgcolor="white">
      <HFlex alignItems="baseline" pre>
        <Text xlarge mb={0}>
          Backoffice{" "}
        </Text>
        <Select name="database" defaultValue={process.env.REACT_APP_DATABASE} disableUnderline>
          {getSignInLinks().map(({ name, url, value, Flag }) => (
            <MenuItem key={value} value={value}>
              <Anchor href={url}>
                <HFlex alignItems="center">
                  <Text xlarge mr={2}>
                    {name}
                  </Text>{" "}
                  <Icon of={Flag} size={3} ml="auto" />
                </HFlex>
              </Anchor>
            </MenuItem>
          ))}
        </Select>
      </HFlex>
      <div className={styles.door}>
        <Icon of={Logo} size={5} mt={14} mb={4} />
        <Button href={join(authServer, authParams)} variant="contained" color="primary">
          {t`buttons.sign_in`}
        </Button>
      </div>
    </VFlex>
  );
};

export default SignIn;

const styles = {
  door: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 763px;
    width: 440px;
    background: url(${doorPng}) no-repeat center;
    background-size: contain;
    margin-top: 50px;

    @media screen and (max-width: ${Breakpoint.xs}px) {
      background: none;
    }
  `,
};
