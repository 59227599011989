import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "shared";
import useInsuranceProfile from "../../queries/useInsuranceProfile";

const MEDICARE_SOFT_LIMIT_AMOUNT = 2333;

const MedicareUsage: React.VFC = () => {
  const { t } = useTranslation();
  const { data: insuranceProfiles = [] } = useInsuranceProfile();

  const hasMedicare = insuranceProfiles.find(({ us_insurance_company: { medicare } = {} }) => medicare);
  const doesntHaveMedicare = !hasMedicare;
  if (doesntHaveMedicare) {
    return null;
  }

  const totalMedicareAmount = insuranceProfiles.reduce(
    (cumulativeAmount, { medicare_amount_used: amountForInsurer }) => {
      const additionalAmount = amountForInsurer || 0;
      return cumulativeAmount + additionalAmount;
    },
    0
  );

  const overLimit = totalMedicareAmount >= MEDICARE_SOFT_LIMIT_AMOUNT;

  return (
    <Text paragraph>
      <Text light mr={1}>
        {t("insurance.medicare_usage")}:
      </Text>
      <Text bold error={overLimit}>
        {totalMedicareAmount}
      </Text>
    </Text>
  );
};

export default MedicareUsage;
