import React from "react";
import { Button, Box, Container } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import { CSelect, CTextField, HFlex, VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import useClosePatientAccount, { Vars } from "../../queries/useClosePatientAccount";
import usePatient from "routes/patient/queries/usePatient";
import { fullName, queryKeys } from "utils/misc";
import { useMountState } from "utils/hooks";

interface Props {
  onClose: () => void;
}

const CloseAccountForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const form = useForm<Vars>();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { mutateAsync, isLoading } = useClosePatientAccount();
  const { data: patient } = usePatient();
  const getMountState = useMountState();

  if (!patient) return null;

  const reasons = [
    {
      value: "unreachable",
      label: t`patients.end_treatment_form.reasons.unreachable`,
    },
    {
      value: "referred_elsewhere",
      label: t`patients.end_treatment_form.reasons.referred_elsewhere`,
    },
    {
      value: "not_suitable",
      label: t`patients.end_treatment_form.reasons.not_suitable`,
    },
    {
      value: "not_interested",
      label: t`patients.end_treatment_form.reasons.not_interested`,
    },
    {
      value: "cost",
      label: t`patients.end_treatment_form.reasons.cost`,
    },
    {
      value: "surgery",
      label: t`patients.end_treatment_form.reasons.surgery`,
    },
    {
      value: "other",
      label: t`patients.end_treatment_form.reasons.other`,
    },
    {
      value: "ceased_activity",
      label: t`patients.end_treatment_form.reasons.ceased`,
    },
    {
      value: "symptom_free",
      label: t`patients.end_treatment_form.reasons.symptom_free`,
    },
    {
      value: "acquired_tools",
      label: t`patients.end_treatment_form.reasons.acquired_tools`,
    },
  ];

  const submit = form.handleSubmit(async fields => {
    try {
      await mutateAsync(fields);
      queryClient.invalidateQueries(queryKeys.patient(patient.id));
      queryClient.invalidateQueries(queryKeys.patientSearch());
      getMountState() && onClose();
    } catch (err) {
      setErrors(t`errors.could_not_close_account`, err);
    }
  });

  return (
    <Container>
      <HFlex justifyContent="center" mb={2}>
        <CancelRounded style={{ color: "#ff8585", width: 80, height: 80 }} />
      </HFlex>
      <Text heading bold center id="close-account">
        {t`patients.end_treatment_form.close_account`}
      </Text>
      <Text center paragraph mb={4}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>
      <Box mx={2}>
        <FormProvider {...form}>
          <form onSubmit={submit}>
            <CSelect margin="dense" fullWidth label={t`form.reason`} name="option" items={reasons} required>
              {({ label }) => <Text>{label}</Text>}
            </CSelect>
            <CTextField
              margin="dense"
              size="small"
              fullWidth
              multiline
              name="comment"
              placeholder={t`form.comment`}
              noClear
            />
            <VFlex alignItems="center" gap={2} my={5}>
              <ProgressButton loading={isLoading}>{t`patients.end_treatment_form.close_account`}</ProgressButton>
              <Button disabled={isLoading} onClick={onClose}>
                {t`buttons.cancel`}
              </Button>
            </VFlex>
          </form>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default CloseAccountForm;
