import React from "react";
import { Box, CircularProgress, TableCell, Fab } from "@material-ui/core";
import { CTable, Text, VFlex } from "shared";
import { useTranslation } from "react-i18next";
import { Breakpoint } from "theme";
import { Area, WithChildren } from "types";
import { RowLink } from "shared/CTable/CTable";
import { join } from "utils/misc";
import useCareEventConfigurations from "../../queries/useCareEventConfigurations";
import { Add } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Result } from "api";
import usePayers from "routes/patientSearch/queries/usePayers";
import { Country, isUsMarket } from "utils/market";

const Cell: React.VFC<WithChildren> = ({ children }) => (
  <TableCell>
    <Text tiny>{children}</Text>
  </TableCell>
);

const CareEventConfigurationsTable: React.VFC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data, isLoading } = useCareEventConfigurations();
  const { data: payers, isLoading: isLoadingPayers } = usePayers(isUsMarket ? Country.US : Country.SE);

  const tableHeader = {
    id: t("care_event_configurations.id"),
    name: t("care_event_configurations.name"),
    utilizer_id: t("care_event_configurations.payer"),
  };

  type CareEventConfigurations = Result<"GET /care_event_configurations">;

  const tableCellFactory = ({ id, name, utilizer_id }: CareEventConfigurations[number]) => {
    const payer = payers?.find(payer => payer.id === utilizer_id);

    return {
      id: (
        <TableCell>
          <RowLink
            to={{
              pathname: join(Area.CareEventConfigurations, "edit", id),
              state: window.location.href.match(/\?.+$/)?.[0],
            }}
          />
          <Text tiny ml={0.5}>
            {id}
          </Text>
        </TableCell>
      ),
      name: <Cell>{name}</Cell>,
      utilizer_id: <Cell>{isLoadingPayers ? <CircularProgress size="small" /> : payer?.name}</Cell>,
    };
  };

  return (
    <VFlex>
      <VFlex bgcolor="background.paper" py={3} alignItems="center">
        {isLoading || (isLoadingPayers && <CircularProgress />)}
        {data?.length === 0 && (
          <Text bold secondary paragraph mb={0}>
            {t("care_event_configurations.tables.no_configs_found")}
          </Text>
        )}
        <Box width={"100%"} maxWidth={Breakpoint.lg} alignItems="center" marginBottom={2}>
          <Fab
            color="primary"
            size="medium"
            onClick={() => history.push(join(Area.CareEventConfigurations, "new"))}
            data-test-id="invite-patient"
          >
            <Add />
          </Fab>
          <Text caption secondary ml={1}>
            {t("buttons.create")}
          </Text>
        </Box>

        {data?.length && (
          <Box width="100%" maxWidth={Breakpoint.lg} overflow="auto" data-test-id="search-results">
            <CTable header={tableHeader} items={data} cellFactory={tableCellFactory} />
          </Box>
        )}
      </VFlex>
    </VFlex>
  );
};

export default CareEventConfigurationsTable;
