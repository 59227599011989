import { CircularProgress, TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { Controller, useFormContext } from "react-hook-form";

type Props<Option, Value> = Omit<AutocompleteProps<Option, false, false, false>, "renderInput" | "defaultValue"> &
  Pick<TextFieldProps, "name" | "label" | "required" | "defaultValue" | "margin"> & {
    getOptionValue?: (option: Option) => Value;
  };

const hasValue = (option: any): option is { value: any } => "value" in option;
const hasLabel = (option: any): option is { label: any } => "label" in option;

function CAutocomplete<Option, Value>({
  options,
  name = "",
  label,
  required,
  margin,
  defaultValue = null,
  getOptionValue = option => (hasValue(option) ? option.value : option),
  getOptionLabel = option => (hasLabel(option) ? option.label : option),
  disabled,
  noOptionsText,
  className,
  ...rest
}: Props<Option, Value>) {
  const { control } = useFormContext();
  const value = control.watchInternal(name, defaultValue) as Value;

  const findOptionByValue = (value?: Value) => options.find(option => getOptionValue(option) === value);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={() => (
        <Autocomplete
          {...rest}
          disabled={disabled}
          freeSolo={false}
          autoComplete
          autoHighlight
          value={findOptionByValue(value) ?? null}
          getOptionLabel={getOptionLabel}
          loadingText={<CircularProgress />}
          onChange={(_, data) => control.setValue(name, data && getOptionValue(data))}
          options={options}
          className={className}
          renderInput={params => (
            <TextField name={name} label={label} required={required} margin={margin} {...params} />
          )}
        />
      )}
    />
  );
}

export default CAutocomplete;
