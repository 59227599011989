import { Button, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CDialog, Text, useErrorContext, Icon } from "shared";
import RecordForm from "./RecordForm";
import useAddRecord from "../queries/useAddRecord";
import { Column, Row } from "../ValueTable";
import { Check } from "@material-ui/icons";
import { registries } from "../";
import { SPACING } from "theme";
import { css } from "@emotion/css";
import useRegistry from "../useRegistry";

interface Props {
  filtered: Column[];
  onFilterChange: (columns: string[]) => void;
  onRegistryChange: (registry: string) => void;
}

const Head: React.VFC<Props> = ({ filtered, onFilterChange, onRegistryChange }) => {
  const registry = useRegistry();
  const [isDialogOpen, setDialog] = useState(false);
  const setError = useErrorContext();
  const { addRecord } = useAddRecord();
  const { t } = useTranslation();
  const closeDialog = () => setDialog(false);
  const openDialog = () => setDialog(true);

  const addRow = async (row: Row) => {
    closeDialog();
    try {
      await addRecord(row);
    } catch (err) {
      setError(t`errors.could_not_create_record`, err);
    }
  };

  const singleColumn = filtered.length === 1;

  return (
    <>
      <Grid container alignItems="center" spacing={2} className={styles.grid}>
        <Grid item xs={12} md="auto">
          <Select
            value={registry.name}
            onChange={event => {
              onRegistryChange(event.target.value as string);
            }}
          >
            {registries.map(({ name }) => (
              <MenuItem key={name} value={name}>
                <Text large nowrap>
                  {
                    // @ts-ignore
                    t(`registry.${name}`)
                  }
                </Text>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md>
          <Autocomplete
            renderOption={({ label }, { selected }) => (
              <Text nowrap>
                {label} {selected && <Icon of={Check} size={1.2} />}
              </Text>
            )}
            size="small"
            fullWidth
            disableCloseOnSelect
            disableClearable
            ChipProps={singleColumn ? { onDelete: null } : {}}
            placeholder="columns"
            autoComplete
            autoHighlight
            value={filtered}
            getOptionLabel={column => column.label}
            multiple
            onChange={(_, selection, reason) => {
              if (reason === "remove-option" && singleColumn) return;
              onFilterChange(selection.map(({ name }) => name));
            }}
            options={registry.columns}
            renderInput={params => <TextField label="Columns" {...params} />}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <Button variant="contained" color="primary" onClick={openDialog} endIcon={<Add />} size="medium">
            <Text nowrap>{t`buttons.add_record`}</Text>
          </Button>
        </Grid>
      </Grid>

      <CDialog maxWidth="xs" fullWidth open={isDialogOpen} onClose={closeDialog} aria-labelledby="addRecord">
        <RecordForm heading={t`form.new_record`} onCancel={closeDialog} onAdd={addRow} />
      </CDialog>
    </>
  );
};

export default Head;

const styles = {
  grid: css`
    padding-top: ${8 * SPACING}px;
    padding-bottom: ${4 * SPACING}px;
  `,
};
