import { Link, Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { styles } from "../styles";

interface Props {
  dischargeEmailConsent: boolean;
  gpAddress: string;
  patientName: string;
  patientAddress: string;
  patientEmail: string;
  therapistFirstName: string;
  therapistLastName: string;
}

export const Footer: React.VFC<Props> = ({
  dischargeEmailConsent,
  gpAddress,
  patientName,
  patientAddress,
  patientEmail,
  therapistFirstName,
  therapistLastName,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <View style={styles.section}>
        <Text>{t("patients.uk_discharge_letter.footer.line_1")}</Text>
        <Text>
          {t("patients.uk_discharge_letter.footer.line_2", {
            therapistName: `${therapistFirstName} ${therapistLastName}`,
          })}
        </Text>
      </View>

      <View style={styles.section}>
        <View style={styles.row}>
          <Text>{t("patients.uk_discharge_letter.footer.line_3")}</Text>
          <Text>
            <Link src={`mailto:${t("patients.uk_discharge_letter.contact_link")}`}>
              {t("patients.uk_discharge_letter.contact_link")}
            </Link>
            .
          </Text>
        </View>
        <Text>{t("patients.uk_discharge_letter.footer.line_4")}</Text>
      </View>

      <View wrap={false}>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text>CC.</Text>
            <Text style={styles.hidden}>-START</Text>
          </View>
          <Text>{patientName}</Text>
          <Text>{patientAddress}</Text>
          <Text style={styles.hidden}>CC.-END</Text>
          {dischargeEmailConsent && <Text>{patientEmail}</Text>}
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.bold}>DCC.</Text>
            <Text style={styles.hidden}>-START</Text>
          </View>
          <Text>{gpAddress}</Text>
          <Text style={styles.hidden}>DCC.-END</Text>
        </View>
      </View>
    </>
  );
};
