import { CDialog } from "shared";
import FunctionalityForm from "./FunctionalityForm";
import HealthReportForm from "./HealthReportForm";
import { TYPES } from "./Activities";
import { definitions } from "api";

type Activity = definitions["activity"];

interface Props {
  activity: Activity | null;
  onClose: () => void;
}

const EditActivity: React.VFC<Props> = ({ activity, onClose }) => {
  if (!activity) return null;
  let formEm = null;

  if (TYPES.functionality.includes(activity.type)) formEm = <FunctionalityForm activity={activity} onClose={onClose} />;
  else if (TYPES.health.includes(activity.type)) formEm = <HealthReportForm activity={activity} onClose={onClose} />;

  return (
    <CDialog maxWidth="xs" fullWidth open={!!formEm} onClose={onClose} aria-labelledby="edit-activity">
      {formEm}
    </CDialog>
  );
};

export default EditActivity;
