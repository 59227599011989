import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useReopenPatientAccount = () => {
  const id = useId();
  return useMutation(() => api("POST /patients/{id}/reopen", [id]));
};

export default useReopenPatientAccount;
