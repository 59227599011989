import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles } from "@material-ui/core";
import { capitalizeFirstLetter } from "utils/misc";

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    marginBottom: 5,
    borderBottom: "2px solid rgba(38,50,56, 0.16)",
  },
  headCell: {
    color: "#263238",
  },
  table: {
    minWidth: 250,
    marginLeft: 28,
    border: "none",
  },
  centered: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  flex: {
    display: "flex",
    margin: 12,
    justifyContent: "space-between",
    width: 150,
  },
  row: {
    borderTop: "1px solid rgba(38,50,56, 0.16)",
    "&:nth-of-type(2n + 0)": {
      backgroundColor: "rgba(80,150,232, 0.04)",
    },
  },
  cell: {
    fontSize: 16,
  },
}));

interface IcdCode {
  code: string;
  meaning: string;
}

const ICD_CODES: IcdCode[] = [
  {
    code: "CPT 97161",
    meaning: "Pt eval low",
  },
  {
    code: "CPT 97162",
    meaning: "Pt eval med",
  },
  {
    code: "CPT 97163",
    meaning: "Pt eval high complex 20 min",
  },
  {
    code: "CPT 97164",
    meaning: "Pt re-eval est plan care",
  },
  {
    code: "CPT 98970",
    meaning: "Qnhp ol dig assmt&mgmt 5-10",
  },
  {
    code: "CPT 97530",
    meaning: "Discharge Note (Supplemental Call)",
  },
  {
    code: "CPT 97164",
    meaning: "Reeval: 97164. Supplemental Call, depending on medical record.",
  },
  {
    code: "CPT 97110",
    meaning: "Treatment: 97110. Supplemental Call, depending on medical record.",
  },
  {
    code: "CPT 97164",
    meaning: "Has to be AV call",
  },
];

const CDMTable: React.VFC = () => {
  const classes = useStyles();
  const [firstObj] = ICD_CODES;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {Object.keys(firstObj).map((property, i) => (
              <TableCell key={`cdm-head-${i}`}>{capitalizeFirstLetter(property)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ICD_CODES.map(({ code, meaning }, i) => (
            <TableRow key={`cdm-row-${i}`} className={classes.row}>
              <TableCell key={`cdm-row-code-${i}`}>{code}</TableCell>
              <TableCell key={`cdm-row-meaning-${i}`}>{meaning}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CDMTable;
