import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useReopenTherapistAccount = () => {
  const id = useId();
  return useMutation(() => api("POST /therapists/{id}/reopen", [id]));
};

export default useReopenTherapistAccount;
