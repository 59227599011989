import React from "react";
import { Button, Container } from "@material-ui/core";
import { VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useReopenPatientAccount from "../../queries/useReopenPatientAccount";
import usePatient from "routes/patient/queries/usePatient";
import { fullName, queryKeys } from "utils/misc";
import { definitions } from "api";

interface Props {
  onClose: () => void;
}

const ReopenAccountForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();
  const { mutateAsync, isLoading } = useReopenPatientAccount();
  const { data: patient } = usePatient();

  if (!patient) return null;

  const reopenAccount = async () => {
    const patientKey = queryKeys.patient(patient.id);
    const oldData = queryClient.getQueryData<definitions["patient_full"]>(patientKey);
    queryClient.cancelQueries(patientKey);
    queryClient.setQueryData(patientKey, { ...oldData, deleted_at: null });
    onClose();

    try {
      await mutateAsync();
    } catch (err) {
      queryClient.setQueryData(patientKey, oldData);
      setErrors(t`errors.could_not_reopen_account`, err);
    } finally {
      queryClient.invalidateQueries(patientKey);
      queryClient.invalidateQueries(queryKeys.patientSearch());
    }
  };

  return (
    <Container>
      <Text heading bold center id="reopen-account">
        {t("patients.reopen_account")}
      </Text>
      <Text center paragraph mb={3}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>
      <VFlex alignItems="center" gap={2} my={5}>
        <ProgressButton loading={isLoading} onClick={reopenAccount}>
          {t("patients.reopen_account")}
        </ProgressButton>
        <Button disabled={isLoading} onClick={onClose}>
          {t("buttons.cancel")}
        </Button>
      </VFlex>
    </Container>
  );
};

export default ReopenAccountForm;
