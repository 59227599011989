import { useParams } from "react-router-dom";
import useCareEventConfiguration from "../../queries/useCareEventConfiguration";
import CareEventConfigurationForm from "../CareEventConfigurationForm/CareEventConfigurationForm";

const CareEventConfiguration = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useCareEventConfiguration(id);

  if (isLoading || !data) return null;
  return <CareEventConfigurationForm careEventConfiguration={data} />;
};

export default CareEventConfiguration;
