import api, { BodyParams } from "api";
import { useMutation } from "react-query";

const OP = "POST /us_insurance_claims";

export type Vars = BodyParams<typeof OP>;

const useGenerateClaim = () =>
  useMutation<Response, Error, Vars>(({ care_event_id, amount_cents, cpt_code }) =>
    api(OP, {
      data: {
        care_event_id,
        amount_cents,
        cpt_code,
      },
    })
  );

export default useGenerateClaim;
