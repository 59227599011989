import api from "api";
import { BodyParams } from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "POST /patients/{patient_id}/us_provider_group_profiles";

type Vars = BodyParams<typeof OP>;

const useUpdateProviderGroup = () => {
  const id = useId();

  return useMutation(({ us_provider_group_id }: Vars) => {
    return api(OP, {
      params: [id],
      data: { us_provider_group_id } as any,
    });
  });
};

export default useUpdateProviderGroup;
