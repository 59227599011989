import React from "react";
import { Text } from "shared";
import { CloseAccount, RemoveOfflineVisitAndBilling, ReopenAccount, ReopenTreatment } from "./";
import { useTranslation } from "react-i18next";
import usePatient from "../queries/usePatient";
import { patientNotInTreatment } from "./ReopenTreatment/patientNotInTreatment";

const Actions: React.VFC = () => {
  const { t } = useTranslation();
  const { data: patient } = usePatient();

  return (
    <div>
      <Text paragraph caption secondary>
        {t`patients.actions`}
      </Text>

      {patientNotInTreatment(patient?.state) && <ReopenTreatment />}
      {patient?.deleted_at ? <ReopenAccount /> : <CloseAccount />}
      <RemoveOfflineVisitAndBilling />
    </div>
  );
};

export default Actions;
