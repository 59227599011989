import { css } from "@emotion/css";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import { Download } from "assets";
import { useTranslation } from "react-i18next";
import { HFlex, Pane, Text, Icon, Anchor } from "shared";
import { Shadows } from "theme";
import { getDate } from "utils/time";
import { definitions } from "api";

type Props = definitions["advanced_beneficiary_notice"] & {
  deleteMode?: boolean;
  onDelete?: () => void;
};

export const ABN: React.VFC<Props> = ({ signed_at, expires_at, option, url, created_at, deleteMode, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Pane className={styles.doc}>
      <Accordion>
        <AccordionSummary
          expandIcon={deleteMode ? <Icon role="button" error of={Close} onClick={onDelete} /> : <ExpandMore />}
        >
          <HFlex width="100%" justifyContent="space-between" alignItems="baseline">
            <Text>{t`patients.medical_records.abn`}</Text>
            <span>
              <Text small light>
                {t`common.signed`}:{" "}
              </Text>
              <Text small>{signed_at && getDate(signed_at)}</Text>
            </span>
            <span>
              <Text small light>
                {t`common.expires`}:{" "}
              </Text>
              <Text small>{expires_at && getDate(expires_at)}</Text>
            </span>
          </HFlex>
        </AccordionSummary>
        <AccordionDetails>
          <Text paragraph small>
            <Text light>{t`common.created_at`}: </Text>
            {getDate(created_at)}
          </Text>
          <Text paragraph small>
            <Text light>{t`patients.document`}: </Text>
            <Anchor link href={url}>
              <Icon of={Download} />
            </Anchor>
          </Text>
          <Text paragraph small>
            <Text light>{t`form.option`}: </Text>
            {
              // @ts-ignore
              t(`form.options.${option}`)
            }
          </Text>
        </AccordionDetails>
      </Accordion>
    </Pane>
  );
};

export default ABN;

const styles = {
  doc: css`
    box-shadow: ${Shadows.soft};
  `,
};
