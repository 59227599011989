import React from "react";
import { Button, Grid, Box } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CRadioGroup, CSelect, CTextField, HFlex, Text, Icon } from "shared";
import { Area } from "types";
import { Category, Vars as Props } from "../queries/useListTherapists";
import { useWindowWidth } from "utils/hooks";
import { Breakpoint } from "theme";
import { Search } from "@material-ui/icons";
import { countries } from "utils/market";
import { join } from "utils/misc";

const SearchTherapistForm: React.VFC<Props> = ({ country, category, search }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const isNarrow = useWindowWidth() < Breakpoint.sm;
  const form = useForm();
  const currentCategory = form.watch("category", category);
  const currentSearch = form.watch("search", search);

  const categories = [
    {
      value: Category.id,
      label: t`patients.id`,
      placeholder: t`patients.id`,
      pattern: /\d+/,
    },
    {
      value: Category.name,
      label: t`form.name`,
      placeholder: t`form.name`,
    },
    {
      value: Category.hcp,
      label: t`form.health_care_provider`,
    },
  ];

  const selectedCategory = categories.find(category => category.value === currentCategory)!;

  const submit = form.handleSubmit(fields => {
    const params = new URLSearchParams(fields);
    push(join(Area.Therapists, params));
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={submit}>
        <HFlex mb={4} justifyContent="center" alignItems="baseline" pre>
          <Text xlarge light>
            {t`form.find_therapists_in`}{" "}
          </Text>
          <CSelect name="country" items={countries} defaultValue={country} variant="standard">
            {({ name, Flag }) => (
              <HFlex alignItems="center">
                <Text xlarge light mr={2}>
                  {name}
                </Text>
                <Icon ml="auto" size={3} of={Flag} />
              </HFlex>
            )}
          </CSelect>
        </HFlex>
        <Box mb={1}>
          {isNarrow ? (
            <HFlex mb={2} alignItems="baseline" pre>
              <Text>{t`form.by`} </Text>
              <CSelect name="category" items={categories} defaultValue={currentCategory} variant="standard">
                {({ label }) => <Text initial>{label}</Text>}
              </CSelect>
            </HFlex>
          ) : (
            <CRadioGroup
              label={t`form.by_category`}
              row
              items={categories}
              name="category"
              defaultValue={currentCategory}
              aria-label="Categories"
            />
          )}
        </Box>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item xs={12} sm>
            <CTextField
              fullWidth
              name="search"
              defaultValue={search}
              required
              placeholder={isNarrow ? "" : selectedCategory.label}
              pattern={selectedCategory.pattern}
              adornment={<Icon of={Search} size={1.5} />}
              aria-label="Search string"
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={!currentSearch}
              aria-label="Search"
            >
              {t`buttons.search`}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SearchTherapistForm;
