import React from "react";
import { Button } from "@material-ui/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { CTextField, Dimmer, HFlex, Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Breakpoint, SPACING } from "theme";
import { Country } from "utils/market";
import { stripDeletedTagFromEmail } from "utils/misc";
import { css } from "@emotion/css";
import usePatient from "../../queries/usePatient";

const FieldXS = styled(CTextField)`
  & input {
    padding-bottom: 7px;
    padding-top: 7px;
  }
`;

export type Fields = Record<string, string>;

interface Props {
  onCancel: () => void;
  onSubmit: SubmitHandler<Fields>;
}

const PersonalForm: React.VFC<Props> = ({ onCancel, onSubmit }) => {
  const form = useForm<Fields>();
  const { t } = useTranslation();
  const { data: patient } = usePatient();

  if (!patient) return null;

  const labels = {
    first_name: t("form.first_name"),
    last_name: t("form.last_name"),
    preferred_name: t("form.preferred_name"),
    email: t("form.email"),
    birthday: t("form.birth"),
    personal_number: t("patients.medical_records.personal_number"),
    phone_number: t("form.phone"),
    social_security: t("patients.medical_records.social_security_number"),
  };

  const onSave = form.handleSubmit(onSubmit);

  return (
    <Pane dark mb={2}>
      <FormProvider {...form}>
        <form onSubmit={onSave} aria-label="Personal information">
          <Dimmer />
          <div className={styles.grid}>
            <Text light>{labels.first_name}</Text>
            <FieldXS
              fullWidth
              required
              size="small"
              autoComplete="off"
              name="first_name"
              defaultValue={patient.first_name}
            />
            <Text light>{labels.last_name}</Text>
            <FieldXS
              fullWidth
              required
              size="small"
              autoComplete="off"
              name="last_name"
              defaultValue={patient.last_name}
            />
            <Text light>{labels.preferred_name}</Text>
            <FieldXS
              fullWidth
              required
              size="small"
              autoComplete="off"
              name="preferred_name"
              defaultValue={patient.preferred_name}
            />
            <Text light>{labels.email}</Text>
            <FieldXS
              fullWidth
              required
              size="small"
              autoComplete="off"
              name="email"
              type="email"
              defaultValue={patient.email && stripDeletedTagFromEmail(patient.email)}
            />
            {patient.country === Country.SE ? (
              <>
                <Text light>{t("patients.medical_records.personal_number")}</Text>
                <Text bold>{patient.personal_number}</Text>
              </>
            ) : (
              <>
                <Text light>{labels.birthday}</Text>
                <FieldXS
                  fullWidth
                  required
                  size="small"
                  autoComplete="off"
                  name="birthday"
                  defaultValue={patient.birthday}
                  pattern={/^\d{4}-\d{2}-\d{2}$/}
                  placeholder="YYYY-MM-DD"
                />
              </>
            )}
            <Text light>{labels.phone_number}</Text>
            <FieldXS
              fullWidth
              required
              size="small"
              autoComplete="off"
              name="phone_number"
              defaultValue={patient.phone_number}
            />
          </div>
          <HFlex pt={2} gap={2} justifyContent="flex-end">
            <Button onClick={onCancel}>{t("buttons.cancel")}</Button>
            <Button type="submit" variant="contained" color="primary">
              {t("buttons.save")}
            </Button>
          </HFlex>
        </form>
      </FormProvider>
    </Pane>
  );
};

export default PersonalForm;

const styles = {
  grid: css`
    display: grid;
    grid-auto-rows: 45px;
    align-items: center;
    grid-column-gap: ${SPACING}px;
    @media screen and (max-width: ${Breakpoint.sm - 1}px) {
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${Breakpoint.sm}px) {
      grid-template-columns: auto 1fr;
    }
  `,
};
