import React from "react";
import { Container } from "@material-ui/core";
import InviteDoctor from "./components/InviteDoctor";
import InvitePartner from "./components/invitePartner";
// import UploadExerciseImage from "./components/UploadExerciseImage";
import UploadUnclaimedReferral from "./components/UploadUnclaimedReferral";
// import UploadTextLessonCoverImage from "./components/UploadTextLessonCoverImage";
// import UploadTextLessonParagraphImage from "./components/UploadTextLessonParagraphImage";
import { isUsMarket } from "utils/market";
import ManageTreatments from "./components/ManageTreatments";
import FRInvoices from "./components/FRInvoices";
import { BoxButton } from "shared";
import { useTranslation } from "react-i18next";
import { Area } from "types";
import { join } from "utils/misc";
import { Link } from "react-router-dom";
import { ViewComfy, Edit } from "@material-ui/icons";
import CareEventConfigurations from "./components/CareEventConfigurations";
// import useUpdateFunctionalTestTranslations from "./queries/useUpdateFunctionalTestTranslations";
// import useUpdateExerciseTranslations from "./queries/useUpdateExerciseTranslations";
// import useUpdateTextLessonTranslations from "./queries/useUpdateTextLessonTranslations";

const Other: React.VFC = () => {
  const { t } = useTranslation();
  // const {
  //   mutateAsync: updateFunctionalTestTranslations,
  //   isLoading: isLoadingFunctionalTestTranslations,
  // } = useUpdateFunctionalTestTranslations();
  // const {
  //   mutateAsync: updateExerciseTranslations,
  //   isLoading: isLoadingExerciseTranslations,
  // } = useUpdateExerciseTranslations();
  // const {
  //   mutateAsync: updateTextLessonTranslations,
  //   isLoading: isLoadingTextLessonTranslations,
  // } = useUpdateTextLessonTranslations();

  return (
    <Container>
      <Container maxWidth="sm">
        {/* <UploadExerciseImage />
        <UploadTextLessonCoverImage />
        <UploadTextLessonParagraphImage />
        <BoxButton
          disabled={isLoadingFunctionalTestTranslations}
          startIcon={isLoadingFunctionalTestTranslations ? <CircularProgress size={30} /> : <Translate />}
          onClick={() => updateFunctionalTestTranslations()}
        >
          {t("translations.update_functional_test")}
        </BoxButton>
        <BoxButton
          disabled={isLoadingExerciseTranslations}
          startIcon={isLoadingExerciseTranslations ? <CircularProgress size={30} /> : <Translate />}
          onClick={() => updateExerciseTranslations()}
        >
          {t("translations.update_exercise")}
        </BoxButton>
        <BoxButton
          disabled={isLoadingTextLessonTranslations}
          startIcon={isLoadingTextLessonTranslations ? <CircularProgress size={30} /> : <Translate />}
          onClick={() => updateTextLessonTranslations()}
        >
          {t("translations.update_text_lesson")}
        </BoxButton> */}
        {isUsMarket && <UploadUnclaimedReferral />}
        {isUsMarket && (
          <Link to={Area.UnclaimedMedicalReferrals}>
            <BoxButton startIcon={<ViewComfy />}>{t("views.unclaimed_medical_referrals")}</BoxButton>
          </Link>
        )}
        {isUsMarket && <InviteDoctor />}
        {isUsMarket && <InvitePartner />}
        <ManageTreatments />
        <FRInvoices />
        <Link to={join(Area.Registry, "referral-sources")}>
          <BoxButton startIcon={<ViewComfy />}>{t("views.registry")}</BoxButton>
        </Link>
        <CareEventConfigurations />
        <Link to={Area.Payers}>
          <BoxButton startIcon={<ViewComfy />}>{t("views.payers")}</BoxButton>
        </Link>
        <Link to={Area.LessonEditor}>
          <BoxButton startIcon={<Edit />}>{t("views.lessonEditor")}</BoxButton>
        </Link>
      </Container>
    </Container>
  );
};

export default Other;
