import styled from "styled-components";
import { Colors } from "theme";
import { Area } from "types";
import { join } from "utils/misc";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: ${Colors.JA_BLUE};
`;

interface PatientLinkProps {
  readonly patientId: string | number | null;
}
const PatientLink: React.VFC<PatientLinkProps> = ({ patientId }) => (
  <StyledLink to={join(Area.Patients, patientId ?? "")}>Patient: {patientId}</StyledLink>
);

export default PatientLink;
