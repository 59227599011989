import React from "react";
import { Container } from "@material-ui/core";
import { CTextField, HFlex, DialogSuccessNotice, ProgressButton, Text, useErrorContext } from "shared";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { delay, fullName, queryKeys } from "utils/misc";
import { useId, useMountState } from "utils/hooks";
import { FORM_NOTIFICATION_DELAY } from "theme";
import useAddStripeCustomer, { Vars } from "../../queries/useAddStripeCustomer";
import useTherapist from "../../queries/useTherapist";
import { useQueryClient } from "react-query";
import { Edit } from "@material-ui/icons";

const defaultValues = {
  stripe_account: "France",
};

interface Props {
  onClose: () => void;
}

const AddStripeCustomerForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<Vars>({ defaultValues });
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data } = useAddStripeCustomer();
  const therapistId = useId();
  const { data: therapist } = useTherapist();

  const setErrors = useErrorContext();
  const getMountState = useMountState();

  const stripe_account = form.watch("stripe_account");

  const submit = form.handleSubmit(async fields => {
    const ptKey = queryKeys.therapist(therapistId);
    const rollbackData = queryClient.getQueryData(ptKey);
    queryClient.cancelQueries(ptKey);

    try {
      queryClient.setQueryData(ptKey, old =>
        Object.assign(old, {
          has_customer: true,
        })
      );
      await mutateAsync(fields);

      if (!getMountState()) {
        return;
      }

      await delay(FORM_NOTIFICATION_DELAY);
      getMountState() && onClose();
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      queryClient.invalidateQueries(ptKey);
      queryClient.invalidateQueries(queryKeys.therapistSearch());
      setErrors(t("form.add_stripe_customer.could_not_create"), err);
    }
  });

  if (!isError && data) {
    return (
      <DialogSuccessNotice
        heading={t("form.add_stripe_customer.title")}
        body={t("form.add_stripe_customer.successfully_created")}
        onClose={onClose}
      />
    );
  }

  return (
    <Container>
      <Text heading bold id="add-stripe-customer">
        {t("form.add_stripe_customer.title")}
      </Text>
      <Text paragraph mb={4}>
        {fullName(therapist)}, <Text link>{therapist?.id}</Text>
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <CTextField
            name="stripe_account"
            label={t("form.add_stripe_customer.stripe_account")}
            margin="normal"
            size="small"
            required
            fullWidth
            defaultValue={"France"}
            disabled
            noClear
          />
          {stripe_account === "France" && (
            <CTextField
              name="siren"
              label={t("form.add_stripe_customer.siren")}
              margin="normal"
              size="small"
              required
              fullWidth
            />
          )}
          <HFlex justifyContent="center" my={3}>
            <ProgressButton loading={isLoading} endIcon={<Edit width={20} />}>
              {t("form.add_stripe_customer.button")}
            </ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default AddStripeCustomerForm;
