import { Grid, Container, Box, Fab } from "@material-ui/core";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "shared";
import useTherapist from "./queries/useTherapist";
import { Actions, Account } from "./components";
import { Area } from "types";
import { join } from "utils/misc";
import { Search } from "@material-ui/icons";

const Therapist: React.VFC = () => {
  const { state } = useLocation<string | undefined>();
  const { isSuccess } = useTherapist();

  const mainEm = (
    <Grid container spacing={2}>
      <Grid item zeroMinWidth md={7}>
        <Account />
      </Grid>
      <Grid item zeroMinWidth md={5}>
        <Actions />
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Box ml={3} my={3}>
        <Link to={join(Area.Therapists, state)}>
          <Fab color="secondary" size="medium" aria-label="Back to search">
            <Icon of={Search} />
          </Fab>
        </Link>
      </Box>
      <Container fixed>{isSuccess ? mainEm : <Account />}</Container>
    </div>
  );
};

export default Therapist;
