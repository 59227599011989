import { Dialog, DialogActions, DialogProps, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { Icon } from "shared";

interface CDialogProps extends DialogProps {
  onClose: () => void;
  children: React.ReactNode;
}

const CDialog: React.VFC<CDialogProps> = ({ children, ...rest }) => (
  <Dialog disableScrollLock {...rest}>
    <DialogActions>
      <IconButton onClick={rest.onClose} aria-label="Close dialog">
        <Icon secondary of={Close} />
      </IconButton>
    </DialogActions>
    {children}
  </Dialog>
);

export default CDialog;
