import React from "react";
import { Props as PaneProps } from "./Pane";
import { Box, CircularProgress } from "@material-ui/core";

const Loading: React.VFC<PaneProps> = props => (
  <Box textAlign="center" lineHeight={0} {...props}>
    <CircularProgress size={48} />
  </Box>
);

export default Loading;
