import React from "react";
import { Avatar, Box } from "@material-ui/core";
import { HFlex, Text } from "shared";
import useTherapist from "../queries/useTherapist";

const Summary: React.VFC = () => {
  const { data: pt } = useTherapist();
  if (!pt) return null;

  return (
    <HFlex gap={2} alignItems="center" mb={2}>
      <Avatar src="" />
      <Box overflow="auto">
        <Text heading bold nowrap mb={0}>
          {pt.first_name} {pt.last_name}
        </Text>
        <Text paragraph nowrap mb={0}>
          {pt.hcp}
        </Text>
      </Box>
    </HFlex>
  );
};

export default Summary;
