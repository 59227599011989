import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { join } from "utils/misc";
import { Area } from "types";
import api from "api";

const OP = "POST /care_event_configurations";

type Week = {
  label?: string;
  type?: string;
  protocol?: boolean;
  appointment?: boolean;
};

export type Fields = {
  name: string;
  country: string;
  payer_id: number;
  weekly_config: Record<string, Week | boolean>;
};

const useCreateCareEventConfiguration = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation((data: Fields) => api.parse(OP, { data }), {
    onSuccess: careEventConfiguration => {
      queryClient.invalidateQueries(["care_event_configurations"]);
      history.push(join(Area.CareEventConfigurations, "edit", careEventConfiguration.id));
    },
  });
};

export const convertTypes = (fields: Fields) => {
  const newFields = { ...fields };
  Object.keys(newFields.weekly_config).forEach((name: string) => {
    if (typeof newFields.weekly_config[name] === "object") {
      const obj = newFields.weekly_config[name] as Week;
      const type = obj.type;
      switch (type) {
        case "protocol":
          obj["protocol"] = true;
          break;
        case "appointment":
          obj["appointment"] = true;
          break;
        default:
          break;
      }
      delete obj.type;
      newFields.weekly_config[name] = obj;
    }
  });
  return newFields;
};

export default useCreateCareEventConfiguration;
