import { ButtonBase } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { useState } from "react";
import { CDialog, Icon } from "shared";
import ClaimForm from "./ClaimForm";

interface Props {
  patientId: number;
  defaultValue: any;
}

const ResubmitClaim: React.VFC<Props> = ({ patientId, defaultValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  return (
    <>
      <ButtonBase onClick={() => setIsOpen(true)}>
        <Icon link of={ArrowForward} />
      </ButtonBase>
      <CDialog maxWidth={false} open={isOpen} onClose={close} aria-labelledby="resubmit-claim">
        <ClaimForm patientId={patientId} defaultValue={defaultValue} onClose={() => setIsOpen(false)} />
      </CDialog>
    </>
  );
};

export default ResubmitClaim;
