import React, { useState } from "react";
import { Container, Checkbox, FormControlLabel, LinearProgress, Button } from "@material-ui/core";
import { Text, useErrorContext, Anchor, Icon, Loading } from "shared";
import { useTranslation } from "react-i18next";
import useCreatePOC from "../../queries/useCreatePOC";
import { useCareEvents } from "../../queries/useCareEvents";
import { fullName } from "utils/misc";
import usePatient from "routes/patient/queries/usePatient";
import { css } from "@emotion/css";
import { useMountState } from "utils/hooks";
import { Download } from "assets";
import { getDate } from "utils/time";
import { SPACING } from "theme";
import { definitions } from "api";

type CareEvent = definitions["care_event"];

interface Props {
  onClose: () => void;
}

const acceptedCareEvents = [
  "protocol_weekly_w0",
  "protocol_quarterly",
  "appointment",
  "protocol_weekly_w6",
  "treatment_and_progress",
  "re_evaluation",
];

interface Doc {
  careEvent: CareEvent;
  url: string;
}

export const useSortCareEventsByNameAndDate = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const translate = (label: string) => t(`dashboard.tables.notes.types.${label.toUpperCase()}`);

  return (a: CareEvent, b: CareEvent) => {
    const str1 = translate(a.label);
    const str2 = translate(b.label);
    if (str1 === str2) return new Date(a.start_time) < new Date(b.start_time) ? 1 : -1;
    return str1 > str2 ? 1 : -1;
  };
};

const CreatePlanOfCareForm: React.VFC<Props> = () => {
  const { t } = useTranslation();
  const [docs, setDocs] = useState<Doc[]>([]);
  const [signedOnly, setSignedOnly] = useState(true);
  const { data: patient } = usePatient();
  const createPOC = useCreatePOC();
  const careEvents = useCareEvents();
  const setError = useErrorContext();
  const getMountState = useMountState();
  const byNameAndDate = useSortCareEventsByNameAndDate();

  if (!patient) return null;

  // @ts-ignore
  const translate = (label: string) => t(`dashboard.tables.notes.types.${label.toUpperCase()}`);

  const availableCareEvents = careEvents.data?.filter(ce => acceptedCareEvents.includes(ce.label));
  const filteredCareEvents = availableCareEvents?.filter(ce => ce.signed_at || !signedOnly).sort(byNameAndDate);

  const upload = async (index: number) => {
    try {
      const careEvent = filteredCareEvents![index];
      const { url } = await createPOC.mutateAsync(careEvent.id);
      getMountState() && setDocs([...docs, { careEvent, url }]);
    } catch (err) {
      setError(err);
    }
  };

  const careEventGroup = (
    <div>
      <div>
        <FormControlLabel
          className={styles.check}
          control={<Checkbox checked={signedOnly} color="primary" onChange={() => setSignedOnly(!signedOnly)} />}
          label={<Text small>{t`form.signed_care_events_only`}</Text>}
        />
      </div>

      {filteredCareEvents?.map((ce, i) => (
        <Button key={ce.id} onClick={() => upload(i)}>
          <Text primary initial small>
            {translate(ce.label)} <Text light>({getDate(ce.start_time)})</Text>
          </Text>
        </Button>
      ))}
    </div>
  );

  const noSignedCareEvents = <Text paragraph>{t`form.no_signed_care_events`}</Text>;
  const noCareEvents = <Text paragraph>{t`form.no_care_events`}</Text>;
  const planOfCare = <Text paragraph bold disabled>{t`patients.medical_records.plan_of_care`}</Text>;

  const mainContent = (
    <div className={styles.grid}>
      {!!filteredCareEvents?.length ? careEventGroup : noSignedCareEvents}
      <div>
        {(createPOC.isLoading || !!docs.length) && planOfCare}
        {docs?.map(({ careEvent, url }) => (
          <p key={url}>
            <Anchor small link href={url} target="_blank" rel="noreferrer">
              {translate(careEvent.label)} <Text light>({getDate(careEvent.start_time)})</Text> <Icon of={Download} />
            </Anchor>
          </p>
        ))}
        {createPOC.isLoading && <LinearProgress />}
      </div>
    </div>
  );

  return (
    <Container>
      <Text heading bold id="create-poc">
        {t`form.create_plan_of_care`}
      </Text>
      <Text paragraph mb={3}>
        {fullName(patient)}, <Text link>{patient.id}</Text>
      </Text>
      <div className={styles.container}>
        {careEvents.isLoading && <Loading />}
        {availableCareEvents?.length === 0 && noCareEvents}
        {!!availableCareEvents?.length && mainContent}
      </div>
    </Container>
  );
};

export default CreatePlanOfCareForm;

const styles = {
  container: css`
    height: 600px;
  `,
  info: css`
    white-space: pre-wrap;
  `,
  grid: css`
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
  `,
  check: css`
    margin-bottom: ${SPACING}px;
  `,
};
