import React from "react";
import { Container } from "@material-ui/core";
import { CTextField, HFlex, DialogSuccessNotice, ProgressButton, Text, useErrorContext, CAutocomplete } from "shared";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Send } from "assets";
import { delay } from "utils/misc";
import useInvitePatient, { Vars } from "../../queries/useInvitePatient";
import { FORM_NOTIFICATION_DELAY } from "theme";
import { Country, isUsMarket } from "utils/market";
import usePayers from "routes/patientSearch/queries/usePayers";
import { useMountState } from "utils/hooks";

interface Props {
  onClose: () => void;
}

const InvitePatientForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<Vars>();
  const { t } = useTranslation();
  const { mutateAsync, data, isLoading, isError } = useInvitePatient();
  const country = isUsMarket ? Country.US : Country.SE;
  const { data: payers, isLoading: isLoadingPayers } = usePayers(country);
  const setError = useErrorContext();
  const getMountState = useMountState();

  const submit = form.handleSubmit(async fields => {
    try {
      await mutateAsync(fields);
      if (!getMountState()) return;
      await delay(FORM_NOTIFICATION_DELAY);
      getMountState() && onClose();
    } catch (err) {
      setError(t`errors.could_not_send_invite`, err);
    }
  });

  if (!isError && data)
    return (
      <DialogSuccessNotice
        heading={t("patients.invite_sent")}
        body={`${t("form.invitation_successfully_sent_to")} ${data.email}`}
        onClose={onClose}
      />
    );

  return (
    <Container>
      <Text heading bold mb={3} id="invite-patient">
        {t("patients.invite_a_patient")}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <CTextField
            required
            name="email"
            autoFocus
            label={t("form.email")}
            type="email"
            margin="normal"
            size="small"
            fullWidth
          />
          <CTextField
            asNumber
            required
            name="therapist_id"
            type="number"
            label={t("patients.roles.therapist") + " ID"}
            margin="normal"
            size="small"
            fullWidth
          />
          <CAutocomplete
            getOptionValue={({ name }) => name}
            getOptionLabel={({ name }) => name ?? "NAME MISSING"}
            loading={isLoadingPayers}
            options={payers ?? []}
            name="payer_name"
            label={t("form.payer")}
            margin="normal"
            size="small"
            fullWidth
          />
          <HFlex justifyContent="center" my={6}>
            <ProgressButton loading={isLoading} endIcon={<Send width={20} />}>
              {t("buttons.send_invitation")}
            </ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default InvitePatientForm;
