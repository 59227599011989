import React from "react";
import { Box, Button, InputAdornment, TextField } from "@material-ui/core";
import { BeachAccessRounded, Event } from "@material-ui/icons";
import { HFlex, VFlex, Text, ProgressButton, useErrorContext } from "shared";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import useStartOutOfOffice from "../../queries/useStartOutOfOffice";
import { queryKeys } from "utils/misc";
import { useId } from "utils/hooks";

interface Props {
  onClose: () => void;
}
interface FormState {
  readonly returnDate?: string;
}

const StartOutOfOfficeForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { handleSubmit, register } = useForm<FormState>();
  const id = useId();
  const { mutateAsync, isLoading } = useStartOutOfOffice();
  const queryClient = useQueryClient();
  const setErrors = useErrorContext();

  const submit = handleSubmit(async ({ returnDate: end_date }) => {
    try {
      await mutateAsync({ end_date });
      queryClient.invalidateQueries(queryKeys.therapist(id));
      onClose();
    } catch (err) {
      setErrors(t`errors.could_not_register_time_off`, err);
    }
  });

  return (
    <>
      <HFlex justifyContent="center" mb={2}>
        <BeachAccessRounded style={{ color: "#60c286", width: 80, height: 80 }} />
      </HFlex>
      <Text heading bold center>
        {t("therapists.out_of_office.start.title")}
      </Text>
      <Box mx={2}>
        <form onSubmit={submit}>
          <VFlex alignItems="center" gap={5} my={3}>
            <TextField
              id="returnDate"
              label="Returns at"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Event />
                  </InputAdornment>
                ),
              }}
              name="returnDate"
              inputRef={register}
              required
            />
            <VFlex alignItems="center" gap={2}>
              <ProgressButton loading={isLoading} type="submit" variant="contained" color="primary">
                {t("therapists.out_of_office.start.submit")}
              </ProgressButton>
              <Button disabled={isLoading} onClick={onClose}>
                {t("buttons.cancel")}
              </Button>
            </VFlex>
          </VFlex>
        </form>
      </Box>
    </>
  );
};

export default StartOutOfOfficeForm;
