// TODO: stop ignoring ts errors once we want to start using sv language (and we have added
// the missing translations)
// @ts-nocheck

import { en } from "./en";

export const sv: typeof en = {
  buttons: {
    add: "Lägg till",
    add_new_goal: "Lägg till nytt",
    cancel: "Avbryt",
    change: "Ändra",
    change_language: "Byt språk till engelska",
    confirm: "Bekräfta",
    confirm_sign: "Ja, signera",
    create_password: "Skapa nytt lösenord",
    delete: "Ta bort",
    delete_goal: "Radera mål",
    edit: "Ändra",
    edit_goal: "Ändra mål",
    invite: "Bjud in",
    no: "Nej",
    ok: "OK",
    save: "Spara",
    send_invitation: "Skicka inbjudan",
    sign_in_with_google: "Logga in med Google",
    sign_in: "Logga in",
    yes: "Ja",
    yes_sign: "Ja, signera",
  },
  change_therapist: {
    remove_substitute: "Ta bort vik.",
  },
  create_plan_of_care: {
    box_button: "Skapa behandlingsplan",
  },
  dashboard: {
    tables: {
      notes: {
        types: {
          PROTOCOL_DISCHARGE: "Utskrivningsanteckning",
          PROTOCOL_MONTHLY: "Månadsanteckning",
          PROTOCOL_QUARTERLY: "Kvartalsuppföljning",
          PROTOCOL_WEEKLY: "Anteckning för vecka { week_number }",
          PROTOCOL_WEEKLY_W0: "Uppstartssamtal",
          PROTOCOL_WEEKLY_W1: "Anteckning",
          PROTOCOL_WEEKLY_W10: "Anteckning för vecka 9",
          PROTOCOL_WEEKLY_W11: "Anteckning för vecka 10",
          PROTOCOL_WEEKLY_W11_AND_W12: "Anteckning för vecka 10-11",
          PROTOCOL_WEEKLY_W12: "Anteckning för vecka 11",
          PROTOCOL_WEEKLY_W13: "Anteckning för vecka 12",
          PROTOCOL_WEEKLY_W14: "Anteckning för vecka 13",
          PROTOCOL_WEEKLY_W15: "Anteckning för vecka 14",
          PROTOCOL_WEEKLY_W16: "Anteckning för vecka 15",
          PROTOCOL_WEEKLY_W17: "Anteckning för vecka 16",
          PROTOCOL_WEEKLY_W18: "Anteckning för vecka 17",
          PROTOCOL_WEEKLY_W19: "Anteckning för vecka 18",
          PROTOCOL_WEEKLY_W2: "Anteckning för vecka 1",
          PROTOCOL_WEEKLY_W20: "Anteckning för vecka 19",
          PROTOCOL_WEEKLY_W3: "Anteckning för vecka 2",
          PROTOCOL_WEEKLY_W4: "Anteckning för vecka 3",
          PROTOCOL_WEEKLY_W5: "Anteckning för vecka 4",
          PROTOCOL_WEEKLY_W6: "Uppföljningssamtal",
          PROTOCOL_WEEKLY_W7: "Anteckning för vecka 6",
          PROTOCOL_WEEKLY_W7_AND_W8: "Anteckning för vecka 6-7",
          PROTOCOL_WEEKLY_W8: "Anteckning för vecka 7",
          PROTOCOL_WEEKLY_W9: "Anteckning för vecka 8",
          PROTOCOL_WEEKLY_W9_AND_W10: "Anteckning för vecka 8-9",
        },
      },
    },
  },
  dropdown: {
    logout: "Logga ut",
  },
  errors: {
    generic: "Något gick fel. Försök igen.",
    generic_login: "Något gick fel. Försök igen senare.",
  },
  form: {
    address: "Adress",
    assign_temporary_therapist: "Vikarierande fysioterapeut",
    available_for_patients: "Tillgänglig",
    birth: "Födelsedatum",
    by_category: "Efter kategori",
    country: "Land",
    email: "E-post",
    find_patients_in: "Hitta patienter i",
    first_name: "Förnamn",
    last_name: "Efternamn",
    name: "Namn",
    patient_first_name: "Förnamn",
    phone: "Telefon",
    phone_number: "Telefonnummer",
    selfcare: "Egenvård",
    health_care_provider_invitation_payer: "Payer för patientinbjudningar",
  },
  messages: {
    substitute_therapist_change: {
      end: "Den här patienten har åter tilldelats dig från {therapistName}",
      start: "Den här patienten har tilldelats dig temporärt från {therapistName}",
      start_with_end_date:
        "Den här patienten har tilldelats dig temporärt från {therapistName} till och med {assignmentEndDate}",
    },
  },
  partner_patients: {
    name: "Namn",
  },
  partner_referral_table: {
    referral_issued: "Remiss utfärdad",
  },
  patient: {
    premium_type: "Prenumeration",
    premium_types: {
      none: "Ingen prenumeration",
      selfcare: "Egenvård",
      guided_care: "Handledd vård",
    },
  },
  patients: {
    end_treatment_form: {
      reasons: {
        other: "Övrigt",
        acquired_tools: "Har fått verktygen att fortsätta på egen hand",
        ceased_activity: "Har upphört att vara aktiv i behandlingen",
        cost: "Kostnad för hög",
        not_interested: "Inte intresserad",
        not_selfcare: "Patient blivit erbjuden egenvårdsprogrammet men ej intresserad",
        not_suitable: "Behandlingen bedöms ej lämplig",
        referred_elsewhere: "Hänvisas till fysisk vård",
        surgery: "Har eller ska opereras",
        symptom_free: "Patient är besvärsfri",
        unreachable: "Får ej tag på patient vid start",
        wrong_treatment: "Patient valde fel led från början - behöver registrera om sig",
      },
    },
    header_info: {
      most_painful_joint_short: "Led",
    },
    health_reports: {
      none: "Ingen",
      questions_content: {
        none: "Ingen",
        rmdq_none: "Inget av ovanstående stämmer för idag",
      },
    },
    id: "ID",
    invite_a_patient: "Bjud in en patient",
    invite_sent: "Inbjudan har skickats!",
    medical_records: {
      not_applicable: "Uppgift saknas",
      personal_number: "Personnummerr",
      referral: "Remiss från",
      referral_for: "Remiss för ",
    },
    menu: {
      protocol: "Protokoll",
    },
    notes: {
      discharge_visit_dropdown: {
        options: {
          none: "Ingen kvalificerad vård (ingen debitering)",
        },
      },
      fields: {
        discharge_communication_options: {
          none: "Ingen kvalificerad vård (ingen debitering)",
        },
      },
    },
    protocol: {
      awaiting_completion: "väntar på färdigställande",
      change_levels: "Ändra nivåer",
      completed: "Avklarad",
      current: "Nuvarande",
      delay: "{days} dagars försening",
      levels_description:
        "Ändringar kommer appliceras på patientens nuvarande övningar.\nAtt sätta nivån till 0 innebär att övningen tas bort för patienten.",
      no_activities: "Inga planerade aktiviteter",
      overdue: "Den här veckan är pausad fram till dess att patienten har slutfört de obligatoriska aktiviteterna.",
      removed_exercise: "Övningen tas bort",
      required: "* Denna aktivitet måste slutföras för att ge tillgång till nästa vecka.",
    },
    roles: {
      therapist: "Fysioterapeut",
    },
    state: "State",
    statuses: {
      active: "Aktiv",
      deleted: "Avslutat konto",
      end_treatment: "Avslutas",
      inactive: "Inaktiv",
      referral_needed: "Remiss behövs",
    },
    substitute_therapist: "Tillfälligt",
    tool_tip: {
      substitute_therapist: "Vikarierande fysioterapeut",
    },
    treatment: "Behandling",
    week: "Vecka",
    weeks: "Veckor",
  },
  remove_sub_form: {
    remove_substitute: "Ta bort vikarierande fysioterapeut",
  },
  reports: {
    close_note: "Stäng anteckning",
    note: "Anteckning",
    note_does_not_exist: "Det finns ingen anteckning",
    open_note: "Öppna anteckning",
  },
  settings: {
    profile_information: {
      time_zone: "Tidszon",
      weekly_assignment_limit: "Max antal nya patienter i veckan:",
    },
  },
  substitute_therapist: "Vikarierande fysioterapeut",
  text_no: "Nej",
  text_yes: "Ja",
  views: {
    other: "Annat",
    patient: "Patient",
    patients: "Patienter",
    reports: "Rapporter",
    therapist: "Terapeut",
  },
};
