import React from "react";

export interface PaginationParams {
  offset: number;
  limit: number;
}

export interface Paginated<T> {
  items: T[];
  total: number;
}

export enum Area {
  Patients = "/patients",
  Therapists = "/therapists",
  Login = "/login",
  Payers = "/payers",
  LessonEditor = "/lessonEditor",
  Auth = "/auth",
  Reports = "/reports",
  Other = "/other",
  RCM = "/RCM",
  Invoices = "/RCM/invoices",
  CDM = "/RCM/CDM",
  GenerateClaim = "/RCM/generateclaim",
  Logout = "/logout",
  Registry = "/other/registry",
  CareEventConfigurations = "/other/careEventConfigurations",
  UnclaimedMedicalReferrals = "/other/unclaimed_medical_referrals",
}

export interface HasId {
  id: number;
}

export interface WithChildren {
  children?: React.ReactNode;
}

export interface Meta {
  created_at: string;
  updated_at: string;
}

export type ArrayEm<T extends any[]> = T extends (infer R)[] ? R : never;
