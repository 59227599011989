import { default as React, ChangeEvent, useState, useEffect } from "react";
import { capitalizeFirstLetter } from "utils/misc";
import {
  makeStyles,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableSortLabel,
} from "@material-ui/core";
import ErrorComponent from "./components/ReportErrorComponent";
import LoadingComponent from "./components/ReportLoadingComponet";
import { ReportResponse, ColumnEntry, RowEntry, useReports } from "./queries/report";
import styled from "styled-components";
import { Text } from "shared";
import PatientLink from "./components/PatientLink";
import TherapistLink from "./components/TherapistLink";
import NoteModal from "./components/NoteModal";
import api from "api";

export enum OrderBy {
  ASC = "asc",
  DESC = "desc",
}

const MainContainer = styled.div`
  flex-grow: 1;
  padding: 4px;
`;

const MainContainerScroll = styled(MainContainer)`
  overflow-x: scroll;
`;

const useStyles = makeStyles(() => ({
  mainContainer: {
    background: "#F7F7F7",
  },
}));

interface ReportsProps {
  activeReport: string;
}
export const Reports: React.VFC<ReportsProps> = ({ activeReport }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [orderDirection, setOrderDirection] = useState<OrderBy>(OrderBy.ASC);
  const classes = useStyles();

  const params = {
    report: activeReport,
    rows_per_page: rowsPerPage,
    current_page: currentPage,
    order_by: orderBy ? orderBy : undefined,
    order_direction: orderDirection,
  };

  const export2CSV = async () => {
    const response = await api("GET /reports.csv", { query: params });
    const blob = await response.blob();
    window.location.href = URL.createObjectURL(blob);
  };

  useEffect(() => {
    setOrderBy(null);
    setCurrentPage(0);
    setOrderDirection(OrderBy.ASC);
  }, [activeReport]);

  const { data, error, isLoading } = useReports(params);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const updateOrderBy = (column: string) => {
    if (column !== orderBy) {
      setOrderBy(column);
      setOrderDirection(OrderBy.ASC);
    } else {
      setOrderDirection(orderDirection === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC);
    }
  };

  if (error)
    return (
      <MainContainer className={classes.mainContainer}>
        <ErrorComponent error={error} />
      </MainContainer>
    );
  if (isLoading)
    return (
      <MainContainer>
        <LoadingComponent />
      </MainContainer>
    );
  if (data === undefined) return null;

  const { rows, columns } = data;
  return (
    <MainContainerScroll className={classes.mainContainer}>
      <Text p={1} bold heading>
        {capitalizeFirstLetter(activeReport).split("_").join(" ")}
        <Text role="button" caption secondary ml={4} onClick={export2CSV}>
          Export to CSV
        </Text>
      </Text>

      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <ReportTableHeaderCell
                {...column}
                key={column.name}
                updateOrderBy={updateOrderBy}
                orderBy={data.order_by}
                orderDirection={data.order_direction}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <ReportTableRow row={row} columns={columns} key={index} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={data.total_count}
        page={data.current_page}
        onPageChange={handleChangePage}
        rowsPerPage={data.rows_per_page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </MainContainerScroll>
  );
};
interface TableHeaderCellProps {
  type: string;
  name: string;
  orderBy?: string | null;
  orderDirection: OrderBy;
  updateOrderBy: (orderBy: string) => void;
}

const ReportTableHeaderCell: React.VFC<TableHeaderCellProps> = ({
  type,
  name,
  orderBy,
  orderDirection,
  updateOrderBy,
}) => {
  const nameEqualsOrderBy = name === orderBy;
  return (
    <TableCell align={columnAlignment(type)} sortDirection={nameEqualsOrderBy ? orderDirection : false}>
      <TableSortLabel
        active={name === orderBy}
        direction={nameEqualsOrderBy ? orderDirection : OrderBy.ASC}
        onClick={() => updateOrderBy(name)}
      >
        {capitalizeFirstLetter(name.split("_").join(" "))}
      </TableSortLabel>
    </TableCell>
  );
};

interface ReportTableRowProps extends Pick<ReportResponse, "columns"> {
  readonly row: RowEntry;
}
const ReportTableRow: React.VFC<ReportTableRowProps> = ({ columns, row }) => (
  <TableRow>
    {columns.map(column => (
      <ReportTableCell {...column} key={column.name} row={row} />
    ))}
  </TableRow>
);

interface ReportTableCellProps extends ColumnEntry, Pick<ReportTableRowProps, "row"> {}
const ReportTableCell: React.VFC<ReportTableCellProps> = ({ name, type, row }) => (
  <TableCell align={columnAlignment(type)}>{renderColumn({ type, value: row[name], nameOfRow: name })}</TableCell>
);

interface RenderColumn {
  readonly type: string;
  readonly value: string | number | null;
  readonly nameOfRow: string;
}
const renderColumn = ({ type, value, nameOfRow }: RenderColumn) => {
  if (type === "PatientID") {
    return <PatientLink patientId={value} />;
  }
  if (nameOfRow === "note") {
    return <NoteModal noteContent={String(value)} />;
  }
  if (type === "TherapistID") {
    return <TherapistLink therapistId={value} />;
  }
  return value?.toString();
};

const columnAlignment = (type: String) => (type === "number" ? "right" : "left");

export default Reports;
