// import { Box, Button } from "@material-ui/core";
// import { EditOutlined } from "@material-ui/icons";
import React from "react";
import { Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import { SPACING, Breakpoint } from "theme";
import { stripDeletedTagFromEmail } from "utils/misc";
import { css } from "@emotion/css";
import useTherapist from "../queries/useTherapist";

interface Props {
  setEdit: (editing: boolean) => void;
}

const PersonalInfo: React.VFC<Props> = () => {
  const { t } = useTranslation();
  const { data: pt } = useTherapist();
  if (!pt) return null;

  // const onEdit = () => setEdit(true);

  const details = [
    [t`form.first_name`, pt.first_name],
    [t`form.last_name`, pt.last_name],
    ["State/ZIP", pt.state_zip],
    [t`form.address`, pt.address],
    [t`form.country`, pt.country],
    [t`form.birth`, pt.birthday],
    [t`form.phone`, pt.phone_number],
    [t`form.email`, pt.email && stripDeletedTagFromEmail(pt.email)],
    [t`settings.profile_information.time_zone`, pt.time_zone],
    [t`form.stripe_customer`, pt.has_customer ? t`text_yes` : t`text_no`],
  ];

  return (
    <Pane dark className={styles.grid}>
      {details.map(([label, value]) => (
        <React.Fragment key={label}>
          <Text light nowrap>
            {label}
          </Text>
          <Text bold nowrap>
            {value}
          </Text>
        </React.Fragment>
      ))}
      {/* TODO: Hidden due to not implemented */}
      {/*<Box mt={2} textAlign="right">*/}
      {/* TODO add translation */}
      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  color="primary"*/}
      {/*  size="medium"*/}
      {/*  onClick={onEdit}*/}
      {/*  endIcon={<EditOutlined fontSize="small" />}*/}
      {/*>*/}
      {/*  Edit*/}
      {/*</Button>*/}
      {/*</Box>*/}
    </Pane>
  );
};

export default PersonalInfo;

const styles = {
  grid: css`
    overflow: auto;
    display: grid;
    grid-auto-rows: 45px;
    align-items: center;
    grid-column-gap: ${SPACING}px;
    @media screen and (max-width: ${Breakpoint.sm - 1}px) {
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${Breakpoint.sm}px) {
      grid-template-columns: auto 1fr;
    }
  `,
};
