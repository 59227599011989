import React from "react";
import { TableBody, TableCell, TableRow, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { join } from "utils/misc";
import { Area } from "types";
import NoteModal from "routes/reports/components/NoteModal";
import { Colors } from "theme";
import { Result } from "api";
import { useTranslation } from "react-i18next";
import ResubmitClaim from "./ResubmitClaim/ResubmitClaim";

const UNKNOWN = "Unknown";

const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: Colors.DARK_BLUE_FADED,
  },
  row: {
    borderTop: `1px solid ${Colors.DARK_BLACK_FADED}`,
  },
  cell: {
    fontSize: 16,
  },
  bold: {
    fontWeight: 500,
    color: Colors.JA_BLUE,
  },
}));

type RCMDataObject = Result<"GET /us_insurance_claims">;

interface RCMTableBodyProps {
  data: RCMDataObject | undefined;
}

const RCMTableBody: React.VFC<RCMTableBodyProps> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!data?.rows) {
    return null;
  }

  return (
    <TableBody>
      {data.rows.map((claimObject, i) => {
        const { care_event, patient, reference_id = UNKNOWN, request_body, payer_state = UNKNOWN, id = UNKNOWN } =
          claimObject ?? {};

        const { billing_provider } = request_body ?? {};
        const { address } = (billing_provider as any) ?? {};
        const { state = UNKNOWN } = address ?? {};

        const {
          patient_id,
          start_time = "",
          signed_at = "",
          medical_record_note,
          label = UNKNOWN,
          confirmed_video_call,
        } = care_event ?? {};

        const { text = UNKNOWN, icd_codes } = medical_record_note ?? {};
        const [icdCode = UNKNOWN] = icd_codes ?? [];

        const { us_insurance_profiles } = patient ?? {};

        const [firstUsInsuranceProfile] = us_insurance_profiles ?? [];

        const { us_insurance_company, member_id = UNKNOWN } = firstUsInsuranceProfile ?? {};
        const { payer } = us_insurance_company ?? {};
        const { name = UNKNOWN } = payer ?? {};
        const [startDate = UNKNOWN] = start_time?.split("T") ?? [];
        const [sentDate = UNKNOWN] = signed_at?.split("T") ?? [];
        const confirmedVideoCall = confirmed_video_call ? t`buttons.yes` : t`buttons.no`;

        return (
          <TableRow className={i % 2 === 0 ? classes.row : classes.evenRow} key={`RCM-table-row-${i}`}>
            <TableCell className={classes.cell + " " + classes.bold}>
              <Link to={join(Area.Patients, patient_id ?? "")}>{patient_id}</Link>
            </TableCell>
            {[
              name,
              member_id,
              reference_id,
              id,
              startDate,
              sentDate,
              state,
              payer_state,
              label,
              confirmedVideoCall,
              icdCode,
            ].map((field, i) => (
              <TableCell key={`table-body-cell-${i}`} className={classes.cell}>
                {field}
              </TableCell>
            ))}
            <TableCell>
              <NoteModal noteContent={text} />
            </TableCell>
            <TableCell align="center">
              {patient_id && <ResubmitClaim patientId={patient_id} defaultValue={care_event} />}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default RCMTableBody;
