import { default as React, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  makeStyles,
  ListItem,
  List,
  Divider,
} from "@material-ui/core";
import { Text } from "shared";
import { DrawerContents } from "./components/DrawerContent";
import styled from "styled-components";
import muiTheme from "theme";
import { ExpandMore } from "@material-ui/icons";

const drawerWidth = 360;
const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#ECECEC",
    top: "auto",
  },
  hideBorder: {
    "&.MuiAccordion-root:before": {
      display: "none",
    },
    padding: 4,
  },
  accordionSummaryContent: {
    alignItems: "center",
  },
  accordionDetailRoot: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface ReportsDrawerProps {
  readonly setActiveValue: (value: string) => void;
  readonly activeValue: string;
  readonly drawerContent: DrawerContents[];
}
const ReportsDrawer: React.VFC<ReportsDrawerProps> = ({ setActiveValue, activeValue, drawerContent }) => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
      open
    >
      {drawerContent.map(content => (
        <ReportsAccordion
          key={content.category}
          setActiveValue={setActiveValue}
          activeValue={activeValue}
          {...content}
        />
      ))}
    </Drawer>
  );
};

interface ReportsAccordionProps extends DrawerContents {
  readonly activeValue: string;
  readonly setActiveValue: (value: string) => void;
}
const ReportsAccordion: React.VFC<ReportsAccordionProps> = ({
  displayName,
  values,
  activeValue,
  icon,
  setActiveValue,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Accordion
        classes={{ root: classes.hideBorder }}
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <AccordionSummary
          classes={{ content: classes.accordionSummaryContent }}
          expandIcon={<ExpandMore />}
          aria-controls={`${displayName}-content`}
          id={`${displayName}-header`}
        >
          <IconContainer>{icon}</IconContainer>
          <TextContainer>
            <Text small>{displayName}</Text>
          </TextContainer>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailRoot }}>
          <StyledList>
            {values.map(({ report_name, display_name }) => (
              <ReportTypeItem
                setActiveValue={setActiveValue}
                displayName={display_name}
                active={report_name === activeValue}
                key={report_name}
                value={report_name}
              />
            ))}
          </StyledList>
        </AccordionDetails>
      </Accordion>
      <StyledDivider />
    </>
  );
};

const IconContainer = styled.div`
  color: #979797;
  flex: 0.2;
`;

const TextContainer = styled.div`
  flex: 0.8;
  margin: 12px 0;
`;

const StyledList = styled(List)`
  flex: 0.82;
`;

interface ReportTypeItemProps {
  readonly value: string;
  readonly active: boolean;
  readonly displayName: string;
  readonly setActiveValue: (value: string) => void;
}

const ReportTypeItem: React.VFC<ReportTypeItemProps> = ({ value, active, setActiveValue, displayName }) => {
  return active ? (
    <ActiveStyledListItem onClick={() => setActiveValue(value)} button>
      <StyledText $active={true} small heavy>
        {displayName}
      </StyledText>
    </ActiveStyledListItem>
  ) : (
    <InActiveStyledListItem onClick={() => setActiveValue(value)} button>
      <StyledText small>{displayName}</StyledText>
    </InActiveStyledListItem>
  );
};

const StyledText = styled(Text)<{ $active?: boolean }>`
  margin: 4px;
  padding-left: ${props => (props.$active ? "8px" : "0px")};
`;

const InActiveStyledListItem = styled(ListItem)`
  padding-left: 0px;
`;
const ActiveStyledListItem = styled(ListItem)`
  padding-left: 0px;
  background-color: ${muiTheme.palette.background.paper};
  border-radius: 8px;
  box-shadow: 0px 2px 7px rgba(68, 104, 147, 0.2);
`;

const StyledDivider = styled(Divider)`
  width: 82%;
  align-self: flex-end;
`;

export default ReportsDrawer;
