import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import validateToken from "../queries/validateToken";
import { join, setAuth } from "utils/misc";
import { useErrorContext } from "shared";
import { Area } from "types";
import { Box, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  target: string;
}

const Auth: React.VFC<Props> = ({ target }) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const setError = useErrorContext();
  const { t } = useTranslation();

  useEffect(() => {
    const code = search?.match(/code=([^&]+)/)?.[1];
    const state = search?.match(/state=([^&]+)/)?.[1];

    if (code) {
      validateToken({
        code: code,
        redirect_uri: join(window.location.origin, pathname, "/"),
      })
        .then(({ id_token, refresh_token }) => {
          setAuth(id_token, refresh_token);
          const loc = state && atob(decodeURIComponent(state));
          history.push(loc || target);
        })
        .catch(err => {
          setError(t`errors.could_not_sign_in`, err);
          history.push(Area.Login);
        });
    }
  }, [search, pathname, history, setError, target, t]);

  return (
    <Box textAlign="center" mt={10}>
      <CircularProgress size={80} />
    </Box>
  );
};

export default Auth;
