import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { HFlex } from "shared";
import React from "react";
import { css, cx } from "@emotion/css";

interface Props extends ButtonProps {
  loading: boolean;
}

// Renders a button with progress indicator if loading=true

const ProgressButton: React.VFC<Props> = ({ children, loading, className, ...rest }) => {
  return (
    <Button
      className={cx(
        css`
          & .MuiButton-label {
            visibility: ${loading ? "hidden" : "initial"};
          }
          & .MuiCircularProgress-root {
            visibility: initial;
          }
        `,
        className
      )}
      type="submit"
      variant="contained"
      color="primary"
      disabled={loading}
      {...rest}
    >
      {children}
      {loading && (
        <HFlex
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          style={{ pointerEvents: "none" }}
        >
          <CircularProgress size={32} />
        </HFlex>
      )}
    </Button>
  );
};

export default ProgressButton;
