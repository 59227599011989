import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { styles } from "../styles";

interface Props {
  initialPainRating: string;
  latestPainRating: string;
  initialFunctionScore: string;
  latestFunctionScore: string;

  healthformScoreType: string;
  initialHealthformScore: string;
  healthformScore: string;
  initialEQ5D: string;
  latestEQ5D: string;
  adherence: string;
  bmi: string;
}

export const Table: React.VFC<Props> = ({
  initialPainRating,
  latestPainRating,
  initialFunctionScore,
  latestFunctionScore,
  healthformScoreType,
  initialHealthformScore,
  healthformScore,
  initialEQ5D,
  latestEQ5D,
  adherence,
  bmi,
}) => {
  const { t } = useTranslation();

  const getHealthformScoreTypeTranslation = (type: string) => {
    switch (type) {
      case "FIHOA":
        return t("patients.uk_discharge_letter.table.healthform_score.fihoa");
      case "HOOS":
        return t("patients.uk_discharge_letter.table.healthform_score.hoos");
      case "KOOS":
        return t("patients.uk_discharge_letter.table.healthform_score.koos");
      case "RMDQ":
        return t("patients.uk_discharge_letter.table.healthform_score.rmdq");
      case "NPQ":
        return t("patients.uk_discharge_letter.table.healthform_score.npq");
      case "SPADI":
        return t("patients.uk_discharge_letter.table.healthform_score.spadi");
      case "'{FIHOA / HOOS / KOOS / RMDQ / NPQ / SPADI}'":
        return t("patients.uk_discharge_letter.table.healthform_score.unknown");
      default:
        return "";
    }
  };

  const getOutcome = (initialValue: string, latestValue: string, type?: string) => {
    const initialInt = parseFloat(initialValue);
    const latestInt = parseFloat(latestValue);

    if (type === "RMDQ") {
      if (initialInt > latestInt) {
        return t("patients.uk_discharge_letter.table.outcome.improved");
      } else if (initialInt < latestInt) {
        return t("patients.uk_discharge_letter.table.outcome.regressed");
      } else {
        return t("patients.uk_discharge_letter.table.outcome.unchanged");
      }
    }

    if (initialInt < latestInt) {
      return t("patients.uk_discharge_letter.table.outcome.improved");
    } else if (initialInt > latestInt) {
      return t("patients.uk_discharge_letter.table.outcome.regressed");
    } else {
      return t("patients.uk_discharge_letter.table.outcome.unchanged");
    }
  };

  return (
    <View style={[styles.table, styles.section]} wrap={false}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={[styles.tableCell, styles.bold]}>{t("patients.uk_discharge_letter.table.header.measure")}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={[styles.tableCell, styles.bold]}>{t("patients.uk_discharge_letter.table.header.pre")}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={[styles.tableCell, styles.bold]}>{t("patients.uk_discharge_letter.table.header.post")}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={[styles.tableCell, styles.bold]}>{t("patients.uk_discharge_letter.table.header.outcome")}</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{t("patients.uk_discharge_letter.table.nrs")}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{initialPainRating}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{latestPainRating}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{getOutcome(latestPainRating, initialPainRating)}</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{t("patients.uk_discharge_letter.table.function")}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{initialFunctionScore}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{latestFunctionScore}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{getOutcome(initialFunctionScore, latestFunctionScore)}</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{getHealthformScoreTypeTranslation(healthformScoreType)}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{initialHealthformScore}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{healthformScore}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            {getOutcome(initialHealthformScore, healthformScore, healthformScoreType)}
          </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{t("patients.uk_discharge_letter.table.eq5d")}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{initialEQ5D}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{latestEQ5D}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{getOutcome(initialEQ5D, latestEQ5D)}</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{t("patients.uk_discharge_letter.table.adherence")}</Text>
        </View>
        <View style={[styles.tableCol, styles.tableCellFull]}>
          <Text style={styles.tableCell}>{adherence}%</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{t("patients.uk_discharge_letter.table.bmi")}</Text>
        </View>
        <View style={[styles.tableCol, styles.tableCellFull]}>
          <Text style={styles.tableCell}>{bmi}</Text>
        </View>
      </View>
    </View>
  );
};
