import Main from "./Main";
import { CssBaseline } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import theme from "theme";
import { BrowserRouter } from "react-router-dom";
import { ErrorContext, ErrorModal, useError } from "shared";
import { ReactQueryDevtools } from "react-query/devtools";

const muiTheme = createTheme(theme);
const cacheClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60000,
    },
  },
});

const App: React.VFC = () => {
  const { setError, ...error } = useError();

  return (
    <SCThemeProvider theme={muiTheme}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <QueryClientProvider client={cacheClient}>
          <ReactQueryDevtools />
          <Suspense fallback={<></>}>
            <BrowserRouter>
              <ErrorContext.Provider value={setError}>
                <Main />
                <ErrorModal {...error} />
              </ErrorContext.Provider>
            </BrowserRouter>
          </Suspense>
        </QueryClientProvider>
      </ThemeProvider>
    </SCThemeProvider>
  );
};

export default App;
