import React from "react";
import { Link } from "react-router-dom";
import { Text } from "shared";
import { Area } from "types";
import { join } from "utils/misc";
import usePatient from "../queries/usePatient";

interface Props {
  patientId?: number;
}

const PTSummary: React.VFC<Props> = ({ patientId }) => {
  const { data } = usePatient(patientId);
  const therapist = data?.therapist;
  if (!therapist) return null;
  const { id, first_name, last_name } = therapist;

  return (
    <Text paragraph nowrap mb={0}>
      <Text light>PT: </Text>
      {first_name} {last_name},{" "}
      <Link to={join(Area.Therapists, id)}>
        <Text link>{id}</Text>
      </Link>
    </Text>
  );
};

export default PTSummary;
