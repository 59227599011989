import React from "react";
import { ProtocolSelector } from "./ProtocolSelector";
import BoolSelector from "./BoolSelector";

export interface Week {
  label: string;
  protocol: boolean;
  appointment: boolean;
}

interface Props {
  name: string;
  value: Week | boolean;
  edit: boolean;
}

export const WeekForm: React.VFC<Props> = ({ name, value, edit }) => {
  if (typeof value === "boolean") {
    return <BoolSelector value={value} name={name} edit={edit} />;
  }

  return <ProtocolSelector week={value as Week} weekNumber={name} edit={edit} />;
};
