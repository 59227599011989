import React from "react";
import ExpandedMenu from "./ExpandedMenu";
import CollapsedMenu from "./CollapsedMenu";
import { useWindowWidth } from "utils/hooks";
import { Breakpoint } from "theme";
import { css } from "@emotion/css";

const NavBar: React.VFC = () => (
  <div
    className={css`
      height: 90px;

      @media screen and (max-width: ${Breakpoint.sm}px) {
        height: 50px;
      }
    `}
  >
    {useWindowWidth() < Breakpoint.mi ? <CollapsedMenu /> : <ExpandedMenu />}
  </div>
);

export default NavBar;
