import { Button } from "@material-ui/core";
import { Country } from "utils/market";
import usePatient from "../queries/usePatient";
import { CDialog, Text } from "shared";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import UploadReferralForm from "./UploadDocument/UploadReferralForm";

const Something: React.VFC = () => {
  const { data: patient } = usePatient();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);
  const isFrenchPatient = patient?.country === Country.FR;
  if (!isFrenchPatient) return null;

  return (
    <>
      <Text paragraph caption secondary>
        {t`referral.upload_referral`}
      </Text>
      <Button onClick={openDialog} variant="contained" color="primary">
        <Text mr={1}>{t`referral.upload_referral`}</Text>
      </Button>
      <CDialog maxWidth="sm" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="create-poc">
        <UploadReferralForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default Something;
