import api from "api";
import { useMutation, useQueryClient } from "react-query";
import { Row } from "../ValueTable";
import useRegistry from "../useRegistry";

const useAddRecord = <R extends Row>(overrideRegistry?: string) => {
  const registry = useRegistry(overrideRegistry);
  const queryClient = useQueryClient();
  const mutation = useMutation((data: R) => api.call(registry.api, { data }));

  const addRecord = async (record: R) => {
    const key = registry.name;
    const old = queryClient.getQueryData<unknown[]>(key)!;
    queryClient.cancelQueries(key);
    queryClient.setQueryData(key, [...old, record]);

    try {
      await mutation.mutateAsync(record);
      queryClient.invalidateQueries(key);
    } catch (err) {
      queryClient.setQueryData(key, old);
      throw err;
    }
  };

  return { ...mutation, addRecord };
};

export default useAddRecord;
