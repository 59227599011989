import React, { useState } from "react";
import { Box, Fab } from "@material-ui/core";
import { CDialog, Icon, Text } from "shared";
import { Send } from "assets";
import InviteTherapistForm from "./InviteTherapistForm";
import { useTranslation } from "react-i18next";

const InviteTherapist: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  return (
    <>
      <Box display="inline-flex" alignItems="center">
        <Text caption secondary mr={1}>
          {t("buttons.invite")}
        </Text>
        <Fab color="secondary" size="medium" onClick={openDialog} aria-label="Invite PT">
          <Icon of={Send} />
        </Fab>
      </Box>
      <CDialog maxWidth="sm" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="invite-pt">
        <InviteTherapistForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default InviteTherapist;
