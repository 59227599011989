import React from "react";
import { useTranslation } from "react-i18next";
import { Pane, Text } from "shared";
import { Breakpoint, SPACING } from "theme";
import { css } from "@emotion/css";
import useTherapist from "routes/therapist/queries/useTherapist";

const WeeklyPatientLimit: React.VFC = () => {
  const { t } = useTranslation();
  const { data: pt } = useTherapist();
  if (!pt?.weekly_assignment_limit) return null;

  return (
    <Pane dark p={2} className={styles.grid}>
      <Text>{t`settings.profile_information.weekly_assignment_limit`}</Text>
      <Text bold>{pt.weekly_assignment_limit}</Text>
    </Pane>
  );
};

export default WeeklyPatientLimit;

const styles = {
  grid: css`
    display: grid;
    grid-auto-rows: 45px;
    align-items: center;
    grid-column-gap: ${SPACING}px;
    & *:nth-child(3n + 0) {
      justify-self: end;
    }
    @media screen and (max-width: ${Breakpoint.sm - 1}px) {
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${Breakpoint.sm}px) {
      grid-template-columns: auto auto 1fr;
    }
  `,
};
