import { IconButton, InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Cancel } from "@material-ui/icons";
import { Icon } from "shared";

type CTextFieldProps = TextFieldProps & {
  name: string;
  adornment?: React.ReactNode;
  noClear?: boolean;
  asNumber?: boolean;
  pattern?: RegExp;
  autoSelect?: boolean;
};

const CTextField: React.VFC<CTextFieldProps> = ({
  name,
  adornment,
  noClear,
  asNumber,
  defaultValue,
  pattern,
  inputProps,
  InputProps,
  autoSelect,
  ...rest
}) => {
  const { control } = useFormContext();
  const empty = asNumber ? null : "";
  const value = control.watchInternal(name, defaultValue ?? empty);

  const clear = () => {
    control.setValue(name, empty);
  };

  return (
    <Controller
      render={() => (
        <TextField
          name={name}
          id={name}
          value={value ?? ""}
          onChange={({ target }) => {
            control.setValue(name, !target.value ? empty : asNumber ? parseInt(target.value) : target.value);
          }}
          inputProps={{
            ...inputProps,
            pattern: pattern
              ?.toString()
              .match(/^\/(.*)\/$/)![1]
              .replace(/\\/g, "\\"),
          }}
          InputProps={{
            ...InputProps,
            startAdornment: adornment && <InputAdornment position="start">{adornment}</InputAdornment>,
            endAdornment: !noClear && value !== empty && (
              <InputAdornment position="end">
                <IconButton onClick={clear} aria-label="Clear input" style={{ padding: 0 }}>
                  <Icon of={Cancel} secondary />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onFocus={event => autoSelect && event.currentTarget.select()}
          InputLabelProps={{
            shrink: rest.type === "date" || undefined,
          }}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue ?? empty}
    />
  );
};

export default CTextField;
