import React from "react";
import { Button, Checkbox, Container } from "@material-ui/core";
import { CTextField, HFlex, Text } from "shared";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input, Row } from "../ValueTable";
import { registries } from "../";
import useRegistry from "../useRegistry";

interface Props {
  overrideRegistry?: string;
  heading: string;
  onAdd: (row: Row) => void;
  onCancel: () => void;
  defaults?: Row | null;
}

const AddRecordForm: React.VFC<Props> = ({ overrideRegistry, heading, onAdd, onCancel, defaults }) => {
  const registry = useRegistry(overrideRegistry);
  const { columns } = registries.find(({ name }) => name === registry.name)!;
  const form = useForm<Row>();
  const { t } = useTranslation();
  const submit = form.handleSubmit(record => {
    onAdd(record as Row);
  });

  return (
    <Container>
      <Text heading bold mb={3} id="add-record">
        {heading}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          {columns.map(({ label, name, type, required }, i) =>
            type === Input.Checkbox ? (
              <label key={name}>
                <Checkbox name={name} inputRef={form.register} defaultChecked={defaults?.[name]} />
                <Text small secondary>
                  {label}
                </Text>
              </label>
            ) : (
              <CTextField
                autoFocus={!i}
                required={required}
                defaultValue={defaults?.[name]}
                key={name}
                name={name}
                label={label}
                fullWidth
                type={type}
                margin="dense"
                size="small"
                asNumber={type === Input.Number}
              />
            )
          )}
          <HFlex justifyContent="center" my={6} gap={2}>
            <Button onClick={onCancel}>{t`buttons.cancel`}</Button>
            <Button type="submit" variant="contained" color="primary">
              {t`buttons.add`}
            </Button>
          </HFlex>
        </form>
      </FormProvider>
    </Container>
  );
};

export default AddRecordForm;
