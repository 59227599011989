import { Result } from "api";
import React from "react";
import { Week, WeekForm } from "../WeekForm/WeekForm";

const WeeklyConfiguration = ({
  weeklyConfig,
  edit,
}: {
  weeklyConfig?: Result<"GET /care_event_configurations/{id}">["weekly_config"];
  edit: boolean;
}) => {
  if (!weeklyConfig) return null;
  return (
    <>
      {Object.keys(weeklyConfig)
        .filter(key => key.startsWith("week"))
        .map((weekNumber: string) => (
          // @ts-ignore
          <WeekForm key={weekNumber} value={weeklyConfig[weekNumber] as Week} name={weekNumber} edit={edit} />
        ))}
    </>
  );
};

export default WeeklyConfiguration;
