import React from "react";
import { ErrorBox, Loading, Pane, Text } from "shared";
import { Personal, Status, Summary, Treatment, HealthJournal, Payer, PTOnDemand } from "./";
import { useTranslation } from "react-i18next";
import usePatient from "../queries/usePatient";
import TreatmentHistory from "./TreatmentHistory";

const Account: React.VFC = () => {
  const { t } = useTranslation();
  const { error, isLoading, isSuccess } = usePatient();

  const mainEm = (
    <>
      <Status />
      <Pane>
        <Summary />
        <Personal />
        <Treatment />
        <TreatmentHistory />
        <HealthJournal />
        <Payer />
        <PTOnDemand />
      </Pane>
    </>
  );

  const altEm = (
    <Pane>
      {error && <ErrorBox error={error} verbose dark />}
      {isLoading && <Loading />}
    </Pane>
  );

  return (
    <div>
      <Text paragraph caption secondary>
        {t`patients.account`}
      </Text>
      {isSuccess ? mainEm : altEm}
    </div>
  );
};

export default Account;
