import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useToggleVideoCalls = () => {
  const id = useId();

  return useMutation((accepting_video_calls: boolean) =>
    api("PATCH /therapists/{id}", {
      params: [id],
      data: {
        video_calls_enabled: accepting_video_calls,
      },
    })
  );
};

export default useToggleVideoCalls;
