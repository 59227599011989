import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/misc";
import { styles } from "../styles";

interface Props {
  full_name: string;
  birthday: string;
  address: string;
  phone_number: string;
  nhs_number: string;
  differential_diagnosis: string;
}

export const PatientDetails: React.VFC<Props> = ({
  full_name,
  birthday,
  address,
  phone_number,
  nhs_number,
  differential_diagnosis,
}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.section}>
      <View style={styles.row}>
        <Text style={styles.bold}>{t("patients.uk_discharge_letter.regarding")}</Text>
        <Text>{full_name}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.bold}>{t("patients.uk_discharge_letter.date_of_birth")}</Text>
        <Text>{birthday}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.bold}>{t("patients.uk_discharge_letter.address")}</Text>
        <Text>{address}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.bold}>{t("patients.uk_discharge_letter.telephone")}</Text>
        <Text>{phone_number}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.bold}>{t("patients.uk_discharge_letter.nhs_number")}</Text>
        <Text>{nhs_number}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.bold}>{t("patients.uk_discharge_letter.differential_diagnosis")}</Text>
        <Text>{differential_diagnosis && capitalizeFirstLetter(differential_diagnosis)}</Text>
      </View>
    </View>
  );
};
