import React from "react";
import { useTranslation } from "react-i18next";
import useProviderGroupsProfile from "routes/patient/queries/useProivderGroupsProfile";
import { Pane, Text, HFlex } from "shared";
import UpdateProviderGroup from "./UpdateProivderGroup";

const ProviderGroup: React.VFC = () => {
  const { t } = useTranslation();
  const { data } = useProviderGroupsProfile();
  const mainElement = (
    <HFlex alignItems="baseline" justifyContent="space-between">
      <Text paragraph light dense>
        {t`provider_group.title`}:
        <Text bold ml={1}>
          {data?.us_provider_group_clinic?.name ?? t`provider_group.none`}
        </Text>
      </Text>
      <UpdateProviderGroup />
    </HFlex>
  );

  return <Pane dark>{mainElement}</Pane>;
};

export default ProviderGroup;
