import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useChangePayer = () => {
  const id = useId();

  return useMutation(({ payer_id }: { payer_id: number }) =>
    api("PUT /patients/{id}", {
      params: [id],
      data: {
        patient_profile_attributes: {
          payer_id,
        },
      } as any,
    })
  );
};

export default useChangePayer;
