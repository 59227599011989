import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Area } from "types";
import { Logo } from "assets";
import { Icon, Text } from "shared";
import { SPACING } from "theme";
import { isUsMarket } from "utils/market";
import { css } from "@emotion/css";

const CollapsedMenu: React.VFC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = (path: string) => {
    setAnchorEl(null);
    push(path);
  };

  const logout = () => {
    push(Area.Logout);
  };

  return (
    <header className={styles.header}>
      <Icon of={Logo} size={2} />
      <Button aria-controls="menu" aria-haspopup="true" onClick={handleClick} aria-label="Expand navigation">
        <MenuIcon fontSize="large" />
      </Button>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        style={{ marginLeft: "auto" }}
      >
        <MenuItem onClick={() => navigate(Area.Patients)}>{t("views.patient")}</MenuItem>
        <MenuItem onClick={() => navigate(Area.Therapists)}>{t("views.therapist")}</MenuItem>
        <MenuItem onClick={() => navigate(Area.Reports)}>{t("views.reports")}</MenuItem>
        {isUsMarket && <MenuItem onClick={() => navigate(Area.RCM)}>{t("views.rcm")}</MenuItem>}
        <MenuItem onClick={() => navigate(Area.Other)}>{t("views.other")}</MenuItem>
        <MenuItem onClick={logout} style={{ marginTop: SPACING * 3 }}>
          <Text secondary>{t("dropdown.logout")}</Text>
        </MenuItem>
      </Menu>
    </header>
  );
};

export default CollapsedMenu;

const styles = {
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(42, 63, 88, 0.0680452);
    padding: 0 ${3 * SPACING}px;
  `,
};
