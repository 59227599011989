const images = {
  "1": [
    "what-is-OA-1a-dd048fbb687ec56f7351380cb281a8c5c869fc39c19ad2f49a44a50ad152b406.svg",
    "what-is-OA-1b-f2b97182569b7a069f77c828f8e788e8871907d068878b52e8c6743a9051eb4d.svg",
    "what-is-OA-2a-c448605a914171a1abc7ab4e0847ca370d37e119ec4db1f2c124d830a719ee5c.svg",
    "what-is-OA-3a-da760d1f1b9533f5d7de0498506279cdae0ce252cfe214c20ee55641635da5e0.svg",
    "what-is-OA-4a-1d62c85878ede131bc27ca107cc39deedd1870d27d3cb0ae74b4df72acee4551.svg",
    "what-is-OA-4b-bc5d40bce8f07c9a8ce032895a0fb6ac8c37659dd4c440b1b18573bf4bf0c1b9.svg",
    "what-is-OA-5a-3163b864dcb10a5af15af295345183f755008f7241be05a83b50a57a7cc0b29d.svg",
  ],
  "2": [
    "riskfactorsforOA-1a-82fe71499f26003ae2d2f4fcb2fc43310da16e87369e544eef6c3f0757d2fc45.svg",
    "riskfactorsforOA-2a-93a2781cd8ee84a8b44b55627429c6b369b0a4e65fc6fddfc982f282601b961c.svg",
    "riskfactorsforOA-3a-c773218d3276c3e1e0911afeea5cc24c6cc6dcf4c216f74744b8efa0b6d852f2.svg",
    "riskfactorsforOA-4a-01a02c0ad43ed68ec4a5e11b1416c4445710e4887e0b96413e08a834754a6a1f.svg",
  ],
  "3": [
    "handlingyoursymptoms-1a-0302f20cdb2037f6401e210731096af648f5c4a4361712200d3148a6c36d5ba3.svg",
    "handlingyoursymptoms-2a-28236fcbeec3644ca58cc6e972fb7ea0c64e247b112eeba6f7705b5fdd3894c4.svg",
    "handlingyoursymptoms-3a-7054d2eabfdfe733855a15fe9700258c63f0561da1d96f71b58e0fc7cf3bdffb.svg",
    "handlingyoursymptoms-4a-7757e21eafb542f1c5ddb448386dd41955753b86305376e3c6311ea50608722f.svg",
  ],
  "4": [
    "findingyourdiagnosis-1a-b1d5ca2b3900b03173ba9f46184fd6533e1a047d8c41ed10d06619a5a09545b7.svg",
    "findingyourdiagnosis-2a-97022a389906a069b86e66ea4200586adc4ea60ca301059f584a230f6c2c020d.svg",
  ],
  "5": [
    "trainingandweightloss-3b-c00acce0aba4423e0a58d7b2e63635e844fe1c4dbc99503dc90109e03e63106c.svg",
    "trainingandweightloss-1a-f7b3f2d1c1f63b17cf8afaa5f437b0af2003a274a0f0ea1343ff2f47e005442a.svg",
    "trainingandweightloss-2a-4696c45fccb5876737f4fd2e67cbcfa54ad0e4ab531a96ac947dd6950cdd0e71.svg",
    "trainingandweightloss-3a-782d7e4db6dce78f166193e5762d2e076a46a3dc593cee322c8c6194318d27a1.svg",
    "trainingandweightloss-4a-d0835b62081d2991c3d6385c5d14b0e0899f89ac80fc88fa83d0c704a5f1444f.svg",
    "trainingandweightloss-5a-0691063dfad16f64d8bc6d3a4244792a75908f637134eb762d8dbf17b68573c5.svg",
  ],
  "6": [
    "everydaymeasures-1a-8bbfe0e9208a4dbcd266e14c588d397a831d8673849e986a3eb946b4f689ec8e.svg",
    "everydaymeasures-2a-245667d789d44164bf74def538f93a97f3c4bca2ed793ae8546bb6dd84f0a86e.svg",
    "everydaymeasures-3a-c22e48b78a37fb0ad9c80d64e2cb6c16c5d0ec5aaa416019cfbbdc9acdfbadc5.svg",
  ],
  "7": [
    "traininganditshealtheffects-1a-8451d779ce05f3f8a656a2d6a3d48a7d3a523993da320228370b0c0a9c0dafaf.svg",
    "traininganditshealtheffects-2a-61001dbdd464635a8f5a9fa0e7efee9ea5bfd5e14493ee5b6a9d2702ec0f22fa.svg",
    "traininganditshealtheffects-3a-4ac3345616e480114368a2822c5806e530ebd795d0a0090c7f3418ad785a1b8b.svg",
    "traininganditshealtheffects-3b-9bfefbb652e4ec241ad05d5f66af9d05d4f59b847ad9805ced5552acfacb34dc.svg",
    "traininganditshealtheffects-4a-e75f9b161dc44864edcf23a213d1679c2f8472ba12c739baaed78cfb6905a821.svg",
  ],
  "8": [
    "pillstrainingandunusualtreatments-3a-31a9186911534076f0b0582fffb3e64f10b58d2eaac3e7f21b1d331abd8625cc.svg",
    "pillstrainingandunusualtreatments-1a-a435b49e18aaf91c74111d435787df9abd53518db9a1d9ec2861710d7e6524ae.svg",
    "pillstrainingandunusualtreatments-2a-792b548e748e65672345d31763ca5cc4f31646e9df40807b65f9e4efcfe7e500.svg",
    "pillstrainingandunusualtreatments-4a-ad8a8537baeecb3c51308684da552126fe130571d2f84790a1257da5e07d7a1f.svg",
    "pillstrainingandunusualtreatments-4a-dd8a2e04b4414a9ff9da9b52f6f4251c02360af5abbae46a60ae2bf8b705b9f3.svg",
  ],
  "9": [
    "acupuncturinjectionsandsurgery-4a-66c069099a54f36931ae5351efdc127817f9d0cee60b95891732c54811059fa7.svg",
    "acupuncturinjectionsandsurgery-1a-1387c5745d6f87be3a5c70f997f686d19258acb17896c1be35b27d6521eb9d90.svg",
    "acupuncturinjectionsandsurgery-2b-4fd1e34572d2215d42a8e8518d7db558055db6963ca780b776194ddc0c0e8dec.svg",
    "acupuncturinjectionsandsurgery-3a-f17cdb80ba9710f5aea3e8314e0e830e34b9cc0096d754cd0c076f1954e8945a.svg",
    "acupuncturinjectionsandsurgery-5a-75830d1db9ee17e8998fb523364e1eeb6237fed948e09e7b36f8203d7460814d.svg",
  ],
  "10": [
    "stayingmotivated-1a-a280ee1ed0d92d50f8b6b7f7fe40be17e688c42c8fa84579a12907c4e1b74eb9.svg",
    "stayingmotivated-2a-241102d6c2282ddbe701412259608bd715b5d2fde6075c255deef296990a9d7b.svg",
  ],
  "11": [
    "weightlossandOA-1a-8e432696b2abfd52541d757d66a1da69767d39a12251c7cef77033cd1a06166f.svg",
    "weightlossandOA-2a-d521cddbfc12b6e271263034429432807433c9322a41ce52fff26273b49ee97f.svg",
  ],
  "12": [
    "theimportanceofsleep-1a-bb05e1f4d7edd05706c658eeedbffcbe322ad2fab5f990ae396658a4ff05824b.svg",
    "theimportanceofsleep-2a-a6c1eb567bc0b73d80447010a6943442f3bfdb163aa2bab121c8e61b80d80874.svg",
  ],
  "13": [
    "createyourowntrainingprogram-2a-5fffa59e718e707e1a4522af8465ddbfe2786e58b5150e8a35716380927ba0ac.svg",
    "createyourowntrainingprogram-3a-4cdb416e229fc30514c1ba99c537f65faf44468b6e69b8b3c26ffd344be456e3.svg",
    "createyourowntrainingprogram-3b-99b6da2f1318cff329008609405c2494ce5f4a61a115239407d8d314e00e2c92.svg",
  ],
  "14": [
    "eatinghealthywithoutthinkingaboutit-1a-7a8c9fb0a2b8fc93a2f08fb13dfe595d874942098e6a6a8a7214afaa108842a9.svg",
    "eatinghealthywithoutthinkingaboutit-2a-e2dbfc7bc4c09a23f9ba262fc41628f8b060344e701a127de75cd5f4ac2f4d49.svg",
    "eatinghealthywithoutthinkingaboutit-3a-557f6409c1721902b2c1351956a5dbeaa026b6f839cb4cb73927050b90927edb.svg",
  ],
  "15": [
    "OAtreatmentandfulltimejob-1a-2703d0dcb5b9292699de420bcaf763bcf7c68216560a5925c1cdd115c4d5a9f7.svg",
    "OAtreatmentandfulltimejob-2a-dda9188a863d99791d72b8abc5691254c026aa05b9d9ede5995a3d9af57be8b9.svg",
  ],
  "17": ["prostheticsurgeryprosandcons-2a-ff2e544ea1964d503d128766a882108805cf9afce85592ee705f7bca976cc231.svg"],
  "19": [
    "healthyeatingaccordingtoscience-1a-392a663a2c6ebb067cf01b25c5ac3661a889058b22935dad4826559345778f48.svg",
    "healthyeatingaccordingtoscience-2a-897a8a29d15f45a004d12107e3ac06d06c27fa0981a21c418c801d3801da73a0.svg",
  ],
  "20": [
    "whatyoushouldknowaboutsurgery-1a-1b0ebd841b109ee640f0bb35960be22c422d97f2d267e77a08ad23f81c5cdcb7.svg",
    "whatyoushouldknowaboutsurgery-2a-73c0cfb26df3a3d4293c347739f25e4e0720ce3915e2639ed9b6d56fb5e66d2c.svg",
  ],
  "21": [
    "omstrenghtaerobicandmobility-1a-5c45e66f16d2d2a63f3a280e1c90cf6fb9fc977662e118c557e2c6e5216afb41.svg",
    "omstrenghtaerobicandmobility-2b-90d46cf4647abdce5104e6336f76361b994aa3beceafeb17afbac192685cac66.svg",
    "omstrenghtaerobicandmobility-3a-3d84ebe62029aa65ec230ef1b856317e53df1bd44499447d939cb0968475253c.svg",
  ],
  "22": [
    "travelingwithOA-1a-a6e5466235d2282626182821e0dd26edf9fa7ccd01616d2a92731778f21dee70.svg",
    "travelingwithOA-2a-ccb275196c4c05fe174f68e9594f29e91ef9e3f15ef3c30a6b5af24cee3a7f6d.svg",
  ],
  "23": ["trainingbeforesurgery-1b-1f71845fd4d0f8d8092f607a82bb61d12f8cc5dd45326d9853aa68cff37b2f43.svg"],
  "24": [
    "setbacksarecompletelynormal-1a-46331bf7c58fd19c0c27465d236487da4fa31b8bb245c51ad2e28b0eac50833d.svg",
    "setbacksarecompletelynormal-2b-c7bb7637374dc00ecacf5215b05c81246b9339adcb9e1c200202640bc37b7d13.svg",
  ],
  "25": [
    "naturalremediesandothernonprescriptiondrugs-1a-28ea54aea36b30c295b3484109bd9b11dfd5c7dcdd1d10d3cf3248a566e0c9ed.svg",
  ],
  "26": ["letyourdoghelpyou-1a-3425ea14ac9d6489e112f32d5d72c42cf3b0e2ff7a5d17f59f1f1a3bf543c19f.svg"],
  "27": [
    "howtomaketrainingmorefun-1a-b29f662c20e4fa944d2ece8e02321b154a6610616c885449c082045b3befa238.svg",
    "howtomaketrainingmorefun-1b-72a2f45117a38165714f5f435a0e27f8f2cc5a7be231c4ad52c44696ddf4d2c1.svg",
  ],
  "28": ["measuringweightlossprogress-1b-b99b7d16eb41e0bc8cf9a5f24e2fcac9cad39a651b85c432664720c1dea59e11.svg"],
  "": [
    "1-1_Hipknee-c28af0d6614c2f0bb25f48789ef9455693b103097b6fac62656c5c00a6ac49f9.svg",
    "1-1_Stages-oa-9fe1dac591fda8533d82484c90db7638faabe3145fcb22117063bceb2dc69018.svg",
    "1-2_Elastic-band-d5023c243c0f9ea7f83e0b206919e1bfd2271a4a7e057fbc40467e9bf47faf33.svg",
    "1-3_Cartilage-fb7488a92d35b34b6366148153bc948ec3d8e0346e7df040976f6955f834ae46.svg",
    "1-3_breaking-cartilage-fae498513e7897d68313acf96521304a5768b3616c908244e06504c952ac8dce.svg",
    "1-4_New-cells-38e9fe60faec4ba7abcdc8e911810c38c3d2ccc77fdf749384e685efa75f04d1.svg",
    "1-4_Skater-425663e8f85f6114e59aacb8fc000386894c6c9d20ff49a264097206523f7b64.svg",
    "10-1_intrinsic-vs-extrinsic-576ca462f853c063b801307239ee6c70be552e50dddbb799f2a8ef4c6f2523f1.svg",
    "10-2_checking-list-4387aa2cdf527426b4235a0d27f8d1ef82bdfd0531599836aaffb1f1e258918b.svg",
    "11-1_weight-joint-47cba8be2061d9abffd90ecda89d4807665ad86bd2c43dba3b5b1effcd4f07ec.svg",
    "11-2_weight-influence-bfe6a8fa632c3818779dd7b9b5399076a0e9bdef5472ee14e9122f9f8649dc31.svg",
    "12-1_sleeping-eb9186e6c2137356101e20eddf28776f92aeda48dd2a6e75b75a6cf496fadcfc.svg",
    "12-2_sun-sleep-c8accfe730b31348a0c689485f99c8d95e309cdd5517920235f2385a9800f614.svg",
    "13-1_red-thread-goal-9966e2b5a153c1b850892dbb437c108918e679004071f660ac5f92bb2c312dbc.svg",
    "13-2_pulse-time-f69beef1f27d097af2b125c230d4cc9d450d1fbe8f961c47ee289d3111faa15c.svg",
    "13-3_hiit-liit-2b429d8f53d66f15e575b96baf9dcf7d4d8b5241e286cfebe08860aa22dace29.svg",
    "13-3_plank-knees-c38f0563ebf3b151e4834ef493c192a938e463e78b2e2791aaded8ae8a300b21.svg",
    "14-1_chocolate-apple-8036c6139a1f2baafe58275fb2b77cc5dc79938346dce1834ecd628e88f3551f.svg",
    "14-2_smaller-portions-0d4b0218833ca30270352cbc78881c7a78289791bd2ba145c7a3f9e304a00298.svg",
    "14-3_candy-fruit-7f541c932c19ff8067fe13f799f7c680e8496681c1dc7eee982aeb46bce8798d.svg",
    "15-1_exercise-microwave-7473fabdb7abbda63c5393482cbca43aec8730d114c801558eb406f336631ec6.svg",
    "15-2_construction-load-4b2429e3fb3af12fd56130ae58c700e3a88f5454c8722e8dcc5753aae65178a9.svg",
    "17-1_knee-prosthesis-fa3ad3cdb0b2c91042178dd0adf9b1fb7d971e006d5c0904e6237a0dbac6b0f6.svg",
    "17-2_physio-training-af49db7bd15509416598175529e3d0fd145850eeeb4abc36eb8dd2a65c35c6ad.svg",
    "18-1_happy-factors-48af8da95d2413aaae330c1cda683f93d4e71f844ef7e0f7b01fe065de3cc4ae.svg",
    "18-2_diets-c24578442f6642cc983e21822cd45f8ef1620f4037e459c242bfd32a7b3b5aca.svg",
    "19-1_before-surgery-32974bc7e4b806e6045004463c018990f3aef8ec79b8b1c02c48b7d3c7b59bd9.svg",
    "19-2_punch-cigarette-a0657544c0a7dd32615c69fcae6ba28a45f3b6acd9f168cd18cedb30814aab91.svg",
    "2-1_Force-on-joint-7a166ebd3704288aad15c13a8a93cb20619de963affaa08fb1fc6197bf36944c.svg",
    "2-2_Muscle-strength-f87002c72f3ad9efd6010497f2d4fc3737117890d881e44d803e9cd4d9c4d39b.svg",
    "2-3_Knee-parts-9ab40e9b129735f93a76ed21eef2689fe155594035fc1dccb74059645dea6e50.svg",
    "2-3_Street-man-89883ab109c091d4944438178b372abef99fb3d6881573ab5f91567d4309f8e3.svg",
    "2-4_Biceps-4cfe8247d410928dff27ee6b14b448ec8de2de4fef8408553343d84a09fa367d.svg",
    "20-1_Strength-training-ea2b7da3c24544f2071266446057c2f52822655932ae3e76e5dd2a328298b93e.svg",
    "20-2_yoga-2031fe26507774507921f96fd9e7f0017343d77974c8bab578fdd9d32112db2a.svg",
    "20-3_rowing-1e8efc75d142515ee9b4eac74c360a74215c4c5dff6838e893d869e261f9cd7b.svg",
    "21-1_world-travel-761ac9f680af649d732083bc98ae18d82c873e142b3e5f78a0d866ac2aedbbf3.svg",
    "21-2_pool-47a7a6d26e93d8a1f36aa002013a5d7a6523bb91ef1af554816d5facef4bdbd3.svg",
    "22-1_training_lifestyle-0d32e079687c0edd3bc6bc0d44103880ad5fab357b670edae38cc1d58d630a42.svg",
    "22-2_prosthesis-walking-6b10b663b73af7d5c12ba78deb2a259a884f212ed47625711882f66f3b38f902.svg",
    "23-1_dinner-b53967d8ecb105c0bd3cf6c25dfab780199f7e43996d75ec82f851a7f3030e2e.svg",
    "24-1_nature-medicin-cb7efffca0b79f3b8143f25d9c2b4f57fb2fbf771d47270b4e36ef571e0f29fe.svg",
    "24-2_first-aid-1cb5e7142bbd55411cd2986c95a637894d36543d577f50baa24525c998af2e8c.svg",
    "25-1_dog-leash-7202f7ecb9b47eb6e612879fdc98ffb7a0dcc7f869c13ec9698bdb8939e20ec0.svg",
    "25-2_dog-kayak-2a268f8dfb24d004abb530d1c27b4a36f1fa73ad385ab4014642668589e2453c.svg",
    "26-1_TRX-e49b71d2cbb9b639cad36c31419ae545157739eeaa50c5ee7418ccda8934760c.svg",
    "26-2_headphone-562291fb535b98e62db83cef30e607d30ec5b1be33c633e757e7d438bf74ab74.svg",
    "3-1_Bench-84b348c2b96c8ff8349e564ef08e6762154a4d11575948eab6de73b82c151c77.svg",
    "3-2_Broken-tshirt-30fad0d5baf626d294888600e24d95ca418de7a1558029ba0e50a316be9f95ab.svg",
    "3-2_Question-5172e1893a637b8bc6522203397987ec3de9eedf2b3f2c8401444b906969b693.svg",
    "3-3_Swollen-joint-f6bda1bed5b3478f0493a3943415c40f45d26efd632d6bd9042d7f1c0d32ba74.svg",
    "4-2_Diagnosis-5c82e47a0af35facc4f78374f40f4e3d5050db53b61a2c6c3a36d064f4de19d1.svg",
    "4-3_Upward-graph-8b86be87ca099d15efa794459150dc76bb0f8667c412a50ac7bc2d6117c1a3ae.svg",
    "5-1_Squatting-154381f41d92f3cba992e8a418dabe4be194186569cb3dd37152d72159390180.svg",
    "5-2_pills-training-69ce1dd4e1fa6c8e3fa9e1ccace77860de0fa762a5c031a3cf48981054440a34.svg",
    "5-3_goal-8e52869645f9fad30ada10f54ef46576e9d9a59405f064d9e5e7acd42b35ddc5.svg",
    "5-3_scale-80c1b586bd92fc850138fd70ebbf0f19976a87dd96aa6f5bb77972d146bf9a11.svg",
    "5-4_good-food-b5c354a5a78a3013c2806893d86a5238d6cd9e9e25ba51821edf7428095182ad.svg",
    "5-5_calendar-6323d2a6cc737fe6fce86a9f9044025daa53acf372f597a8dee451d995d4922c.svg",
    "6-1_gardening-b891e78ce677bb032a6d433cb7bed2c52599447b14fca06fda93e0aa256fd067.svg",
    "6-1_people-walking-afdde25bcde3432f875ee4037467de27c725eefb6b0fd801568fdad510649c00.svg",
    "6-2_orthotic-device-dbf8d49b706202fc01058a02f6fce4dc6f1e69187a6b0171303899ec66a166e8.svg",
    "6-3_water-aerobics-deec6219a4a4444a0c7494231acf86a686c8ef966e9fdce76bcca9ed6aa08045.svg",
    "7-1_endorphins-dd6039ede2d4aa43a95d4f246c7e40f78b22340fdf06fc6a93f5fcf7e9b0be0d.svg",
    "7-2_muscle-traffic-34ae455a2ade2c463976975c419fc1f26cbe33afe37f854f0c8602af2b6e7971.svg",
    "7-3_muscle-vs-fat-b49198c741e5dc9c35bc5e0e2727c50cb9fe21a510c8bbc11094b6c8c946691d.svg",
    "7-4_level-steps-441387b2812d43ed2ae257e4e2eb822bbf98ae50efb9bc85641ceeedfa10ac07.svg",
    "8-1_non-invasive-treatments-e9acc5f7b1374f1546e38f6f6ad28c3020c82ee2dd1e7d276a22a24eaca76834.svg",
    "8-3_nsaid-204b1adaee2df8b61c7a7f6abe353ad961aeb1cdf918bb54331a8d69ede72510.svg",
    "8-4_tens-21cbf8350a485be31ed58df22d393ae5e6a2e0d41716488a7b79951505f6659f.svg",
    "9-1_cortison-injection-ed8e6b33322edd687158a6e4dc46f14c39b3de9e5c94867f6a5dbc6d7e6311f3.svg",
    "9-2_before-after_osteotomy-328f2f4475ab129b383777967d54ecf08daf385d5580df4e9a378f1373ccb67f.svg",
    "9-3_prosthesis-1cb9a7a71901e5ec5be110596a405b678d5613eadf47ff2598391252cb299e01.svg",
    "9-5_diff-exercises-297b26fe150543b9787d94589dae881ea9a18cc504b39faf41d46e6f8d88fb56.svg",
    "text_lesson_hoa01_part_1a-36a11081241b76d63bd2dca1097fb12698326d89635b8a729b43eb1172dbd66b.svg",
    "text_lesson_hoa07_part_1a-d94c49389adc2c4e9ba125fb64c6f36005ca66c09bfd5f302414a2a322bf5d4c.svg",
    "text_lesson_6_part_1a-fe81ca4b4b4cad57e35a2c8f41c03f215fd86bad400b4ccefb9c65f30ff7b0b0.svg",
    "text_lesson_6_part_2a-87738713ee862702f04d1666e5089d8fc41fe7d2ad1ee649a4dda5636ea8abfb.svg",
    "text_lesson_6_part_3a-3e843972c2406c6d145b269103bfd8432f6956b26142c51291289b3538738173.svg",
    "text_lesson_hoa001_part_1a-6583ea0afeec6c67cf77ae96e4cdc08a02344e54330d2fcdb9c48c9cfb216edf.svg",
    "text_lesson_hoa001_part_1a-9841d6837b6184771f8893efbf5b7a14482e2f0a55d40eb29ec659e6279dcbd7.svg",
    "text_lesson_hoa001_part_1b-876fc9360ecd5dc4e02364355c0515578d3e10dc1f148fe41043d348d11f5c66.svg",
    "text_lesson_hoa001_part_1b-df7b6e5c0f2de57ed0cf70892cad8a4cac680ba7fc1fdc71fec4f62cc2d4b810.svg",
    "text_lesson_hoa002_part_1a-e051f92a0cbdde4a7b6cf72486ded4def7715af8eb14e0edad64cbeabc762e4b.svg",
    "text_lesson_hoa01_part_1a-6631a1f478609c9c7f9dfad9d47f3b8703f3cfe00843f3b1a6626dd44ba83fd4.svg",
    "text_lesson_hoa01_part_1a-e0fc41774a9d8022412820ccf9742e80a5b515b7554fcd809957292a21f052c0.svg",
    "text_lesson_hoa01_part_2a-2ce11a6941e6bdcc511410b530ca464e73f246fc774a5d2b63e8f0d82dee1dee.svg",
    "text_lesson_hoa01_part_2a-8870828771955d4af9fd783f5b0f9813705d84d6784dc8ba33de346d310ff1de.svg",
    "text_lesson_hoa01_part_3a-b0f780b57098687e8b194a379c24254b0d42cb0e3d1886bbb622a7c1297a6101.svg",
    "text_lesson_hoa01_part_3a-c5047fbe770aaa14bf9fcdf7df9e50d06ce8f8ff3ac1b3ed42df62c5566b37e1.svg",
    "text_lesson_hoa02_part_1a-54689b2afd4d49e8d1cb5d74b4ababc91eeb4535842b1ee2bc30fe7d7d354f0e.svg",
    "text_lesson_hoa03_part_1a-62a47f2b85ef54a9d708e6b36b73c30d0794b12618c652939e5b1489a7200ef5.svg",
    "text_lesson_hoa04_part_1a-7c64232fed87ad414e2e9950a2569030db3d64c531cef32471a58061a6ec51c0.svg",
    "text_lesson_hoa04_part_1a-b9768697e23d1fae139914566162cc53da998c994f7186bf8bbfadd9ddfc18a9.svg",
    "text_lesson_hoa04_part_2a-2fc3ded3e5e0fae2ace5479efb2f3e77836e316401f42c84e3e7535f981fe0bc.svg",
    "text_lesson_hoa04_part_2a-f20bbbf7e9009e2cb0c109052f212c739ee55187e4ef6297ae3b7e49ece55d13.svg",
    "text_lesson_hoa05_part_1a-2bd151dc70efb03f4bce550eddfb1e86310213ffc834d00aff8b83890828cb8b.svg",
    "text_lesson_hoa05_part_2a-68950529894771f85e1692b2779172bf4504aa2cdea361a551e0f02021046d6e.svg",
    "text_lesson_hoa06_part_1a-6b25d652e0306c430546ea27a364f76975bdd892f4020817713bef6b2496ef27.svg",
    "text_lesson_hoa06_part_1b-c0f5e1f357e5c454bc4e412f7c21779cbe6f42dc99368bac15959d0da9ba287d.svg",
    "text_lesson_hoa07_part_1a-9ac5750976e8fdbabd6d79654d5d778e2d41554ff11d646f43ad68cfa870a7f9.svg",
    "text_lesson_hoa07_part_2a-02d50cd4165fa7f8623e40652aeb7399bedeaea8d1b54c35fd6cc9ee8d9aef37.svg",
    "text_lesson_hoa08_part_1a-1c6070e7e86ac2dd5db4f0bb5e56057fb645453667703ce131355b7d825b7b79.svg",
    "text_lesson_hoa08_part_1b-8559c1b0e5784939d6819ce0df822a3c0d27b5e86a5586e4cf93daa2d738a952.svg",
    "text_lesson_hoa09_part_1a-940fbf9ecaf6138bc97d0a596c52e9c2dda36177f6c2cde72af16937a3ae921f.svg",
    "text_lesson_hoa10_part_1a-4ce26b7b38b1bd6f705190402e465cb489cbdfb04167b2f742f663de85abab29.svg",
    "text_lesson_hoa10_part_1b-eefe1f1ffa52a1bcd7012542688caca0cc74415e6d3d847479642d7c2857864c.svg",
    "text_lesson_hoa10_part_2a-4f9487952a139adc1a4663e7925b0b1be09606ca2aed10c093fd02cbd8a4a203.svg",
    "text_lesson_hoa10_part_2b-98381adfcd4ccf5e661f1a3448bc877c295f809d17271f823708078615ebc961.svg",
    "text_lesson_hoa10_part_3a-d2b1a904a949a021acdf088158faff3e11933313e3df8f1b6c00a4e331400056.svg",
    "text_lesson_hoa10_part_3b-46161f19397bb2d42cb728fd9d7606d5ce58179d3940f7c8c5fa3c76990e02b5.svg",
    "text_lesson_hoa11_part_1a-49659536850718453a2223a8a54677dc66958e255203c237c079b417c0e3c5a1.svg",
    "text_lesson_hoa11_part_1b-d533f2d5a2b00abb491407996c57d9a63fbb29b6237b7313d0c985163caad302.svg",
    "text_lesson_hoa12_part_1a-cf2573e9fbe589a470aa19d390932856933000ebe68190318835e64979e2817d.svg",
    "text_lesson_hoa12_part_2a-f57d2544b15d54a9734d1804a64382a53615422018580dae1d2976b9404ad140.svg",
    "text_lesson_hoa13_part_1a-5c2f9b5aff39405fb8648f3f46f721ae12011907629c313893a9a153dd0f0122.svg",
    "text_lesson_hoa13_part_1a-6b9827754855c673fecfa8dc607f8f73bfe1e6aef203ebebc9c81fa73a593908.svg",
    "text_lesson_hoa13_part_1b-e835411abe3fa354a87c4332d1e6c703f65b59506b8f43ceba06bbef70825b2e.svg",
    "text_lesson_hoa14_part_1a-21eac7a49355d9b88b5b3a5d9e106bb8e4d4fb3161940633f779d3e1eefabbd0.svg",
    "text_lesson_lbp03_part_2a-6b99276f8d777daee6826a6343b6ff2c302dd3d85d4b664416f71ef57761a9f7.svg",
    "text_lesson_lbp07_part_2a-60817115024c7012406da3d53c251363c3795a51fe48928f3bbe3d163e632541.svg",
  ],
  cover_images: [
    "1.whatisOA-74328074295eb74cb56b582b5dbf6a0015ed002b91a058e6fe2c36425eeace98.svg",
    "10.staying motivated-02af1fca40f8f14bc1390fe20fde7500d35f9ca95200ce3688e602f591ef2406.svg",
    "11.weightlossandoa-66594230e929b0769761038b446a7badbebdd275a608b96f75cffd8f61480184.svg",
    "12.theimportanceofsleep-87702d70c6b262e71c9ec6a0dcd72c851c306aa562d2607be2910f40110d3089.svg",
    "13.createyourowntrainingprogram-3a9107db6b6daa83f5f03386cb536433e4035ce7a2bf09b462bdad73dfd95994.svg",
    "14.eathealthywithoutthinkingaboutit-0668cfeb7a9182330dc2475ef864163e7793e37c912f83af784e6694c5b6ee1c.svg",
    "15.oatratmentandafulltimejob-92a4d4e02fd503a2b8b6f2d31f26825ed05ae0fa184893e9c955bc8600b5dffc.svg",
    "17.prostheticsurgeryprosandcons-b49d2960656a0fe42aefc9bb207a30491aeb4a8b903303d09c36b10370872313.svg",
    "19.healthyeatingaccordingtoscience-94712956a9c2fa80768cdc5efc12c85bf316fffe30b00be58b598b2ba6cb1734.svg",
    "2.riskfactorswithOA-f967b4f2565b217578edb9eedf81e80fa5abec3dd3e20ea1605b650735aabd8f.svg",
    "20.whatyoushowldknowaboutsurgery-4a18eb885c2ece239d106f517d20dbdb956549a8854c6f53a33693ccaae47d24.svg",
    "21.aboutstrenghtaerobicfitnessandmobility-aa3991cc87733ae07cf8a9427af1f350f5bb0df884786d9b16398ac800e16ab4.svg",
    "22.travelingwithoa-0c0a9452f9442263418b3b2ae5d3d854532d87121a50d0db9b6c11c75abfd4ef.svg",
    "23.trainingbeforesurgery-6a9daa59eca13849017b95de395be592c0d685df92df577ad41e97554b9b991f.svg",
    "24.setbacksarecompletelynormal-dbfa89eb52f1a88d13f61f273d6c416fa5d5469fea10e73dd20c3f4f6e5cacb3.svg",
    "25.naturalremediesandothernonprescriptiondrugs-f385c9b584a18f52f51f26dbf477b53190456b556d3fe32240458c87e4db9841.svg",
    "26.letyourdoghelpyou-66af35cc47b2a3ec74c4bc3471808afb4e398703e5e2909466977e093431e306.svg",
    "27.howtomaketrtainingmorefun-1c4edd32d94517b6afad5b8b6923a1d56d024e94fb053596ddd8dbb91d677ef6.svg",
    "28.measuringweightlossprogress-3fdae5281fab33b07a93247538b88db9b3908e05ddd50d0cd03e22c20918e6fd.svg",
    "3.handleyoursymtoms-8e516dd290615717f6089f65ef509d55a214b5343abe288d56901d17af6f97fb.svg",
    "4.findingyourdiagnosis-1a09888dd56c00485ba3503ce5ce3cb7e5e3138e180c15575e12c9f298a6d56c.svg",
    "5.trainingandweightloss-5a148e7ef742481638bc3c93ad174301ac5f5e3b43020544c64be3f9ee90ad98.svg",
    "6.everydaymeasures-25561c64e832f76f49d92cbee7774e591c180aeb8b5f3b6cb641e965ccf10c28.svg",
    "7.trainingandtheirhealtheffects-08181c2e88d3f56d65986221a61802e69992afe6456ba3e10c99180d14d85aba.svg",
    "8.pillstrainingandunusaltreatments-6769136321248fbf1ecc264f97d4d5980ca3ff9c430ae9e80270a07b59f552bf.svg",
    "9.acupunctureinjectionsandsurgery-ca4d26ed0f684b0b9a0a3d80dfbe281754d979e2591c29127f9e8a25d7f37094.svg",
    "lbp01.aboutbackpain-fcbee4881417b91e3634c8f791c10f4102168f66bc816e3d8dbd231c25be00e3.svg",
    "lbp02.gettingadiagnosis-0d35be2610be620ec1694c3016b70b228fa9296a0880a248eafc5fec35df40b4.svg",
    "lbp03.atonomiofthespine-454427f67e4ba10b8b12c17c3c41eac36bf4d2f7ceedf725a2cad8d8b742f521.svg",
    "lbp04.commoncausesofbackpain-93ef58d24f4c7b1fca5cf93127cf2ea3704a316593c5f79ef57ea5d04fc9d793.svg",
    "lbp05.lesscommoncausesofbackpain-63c996b917b1acc42ffde1111d865cebdc448b8f309cd70de54278f56034f344.svg",
    "lbp06.aboutpain-a478458b86496bd61df3666f294d2579751763417bb8186e4710a7e3b9bb49f6.svg",
    "lbp07.painmanagement-6c9985e6694d8947b16e47bec1e7bb992aa8f9c131f0d1fce14bd017306a3561.svg",
    "lbp08.fearofmovement-6091094bdaf04cf31850a6523fce6a9a8741cefd852c60ec16b1df1add4eee5b.svg",
    "lbp09.ergonomics-bceff7e0b2cc8a65e9e5be1feddcf3ec091c6e5087c5a1b88637aa054f2a7c9c.svg",
    "lbp10.physicalactivitybackpain-0628e7252c03e13a4f12a8f20815966ded01093c05e05dbad15cd11e71784dbd.svg",
    "lbp11.exercisebackpain-1344f8a0d30e4a5e438e8c81f742c409a063563cc2af0ba4dbdd2eb05cff7ed7.svg",
    "lbp12.othertreatmentoptions-9501c00d9a4e99a2911b9a377beee27f638cc12f324072de62fd6557fc53b3a1.svg",
    "lbp13.aboutlowbacksurgery-de7aaad154d83ed59dfcc38119c1990ee7f85fb62fdb85d1f7b3b0de689c4a0b.svg",
    "lbp14.mythsfacts-95c16b9c9380d18e846e88bd4e3ab99c6228102cd639d412724c9a9dc1a9f0ef.svg",
    "lbp15.sleeppain-a42f41334523ac2497bed7d91e0f121d471d19843cd85c8aa2e6e7c1406117aa.svg",
    "text_lesson_6-3b02504470146a82ea041ece81f8e022e447a917196e69f2ba55cfe5d8dbe32f.svg",
    "text_lesson_hoa001-e80e53b44ab21440e546bbab4451f30f0b89c7a3cb85cf11199c59f1623b7448.svg",
    "text_lesson_hoa002-c927b798347f8915f8494104596e91d6453e50823cbe180cf80926d8bde149c0.svg",
    "text_lesson_hoa01-634005740ebac8369b4682d58e12b8fa2e061e8136df6242f7cff4aafe0227db.svg",
    "text_lesson_hoa01-8d508e8c3c97ccaa9d0886951fc98db82ab47b9371347105329604f3b5d223bb.svg",
    "text_lesson_hoa02-9a54f1f8270e4729e8f424af521c6baaadbbd7259fd376a6823820ccd3d297c5.svg",
    "text_lesson_hoa02-b658c65983f6ebb0e6a91e12384865488232157ae32ad79e61ad9e7d3635770d.svg",
    "text_lesson_hoa03-9a6c09dbc80eeb4b8c33517bbea682ab15f12adb2467b0b7ce148526ce6e045a.svg",
    "text_lesson_hoa04-6c37d4e68c750146141d762a103507bf09da176ef9573214d23cc8caf55eddc5.svg",
    "text_lesson_hoa05-5fc50a1425522e96e430c5058150af520ed1f7ffeeba476f22ede414ccf6bc7d.svg",
    "text_lesson_hoa06-1f3a11eba88c113700f91add3bd3500f1fda6da85e1dee931ad97c52d9ca4c57.svg",
    "text_lesson_hoa06-5eda89e73ab1fa65aea264f9cd60d750a0e4f2b499b6ec4ce67530afa68571e7.svg",
    "text_lesson_hoa08-270214065e4c78c5724f6349f412fc21ecc895ba9683b30e5285c6707e54c2ff.svg",
    "text_lesson_hoa09-b73d52b7954285286daf9b74cb107298208cd932750f9b72ba61849090d8e437.svg",
    "text_lesson_hoa10-8ce85c49c7da9e3d38052c0fe9d141047673f6fb9bd965fd14258a1161f6f793.svg",
    "text_lesson_hoa11-1062b044e6871a8d166b38e38889e2148c82e3c8ece39f887170357531db3c83.svg",
    "text_lesson_hoa12-75cba00eef5e8b851096c103f43613a65794142dc459ecf180709039e2443df5.svg",
    "text_lesson_hoa13-fe89fe215e68d6320598b73d37a25aabff211e6893dcc7d48af577c42b308049.svg",
    "text_lesson_hoa14-2d99206a97a2061cc446b5a5e19a973d222738af29a80f7c1febeaadb3212dde.svg",
  ],
  lbp01: [
    "aboutbackpain-1a-566d78e3e70352cf3dc0e521baab0419ebf290e56046d75e4e53772c18b7499d.svg",
    "aboutbackpain-2a-dc72f4fde1aebbd9362087d14b334503a98a513d38e5704928d17d4f67f29db4.svg",
    "aboutbackpain-3a-d8850d0e79e3384040cd6ec76d031012c5669092e47d0963fff4f3da145d9b5c.svg",
  ],
  lbp02: [
    "gettingadiagnosis-1a-dfd629c68b3b93a6ef31fdaa9370b3da7285152fda8174bbb3ecaa94a23e63ba.svg",
    "gettingadiagnosis-2a-301bce0631e8923471ba92061ce92eb2fda34fe457b0b865b8130fcdc95f8482.svg",
  ],
  lbp03: [
    "anatomyofthespine-1a-2b738201cb1d5138c8274a9199de0ea44240078f456827bd384eb7becd227db7.svg",
    "anatomyofthespine-1a-d2745e20155b3ebd7bc3a0e9050a14172e7f82a5905cee75b2225ff01085ecc0.svg",
    "anatomyofthespine-2a-deb8fe844055c527e9524225008ca525eda52eaeb4246840c27ced719ddfda98.svg",
    "anatomyofthespine-3a-7c0d3248727c9b17d5b2bb6e31f995e675e3099b85b1b56f2bb01850c290581f.svg",
    "anatomyofthespine-4a-52fc41c0314e51375a23ce2cc1fb5f5548897d8c8a16247bf289bc3a5037fb11.svg",
  ],
  lbp04: [
    "commoncausesofbackpain-1a-8d602755db53ef6affefbfd8918f7fb0df9f840114ee1aeb4cf49b9b7e5a4806.svg",
    "commoncausesofbackpain-2a-ef311787aa9bb88752cf96144fb59bf2f736e69267e1e2aaf6aaed760931a27b.svg",
    "commoncausesofbackpain-3a-553be98c2ff6de1c36ca056e7fdf9fc974601ca071eafd07c136f4a302e049cf.svg",
    "commoncausesofbackpain-3a-74a5e95c5b3752a4f47321be256a9528972bc3f84051d30c4fd8a0bef8789690.svg",
    "commoncausesofbackpain-4a-687ed5b5c6f473f7cd8fb83b51a5241b8837724a4b8439b570f571757b883d0a.svg",
    "commoncausesofbackpain-4a-e0401403752b89b4016d47fed53b542341df204771077241a64769434d83fdde.svg",
  ],
  lbp05: [
    "lesscommoncausesofbackpain-1a-ce241c2139a4ae4472ba2e0bcf28d42a18e3b521c4821460c46042b795358215.svg",
    "lesscommoncausesofbackpain-2a-81f7e7280d28ad4c4b4631ecf88091920a0f6df41ec04286a80dc42c3f0d282e.svg",
  ],
  lbp06: [
    "aboutpain-1a-b12953e2ee912d218286e0a0d531e7eb435497e8bb56c44f707a46024c1827cb.svg",
    "aboutpain-2a-36a091f0758b547b9ec9377f204c48adfa8fe9065a5c367da12b2c8b9e51f709.svg",
    "aboutpain-3a-2ab6f7eb92f93aa196f05de48d3817ed5a79ca68587939f54a71481ede3e3df1.svg",
  ],
  lbp07: [
    "painmanagement-1a-6686d5de359b67786df3995640967025bb861fa076779d6203b6cc1a53ca15b8.svg",
    "painmanagement-2a-58d86cc82946e5cbfc345d70f108e95675d32f4966abf58ac7aa6605674ce676.svg",
    "painmanagement-3a-8d20a8f4e9b1a59216729e21abb1103ffdfd0ea589b9d3be3ec0a04314589208.svg",
  ],
  lbp08: [
    "fearofmovement-1a-b2e90b88af8d4b2501cb9dcc7e30d6e0554d452a07de4cf8cbf6325a013a0c85.svg",
    "fearofmovement-2a-523a1c5f177e7eebd556b07658c6052713c4b126e68192fd2c10b4f2c48d8dbd.svg",
  ],
  lbp09: [
    "ergonomics-1a-31bc226c07fde798419e78eb81f098c2fdf724d6fc5135b2a10b64a2b4ee7837.svg",
    "ergonomics-2a-8fca8ee2a358d9c51243ce32a4d353139f9021aee81fc5aaa64cf7172dbd1f17.svg",
    "ergonomics-3a-7e8403706b744198aea4207662f1524c22012d3b9c181a735b477220b1d83692.svg",
    "ergonomics-4a-51e635906decec2325138a801433d4a4c45cdaa0f0efcdc58640730b27082b91.svg",
  ],
  lbp10: [
    "physicalactivit_backpain-1a-aa8da25d6987082b812bac989697306409fb600cb75c0451239e5335239e17c7.svg",
    "physicalactivit_backpain-2a-e1c63e29b5bb6e06e328a5aeb65132ebd67af014dc8f2eef0d7f7bed4064f5d5.svg",
  ],
  lbp11: [
    "exercise_backpain-1a-2f142e518dd2a07b84c97d5cfe4552e0d44b19d25db8c69b402c254161e04b99.svg",
    "exercise_backpain-2a-3d58c481794de804f7929af5588fb915e1654aad185868f681a7dfb686be4866.svg",
    "exercise_backpain-3a-5c382a3d23e077355b26d06524e58e83804f68618b17d5f01f30029c56c82cc7.svg",
  ],
  lbp12: [
    "othertreatmentoptions-1a-be7b84acd540c17b1f2c1249713353649033e196d45ea2728d8126ba40e5f368.svg",
    "othertreatmentoptions-2a-c3ab4381006f87e22269a0a1d03fd6c01f214560bcbfdf77fff8dece5f30bb47.svg",
    "othertreatmentoptions-3a-087c1cbf800a0583ba37366a9e510da0b1fdfc5d96f78769d194346f67061a26.svg",
  ],
  lbp13: ["aboutlowbacksurgery-1a-f1b8e8213ffff619bb1c97a19c09add72fe39dbd46699536946d1862e3d7bbac.svg"],
  lbp14: [
    "myth_facts-1a-55ffc8395a62f8c6d973e0ba6a8bd39225051f1f3e182657710590ad4e2cdb91.svg",
    "myth_facts-2a-3e10b4b046e92733c73b558b79de7a2316b71d3870d7566ee5f519e2f0b154f8.svg",
    "myth_facts-3a-f02f81c92144b8db7e8df502dafb9c2b932e1936ce23ba827d6d85ae26f409db.svg",
    "myth_facts-3a-fc8386fb3fed626fd114bc17cd0a677ec1be83c3e906a381fe74e21b2e6af3c5.svg",
    "myth_facts-4a-a02830d2866fbdefd08381f72a41f6ee36e1e416ef2c1767814f1e1c7363dacd.svg",
    "myth_facts-5a-42108be27dd9ece547c211bf428847daf2253ec995a1d40e9e0f98828467463e.svg",
  ],
  lbp15: ["sleep_pain-1a-efb880608cddd80127273efe63a8e30596e8d365d05459e71b8f1c53adbbe647.svg"],
};
export default images;
