import { BoxProps, Box } from "@material-ui/core";
import { SPACING } from "theme";
import { css, cx } from "@emotion/css";

interface Props extends BoxProps {
  gap?: string | number;
  reverse?: boolean;
  pre?: boolean;
}

export const space = (value: string | number | undefined) =>
  typeof value === "number" ? `${SPACING * value}px` : value;

export const HFlex: React.VFC<Props & BoxProps> = ({ gap, reverse, pre, className, ...rest }) => (
  <Box
    className={cx(
      css`
        display: flex;
        flex-direction: ${reverse ? "row-reverse" : "row"};
        white-space: ${pre ? "pre" : "unset"};
        & > * + * {
          ${reverse ? "margin-right" : "margin-left"}: ${space(gap)} !important;
        }
      `,
      className
    )}
    {...rest}
  />
);

export const VFlex: React.VFC<Props & BoxProps> = ({ gap, reverse, pre, className, ...rest }) => (
  <Box
    className={cx(
      css`
        display: flex;
        flex-direction: ${reverse ? "column-reverse" : "column"};
        white-space: ${pre ? "pre" : "unset"};
        & > * + * {
          ${reverse ? "margin-bottom" : "margin-top"}: ${space(gap)} !important;
        }
      `,
      className
    )}
    {...rest}
  />
);
