import api, { Form } from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "POST /patients/{patient_id}/care_plans";

export type Vars = Form<typeof OP>;

const useUploadPOC = () => {
  const patientId = useId();

  return useMutation(
    ({
      care_event_id,
      date_of_service,
      duration_days,
      file,
    }: //      provider_first_name,
    //      provider_last_name,
    //      provider_npi,
    Vars) => {
      const [doc] = file as File[];
      const data = new FormData();
      data.append("file", doc, doc.name);
      data.append("care_event_id", String(care_event_id));
      data.append("date_of_service", date_of_service);
      data.append("duration_days", String(duration_days));
      //      data.append("provider_first_name", provider_first_name);
      //      data.append("provider_last_name", provider_last_name);
      //      data.append("provider_npi", provider_npi);

      return api(OP, {
        params: [patientId],
        data,
        headers: new Headers({ Accept: "*/*" }),
      });
    }
  );
};

export default useUploadPOC;
