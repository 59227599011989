import React, { useState } from "react";
import { Button, Container } from "@material-ui/core";
import {
  CTextField,
  HFlex,
  useErrorContext,
  ProgressButton,
  DialogSuccessNotice,
  CAutocomplete,
  ErrorBox,
  Text,
  FileUpload,
  CDialog,
} from "shared";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import useUploadReferralDocument, { Vars } from "../../queries/useUploadReferral";
import { FORM_NOTIFICATION_DELAY, SPACING } from "theme";
import { delay, fullName, queryKeys } from "utils/misc";
import { css } from "@emotion/css";
import { useQueryClient } from "react-query";
import { ReferralSourceForm, ReferralSourceBody, useReferralSources, useAddReferralSource } from "shared/ValueTable";
import { useId, useMountState } from "utils/hooks";
import usePatient from "routes/patient/queries/usePatient";
import { Country } from "utils/market";

interface Props {
  onClose: () => void;
}

interface SourceFormDialog {
  isOpen: boolean;
  formDefaults?: ReferralSourceBody;
}

const UploadReferralForm: React.VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const id = useId();
  const { mutateAsync: uploadReferral, isLoading, isSuccess } = useUploadReferralDocument();
  const referralSources = useReferralSources();
  const queryClient = useQueryClient();
  const { addRecord: addReferralSource } = useAddReferralSource();
  const [sourceFormDialog, setSourceFormDialog] = useState<SourceFormDialog>({ isOpen: false });
  const getMountState = useMountState();
  const form = useForm<Vars>();
  const setError = useErrorContext();
  const { data: patient } = usePatient();

  if (!patient) return null;
  const isFrench = patient.country === Country.FR;

  const closeReferralForm = () => {
    setSourceFormDialog({ isOpen: false });
  };

  const submit = form.handleSubmit(async data => {
    try {
      await uploadReferral(data);
      queryClient.invalidateQueries(queryKeys.documents(id));
      if (!getMountState()) return;
      await delay(FORM_NOTIFICATION_DELAY);
      getMountState() && onClose();
    } catch (err) {
      setError(t`errors.could_not_upload_referral`, err);
    } finally {
      queryClient.invalidateQueries(queryKeys.documents(id));
    }
  });

  const addReferral = async (referralSource: ReferralSourceBody) => {
    form.setValue("source", referralSource.value);
    closeReferralForm();
    try {
      await addReferralSource(referralSource);
    } catch (err) {
      setSourceFormDialog({ isOpen: true, formDefaults: referralSource });
      form.setValue("source", null);
      setError(t`errors.could_not_create_referral_source`, err);
    }
  };

  if (isSuccess)
    return (
      <DialogSuccessNotice heading={t`form.success`} body={`${t`referral.upload_success`}: ${id}`} onClose={onClose} />
    );

  return (
    <Container>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <Text heading bold id="upload-referral">
            {t`referral.upload_referral`}
          </Text>
          <Text paragraph mb={5}>
            {fullName(patient)}, <Text link>{patient.id}</Text>
          </Text>
          <div className={styles.grid}>
            <CTextField size="small" name="reason" label={t`form.reason`} required className={styles.spanRow} />
            <FileUpload size="small" name="file" label={t`patients.document`} required fullWidth />
            {!isFrench && <CTextField size="small" name="doctor_id" label={t`form.doctor_id`} />}
            {!isFrench && (
              <CTextField
                fullWidth
                size="small"
                name="icd_codes"
                label={t`form.icd_codes`}
                placeholder="XXX / XXX.X"
                pattern={/[A-Z]\d\d(\.\d[A-Z]?)?/}
              />
            )}
            {!isFrench && (
              <CTextField
                size="small"
                name="provider_npi"
                label={t`form.provider_npi`}
                placeholder="XXXXXXXXXX"
                pattern={/\d{10}/}
              />
            )}
            <CTextField
              size="small"
              name="provider_first_name"
              label={t`form.provider_first_name`}
              required={isFrench}
            />
            <CTextField size="small" name="provider_last_name" label={t`form.provider_last_name`} required={isFrench} />
            <CTextField
              size="small"
              label={t`form.date_of_service`}
              type="date"
              name="date_of_physician_signature"
              required
            />
            {!isFrench && (
              <CTextField
                name="duration_days"
                size="small"
                label={t`form.duration_days`}
                type="number"
                asNumber
                inputProps={{
                  min: 1,
                  max: 100000,
                }}
                required
              />
            )}
            {!isFrench && (
              <div className={styles.spanRow}>
                <CAutocomplete
                  size="small"
                  name="source"
                  getOptionLabel={({ value }) => value ?? "NAME MISSING"}
                  loading={referralSources.isLoading}
                  noOptionsText={referralSources.isError && <ErrorBox error={referralSources.error} />}
                  label={t`form.source`}
                  options={referralSources.data ?? []}
                />
                <Text paragraph tiny link role="button" mt={0.5} onClick={() => setSourceFormDialog({ isOpen: true })}>
                  {t`form.new_referral_source`}
                </Text>
              </div>
            )}
          </div>
          <HFlex justifyContent="center" gap={2} mt={4} mb={6}>
            <Button disabled={isLoading} onClick={onClose}>
              {t`buttons.cancel`}
            </Button>
            <ProgressButton loading={isLoading}>{t`buttons.upload`}</ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
      <CDialog
        maxWidth="xs"
        fullWidth
        open={sourceFormDialog.isOpen}
        onClose={closeReferralForm}
        aria-labelledby="add-record"
      >
        <ReferralSourceForm onCancel={closeReferralForm} onAdd={addReferral} defaults={sourceFormDialog.formDefaults} />
      </CDialog>
    </Container>
  );
};

export default UploadReferralForm;

const styles = {
  grid: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${1.5 * SPACING}px;
  `,
  spanRow: css`
    grid-column: 1 / span 2;
  `,
};
