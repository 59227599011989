import React from "react";
import { Box, Switch } from "@material-ui/core";
import { CTextField, CSelect, HFlex, ProgressButton, Text, DialogSuccessNotice, useErrorContext } from "shared";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Send } from "assets";
import useInviteDoctor, { Vars } from "../../queries/useInviteDoctor";
import { delay } from "utils/misc";
import { Country, isUsMarket, TimeZone } from "utils/market";
import { FORM_NOTIFICATION_DELAY } from "theme";
import { useMountState } from "utils/hooks";
import { css } from "@emotion/css";

interface Props {
  onClose: () => void;
}

const timezonesUS = [
  { value: TimeZone.Pacific, label: "Pacific Time" },
  { value: TimeZone.Mountain, label: "Mountain Time" },
  { value: TimeZone.Central, label: "Central Time" },
  { value: TimeZone.Eastern, label: "Eastern Time" },
];

const timezonesEU = [
  { value: TimeZone.London, label: "London Time" },
  { value: TimeZone.Stockholm, label: "Stockholm Time" },
  { value: TimeZone.Berlin, label: "Berlin Time" },
  { value: TimeZone.Paris, label: "Paris Time" },
];

const countriesEU = [
  { value: Country.UK, label: "United Kingdom" },
  { value: Country.DE, label: "Germany" },
  { value: Country.FR, label: "France" },
  { value: Country.SE, label: "Sweden" },
];

const InviteDoctorForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<Vars>();
  const { t } = useTranslation();
  const setError = useErrorContext();
  const availableForPatients = form.watch("available_for_patients");
  const getMountState = useMountState();

  const { mutateAsync, isLoading, isError, data } = useInviteDoctor();

  const submit = form.handleSubmit(async fields => {
    try {
      await mutateAsync(fields);
      if (!getMountState()) return;
      await delay(FORM_NOTIFICATION_DELAY);
      if (!getMountState()) return;
      onClose();
    } catch (err) {
      setError(t`errors.could_not_send_invite`, err);
    }
  });

  if (!isError && data)
    return (
      <DialogSuccessNotice
        heading={t("patients.invite_sent")}
        body={`${t("form.invitation_successfully_sent_to")} ${data.email}`}
        onClose={onClose}
      />
    );

  return (
    <Box mx={3}>
      <Text heading bold mb={3} id="invite-doctor">
        {t("form.invite_doctor")}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <CTextField
            required
            name="email"
            autoFocus
            label={t("form.email")}
            type="email"
            margin="normal"
            size="small"
            fullWidth
          />
          <HFlex gap={2}>
            <CTextField
              fullWidth
              name="first_name"
              label={t("form.first_name")}
              margin="normal"
              size="small"
              required
            />
            <CTextField fullWidth name="last_name" label={t("form.last_name")} margin="normal" size="small" required />
          </HFlex>
          {isUsMarket ? (
            <CSelect
              name="timezone"
              label={t("form.home_timezone")}
              items={timezonesUS}
              margin="normal"
              size="small"
              required
              fullWidth
            >
              {({ label }) => <Text>{label}</Text>}
            </CSelect>
          ) : (
            <CSelect
              name="timezone"
              label={t("form.home_timezone")}
              items={timezonesEU}
              margin="normal"
              size="small"
              required
              defaultValue={timezonesEU[3].value}
              fullWidth
            >
              {({ label }) => <Text>{label}</Text>}
            </CSelect>
          )}
          {isUsMarket ? (
            <CTextField name="country" required type="hidden" noClear variant="standard" defaultValue={Country.US} />
          ) : (
            <CSelect
              name="country"
              label={t("form.country")}
              items={countriesEU}
              margin="normal"
              size="small"
              required
              fullWidth
              defaultValue={Country.FR}
            >
              {({ label }) => <Text>{label}</Text>}
            </CSelect>
          )}
          <label role="button" className={styles.label}>
            <Text small light>
              {t("form.available_for_patients")}
            </Text>
            <Text small ml="auto">
              {availableForPatients ? t("buttons.yes") : t("buttons.no")}
            </Text>
            <Switch color="primary" inputRef={form.register} name="available_for_patients" />
          </label>
          <HFlex justifyContent="center" my={6}>
            <ProgressButton loading={isLoading} endIcon={<Send width={20} />}>
              {t("buttons.send_invitation")}
            </ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
    </Box>
  );
};

export default InviteDoctorForm;

const styles = {
  label: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
};
