import api from "api";
import { useQuery } from "react-query";
import { queryKeys } from "utils/misc";

export interface UnclaimedMedicalReferral {
  id: number;
  patient_first_name: string;
  patient_last_name: string;
  patient_date_of_birth: string;
  provider_first_name: string;
  provider_last_name: string;
  duration_days: number;
  icd_codes: string[];
  date_of_physician_signature: string;
  source: string;
  created_at: string;
  updated_at: string;
  url: null | null;
}

export interface Response {
  unclaimed_medical_referrals: UnclaimedMedicalReferral[];
}

export const useUnclaimedMedicalReferrals = () => {
  return useQuery<Response, Error>(queryKeys.unclaimedMedicalReferrals(), () =>
    api.parse("GET /unclaimed_medical_referrals")
  );
};
