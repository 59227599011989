import { css } from "@emotion/css";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import { Download } from "assets";
import { useTranslation } from "react-i18next";
import { HFlex, Pane, Text, Icon, Anchor } from "shared";
import { Shadows } from "theme";
import { getDate, offsetDate } from "utils/time";
import { definitions } from "api";

type Props = definitions["plan_of_care"] & {
  deleteMode?: boolean;
  onDelete?: () => void;
};

export const POC: React.VFC<Props> = ({
  created_at,
  date_of_service,
  duration_days,
  url,
  care_event,
  deleteMode,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Pane className={styles.doc}>
      <Accordion>
        <AccordionSummary
          expandIcon={deleteMode ? <Icon role="button" error of={Close} onClick={onDelete} /> : <ExpandMore />}
        >
          <HFlex width="100%" justifyContent="space-between" alignItems="baseline">
            <Text>{t`patients.medical_records.plan_of_care`}</Text>
            <span>
              <Text small light>
                {t`common.dos`}:{" "}
              </Text>
              <Text small>{date_of_service && getDate(date_of_service)}</Text>
            </span>
            <span>
              <Text small light>
                {t`common.expires`}:{" "}
              </Text>
              <Text small>{date_of_service && offsetDate({ days: duration_days }, date_of_service)}</Text>
            </span>
          </HFlex>
        </AccordionSummary>
        <AccordionDetails>
          <Text paragraph small>
            <Text light>{t`common.created_at`}: </Text>
            {getDate(created_at)}
          </Text>
          <Text paragraph small>
            <Text light>{t`patients.document`}: </Text>
            <Anchor link href={url}>
              <Icon of={Download} />
            </Anchor>
          </Text>
          <Text paragraph small>
            <Text light>{t`form.care_event`}: </Text>
            <Text link>{care_event?.id}</Text>
          </Text>
        </AccordionDetails>
      </Accordion>
    </Pane>
  );
};

export default POC;

const styles = {
  doc: css`
    box-shadow: ${Shadows.soft};
  `,
};
