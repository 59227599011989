import api from "api";
import { useQuery } from "react-query";
import { Country } from "utils/market";
import { queryKeys } from "utils/misc";

export enum Category {
  id = "id",
  name = "name",
  phone = "phone_number",
  email = "email",
  uid = "uid",
}

export interface Vars {
  country: Country;
  category: Category;
  search: string;
}

const useListPatients = (params: Vars) => {
  const { country, category, search } = params;
  const key = queryKeys.patientSearch(params);

  return useQuery(
    key,
    async () => {
      const isUID = category === Category.uid;
      const isSE = country === Country.SE;
      const shouldOverrideUID = isUID && !isSE;
      const cat = shouldOverrideUID ? "birthday" : category;

      return api.parse("GET /patients", {
        query: {
          [cat]: search,
          country: country,
        },
      });
    },
    { enabled: !!search }
  );
};

export default useListPatients;
