import { Button, makeStyles, Modal } from "@material-ui/core";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HFlex, Text, VFlex } from "shared";
import styled from "styled-components";
import muiTheme, { Colors } from "theme";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: muiTheme.palette.background.paper,
    boxShadow: "0px 2px 7px rgba(68, 104, 147, 0.2)",
    border: `2px solid ${Colors.BACKGROUND}`,
    borderRadius: "8px",
    padding: "20px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

interface NoteModalProps {
  readonly noteContent: string;
}
const NoteModal: React.VFC<NoteModalProps> = ({ noteContent }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <NoteOpenButton noteContent={noteContent} handleOpen={handleOpen} />
      <Modal open={open} onClose={handleClose}>
        <ModalContent handleClose={handleClose} noteContent={noteContent ?? ""} />
      </Modal>
    </>
  );
};

interface NoteOpenButtonProps extends Pick<NoteModalProps, "noteContent"> {
  readonly handleOpen: () => void;
}
const NoteOpenButton: React.VFC<NoteOpenButtonProps> = ({ noteContent, handleOpen }) => {
  const { t } = useTranslation();
  if (noteContent === "null") {
    return <Text>{t("reports.note_does_not_exist")}</Text>;
  }
  return (
    <OpenNoteButton onClick={handleOpen}>
      <Text link>{t("reports.open_note")}</Text>
    </OpenNoteButton>
  );
};

const OpenNoteButton = styled(Button)`
  text-transform: none;
  padding-left: 1px;
  font-variant: normal;
`;

interface ModalContentProps extends Pick<NoteModalProps, "noteContent"> {
  readonly handleClose: () => void;
}
const ModalContent: React.VFC<ModalContentProps> = forwardRef(({ noteContent, handleClose }, _ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const splitContent = noteContent.split("\n");
  return (
    <div className={classes.paper}>
      <Text heading>{t("reports.note")}</Text>
      {splitContent.map((content: string, index: number) => (
        <ModalTextContent content={content} key={`${content}${index}`} />
      ))}
      <VFlex>
        <CloseNoteButton onClick={handleClose}>
          <Text>{t("reports.close_note")}</Text>
        </CloseNoteButton>
      </VFlex>
    </div>
  );
});

const CloseNoteButton = styled(Button)`
  margin-top: 10px;
`;

interface ModalTextContentProps {
  readonly content: string;
}
const ModalTextContent: React.VFC<ModalTextContentProps> = props => {
  const [field, value] = props.content.split(":");
  const updatedField = field !== "" ? `${field}: ` : field;
  return (
    <HFlex>
      <Text mr={0.5} bold>
        {updatedField}
      </Text>
      <Text>{value}</Text>
    </HFlex>
  );
};

export default NoteModal;
