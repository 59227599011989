import { Result } from "api";
import React from "react";
import { WeekForm } from "../WeekForm/WeekForm";

interface Props {
  edit: boolean;
  weeklyConfig?: Result<"GET /care_event_configurations/{id}">["weekly_config"];
}

const ConfigurationOther: React.VFC<Props> = ({ weeklyConfig, edit }) => {
  if (!weeklyConfig) return null;
  return (
    <div>
      {Object.keys(weeklyConfig)
        .filter(key => !key.startsWith("week"))
        .map((name: string) => (
          // @ts-ignore
          <WeekForm key={name} value={weeklyConfig[name]} name={name} edit={edit} />
        ))}
    </div>
  );
};

export default ConfigurationOther;
