import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "PATCH /therapists/{id}";

export type Vars = {
  health_care_provider_name?: string;
  health_care_provider_new?: boolean;
  reset_to_market_default?: boolean;
};

const useChangeHCP = () => {
  const id = useId();

  return useMutation(({ health_care_provider_name, health_care_provider_new, reset_to_market_default }: Vars) =>
    api.parse(OP, {
      params: [id],
      data: {
        health_care_provider: {
          name: health_care_provider_name,
          new: health_care_provider_new,
          reset_to_market_default,
        },
      },
    })
  );
};

export default useChangeHCP;
