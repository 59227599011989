import React, { useState } from "react";
import { BoxButton, CDialog, Icon } from "shared";
import { Send } from "assets";
import InvitePatientForm from "./InvitePatientForm";
import { useTranslation } from "react-i18next";

const InvitePatient: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <>
      <BoxButton startIcon={<Icon of={Send} />} onClick={openDialog}>
        {t("patients.invite_a_patient")}
      </BoxButton>

      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="invite-patient">
        <InvitePatientForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};

export default InvitePatient;
