import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { Person, CheckCircle } from "@material-ui/icons";
import { Text, Icon } from "shared";

interface Props {
  label: string;
  buttonLabel: string;
  active: boolean;
  reactivationEnabled: boolean;
  reactivationButtonLabel?: string;
  onClickActivate: () => void;
}

const TreatmentActivation: React.VFC<Props> = ({
  label,
  buttonLabel,
  active,
  reactivationEnabled,
  reactivationButtonLabel,
  onClickActivate,
}) => (
  <Container>
    <span>
      <Icon size={1.5} of={Person} />
      <Text marginX="16px" large>
        {label}
      </Text>
    </span>
    <span>
      {(!active || reactivationEnabled) && (
        <Button onClick={onClickActivate}>{active ? reactivationButtonLabel : buttonLabel}</Button>
      )}
      {active && <Icon of={CheckCircle} success />}
    </span>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 8px;
  height: 80px;
  padding: 12px 24px;
`;

export default TreatmentActivation;
