import React from "react";
import { Button, Container } from "@material-ui/core";
import { VFlex, Text, ProgressButton } from "shared";
import { useTranslation } from "react-i18next";

import { useDeleteUnclaimedMedicalReferral } from "./queries/useDeleteUnclaimedMedicalReferral";
import { UnclaimedMedicalReferral } from "./queries/useUnclaimedMedicalReferrals";

interface Props {
  selectedReferral: UnclaimedMedicalReferral | null;
  onClose: () => void;
}

export const ConfirmDeletion: React.VFC<Props> = ({ selectedReferral, onClose }) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useDeleteUnclaimedMedicalReferral();

  const deleteUnclaimedReferral = async () => {
    if (selectedReferral?.id) {
      await mutate(selectedReferral.id);
      onClose();
    }
  };

  return (
    <Container>
      <Text heading bold center id="reopen-account">
        {t("form.delete_unclaimed_referral")}
      </Text>
      <Text center paragraph mb={3}>
        {selectedReferral?.id}, {selectedReferral?.patient_first_name} {selectedReferral?.patient_last_name}
      </Text>
      <VFlex alignItems="center" gap={2} my={5}>
        <ProgressButton loading={isLoading} onClick={deleteUnclaimedReferral}>
          {t("buttons.delete")}
        </ProgressButton>
        <Button disabled={isLoading} onClick={onClose}>
          {t("buttons.cancel")}
        </Button>
      </VFlex>
    </Container>
  );
};
